import React from 'react';
import {createUseStyles} from 'react-jss';
import TableUncontrolled from 'components/List/TableUncontrolled';
import {getFieldsFrom} from 'utils/helpers/list';
import CellStatus from 'components/Cells/CellStatus';
import CellLock from '../CellLock';
import CellSite from '../CellSite';
import CellBattery from '../CellBattery';
import CellTrackerBattery from '../CellTrackerBattery';
import CellId from '../CellId';
import CellCopyLink from '../CellCopyLink';
import {
    FILTER_SORT_MAP,
    LABEL_MAP,
} from '../../utils/constants';
import styles from './styles';
import {VEHICLE_MODAL} from 'utils/constants/modals';
import {usePermissions} from 'react-admin'
import CellHomeStation from '../CellHomeStation';
import CellLastUsed from '../CellLastUsed';
import CellReservationStatus from "../CellReservationStatus";

const useStyles = createUseStyles(styles);

const ListVehicles = ({
                          items,
                          onView,
                          onDelete,
                          onBatteryView,
                          refreshData,
                          getBikesPagination,
                          pageSize,
                          pageCount,
                          pageIndex,
                          totalCount,
                          licensePlatesFromDb,
                          lastSearchParam
                      }) => {
    const classes = useStyles();
    const hiddenColumns = React.useMemo(() => ['license_plate'], []);
    const fields = React.useMemo(() => getFieldsFrom(items, FILTER_SORT_MAP, LABEL_MAP), [items]);
    function filterInMultipleSelectedValues(rows, id, filterValue) {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return filterValue.includes(rowValue);
        })
      }
    
    const columns = React.useMemo(
        () => [
            {
                label: '',
                accessor: 'id',
                disableGlobalFilter: true,
                Cell: ({row}) => <CellId
                    row={row}
                    onClick={onView}
                />,
            },
            {
                label: "License Plate",
                accessor: 'license_plate',
                filter: filterInMultipleSelectedValues,
            },
            {
                label: 'State',
                accessor: 'status',
                disableGlobalFilter: true,
                Cell: ({row}) => <CellStatus type={row.original.status}/>,
                filter: filterInMultipleSelectedValues,
            },
            {
                label: 'Pile State',
                accessor: 'is_inside_pile',
                disableGlobalFilter: true,
                Cell: ({row}) => <CellStatus type={row.original.is_inside_pile}/>,
                filter: filterInMultipleSelectedValues,
            },
            {
                label: 'Live Station',
                accessor: 'site.address',
                disableGlobalFilter: false,
                Cell: ({row}) => <CellSite row={row}/>,
            },
            {
                label: 'Home Station',
                accessor: 'homeStation.address',
                disableGlobalFilter: false,
                Cell: ({row}) => <CellHomeStation row={row}/>,
            },
            {
                label: 'Smart Lock',
                accessor: 'lock.id',
                disableGlobalFilter: true,
                Cell: ({row}) => <CellLock row={row}/>,
            },
            {
                label: 'Bike',
                accessor: 'energy_level',
                disableGlobalFilter: true,
                Cell: ({row}) => <CellBattery
                    row={row}
                    onClick={onBatteryView}
                />,
                filter:'between',
            },
            {
                label: 'Tracker',
                accessor: 'tracker_battery',
                disableGlobalFilter: true,
                Cell: ({row}) => <CellTrackerBattery
                    row={row}
                    onClick={onBatteryView}
                />,
                filter:'between'
            },
            {
                label: 'Link',
                accessor: 'link',
                Cell: ({row}) => <CellCopyLink row={row}/>,
            },
            {
                label: 'Last Used',
                accessor: 'last_used',
                Cell: ({row}) => <CellLastUsed row={row}/>,
            },
            {
                label: 'Reservation Status',
                accessor: 'wunder_reservation_status',
                Cell: ({row}) => <CellReservationStatus type={row.original.wunder_reservation_status}/>,
                disableGlobalFilter: true,
                filter: filterInMultipleSelectedValues,
            },
        ],
        [],
    );
    const {permissions} = usePermissions()

    return (
        <TableUncontrolled
            isVisibleSort
            isVisibleFilter
            isvisibleSync={permissions && permissions.indexOf('sync-sites') > -1}
            columns={columns}
            hiddenColumns={hiddenColumns}
            caption="All Vehicles"
            createCaption="Add Vehicles"
            entityType="vehicles"
            totalCount={totalCount}
            onDelete={onDelete}
            modalType={VEHICLE_MODAL}
            onView={onView}
            fields={fields}
            rowsPerPage={pageSize}
            data={items}
            isCreatable={permissions && permissions.indexOf('create-vehicles') > -1}
            isEditable={permissions && permissions.indexOf('update-vehicles') > -1}
            isRemovable={permissions && permissions.indexOf('delete-vehicles') > -1}
            isViewable={permissions && permissions.indexOf('view-vehicles') > -1}
            refreshData={refreshData}
            fetchData={getBikesPagination}
            pageCount={pageCount}
            pageIndex={pageIndex}
            licensePlatesFromDb={licensePlatesFromDb}
            lastSearchParam={lastSearchParam}
        />
    );
};

export default ListVehicles;
