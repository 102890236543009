import React from 'react';
import { useDispatch } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { updateRouter } from 'utils/helpers/request/api/routers';
import { setLoader } from 'store/actions/common';
import { parseDataToBackend } from 'utils/helpers/router';

const useUpdate = () => {
  const dispatch = useDispatch();

  const updateItem = async function(data) {
    dispatch(setLoader(true))

    try {
      await updateRouter(parseDataToBackend(data));
      NotificationManager.success('Successfully updated!');
      dispatch(setLoader(false));
    } catch (e) {
      dispatch(setLoader(false));
      throw Error();
    }
  };

  return {
    updateItem,
  };
};

export {
  useUpdate,
};
