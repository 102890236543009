import { getOptions } from "pages/Roles/utils/helpers";
import { useState } from "react";
import { NotificationManager } from "react-notifications";
import { useDispatch } from "react-redux";
import { setLoader } from "store/actions/common";
import { parseFromBackend } from "pages/Roles/utils/helpers";
import { addRole, deleteRole, getRole, getRoles, updateRole } from "utils/helpers/request/api/auth";

export const useFetchItems = () => {
  const [items, setItems] = useState([]);
  const dispatch = useDispatch();

  const fetch = async () => {
    dispatch(setLoader(true));

    try {
      const { data: roles } = await getRoles();

      setItems(roles);
    } catch (error) {
      console.log("Something unexpected happened. Error: ", error);
    }

    dispatch(setLoader(false));
  };

  return {
    items,
    fetch,
  };
};

export const useOptions = () => {
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();

  const fetchOptions = async () => {
    dispatch(setLoader(true));

    try {
      const options = getOptions();

      setOptions(options);
    } catch (error) {
      console.log("Something unexpected happened. Error: ", error);
    }

    dispatch(setLoader(false));
  };

  return {
    options,
    fetchOptions,
  };
};

export const useCreateItem = () => {
  const dispatch = useDispatch();

  const createItem = async (data) => {
    dispatch(setLoader(true));

    try {
      await addRole(data);
      NotificationManager.success("Successfully added!");
    } catch (e) {}

    dispatch(setLoader(false));
  };

  return {
    createItem,
  };
};

export const useUpdateItem = () => {
  const dispatch = useDispatch();

  const updateItem = async (data) => {
    dispatch(setLoader(true));

    try {
      await updateRole(data);
      NotificationManager.success("Successfully updated!");
    } catch (e) {}

    dispatch(setLoader(false));
  };

  return {
    updateItem,
  };
};

export const useFetchItem = (id) => {
  const [item, setItem] = useState({
    name: "",
    permissions: "",
  });
  const dispatch = useDispatch();

  const fetch = async () => {
    dispatch(setLoader(true));

    try {
      const { data: item } = await getRole(id);

      const role = parseFromBackend(item)

      setItem(role);
    } catch (e) {
      console.log('error occurred', e)
    }

    dispatch(setLoader(false));
  };
  return {
    item,
    fetch,
  };
};

export const useDeleteItem = () => {
  const dispatch = useDispatch();

  const deleteItem = async (id) => {
    dispatch(setLoader(true));

    try {
      await deleteRole(id);
      NotificationManager.success("Successfully removed!");
    } catch (e) {
      NotificationManager.error(e.response ? e.response.data : null);
    }

    dispatch(setLoader(false));
  };

  return {
    deleteItem,
  };
};

export const useDelete = () => {
  const dispatch = useDispatch();

  const deleteItems = async (ids) => {
    dispatch(setLoader(true));
    try {
      for (const id of ids) {
        await deleteRole(id);
      }
    } catch (error) {
      console.log("Something unexpected happened. Error: ", error);
    }
  };

  return {
    deleteItems,
  };
};
