import React from 'react';
import { Layout } from 'react-admin';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';
import SigoAppBar from 'components/SigoAppBar';
import AppFooter from 'components/AppFooter';
import AppLoader from 'components/AppLoader';
import Empty from 'components/Empty';

const SigoLayout = props => {
  return (
    <>
      <Layout
        {...props}
        sidebar={Empty}
        appBar={SigoAppBar}
      />
      <AppLoader />
      <NotificationContainer />
      <AppFooter />
    </>
  )
};

export default SigoLayout;
