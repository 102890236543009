import { parseFromBackend } from "pages/DiscountManagement/utils/helpers";
import { useState } from "react";
import { NotificationManager } from "react-notifications";
import { useDispatch } from "react-redux";
import { setLoader } from "store/actions/common";
import {
  addDiscount, deleteDiscount, getDiscount,
  getDiscounts, updateDiscount
} from "utils/helpers/request/api/discounts";

export const useFetchItems = () => {
  const [items, setItems] = useState([]);
  const dispatch = useDispatch();

  const fetch = async () => {
    dispatch(setLoader(true));

    try {
      const { data: discounts } = await getDiscounts();

      setItems(discounts);
    } catch (error) {
      console.log("Something unexpected happened. Error: ", error);
    }

    dispatch(setLoader(false));
  };

  return {
    items,
    fetch,
  };
};

export const useDelete = () => {
  const dispatch = useDispatch();

  const deleteItems = async (ids) => {
    dispatch(setLoader(true));
    try {
      for (const id of ids) {
        await deleteDiscount(id);
      }
    } catch (error) {
      console.log("Something unexpected happened. Error: ", error);
    }
  };

  return {
    deleteItems,
  };
};

export const useDeleteItem = () => {
  const dispatch = useDispatch();

  const deleteItem = async (id) => {
    dispatch(setLoader(true));

    try {
      await deleteDiscount(id);
      NotificationManager.success("Successfully removed!");
    } catch (e) {}

    dispatch(setLoader(false));
  };

  return {
    deleteItem,
  };
};

export const useUpdateItem = () => {
  const dispatch = useDispatch();

  const updateItem = async (data) => {
    dispatch(setLoader(true));

    try {
      await updateDiscount(data);
      NotificationManager.success("Successfully updated!");
    } catch (e) {}

    dispatch(setLoader(false));
  };

  return {
    updateItem,
  };
};

export const useFetchItem = (id) => {
  const [item, setItem] = useState({
    discountId: "",
    discount: "",
    name: "",
    frequency: "",
    partnerId: "",
    stationGroupId: "",
    emailDomains: "",
    parkingTranslationKey: "",
    timingTranslationKey: ""
  });
  const dispatch = useDispatch();

  const fetch = async () => {
    dispatch(setLoader(true));

    try {
      const { data: item } = await getDiscount(id);

      setItem(parseFromBackend(item));
    } catch (e) {}

    dispatch(setLoader(false));
  };
  return {
    item,
    fetch,
  };
};

export const useCreateItem = () => {
  const dispatch = useDispatch();

  const createItem = async (data) => {
    dispatch(setLoader(true));

    try {
      await addDiscount(data);
      NotificationManager.success("Successfully added!");
    } catch (e) {}

    dispatch(setLoader(false));
  };

  return {
    createItem,
  };
};
