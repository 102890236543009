import React from 'react';
import { createUseStyles } from 'react-jss';
import { ReactComponent as ImageLogo } from './images/logo.svg';
import styles from './styles';

const useStyles = createUseStyles(styles);

const Logo = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <ImageLogo style={styles.image} />
    </div>
  )
};

export default Logo;
