import React from 'react';
// import ButtonDefault from 'components/Buttons/ButtonDefault';
import { useTrigger } from './utils/hooks';
import Button from "@material-ui/core/Button";
import ReplayIcon from '@material-ui/icons/Replay';
import { usePermissions } from "react-admin";
/**
 * Component that triggers restart site.
 * @param {id} id 
 * @returns Restart site button
 */
const ButtonSiteRestartV2 = ({ id ,classStyle,setModalData}) => {
  const { trigger } = useTrigger(id);
  const { permissions } = usePermissions();


  const handleModalApprove = (title, body, action) => {
    setModalData({
      title,
      body,
      onApprove: () => {
        action();
        setModalData(null);
      },
    });
  };
  
  return (
    <Button 
    className={classStyle} 
    variant="contained"
    disabled={permissions && permissions.indexOf("wifi-master-restart") > -1 ? false: true}
    onClick={() =>
                    handleModalApprove(
                      "Restart Site",
                      "Are you sure you want to continue?",
                      trigger
                    )
                  }
    >
      <ReplayIcon/>Restart
    </Button>
  )
};

export default ButtonSiteRestartV2;
