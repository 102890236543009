export default {
  wrapper: {
    position: "relative",
    "& .MuiSelect-root.MuiSelect-select": {
      minWidth: 150,
    },
  },
  fullWidth: {
    maxWidth: "100%",
  },
  permissionsContainer: {
    maxHeight: "200px",
    overflowY: "scroll",
    border: "1px solid #dfdfdf",
    padding: "10px",
  },
};
