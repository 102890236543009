import Typography from "@material-ui/core/Typography";
import AppFormGap from "components/AppFormGap";
import TextInputForm from "components/Inputs/TextInputForm";
import React from "react";
import { useForm } from "react-final-form";
import { createUseStyles } from "react-jss";
import { requiredValidation } from "utils/helpers/validations";
import styles from "./styles";

const useStyles = createUseStyles(styles);

const FormContentViewable = ({ operation, item }) => {
  const classes = useStyles();
  const form = useForm();

  React.useEffect(() => {
    if (!!item.id) {
      form.reset(item);
    }
  }, [item]);

  return (
    <div className={classes.wrapper}>
      <Typography variant="h5">Router</Typography>
      <AppFormGap />
      <TextInputForm
        name="serial_number"
        label="Serial Number"
        fieldValidation={requiredValidation}
        disabled
      />
      <AppFormGap />
      <TextInputForm
        name="mac_address"
        label="MAC Address"
        fieldValidation={requiredValidation}
        disabled
      />
    </div>
  );
};

export default FormContentViewable;
