const NAVIGATION_LEFT = [
  {
    id: 'sites',
    title: 'Sites',
  },
  {
    id: 'vehicles',
    title: 'Vehicles',
  },

];

const NAVIGATION_LEFT_MORE = [
  {
    id: 'roles',
    title: 'Roles',
  },
  {
    id: 'users',
    title: 'Users',
  },
  {
    id: 'batteries',
    title: 'Batteries',
  },
  {
    id: 'sim-cards',
    title: 'SIM Cards',
  },
  {
    id: 'routers',
    title: 'Routers',
  },
  {
    id: 'locks',
    title: 'Locks',
  },
  {
    id: 'cities',
    title: 'Cities',
  },
  {
    id: 'pile-types',
    title: 'Pile Types',
  },
  {
    id: 'piles',
    title: 'Piles',
  },
  {
    id: 'connectivity',
    title: 'Connectivity',
  },
  {
    id: 'user-groups',
    title: 'User Groups',
  },
  {
    id: 'discount-management',
    title: 'Discount Management',
  },
  {
    id: 'station-groups',
    title: 'Station Groups',
  }, 
  {
    id: 'partners',
    title: 'Partners'
  },
  {
    id: 'log-viewer',
    title: 'Log Viewer'
  },
  {
    id: 'admin-activities',
    title: 'User Logs'
  },
  {
    id: 'firmware-mgmt',
    title:'Firmware Management'
  },
  {
    id: 'bulk-ota-site',
    title:'Bulk OTA Firmware Updates Site'
  },
  {
    id: 'bulk-ota-track',
    title:'Track OTA Site Request'
  }

];

const NAVIGATION_RIGHT = [
  {
    id: 'settings',
    title: 'Settings',
  },
  {
    id: 'logout',
    title: 'Logout',
  },
];

export {
  NAVIGATION_LEFT,
  NAVIGATION_LEFT_MORE,
  NAVIGATION_RIGHT,
};
