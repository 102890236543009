import React from 'react';
import { createUseStyles } from 'react-jss';
import noop from 'lodash/noop';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import styles from './styles';

const useStyles = createUseStyles(styles);

const DatePicker = ({
  label,
  value = new Date(),
  onChange = noop,
}) => {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        margin="normal"
        label="Date picker dialog"
        format="yyyy/MM/dd"
        value={value}
        onChange={onChange}
      />
    </MuiPickersUtilsProvider>
  )
};

export default DatePicker;
