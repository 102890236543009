import { getBatteries } from 'utils/helpers/request/api/batteries';
import { useFetchItemsWrapper } from 'utils/hooks/fetch';

const useFetchItems = () => {
  return useFetchItemsWrapper(getBatteries);
};

export {
  useFetchItems,
};
