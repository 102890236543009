import FilterNoneIcon from "@material-ui/icons/FilterNone";
import CloseIcon from "@material-ui/icons/Close";
import AppFormGap from "components/AppFormGap";
import ButtonDefault from "components/Buttons/ButtonDefault";
import { useTrigger } from "components/Buttons/ButtonSiteRestart/utils/hooks";
import CellStatus from "components/Cells/CellStatus";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import React, { useState } from "react";
import { usePermissions } from "react-admin";
import { createUseStyles } from "react-jss";
import { NotificationManager } from "react-notifications";
import { sendSMS } from "utils/helpers/request/api/routers";
import styles from "./styles";

const useStyles = createUseStyles(styles);

const StationInfoGeneral = ({ item, hideSiteInfo = false, onClose }) => {
  const { permissions } = usePermissions();
  const classes = useStyles();
  const [copied, setCopied] = useState(false);
  const { trigger } = useTrigger(item.id);

  const handleCopyViewLink = async () => {
    let baseUrl = window.location.origin;
    let viewUrl = `${baseUrl}/#/sites/view/${item.id}`;
    await navigator.clipboard.writeText(viewUrl);
    setCopied(true);
  };

  const [modalData, setModalData] = React.useState(null);

  const handleRejectConfirmation = React.useCallback(() => {
    setModalData(null);
  }, []);

  const handleModalApprove = (title, body, action) => {
    setModalData({
      title,
      body,
      onApprove: () => {
        action();
        setModalData(null);
      },
    });
  };

  const rebootRouter = async () => {
    try {
      const iccid = item?.sim_card?.name;
      if (iccid) {
        const res = await sendSMS(iccid, {
          payload: `fuvpac-gohco9-bocQyw reboot`,
        });
        console.log(res);
        NotificationManager.success("sms send successfully");
      }
    } catch (error) {
      console.log(error);
      NotificationManager.error("Rebooting router failed");
    }
  };

  return (
    <div className={classes.wrapper}>
      {hideSiteInfo === true && (
        <div className={[classes.wrapperHeader, classes.wrapperInfo].join(" ")}>
          <div className={[classes.wrapperHeader, classes.wrapperInfo].join(" ")}>
            {item?.address && <div className={classes.address}>{item.address}</div> }
            {item?.id && <CellStatus type={item.status} />}
            {item?.id && (<div
              onClick={handleCopyViewLink}
              className={copied ? classes.copiedIcon : classes.copyIcon}
            >
              <FilterNoneIcon titleAccess="Copy View Link" />
              <div className={classes.copyLinkText}>
                {copied ? "View Link Copied!" : ""}
              </div>
            </div>)}
          </div>
          <div>
            <CloseIcon
              onClick={() => onClose()}
              className={classes.closeIcon}
            />
          </div>
        </div>
      )}

      {hideSiteInfo == false && (
        <div>
          <AppFormGap />
          {/* <CellReservationStatus type={item.status} /> */}
          <AppFormGap />
          <div className={classes.wrapperInfo}>ID: {item.id}</div>
          <AppFormGap />
          <div className={classes.wrapperInfo}>Version: {item.version}</div>
          <AppFormGap />
          <div className={classes.wrapperHeader}>
            {permissions && permissions.indexOf("wifi-master-restart") > -1 ? (
              <div>
                <ButtonDefault
                  onClick={() =>
                    handleModalApprove(
                      "Restart Site",
                      "Are you sure you want to continue?",
                      trigger
                    )
                  }
                >
                  {" "}
                  Restart{" "}
                </ButtonDefault>
              </div>
            ) : (
              <></>
            )}
            {permissions && permissions.indexOf("router-restart") > -1 ? (
              <div
                style={
                  item?.sim_card?.name === "" ? { cursor: "not-allowed" } : {}
                }
                title={
                  item?.sim_card?.name === ""
                    ? "This Site does not have any Sim Card details"
                    : ""
                }
              >
                <ButtonDefault
                  onClick={() =>
                    handleModalApprove(
                      "Reboot Router",
                      "Are you sure you want to continue?",
                      rebootRouter
                    )
                  }
                  disabled={item?.sim_card?.name === ""}
                >
                  Reboot Router
                </ButtonDefault>
              </div>
            ) : (
              <></>
            )}
          </div>
          <AppFormGap />
        </div>
      )}

      <AppFormGap />
      {!!modalData && (
        <ConfirmationModal {...modalData} onReject={handleRejectConfirmation} />
      )}
    </div>
  );
};

export default StationInfoGeneral;
