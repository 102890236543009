import React from 'react';
import { createUseStyles } from 'react-jss';
import Modal from '@material-ui/core/Modal';
import styles from 'components/Modals/AppModal/styles';

const useStyles = createUseStyles(styles);

const AppModal = ({
  subModal,
  onClose,
  children,
}) => {
  const classes = useStyles();

  React.useEffect(() => {
    const rootElement = document.getElementsByClassName('layout');
    
    if (!subModal) {
      rootElement[0].style = 'filter: blur(8px);-webkit-filter: blur(8px);';
    }
    
    return () => {
      if (!subModal) {
        rootElement[0].style = '';
      }
    };
  }, []);

  return (
    <Modal
      open
      onClose={onClose}
      style={styles.wrapper}
    >
      <div className={classes.wrapperInner}>
        {children}
      </div>
    </Modal>
  )
};

export default AppModal;
