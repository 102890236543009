import {
  COLOR_PRIMARY_BACKGROUND,
  COLOR_DEFAULT_TEXT,
} from 'utils/constants/colors';

export default {
  wrapper: {},
  badge: {
    backgroundColor: `${COLOR_PRIMARY_BACKGROUND} !important`,
    color: `${COLOR_DEFAULT_TEXT} !important`,
  },
};
