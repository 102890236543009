import React from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import LinkView from 'components/Links/LinkView';
import { setModalData } from 'store/actions/common';
import { SITE_MODAL } from 'utils/constants/modals';
import styles from './styles';

const useStyles = createUseStyles(styles);

const LinkSite = ({ id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleClick = React.useCallback(() => {
    dispatch(setModalData({ type: SITE_MODAL, id }));
  }, []);
  
  return (
    <LinkView onClick={handleClick}>
      Site
    </LinkView>
  )
};

export default LinkSite;
