import React from 'react';
import { createUseStyles } from 'react-jss';
import CellView from 'components/Cells/CellView';
import BatteryIcon from 'components/BatteryIcon';

import styles from './styles';

const useStyles = createUseStyles(styles);

const CellBattery = ({
  id,
  level = 0,
}) => {
  const classes = useStyles();
  const handleClick = React.useCallback(e => {
    return;
  }, [ id ]);
  
  return (
    <CellView onClick={handleClick}>
      <div className={classes.wrapperContent}>
        <BatteryIcon level={level} />
        <div className={classes.wrapperLabel}>
          <div className={classes.label}>
            {/* Tracker Battery */}
          </div>
          <div className={classes.level}>
            {level}
          </div>
        </div>
      </div>
    </CellView>
  );
};

export default CellBattery;
