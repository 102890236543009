import React from 'react';
import { createUseStyles } from 'react-jss';
import { useField } from 'react-final-form';
import TextInput from 'components/Inputs/TextInput';
import styles from './styles';

const useStyles = createUseStyles(styles);

const TextInputForm = ({
  params,
  paramsInputProps,
  label,
  name,
  type,
  fieldValidation,
  disabled,
  initialValue,
  onChange: onChangeArg,
  variant = 'outlined',
  required
}) => {
  const classes = useStyles();
  const {
    input: { onBlur, onChange, value, ...rest },
    meta: { touched, error }
  } = useField(name, { validate: fieldValidation, initialValue });
  const paramsField = React.useMemo(() => ({
    ...rest,
    ...params,
    disabled,
    value,
    type,
    onBlur: e => onBlur(e),
    onChange: e => {
      onChangeArg && onChangeArg(e.target.value);
      onChange(e.target.value);
    },
  }), [ params, value, onChangeArg, onChange ]);

  return (
    <TextInput
      params={paramsField}
      label={label}
      variant={variant}
      error={touched && error}
      helperText={error}
      paramsInputProps={paramsInputProps}
      required={required}
    />
  )
};

export default TextInputForm;
