import React from 'react';
import { createUseStyles } from 'react-jss';
import AsyncAutocompleteForm from 'components/Inputs/AsyncAutocompleteForm';
import { asyncRequestForRouters } from 'utils/helpers/form';
import RouterModal from 'components/Modals/RouterModal';
import { useCreateRouter } from 'utils/hooks/create';
import styles from './styles';

const useStyles = createUseStyles(styles);

const AutocompleteRouter = ({
  form,
  disabled,
  filterFn,
}) => {
  const classes = useStyles();
  const { createItem } = useCreateRouter();
  const [isVisibleModal, setIsVisibleModal] = React.useState();
  const asyncRequest = React.useMemo(() => {
    return function () {
      return asyncRequestForRouters(filterFn);
    };
  }, [filterFn]);
  const [modalInitialData, setModalInitialData] = React.useState(null);
  const showModal = React.useCallback(() => {
    const formState = form.getState();

    setIsVisibleModal(true);
    setModalInitialData({ serial_number: formState.values.router.name });
  }, [form]);
  const hideModal = React.useCallback(() => {
    form.change('router', { id: null, name: '' });
    setIsVisibleModal(false);
  }, []);
  const handleCreate = React.useCallback(({ values }) => {
    createItem(values)
      .then(newId => {
        form.change('router', { id: newId, name: values.serial_number });
        setIsVisibleModal(false);
      });
  }, [form]);

  return (
    <div className={classes.wrapper}>
      <AsyncAutocompleteForm
        label="Router Serial Number"
        asyncRequestFn={asyncRequest}
        onAddClick={showModal}
        disabled={disabled}
        name="router"
        initAsync
      />
      {isVisibleModal && modalInitialData && (
        <RouterModal
          modalInitialData={modalInitialData}
          onCreate={handleCreate}
          onClose={hideModal}
        />
      )}
    </div>
  )
};

export default AutocompleteRouter;
