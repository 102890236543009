import { getColorForStatus } from 'utils/helpers/colorGetters';
import { COLOR_SECONDARY } from 'utils/constants/colors';

export default {
  wrapper: {
    position: ({ position }) => position,
    right: 0,
    backgroundColor: ({ type }) => getColorForStatus(type),
    color: COLOR_SECONDARY,
    borderRadius: 4,
    padding: '6px 8px',
    display: 'inline',
    fontSize: 14,
  },
};
