import React from "react";
import { createUseStyles } from "react-jss";
import Definition from "components/Definition";
import AppFormGap from "components/AppFormGap";
import styles from "./styles";
import moment from 'moment-timezone';
const useStyles = createUseStyles(styles);

const ItsMyBikeInfo = ({ lat,lon,sampledAt,trackerId }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>

      <AppFormGap />
      <Definition term="Tracker ID" value={trackerId} externalLink={`https://web.itsmybike.cloud/device/${trackerId}`}/>
      <AppFormGap />
      <Definition term="Latitude" value={lat||'NA'} />
      <AppFormGap />
      <Definition term="Longitude" value={lon||'NA'} />
      <AppFormGap />
      <Definition term="Last Synced At" value= {moment(sampledAt).tz('Europe/Berlin').format('DD/MM/YYYY HH:mm:ss')} />
    </div>
  );
};

export default ItsMyBikeInfo;
