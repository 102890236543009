export default {
    wrapper: {
      textAlign: 'center',
      padding: '80px 0',
    },
    title: {
      margin: '50px',
      color: 'gray'
    },
    info: {
      margin: '20px'
    },
    error: {
      margin: '0',
      fontSize: '1rem',
      marginTop: '3px',
      color:'#f44336'
    },
    success: {
      margin: '0',
      fontSize: '1rem',
      marginTop: '3px',
      color:'#00cc77',
      fontWeight: 'bold'
    }
  };
  