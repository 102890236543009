import React from 'react';
import { createUseStyles } from 'react-jss';
import styles from './styles';
import AppMap from 'components/AppMap';
import { getColorForStatus } from 'utils/helpers/colorGetters';

const useStyles = createUseStyles(styles);

const BikeLocation = ({ item }) => {
  const classes = useStyles();
  const bikeState = React.useMemo(() => JSON.parse((item && item.state) ? item.state : "{}"), [ item ]);
  const itsMyBikeState = React.useMemo(() => (item && item.tracker_position) ? item.tracker_position : {}, [ item ]);
  const markers = React.useMemo(() => {
    if (!bikeState.lat) return [];

    let geoJson = [
      {
        color: getColorForStatus(item.status),
        lat: bikeState?.lat,
        lon: bikeState?.lon,
      },
    ];
    if (Object.keys(itsMyBikeState).length > 0 && itsMyBikeState.lat && itsMyBikeState.lon) {
      geoJson.push({
        color: getColorForStatus("ACTIVE"),
        lat: itsMyBikeState?.lat,
        lon: itsMyBikeState?.lon,
        type: "itsmybike",
      });
    }

    return geoJson;
  }, [ item, bikeState ]);

  return (
    <div className={classes.wrapper}>
      <AppMap
        lat={bikeState.lat}
        lon={bikeState.lon}
        markers={markers}
      />
    </div>
  )
};

export default BikeLocation;
