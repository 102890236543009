import React from "react";
import { Grid, Button } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function SelectSitesStep(props) {
  // state
  const [componentType, setComponentType] = React.useState(
    props.selectedComponent
  );
  const [firmwareVersion, setFirmwareVersion] = React.useState(
    props.selectedComponentVersion
  );

  const handleChangeComponentType = (event) => {
    const {
      target: { value },
    } = event;

    setComponentType(value);
    updateVOptions(value);
  };

  const updateVOptions = (value) => {
    switch (value) {
      case "msv":
        props.setVOptions(props.msvOptions);
        break;
      case "sdsv":
        props.setVOptions(props.sdsvOptions);
        break;
      case "rsv":
        props.setVOptions(props.rsvOptions);
        break;
      case "psv":
        props.setVOptions(props.psvOptions);
        break;
      case "dsv":
        props.setVOptions(props.dsvOptions);
        break;
      case "esv":
        props.setVOptions(props.esvOptions);
        break;
      default:
        props.setVOptions([]);
        break;
    }
  };

  const handleChangeFirmwareVersion = (event) => {
    const {
      target: { value },
    } = event;
    setFirmwareVersion(value);
  };

  const handleSearchSiteClick = () => {
    props.handleCompTypeChange(componentType);
    props.handleCompTypeVerChange(firmwareVersion);
    props.handleSearchSite(componentType, firmwareVersion);
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="select-component-name-label">
              Component Name
            </InputLabel>
            <Select
              labelId="select-component-name-label"
              id="select-component-name"
              value={componentType}
              onChange={handleChangeComponentType}
              input={<OutlinedInput label="Component Name" />}
              MenuProps={MenuProps}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={"msv"} selected={componentType == "msv"}>
                Terminal
              </MenuItem>
              <MenuItem value={"psv"} selected={componentType == "psv"}>
                ICPU
              </MenuItem>
              <MenuItem value={"dsv"} selected={componentType == "dsv"}>
                Display
              </MenuItem>
              <MenuItem value={"esv"} selected={componentType == "esv"}>
                EICC
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="select-firmware-version-label">
              Firmware Version
            </InputLabel>
            <Select
              labelId="select-firmware-version-label"
              id="select-firmware-version"
              value={firmwareVersion}
              onChange={handleChangeFirmwareVersion}
              input={<OutlinedInput label="Firmware Version" />}
              MenuProps={MenuProps}
            >
              <MenuItem value="all">
                <em>All</em>
              </MenuItem>
              {props.vOptions.map((option) => {
                return (
                  <MenuItem value={option} selected={firmwareVersion == option}>
                    {option}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl sx={{ m: 1, width: 300 }}>
            <Button
              variant="contained"
              size="large"
              onClick={handleSearchSiteClick}
              disabled={
                componentType == '' || componentType == undefined || firmwareVersion == undefined
              }
            >
              Search Sites
            </Button>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          {/* <DataGridSites/> */}
        </Grid>
      </Grid>
    </>
  );
}
