import React from 'react';
import { createUseStyles } from 'react-jss';
import {
  useFetch,
  useDeleteItems,
  useDeleteItem,
  useUpdateItem,
} from './utils/hooks';
import { updateSite } from 'utils/helpers/request/api/sites';
import ListPiles from './components/ListPiles';
import PileModal from './components/PileModal';
import styles from './styles';

const useStyles = createUseStyles(styles);

const Piles = () => {
  const classes = useStyles();
  const { items, fetch } = useFetch();
  const { deleteItem } = useDeleteItem();
  const { updateItem } = useUpdateItem();
  const { deleteItems } = useDeleteItems();
  const [ pileId, setPileId ] = React.useState(null);
  const [ isViewOnly, setIsViewOnly ] = React.useState(false);
  const handleDeleteItems = React.useCallback(indexes => {
    const ids = indexes.map(i => items[+i].id);

    deleteItems(ids)
      .then(() => {
        fetch();
      });
  }, [ items ]);
  const handleDeleteItem = React.useCallback(() => {
    deleteItem(pileId)
      .then(() => {
        hideModal();
        fetch();
      });
  }, [ pileId ]);
  const handleUpdate = React.useCallback(async ({ values }) => {
    // Synchronize site and pile coordinates
    updateSite({
      id: values.siteAddress.id,
      lat: values.pileLat,
      lon: values.pileLon,
    });
    updateItem(values)
      .then(() => {
        hideModal();
        fetch();
      });
  }, [ items ]);
  const handleEdit = React.useCallback(id => {
    setPileId(id);
  }, [ setPileId ]);
  const handleView = React.useCallback(id => {
    setPileId(id);
    setIsViewOnly(true);
  }, []);
  const hideModal = React.useCallback(() => {
    setPileId(null);
    setIsViewOnly(false);
  }, []);
  
  React.useEffect(() => {
    fetch();
  }, []);

  return (
    <div className={classes.wrapper}>
      <ListPiles
        items={items}
        onEdit={handleEdit}
        onView={handleView}
        onDelete={handleDeleteItems}
      />
      {!!pileId && (
        <PileModal
          id={pileId}
          disabled={isViewOnly}
          onClose={hideModal}
          onDelete={handleDeleteItem}
          onUpdate={handleUpdate}
        />
      )}
    </div>
  )
};

export default Piles;
