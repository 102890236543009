import { COLOR_PRIMARY_TEXT_2 } from 'utils/constants/colors';
import { CELL_LINK } from 'utils/constants/styles';

export default {
  wrapper: {
    ...CELL_LINK,
    textTransform: 'uppercase',
  },
  label: {
    color: COLOR_PRIMARY_TEXT_2,
    fontSize: 12,
  },
};
