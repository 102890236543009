import PageWrapper from "components/PageWrapper";
import React from "react";
import {
  useCreateItem, useDelete, useDeleteItem, useFetchItems, useUpdateItem
} from "utils/hooks/users";
import ListUsers from "./components/ListUsers";
import UsersModal from "./components/UsersModal";

const Users = () => {
  const { items, fetch } = useFetchItems();
  const { deleteItems } = useDelete();
  const { deleteItem } = useDeleteItem();
  const { updateItem } = useUpdateItem();
  const { createItem } = useCreateItem();
  const [roleMappingId, setRoleMappingId] = React.useState(null);
  const [isViewOnly, setIsViewOnly] = React.useState(false);
  const [modalTitle, setModalTitle] = React.useState("");

  React.useEffect(() => {
    fetch();
  }, []);

  const handleDeleteItems = React.useCallback(
    (indexes) => {
      const ids = indexes.map((i) => items[+i].id);

      deleteItems(ids).then(() => {
        fetch();
      });
    },
    [items]
  );

  const hideModal = React.useCallback(() => {
    setRoleMappingId(null);
    setIsViewOnly(false);
  }, []);

  const handleDeleteItem = React.useCallback(() => {
    deleteItem(roleMappingId).then(() => {
      hideModal();
      fetch();
    });
  }, [roleMappingId]);

  const handleClickCreate = React.useCallback(() => {
    setRoleMappingId("-1");
    setModalTitle("Add New User");
  }, []);

  const handleUpdate = React.useCallback(async ({ values }) => {
    const mapping = {
      roleId: values.role,
      email: values.email,
      status: values.status,
    };
    updateItem(mapping).then(() => {
      hideModal();
      fetch();
    });
  }, []);

  const handleCreate = React.useCallback(({ values }) => {
    const role = {
      email: values.email,
      roleId: values.role,
      status: values.status
    };
    createItem(role)
      .then(() => {
        return fetch();
      })
      .then(() => {
        hideModal();
      });
  }, []);

  const handleEdit = React.useCallback(
    (id) => {
      setRoleMappingId(id);
      setModalTitle("Edit User");
    },
    [setRoleMappingId]
  );

  const handleView = React.useCallback((id) => {
    setRoleMappingId(id);
    setModalTitle("View User");
    setIsViewOnly(true);
  }, []);

  return (
    <PageWrapper>
      <ListUsers
        items={items}
        onEdit={handleEdit}
        onView={handleView}
        onDelete={handleDeleteItems}
        onCreate={handleClickCreate}
      />
      {!!roleMappingId && (
        <UsersModal
          id={roleMappingId}
          disabled={isViewOnly}
          title={modalTitle}
          onClose={hideModal}
          onDelete={handleDeleteItem}
          onUpdate={handleUpdate}
          onCreate={handleCreate}
        />
      )}
    </PageWrapper>
  );
};

export default Users;
