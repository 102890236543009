import client from '../client';

const retriveLogEvents = (data) =>
  client.post('/audit-logs',data);


const revertRecord = (id) =>
  client.get(`/audit-logs/revert/${id}`);

const fetchAdminActivities = (data) =>
  client.post('/audit-logs',data);

const retriveSigoEvents = (data) =>
  client.post('/events',data);

const retriveSigoBatteryEvents = (data)=>
  client.post('/batteryEvents',data);

const retriveSiteHeartbeatGraphData = (data)=>
  client.post('/siteHbEvents',data);

const retriveEventWiseCount = (data) =>
  client.post('/eventWiseCount',data);



export {
  retriveLogEvents,
  revertRecord,
  fetchAdminActivities,
  retriveSigoEvents,
  retriveSigoBatteryEvents,
  retriveSiteHeartbeatGraphData,
  retriveEventWiseCount
}