const BATTERY_MODAL = 'BATTERY_MODAL';
const LOCK_MODAL = 'LOCK_MODAL';
const SITE_MODAL = 'SITE_MODAL';
const VEHICLE_MODAL = 'VEHICLE_MODAL';
const ROUTER_MODAL = 'ROUTER_MODAL';
const SIM_CARD_MODAL = 'SIM_CARD_MODAL';
const USER_GROUP_MODAL = 'USER_GROUP_MODAL';

export {
  BATTERY_MODAL,
  LOCK_MODAL,
  SITE_MODAL,
  VEHICLE_MODAL,
  ROUTER_MODAL,
  SIM_CARD_MODAL,
  USER_GROUP_MODAL
};
