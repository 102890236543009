export const parseFromBackend = function (rawData) {
  return {
    discountId: rawData.id,
    discount: rawData.discount,
    name: rawData.name,
    frequency: rawData.discount_frequency,
    emailDomains: rawData.email_domains,
    partnerId: rawData.partner_id,
    stationGroupId: rawData.station_group_id,
    parkingTranslationKey: rawData.parking_translation_key,
    timingTranslationKey: rawData.timing_translation_key
  };
};

