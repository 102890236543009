import client from '../client';

const addCity = data =>
  client.post('/city', data);

const getCities = () =>
  client.get('/cities');

const getCity = id =>
  client.get(`/cities/${id}`);

const deleteCity = id =>
  client.delete(`/cities/${id}`);

const updateCity = data =>
  client.put(`/cities/${data.id}`, data);

export {
  addCity,
  getCities,
  getCity,
  deleteCity,
  updateCity,
};
