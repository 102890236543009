export const parseFromBackend = function ({ id, name, status, stationIds }) {
  return {
    stationGroupId: id,
    name,
    status,
    stationIds,
  };
};

export const initOptions = (sites) => {
  let options = [];

  if (sites && sites.items) {
    sites.items.forEach(({site_id}) =>
      options.push({ id: site_id, name: site_id })
    );
  }

  return options;
};
