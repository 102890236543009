import client from '../client';

const addLock = data =>
  client.post('/lock', data);

const getLocks = () =>
  client.get('/locks');

const getLock = id =>
  client.get(`/locks/${id}`);

const deleteLock = id =>
  client.delete(`/locks/${id}`);

const updateLock = data =>
  client.put(`/locks/${data.id}`, data);

const unlock = lockId =>
  client.get(`/locks/unlock/${lockId}`);

export {
  addLock,
  getLocks,
  getLock,
  deleteLock,
  updateLock,
  unlock,
};
