import React from 'react';
import { createUseStyles } from 'react-jss';
import CellEditPile from 'components/Cells/CellEditPile';
import styles from './styles';

const useStyles = createUseStyles(styles);

const CellLogo = ({
  row,
  onClick,
}) => {
  const classes = useStyles();
  const handleClick = React.useCallback(e => {
    e.preventDefault();
    onClick(row.original.id);
  }, [ row.original.id ]);
  
  return (
    <CellEditPile
      href='#'
      status={row.original.status}
      onClick={handleClick}
    >
      {row.original.id}
    </CellEditPile>
  );
};

export default CellLogo;
