import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from 'react-table';
import MaUTable from '@material-ui/core/Table';
import MaUTTableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TablePaginationActions from './components/TablePaginationActions';
import Toolbar from 'components/List/Toolbar';
import ButtonDefault from 'components/Buttons/ButtonDefault';
import CellActions from 'components/List/CellActions';
import CellCheckbox from 'components/List/CellCheckbox';
import TableSearch from './components/TableSearch';
import styles from './styles';
import { syncSitesToMetabase , syncFromHeartbeats, syncTrackerInfo, syncBikesAndSitesAfterDailyRestart,refreshPileState} from 'utils/helpers/request/api/sites';
import { syncBikesToMetabase } from 'utils/helpers/request/api/bikes';
import { NotificationManager } from 'react-notifications';
import moment from 'moment-timezone';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AdvancedSearch from './components/AdvancedSearch';
import AdvancedSearchSites from './components/AdvancedSearchSites';
import { ListItem, ListItemText } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import AddIcon from '@material-ui/icons/Add';
import SyncIcon from '@material-ui/icons/Sync';

import AddMultipleVehicleDialog from "../../../pages/AddMultipleVehicleDialog";


const useStyles = createUseStyles(styles);

const TableUncontrolled = ({
  rowsPerPage = 100,
  onDelete,
  onEdit,
  onView,
  entityType,
  isRemovable = true,
  isCreatable = true,
  isViewable = true,
  isEditable = true,
  modalType,
  columns,
  caption,
  data,
  hiddenColumns = [],
  isVisibleFilter,
  isVisibleSort,
  isvisibleSync,
  fields,
  totalCount,
  createCaption,
  onCreate,
  refreshData,
  fetchData=null,
  pageCount:controlledPageCount,
  pageIndex:pageIndexFromParent,
  citiesFromDb=[],
  licensePlatesFromDb=[],
  siteAddressFromDb=[],
  lastSearchParam
}) => {
  const classes = useStyles();
  const defaultColumn = React.useMemo(
    () => ({
      width: 150,
    }),
    []
  );
  const linkCreate = React.useMemo(() => `#${entityType}/add`, [ entityType ]);
  const extendedColumns = React.useMemo(() =>
    [
      ...columns,
      {
        id: 'listActions',
        Cell: ({ row }) => <CellActions
          isViewable={isViewable}
          isEditable={isEditable}
          entityType={entityType}
          onEdit={onEdit}
          onView={onView}
          modalType={modalType}
          id={row.original.id}
          otherData={row.original}
        />,
      }
    ],
    [ columns, isEditable, isViewable ],
  );
  const {
    getTableProps,
    prepareRow,
    page,
    gotoPage,
    toggleSortBy,
    setGlobalFilter,
    setFilter,
    state: {
      pageIndex,
      globalFilter,
      selectedRowIds,
      filters,
      pageSize 
    },
    pageCount,
    canNextPage,
    canPreviousPage,
    nextPage,
    previousPage
  } = useTable(
    {
      columns: extendedColumns,
      defaultColumn,
      data,
      initialState: {
        hiddenColumns,
        pageIndex: pageIndexFromParent,
        pageSize: rowsPerPage,
        // sortBy: fields[0] ? [
        //   {
        //     id: fields[0].id,
        //     desc: false
        //   }
        // ] : [],
      },
      manualPagination: true,
      pageCount: controlledPageCount,
      autoResetPage:false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      if (!isRemovable) return;
      hooks.visibleColumns.push(columns => {
        return [
          {
            id: 'selection',
            Cell: ({ row }) => (
              <CellCheckbox {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...columns,
        ]
      })
    },
  );
  const selectProps = React.useMemo(() => ({
    inputProps: { 'aria-label': 'rows per page' },
    native: true,
  }), []);
  const colSpan = React.useMemo(() => extendedColumns.length + 1, [ extendedColumns ]);
  const rowsPerPageOptions = React.useMemo(() => ([
    rowsPerPage
  ]),  [ rowsPerPage ]);
  const handleChangePage = React.useCallback((event, newPage) => {
    gotoPage(newPage);
  }, []);
  const handleSort = React.useCallback(({ isAsc, field }) => {
    const desc = !isAsc;
    // console.log('sort',isAsc,field);
    // console.log(field.id)
    toggleSortBy(field.id, desc);
  }, []);
  const handleFilter = React.useCallback(({ id, value }) => {
    // console.log('handle',id,value.label);
    setFilter(id, value.label);
  }, []);
  const handleRemoveFilter = React.useCallback(filterId => {
    setFilter(filterId, null);
  }, []);
  const handleMetabaseSync = async () => {
    if(entityType === 'sites'){
      await syncSitesToMetabase()
    } else{
      await syncBikesToMetabase()
    }
  }
  const handleHeartbeatSync = async () => {
    await syncFromHeartbeats();
    NotificationManager.success("Sync started successfully and will complete in 10 seconds");
  }
  const handleRefreshPileState = async () => {
    await refreshPileState();
    NotificationManager.success("Refresh Pile State started successfully and will complete in 10 seconds");
  }

  const handleTrackerSync = async () => {
  
    await syncTrackerInfo();
    NotificationManager.success("Tracker Information synced successfully, Please refresh page.");
  }

  const handleDailySync = () => {
    syncBikesAndSitesAfterDailyRestart();
    NotificationManager.success('Daily sites & bikes sync process started');
  }

  const [orderBy,setOrderBy] = useState(null);
  function initalOrderState(columns){
    let result = {};
    columns.forEach(column=>{
      result[column.accessor]=null;
    });
    return result;
  }
  const [order,setOrder] = useState(initalOrderState(columns));
  const genericSortClick = async(property) =>{
    // alert(property);
    // console.log(order);
    let isAsc = null;
    if (order[property]== null) {
      isAsc = true;
      let obj = {...order};
      obj[property]=true;
      await setOrder(obj);
    } else {
      isAsc = !order[property];
      let obj = {...order};
      obj[property]=!obj[property];
      await setOrder(obj);
      // setOrder(...order,!order[property])
    }
    // console.log(property,isAsc);
    let sortParam = property;
    if (!isAsc){
      sortParam = '-'+sortParam; 
    }
    gotoPage(0);
    // console.log(lastSearchParam)
    fetchData({ pageIndex:0, pageSize ,search:lastSearchParam.current,isFreshReqParam:true,sortParam})
    setOrderBy(property,isAsc);
    handleSort.bind(this,{"isAsc":isAsc,"field" :{"id":property}}).call();
  }

  let headers = [];
 
  if (entityType == 'vehicles') {
    columns.forEach((column)=>{
      if (column.accessor == 'id'){
        headers.push(<TableCell key={column.label} className={classes.headerCell}>{column.label}</TableCell>)

      } else {
        headers.push(<TableCell 
          key={column.label} 
          isAsc={true} 
          onClick={genericSortClick.bind(this,column.accessor)} 
          className={classes.sortBtn}>
            {column.label}
            {
              orderBy == column.accessor && <span component="span" >
                    {order[column.accessor] == true ? <ArrowDownwardIcon fontSize='small'/> : <ArrowUpwardIcon fontSize='small'/>}
              </span>
            }
            
            </TableCell>)
      }


      // return(<TableCell key={column.label}>{column.label}</TableCell>)
    })
    headers.push(<TableCell key="Edit/View" className={classes.headerCell}>Edit View</TableCell>)
  } else if (entityType == 'sites') {
    const sortableHeaders = ['status','address','version','onlineStatus','icpu','site_heartbeat_timestamp'];

    headers.push(<TableCell key={''} className={classes.headerCell}>{''}</TableCell>)
    columns.forEach(column => {
        
        if (hiddenColumns.indexOf(column.accessor) == -1 && sortableHeaders.indexOf(column.accessor) == -1) {
          headers.push(<TableCell key={column.label} className={classes.headerCell}>{column.label}</TableCell>)
         
        }  else if (hiddenColumns.indexOf(column.accessor) == -1 && sortableHeaders.indexOf(column.accessor) != -1)  {
          headers.push(<TableCell 
            key={column.label} 
            isAsc={true} 
            onClick={genericSortClick.bind(this,column.accessor)} 
            className={classes.sortBtn}>
              {column.label}
              {
                orderBy == column.accessor && <span component="span" >
                      {order[column.accessor] == true ? <ArrowDownwardIcon fontSize='small'/> : <ArrowUpwardIcon fontSize='small'/>}
                </span>
              }
              
              </TableCell>)
        }
    })

    headers.push(<TableCell key="Edit/View" className={classes.headerCell}>Edit View</TableCell>)
  }

  const [openAdvSearch,setOpenAdvSearch] = React.useState(false);
  const handleOpenAdvSearch = () =>{
    setOpenAdvSearch(!openAdvSearch);
  }
  const handleAdvSearch = (filterObj)=>{
    // console.log(filterObj);
    // below code is used for on-ui filter
    // filterObj.forEach(filter=>{
    //   setFilter(filter.id,filter.value);
    // })
    gotoPage(0);
    fetchData({ pageIndex:0, pageSize ,search:filterObj,isFreshReqParam:true})
  }
  const handleClearAdvSearch =(list)=>{
    // console.log(filters)
    // below code is used for on-ui filter
    // list.forEach(item=>{
    //   setFilter(item,undefined);
    // })
    gotoPage(0);
    fetchData({ pageIndex:0, pageSize ,search:[],isFreshReqParam:true})
  }

    // add multiple vehicle using file
    let [openAddMultipleVehicleDialog,setOpenAddMultipleVehicleDialog] = React.useState(false);
    const handleOnClickAddMultipleVehicleDialog = ()=>{
      setOpenAddMultipleVehicleDialog(true);
    }
    const handleOnCloseAddMultipleVehicleDialog = () =>{
      setOpenAddMultipleVehicleDialog(false);
      refreshData();
    }
  
   // vehicle 's action drawer
   const [drawerState,setDrawerState] = React.useState(false);

   const toggleDrawer = (open)=>(event)=>{
     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
       return;
     }
     setDrawerState(open);
   }
 
   const vehicleActionList = () => (
     <Box
       sx={{ width: "auto" }}
       role="presentation"
       onClick={toggleDrawer(false)}
       onKeyDown={toggleDrawer(false)}
     >
       <List>
         {isCreatable &&
           (!!onCreate ? (
             <ListItem key={"add-vehicle-1"} disablePadding>
               <ListItemButton onClick={onCreate}>
                 <ListItemIcon style={{ minWidth: "30px" }}>
                   <AddIcon />
                 </ListItemIcon>
                 <ListItemText primary={createCaption} />
               </ListItemButton>
             </ListItem>
           ) : (
             <ListItem key={"add-vehicle-2"} disablePadding>
               <ListItemButton href={linkCreate}>
                 <ListItemIcon style={{ minWidth: "30px" }}>
                   <AddIcon />
                 </ListItemIcon>
                 <ListItemText primary={createCaption} />
               </ListItemButton>
             </ListItem>
           ))}
         {/* {console.log(entityType)} */}
         {(entityType == 'vehicles')&& isCreatable &&
            (
             <ListItem key={"add-multiple-vehicle"} disablePadding>
                          <ListItemButton onClick={handleOnClickAddMultipleVehicleDialog}>
              <ListItemIcon style={{ minWidth: "30px" }}>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary={"Add Multiple Vehicle"} />
            </ListItemButton>

             </ListItem>
           ) }
 
         {isvisibleSync && (
           <ListItem key={"sync-to-metabase"} disablePadding>
             <ListItemButton onClick={handleMetabaseSync}>
               <ListItemIcon style={{ minWidth: "30px" }}>
                 <SyncIcon />
               </ListItemIcon>
               <ListItemText primary="Sync to Metabase" />
             </ListItemButton>
           </ListItem>
         )}
         {isvisibleSync && (
           <ListItem key={"sync-refresh-pile-state"} disablePadding>
             <ListItemButton onClick={handleRefreshPileState}>
               <ListItemIcon style={{ minWidth: "30px" }}>
                 <SyncIcon />
               </ListItemIcon>
               <ListItemText primary="Refresh Pile State" />
             </ListItemButton>
           </ListItem>
         )}
         {isvisibleSync && (
           <ListItem key={"sync-tracker"} disablePadding>
             <ListItemButton onClick={handleTrackerSync}>
               <ListItemIcon style={{ minWidth: "30px" }}>
                 <SyncIcon />
               </ListItemIcon>
               <ListItemText primary="Sync Tracker" />
             </ListItemButton>
           </ListItem>
         )}
         {isvisibleSync && (
           <ListItem key={"sync-site-and-bikes"} disablePadding>
             <ListItemButton onClick={handleDailySync}>
               <ListItemIcon style={{ minWidth: "30px" }}>
                 <SyncIcon />
               </ListItemIcon>
               <ListItemText primary="Sync Sites &amp; Bikes" />
             </ListItemButton>
           </ListItem>
         )}
         {/* case where there is no actions in page , hence added dummy*/}
         <ListItem key={"dummy"} disablePadding>
             <ListItemButton >
               <ListItemIcon style={{ minWidth: "30px" }}>
                 {/* <SyncIcon /> */}
               </ListItemIcon>
               <ListItemText primary="" />
             </ListItemButton>
           </ListItem>
       </List>
     </Box>
   );

  // text search
  const handleTextSearch = (text) =>{
    // console.log('handle texte searcg',text);
    if (text && text.length > 3){
      gotoPage(0);
      fetchData({pageIndex:0,pageSize,search:[{id:'text_search',value:text}],isFreshReqParam:true})
      // console.log('text search started');
    } else if (text == undefined) {
      gotoPage(0);
      fetchData({pageIndex:0,pageSize,search:[],isFreshReqParam:true})
      // console.log('text search reset');
    } else {
      // do nothing
    }
  }
 
  // Listen for changes in pagination and use the state to fetch our new data
  React.useEffect(() => {
    if (fetchData!=null){
      fetchData({ pageIndex, pageSize ,search:[],isFreshReqParam:false})
    }
  }, [fetchData,pageIndex,pageSize])
  return (
      <MaUTTableContainer className={classes.wrapper}>
        <div className={classes.wrapperCaption}>
          <div className={classes.wrapperLeft}>
            <Toolbar
              label={caption}
              fields={fields}
              onDelete={onDelete}
              onSort={isVisibleSort && handleSort}
              selectedRowIds={selectedRowIds}
              onFilter={isVisibleFilter && handleFilter}
              onRemoveFilter={handleRemoveFilter}
            />
          </div>
          <div className={classes.wrapperRight}>
            <div>
              Last Refresh at :{" "}
              {moment().tz("Europe/Berlin").format("DD/MM/YYYY HH:mm:ss")}
            </div>
            <TableSearch
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              handleTextSearch = {handleTextSearch}
            />
            
            { (entityType == 'vehicles'|| entityType == 'sites') && <ButtonDefault onClick={handleOpenAdvSearch}>
                Advance Search
              </ButtonDefault>
              }
              {<React.Fragment key="right">
              <ButtonDefault onClick={toggleDrawer(true)}>
                Actions
              </ButtonDefault>
                <Drawer 
                  anchor='right'
                  open={drawerState}
                  onClose={toggleDrawer(false)}
                >
                    {vehicleActionList()}
                </Drawer>
                </React.Fragment>
                }
              

          </div>
        </div>
        {(openAdvSearch && entityType == 'vehicles' ) && <AdvancedSearch 
          bikesFromDb={data} 
          handleAdvSearch={handleAdvSearch}
          handleClearAdvSearch={handleClearAdvSearch}
          licensePlatesFromDb={licensePlatesFromDb}/>}

        {(openAdvSearch && entityType == 'sites') && <AdvancedSearchSites 
          sitesFromDb={data}
          handleAdvSearch={handleAdvSearch}
          handleClearAdvSearch={handleClearAdvSearch}
          citiesFromDb={citiesFromDb}
          siteAddressFromDb={siteAddressFromDb}
        />}
        {openAddMultipleVehicleDialog && <AddMultipleVehicleDialog open={openAddMultipleVehicleDialog} close= {handleOnCloseAddMultipleVehicleDialog}/>}

        <MaUTable {...getTableProps()} stickyHeader>
          <TableHead>
            <TableRow>{headers}</TableRow>
          </TableHead>
          <TableBody>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <TableCell {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                className={classes.wrapperPagination}
                rowsPerPageOptions={rowsPerPageOptions}
                colSpan={colSpan}
                count={totalCount}
                rowsPerPage={rowsPerPage ? rowsPerPage: 0}
                page={pageIndexFromParent}
                SelectProps={selectProps}
                onChangePage={{previousPage,canPreviousPage,nextPage,canNextPage}}
                ActionsComponent={TablePaginationActions}
                labelDisplayedRows={({ from, to, count })=>{return `${entityType == 'vehicles'?'Vehicles':'Sites'} ${from}–${to} of ${count}`}}
                
              />
            </TableRow>
          </TableFooter>
        </MaUTable>
      </MaUTTableContainer>
  );
};

export default TableUncontrolled;
