import React from 'react';
import BuildIcon from '@material-ui/icons/Build';
import { createUseStyles } from 'react-jss';
import { NotificationManager } from 'react-notifications';
import ButtonDefault from 'components/Buttons/ButtonDefault';
import ErrorCodeModal from 'components/Modals/ErrorCodeModal';
import { useCureErrorTrigger } from './utils/hooks';
import styles from './styles';

const useStyles = createUseStyles(styles);

const CureErrorButton = ({
  siteId,
  dockingId,
}) => {
  const classes = useStyles();
  const { trigger } = useCureErrorTrigger();
  const [ modalData, setModalData ] = React.useState();
  const showModal = React.useCallback(() => {
    setModalData({ code: 18 });
  }, []);
  const hideModal = React.useCallback(() => {
    setModalData();
  }, []);
  const handleTrigger = React.useCallback(({ values }) => {
    let message = null;
    
    if (values.code && siteId && dockingId) {
      trigger({
        siteId,
        pileId: dockingId,
        errorCode: values.code,
      })
    }
    
    if (!values.code) message = 'Code wasn\'t provided';
    if (!siteId) message = 'SiteId is empty';
    if (!dockingId) message = 'PileId is empty';
    
    if (message) NotificationManager.warning(message);
  }, []);
  
  return (
    <>
      <ButtonDefault onClick={showModal}>
        {/* <BuildIcon/> &nbsp; Cure Error */}
        Cure Error
      </ButtonDefault>
      {!!modalData && (
        <ErrorCodeModal
          onClose={hideModal}
          modalInitialData={modalData}
          onTrigger={handleTrigger}
        />
      )}
    </>
  )
};

export default CureErrorButton;
