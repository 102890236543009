import React from 'react';
import CellNameCommon from 'components/Cells/CellName';

const CellWunderPromotionCode = ({ row }) => {
  return (
    <CellNameCommon row={row.original.wunder_promotion_code_id}/>
  );
};

export default CellWunderPromotionCode;
