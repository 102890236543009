import PageWrapper from "components/PageWrapper";
import React from "react";
import {
  useFetchAllPartners,
  useUpdateItem
} from "utils/hooks/partners";
import ListPartners from "./components/ListPartners";
import PartnerModal from "./components/PartnerModal";

const Partners = () => {
  const { partners, fetchAllPartners } = useFetchAllPartners();
  const { updateItem } = useUpdateItem();
  const [partnerId, setPartnerId] = React.useState(null);
  const [isViewOnly, setIsViewOnly] = React.useState(false);
  const [modalTitle, setModalTitle] = React.useState("");

  React.useEffect(() => {
    fetchAllPartners();
  }, []);

  const hideModal = React.useCallback(() => {
    setPartnerId(null);
    setIsViewOnly(false);
  }, []);

  const handleUpdate = React.useCallback(async ({ values }) => {
    const updateVendorMapping = {
      id: values.id,
      bikes: values.bikes
    };
    updateItem(updateVendorMapping).then(() => {
      hideModal();
      fetchAllPartners();
    });
  }, []);


  const handleEdit = React.useCallback(
    (id) => {
      setPartnerId(id);
      setModalTitle("Edit Partner");
    },
    [setPartnerId]
  );

  const handleView = React.useCallback((id) => {
    setPartnerId(id);
    setModalTitle("View Partner");
    setIsViewOnly(true);
  }, []);

  return (
    <PageWrapper>
      <ListPartners
        items={partners}
        onEdit={handleEdit}
        onView={handleView}
      />
      {!!partnerId && (
        <PartnerModal
          id={partnerId}
          disabled={isViewOnly}
          title={modalTitle}
          onClose={hideModal}
          onUpdate={handleUpdate}
        />
      )}
    </PageWrapper>
  );
};

export default Partners;
