import React from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CRUModal from 'components/Modals/CRUModal';
import { useDelete, useFetchItem } from 'utils/hooks/site';
import { setModalData } from 'store/actions/common';
import FormContent from './components/FormContent';
import styles from './styles';
import { usePermissions } from 'react-admin'

const useStyles = createUseStyles(styles);

const SiteModal = ({
  id,
  fetchItems,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { deleteItems } = useDelete();
  const { fetch, item } = useFetchItem(id);
  const { permissions } = usePermissions()

  const handleDelete = React.useCallback(() => {
    deleteItems([ id ])
      .finally(() => {
        fetchItems();
        dispatch(setModalData());
      });
  }, [ id, dispatch ]);

  const handleClose = React.useCallback(() => {
    dispatch(setModalData());
  }, []);

  const handleEdit = React.useCallback(() => {
    history.push(`/sites/edit/${id}`);
    dispatch(setModalData());
  }, [ id ]);
  
  const handleView = React.useCallback(() => {
    history.push(`/sites/view/${id}`);
    dispatch(setModalData());
  }, [id]);

  const handleLogView = React.useCallback(()=>{
    history.push(`/log-viewer/site/${id}`);
    dispatch(setModalData());
  }, [id]);

  React.useEffect(() => {
    if (id) {
      fetch(id);
    }
  }, [ id ]);
  
  return (
    <CRUModal
      // title="View Site"
      entity="Site"
      minWidth={800}     
      onClose={handleClose}
      onDelete={
        permissions && permissions.indexOf("delete-sites") > -1
          ? handleDelete
          : false
      }
      onEdit={
        permissions && permissions.indexOf("update-sites") > -1
          ? handleEdit
          : false
      }
      onView={handleView}
      onLogView={handleLogView}
      ctgr='site'
      ctgrId={id}
      item={item}
    >
      <FormContent item={item} />
    </CRUModal>
  )
};

export default SiteModal;
