import React from "react";
import { createUseStyles } from "react-jss";
import styles from "./styles";
import { usePermissions } from "react-admin";
import Forbidden from "pages/Forbidden";

const useStyles = createUseStyles(styles);

const PageLayout = ({ page, children }) => {
  const classes = useStyles();
  const { permissions } = usePermissions();

  return permissions && permissions.indexOf("view-" + page) > -1 ? (
    <div className={classes.wrapper}>
      <div className={classes.wrapperInner}>
        {React.cloneElement(children, { page })}
      </div>
    </div>
  ) : (
    <Forbidden />
  );
};

export default PageLayout;
