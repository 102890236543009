import React from 'react';
import { useDispatch } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import {
  deleteRouter, getRouter,
  getRouters,
} from 'utils/helpers/request/api/routers';
import { setLoader } from 'store/actions/common';
import { INVENTORY } from 'utils/constants/statuses';
import { parseDataFromBackend } from 'utils/helpers/router';

const useFetchItems = () => {
  const [ items, setItems ] = React.useState([]);
  const dispatch = useDispatch();
  
  const fetch = async function() {
    dispatch(setLoader(true))
    
    try {
      const { data: { items: routers } } = await getRouters();
      
      setItems(routers);
    } catch (e) {}
    
    dispatch(setLoader(false))
  };
  
  return {
    items,
    fetch,
  };
};

const useDelete = () => {
  const dispatch = useDispatch();
  
  const deleteItems = async function(ids) {
    dispatch(setLoader(true))
    
    try {
      for (const id of ids) {
        await deleteRouter(id);
      }
      NotificationManager.success('Successfully  removed!');
    } catch (e) {}
    
    dispatch(setLoader(false))
  };
  
  return {
    deleteItems,
  };
};

const useFetchItem = id => {
  const [ item, setItem ] = React.useState({
    serial_number: null,
    mac_address: null,
    status: INVENTORY,
    sim_card: null,
    site: { id: null, name: '' },
  });
  const dispatch = useDispatch();
  
  const fetch = async function() {
    if (!id) return ;
    dispatch(setLoader(true))
    
    try {
      const { data } = await getRouter(id);
      if (!!data) {
        setItem(parseDataFromBackend(data));
      } else {
        NotificationManager.error('Not found');
      }
      
    } catch (e) {
      if (e.response.status === 404) window.location.href = '#/not-found';
    }
    
    dispatch(setLoader(false))
  };
  
  return {
    item,
    fetch,
  };
};

export {
  useFetchItem,
  useFetchItems,
  useDelete,
};
