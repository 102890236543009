const CHARGING = 'CHARGING';
const EMPTY = 'EMPTY';
const ERROR = 'ERROR';
const TRIP = 'TRIP';
const INVENTORY = 'INVENTORY';
const WARNING = 'WARNING';
const SUCCESS = 'SUCCESS';
const OPERATION = 'OPERATION';
const TESTING = 'TESTING';
const ACTIVE = 'ACTIVE';
const OFFLINE = 'OFFLINE';
const FUEL_LEVEL_LOW = 'FUEL_LEVEL_LOW';
const INTERNAL_RESERVATION = 'INTERNAL_RESERVATION';
const SHIPPING_MODE = 'SHIPPING_MODE';
const OUT_OF_ORDER = 'OUT_OF_ORDER';
const MAINTENANCE = 'MAINTENANCE';
const PRIVATE = 'PRIVATE';
const INACTIVE = 'INACTIVE';
const NO_TRACKER = 'NO_TRACKER';
const ONLINE = 'ONLINE';
const INSIDE = 'INSIDE';
const OUTSIDE = 'OUTSIDE';
export {
  CHARGING,
  ERROR,
  EMPTY,
  TRIP,
  WARNING,
  OFFLINE,
  SHIPPING_MODE,
  FUEL_LEVEL_LOW,
  INTERNAL_RESERVATION,
  SUCCESS,
  OUT_OF_ORDER,
  INVENTORY,
  OPERATION,
  TESTING,
  ACTIVE,
  MAINTENANCE,
  PRIVATE,
  INACTIVE,
  NO_TRACKER,
  ONLINE,
  INSIDE,
  OUTSIDE
};
