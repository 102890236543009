import React from 'react';
import { useDispatch } from 'react-redux';
import {
  addPileType,
  getPileTypes,
  deletePileType,
} from 'utils/helpers/request/api/dockings';
import { setLoader } from 'store/actions/common';

const useFetch = () => {
  const [ items, setItems ] = React.useState([]);
  const dispatch = useDispatch();

  const fetch = async function() {
    dispatch(setLoader(true))

    try {
      const { data: { items: pileTypes } } = await getPileTypes();

      setItems(pileTypes);
    } catch (e) {}

    dispatch(setLoader(false))
  };

  return {
    items,
    fetch,
  };
};

const useDeleteItems = () => {
  const dispatch = useDispatch();

  const deleteItems = async function(ids) {
    dispatch(setLoader(true))

    try {
      for (const id of ids) {
        await deletePileType(id);
      }
    } catch (e) {}

    dispatch(setLoader(false))
  };

  return {
    deleteItems,
  };
};

const useCreateItem = () => {
  const dispatch = useDispatch();

  const createItem = async function(pileType) {
    dispatch(setLoader(true))

    try {
      await addPileType(pileType);
    } catch (e) {}

    dispatch(setLoader(false))
  };

  return {
    createItem,
  };
};

export {
  useFetch,
  useCreateItem,
  useDeleteItems,
};
