import React from 'react';
import { createUseStyles } from 'react-jss';
import { useForm } from 'react-final-form';
import Typography from '@material-ui/core/Typography';
import AppFormGap from 'components/AppFormGap';
import TextInputForm from 'components/Inputs/TextInputForm';
import AutocompleteCity from 'components/Inputs/AutocompleteCity';
import AutocompleteSimCard from 'components/Inputs/AutocompleteSimCard';
import AutocompleteRouter from 'components/Inputs/AutocompleteRouter';
import AppSelectForm from 'components/Inputs/AppSelectForm';
import { STATUSES } from '../../utils/constants';
import styles from './styles';
import AutocompleteUserGroup from 'components/Inputs/AutocompleteUserGroup';
import { usePermissions } from 'react-admin'
import Forbidden from 'pages/Forbidden';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
const useStyles = createUseStyles(styles);

const FormContent = ({
  item,
  disabled,
}) => {
  const classes = useStyles();
  const form = useForm();
  const { permissions } = usePermissions()
  const filterForRouter = React.useMemo(() => {
    return function (router) {
      return !router.site ? true : router.site.id === item.id;
    };
  }, [item.id]);

  const filterForSimCard = React.useMemo(() => {
    return function (simCard) {
      if (!!simCard.router) {
        return simCard.router.id === item.router.id;
      }
      if (!simCard.router) return true;
      if (!simCard.lock) return true;
    };
  }, [item]);

  /**
   * Push to a new array all user group ids related to a site
   * @returns { Array }
   */
  const filterForUserGroups = () => {
    const userGroupIds = []

    if (item.userGroups && item.userGroups.length > 0) {
      item.userGroups.forEach(userGroup => {
        userGroupIds.push(userGroup.id)
      })
    }

    return userGroupIds
  }

  React.useEffect(() => {
    form.reset(item);
  }, [item]);

  return permissions && permissions.indexOf("update-sites") > -1 ? (
    <div className={classes.wrapper}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5">Site</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextInputForm name="id" label="Id" disabled />
          </Grid>
          <Grid item xs={6}>
            <TextInputForm name="version" label="Version" />
          </Grid>
          <Grid item xs={6}>
            <TextInputForm name="icpu" label="Sonoff" />
          </Grid>
          <Grid item xs={6}>
            <TextInputForm name="display" label="Display" />
          </Grid>
          <Grid item xs={6}>
            <TextInputForm name="wifi_master" label="WiFi-Master" />
          </Grid>
          <Grid item xs={6}>
            <TextInputForm name="reset_controller" label="Reset Controller" />
          </Grid>
          <Grid item xs={6}>
            <TextInputForm
              name="year_of_construction"
              label="Year of Construction"
            />
          </Grid>
          <Grid item xs={6}>
            {!disabled && (
              <>
                <AppSelectForm name="status" label="Status" options={STATUSES} />
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">Location</Typography>
          </Grid>
          <Grid item xs={6}>
            <AutocompleteCity disabled={disabled} form={form} />
          </Grid>
          <Grid item xs={6}>
            <TextInputForm name="street" label="Street" disabled={disabled} />
          </Grid>
          <Grid item xs={6}>
            <TextInputForm name="lat" label="Lat" disabled={disabled} />
          </Grid>
          <Grid item xs={6}>
            <TextInputForm name="lon" label="Lon" disabled={disabled} />
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h5">Partner</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h5">User Group</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextInputForm name="partner" label="Partner Name" />
          </Grid>

          <Grid item xs={6}>
            <AutocompleteUserGroup
              form={form}
              disabled={disabled}
              userGroups={filterForUserGroups()}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">Router</Typography>
          </Grid>
          <Grid item xs={6}>
            <AutocompleteRouter
              form={form}
              disabled={disabled}
              filterFn={filterForRouter}
            />
          </Grid>
          <Grid item xs={6}>
            <AutocompleteSimCard
              form={form}
              disabled={disabled}
              filterFn={filterForSimCard}
              label="SIM Card ICCID"
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  ) : (
    <div className={classes.wrapper}>
      <Forbidden />
    </div>
  );
};

export default FormContent;
