import React from 'react';
import { createUseStyles } from 'react-jss';
import styles from './styles';
import ButtonRemove from 'components/Buttons/ButtonRemoveUserGroup';

const useStyles = createUseStyles(styles);

const UserGroupInfo = ({ userGroupId, name, site}) => {
  const classes = useStyles();
  
  return (
    <div className={classes.wrapper}>
      <div className={classes.wrapperInfo}>
        <div className={classes.wrapperLabel}>
          <div className={classes.name}>
            {name}
          </div>
        </div>
      </div>
      <div className={classes.wrapperActions}>
        <ButtonRemove
          site={site}
          userGroupId={userGroupId}
        />
      </div>
    </div>
  );
};

export default UserGroupInfo;
