import React from 'react';
import CellBattery from 'components/Cells/CellBattery';

const CellId = ({ row }) => {

  return (
    <CellBattery
      id={row.original.id}
      level={row?.original?.bike?.energy_level}
    />
  );
};

export default CellId;
