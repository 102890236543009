import get from 'lodash/get';

const parseFromBackend = function (rawData) {
  return {
    id: rawData.id,
    status: rawData.status,
    bike: rawData.bike && {
      id: rawData.bike.id,
      name: rawData.bike.license_plate,
    },
    sim_card: rawData.sim_card && {
      id: rawData.sim_card.id,
      name: rawData.sim_card.iccid,
    },
  };
};

const parseToBackend = function (rawData) {
  return {
    id: rawData.id,
    status: rawData.status,
    bike_id: get(rawData, 'bike.id', null),
    sim_card_id: get(rawData, 'sim_card.id', null),
  };
};

export {
  parseFromBackend,
  parseToBackend,
};
