import React from 'react';
import { createUseStyles } from 'react-jss';
import TextInputSearch from 'components/Inputs/TextInputSearch';
import styles from './styles';
import { noop } from 'lodash';

const useStyles = createUseStyles(styles);

const TableSearch = ({
  globalFilter,
  setGlobalFilter,
  handleTextSearch = noop
}) => {
  const [textValue,setTextValue] = React.useState(globalFilter ? globalFilter:undefined);
  const classes = useStyles();
  const handleChange = React.useCallback(value => {
    // setGlobalFilter(value || undefined);
    setTextValue(value|| undefined);
    handleTextSearch(value || undefined);
  }, []);
  const handleReset = React.useCallback(() => {
    // setGlobalFilter();
    setTextValue(undefined)
    handleTextSearch(undefined);
  }, []);

  return (
    <div className={classes.wrapper}>
      <TextInputSearch
        value={textValue}
        onChange={handleChange}
        onReset={handleReset}
        onEnterKey={handleChange}
      />
    </div>
  )
};

export default TableSearch;
