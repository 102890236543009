import React from "react";
import { Grid } from "@mui/material";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ButtonCureError from "components/Buttons/ButtonCureError";
import ButtonSiteUnlock from "components/Buttons/ButtonSiteUnlock";
import ButtonSiteForceUnlock from "components/Buttons/ButtonSiteForceUnlock";
import DockIcon from '@material-ui/icons/Dock';
import TvIcon from '@material-ui/icons/Tv';
import SimCardIcon from '@material-ui/icons/SimCard';
export default function PileInfoVersionv2({ item }) {
  const defaultValue = 'NA';
  return (
    <Grid container spacing={3}>
   
      {item.piles.map((p) => (
        <>
          <Grid item xs={12}>
            <Typography gutterBottom variant="h6" component="h2">
              Dock  ({p.pile_id})
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <DockIcon style={{float:"left",margin:'2px'}}/>
              <Typography gutterBottom variant="subtitle1" component="h2">
                ICPU
              </Typography>
              <Divider />
          </Grid>
          <Grid item xs={6} style={{ "padding-top": 0 }}>
            <Typography gutterBottom variant="subtitle2" component="h2">
              Software Version:
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ "padding-top": 0 }}>
            <Typography gutterBottom variant="subtitle2" component="h2">
              {p.psv ? p.psv : defaultValue}
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ "padding-top": 0 }}>
            <Typography gutterBottom variant="subtitle2" component="h2">
              Hardware Version:
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ "padding-top": 0 }}>
            <Typography gutterBottom variant="subtitle2" component="h2">
            {p.phv ? p.phv : defaultValue}
            </Typography>
          </Grid>


          <Grid item xs={12}>
          <TvIcon style={{float:"left",margin:'2px'}}/>
              <Typography gutterBottom variant="subtitle1" component="h2">
                Display
              </Typography>
              <Divider />
          </Grid>
          <Grid item xs={6} style={{ "padding-top": 0 }}>
            <Typography gutterBottom variant="subtitle2" component="h2">
              Software Version:
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ "padding-top": 0 }}>
            <Typography gutterBottom variant="subtitle2" component="h2">
              {p.dsv ? p.dsv : defaultValue}
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ "padding-top": 0 }}>
            <Typography gutterBottom variant="subtitle2" component="h2">
              Hardware Version:
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ "padding-top": 0 }}>
            <Typography gutterBottom variant="subtitle2" component="h2">
            {p.dhv ? p.dhv : defaultValue}
            </Typography>
          </Grid>


          <Grid item xs={12}>
          <SimCardIcon style={{float:"left",margin:'2px'}}/>
              <Typography gutterBottom variant="subtitle1" component="h2">
                EICC
              </Typography>
              <Divider />
          </Grid>
          <Grid item xs={6} style={{ "padding-top": 0 }}>
            <Typography gutterBottom variant="subtitle2" component="h2">
              Software Version:
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ "padding-top": 0 }}>
            <Typography gutterBottom variant="subtitle2" component="h2">
            {p.esv ? p.esv : defaultValue}
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ "padding-top": 0 }}>
            <Typography gutterBottom variant="subtitle2" component="h2">
              Hardware Version:
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ "padding-top": 0 }}>
            <Typography gutterBottom variant="subtitle2" component="h2">
            {p.ehv ? p.ehv : defaultValue}
            </Typography>
          </Grid>



        </>
      ))}
      
    </Grid>
  );
}
