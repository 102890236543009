const PREFIX = '$$_COMMON';
const SET_LOADER = `${PREFIX}/SET_LOADER`;
const SET_WS_LOCK_HEARTBEAT = `${PREFIX}/SET_WS_LOCK_HEARTBEAT`;
const SET_MODAL_DATA = `${PREFIX}/SET_MODAL_DATA`;

export {
  SET_LOADER,
  SET_MODAL_DATA,
  SET_WS_LOCK_HEARTBEAT,
};
