import axios from 'axios';
import get from 'lodash/get';
import { NotificationManager } from 'react-notifications';
import {
  BASE_URL,
  API_KEY,
} from 'utils/constants/request';

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 120000,
  headers: {
    'x-api-key': API_KEY   
  },
});

instance.interceptors.request.use((config) => {
  config.headers['userId'] = localStorage.getItem('userId')

  return config
})

instance.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 404) {
    // window.location.href = '#/not-found';
  } else if(error.response.status === 403){
    // forbidden
    localStorage.clear()    
    NotificationManager.error('You are not authorized to access. Please contact admin for access.');    
  } else if (error.response.status === 418 && error.response.data) {
    NotificationManager.error(get(error, 'response.data.detail', error.response.data.error));
  } else {
    NotificationManager.error(get(error, 'response.data.detail', error.message));
  }
  return Promise.reject(error);
});

export default instance;
