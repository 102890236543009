import React from 'react';
import { createUseStyles } from 'react-jss';
import styles from './styles';

const useStyles = createUseStyles(styles);

const PileStatus = ({ type, position = 'absolute' }) => {
  const classes = useStyles({ type, position });
  
  return (
    <div className={classes.wrapper}>
      {type}
    </div>
  )
};

export default PileStatus;
