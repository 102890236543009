import React from 'react';
import { createUseStyles } from 'react-jss';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { useForm } from 'react-final-form';
import { useLocation } from 'react-router-dom';
import AppFormGap from 'components/AppFormGap';
import TextInputForm from 'components/Inputs/TextInputForm';
import CellStatus from 'components/Cells/CellStatus';
import AppSelectForm from 'components/Inputs/AppSelectForm';
import AutocompleteSimCard from 'components/Inputs/AutocompleteSimCard';
import AutocompleteVehicle from 'components/Inputs/AutocompleteVehicle';
import { isCreatable, isViewable } from 'utils/helpers/form';
import { simpleMemoize } from 'utils/helpers/fn';
import { getLock } from 'utils/helpers/request/api/locks';
import { STATUSES } from 'utils/constants/lock/statuses';
import styles from './styles';

const useStyles = createUseStyles(styles);

const FormContentViewable = ({
  operation,
  item,
}) => {
  const location = useLocation();
  const classes = useStyles();
  const form = useForm();
  const isVehiclePage = React.useMemo(() => location.pathname ? location.pathname.includes('vehicles') : false, [ location ]);
  const isSimCardPage = React.useMemo(() => location.pathname ? location.pathname.includes('sim-cards') : false, [ location ]);
  const idAvailable = React.useCallback(simpleMemoize(async (value = '') => {
    if (value.trim().length === 0) return 'Required';
    
    if (value.trim() === item.id) return undefined;
    
    const lockItem = await getLock(value);
    
    if (!!lockItem) return 'Id taken';
  }), [ item.id ]);
  const filterForSimCard = React.useMemo(() => {
    return function (simCard) {
      if (!!simCard.lock) {
        return simCard.lock.id === item.id;
      }
      if (!simCard.router) return true;
      if (!simCard.lock) return true;
    };
  }, [ item ]);
  const filterForVehicle = React.useMemo(() => {
    return function (vehicle) {
      if (vehicle.lock_id) return vehicle.lock_id === item.id;
  
      return true;
    };
  }, [ item ]);
  
  React.useEffect(() => {
    if (!!item.id) {
      form.reset(item);
    }
  }, [ item ]);
  
  return (
    <div className={classes.wrapper}>
      <Typography variant="h5">
        Lock {isViewable(operation) && <CellStatus type={item.status}/>}
      </Typography>
      <AppFormGap />
      <TextInputForm
        name="id"
        label="Id"
        initialValue=""
        disabled
      />
      <AppFormGap />
      <AppSelectForm
        name="status"
        label="Status"
        options={STATUSES}
        disabled
      />
      {!isSimCardPage && (
        <>
          <AppFormGap />
          <AppFormGap />
          <Typography variant="h5">
            SIM Card
          </Typography>
          <AppFormGap />
          <AutocompleteSimCard
            disabled
            form={form}
            filterFn={filterForSimCard}
          />
        </>
        )
      }
      {!isVehiclePage && (
        <>
          <AppFormGap />
          <AppFormGap />
          <Typography variant="h5">
            Vehicle
          </Typography>
          <AppFormGap />
          <AutocompleteVehicle
            disabled
            form={form}
            filterFn={filterForVehicle}
          />
        </>
      )}
    </div>
  )
};

export default FormContentViewable;
