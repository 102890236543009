import React from "react";
import { revertRecord } from "../../../../utils/helpers/request/api/logEvents";
import LinearProgress from '@mui/material/LinearProgress';
import { DataGrid } from '@mui/x-data-grid';
import moment from "moment-timezone";
import Button from "@material-ui/core/Button";
// import React from 'react';
import PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import ReactDiffViewer from "react-diff-viewer";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Tooltip from "@material-ui/core/Tooltip";
import { NotificationManager } from "react-notifications";
import Link from "@material-ui/core/Link";

moment.tz.setDefault("Europe/Berlin");
export default function LogView(props) {
  // console.log(props);
  const [open, setOpen] = React.useState(false);

  const [currentRecord, setCurrentRecord] = React.useState(null);
  const [openAlertDialog, setOpenAlertDialog] = React.useState(false);
  const [currentRevertRecord, setCurrentRevertRecord] = React.useState(null);
  const handleClickOpenAlertDialog = (param) => {
    setOpenAlertDialog(true);
    // console.log(param);
    setCurrentRevertRecord(param);
  };

  const handleNoCloseAlertDialog = () => {
    setOpenAlertDialog(false);
    setCurrentRevertRecord(null);
    // console.log('no alert ');
  };
  const handleYesCloseAlertDialog = async () => {
    setOpenAlertDialog(false);
    // revert api call;
    // console.log(currentRevertRecord)
    let response = await revertRecord(currentRevertRecord.id);
    if (response && response.status != 200) {
      NotificationManager.error("Error occured while reverting");
    } else {
      NotificationManager.success("You have successfully reverted changes, please refresh this page to see logs");
    }
    setCurrentRevertRecord(null);
    // console.log('yes alert ');
  };

  const handleClickOpen = (param) => {
    // console.log(param);
    setCurrentRecord({
      oldState: param.row.old_state
        ? JSON.stringify(JSON.parse(param.row.old_state), null, 1)
        : "{}",
      newState: param.row.new_state
        ? JSON.stringify(JSON.parse(param.row.new_state), null, 1)
        : "{}",
    });
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };
  let rows = props.rows;
  const [sortModel, setSortModel] = React.useState([
    {
      field: "epochtime",
      sort: "desc",
    },
  ]);
  // let columns = [];
  let columns = [
    // {field: "id", headerName: "id", width: 200},

    {
      field: "ctgr",
      headerName: "Category",
      width: 150,
      renderCell: (param) => {
        try {

          return `${param.row.ctgr[0].toUpperCase()}${param.row.ctgr.slice(1)}`;
        } catch (error) {
          console.log(error);
          return "";
        }
      },
    },
    {
      field: "ctgr_id",
      headerName: "Identifier",
      width: 200,
      renderCell: (param) => {

        try {
          if (
            param.row.ctgr == "sites" &&
            props.siteViewLookUp &&
            props.siteViewLookUp[param.row.ctgr_id]
          ) {
            // /#/sites/view/9224963512041
            let id = props.siteViewLookUp[param.row.ctgr_id];
            return (
              <Link href={`#/sites/view/${id}`} target="_blank">
                {param.row.ctgr_id}
              </Link>
            );
          } else if (
            param.row.ctgr == "bikes" &&
            props.bikeViewLookUp &&
            props.bikeViewLookUp[param.row.ctgr_id]
          ) {
            let id = props.bikeViewLookUp[param.row.ctgr_id];
            return (
              <Link href={`#/vehicles/view/${id}`} target="_blank">
                {param.row.ctgr_id}
              </Link>
            );
          } else {
            return <div>{param.row.ctgr_id}</div>;
          }
        } catch (error) {
          console.log(error);
          return "";
        }

      },
    },
    {
      field: "op",
      headerName: "Operation",
      width: 250,
      renderCell: (param) => {
        try {
          return `${param.value[0].toUpperCase()}${param.value.slice(1)}`;
        } catch (error) {
          console.log(error);
          return "";
        }
      },
    },
    { field: "by", headerName: "Changed", width: 200 },
    {
      sort: "desc",
      field: "epochtime",
      headerName: "Timestamp",
      width: 350,
      valueGetter: (param) => {
        // console.log(param)
        try {

          return `${moment(Number(param.value)).format("DD/MM/YYYY h:mm:ss a")}`;
        } catch (error) {
          console.log(error);
          return "";
        }
      },
    },
    {
      field: "diff",
      headerName: "Difference",
      width: 200,
      renderCell: (param) => {
        try {
          return (
            <div>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClickOpen.bind(this, param)}
              >
                View
              </Button>
            </div>
          );

        } catch (error) {
          console.log(error);
          return "";
        }
      },
    },
    {
      field: "revert",
      headerName: "Revert",
      width: 150,
      renderCell: (param) => {
        try {

          if (param.row.op.indexOf("revert") == -1) {
            return (
              <div>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleClickOpenAlertDialog.bind(this, param)}
                >
                  Revert
                </Button>
              </div>
            );
          }
        } catch (error) {
          console.log(error);
          return "";
        }
      },
    },
  ];
  // console.log(rows,columns,sortModel)

  try {
    return (
      <div style={{ height: 400, width: "100%" }}>
        <SimpleDialog
          currentRecord={currentRecord}
          open={open}
          onClose={handleClose}
        />
        <AlertDialog
          handleClickOpenAlertDialog={handleClickOpenAlertDialog}
          handleNoCloseAlertDialog={handleNoCloseAlertDialog}
          handleYesCloseAlertDialog={handleYesCloseAlertDialog}
          openAlertDialog={openAlertDialog}
        />
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
          disableSelectionOnClick
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          loading={props.loading}
        />
      </div>
    );
  } catch (error) {
    console.log(error);
    return ;
  }

}

function SimpleDialog(props) {
  const { onClose, currentRecord, open } = props;
  const { oldState, newState } = currentRecord
    ? currentRecord
    : { oldState: {}, newState: {} };
  // console.log(oldState, newState);
  const handleClose = () => {
    onClose(currentRecord);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth={"xl"}
      maxWidth={"auto"}
    >
      <DialogTitle id="simple-dialog-title">
        Old Value Vs New Value{" "}
      </DialogTitle>
      <ReactDiffViewer
        oldValue={oldState}
        newValue={newState}
        splitView={true}
        showDiffOnly={true}
      />
    </Dialog>
  );
}

// SimpleDialog.propTypes = {
//   // onClose: PropTypes.func.isRequired,
//   // open: PropTypes.bool.isRequired,
//   // selectedValue: PropTypes.string.isRequired,
// };

function AlertDialog(props) {
  const {
    handleClickOpenAlertDialog,
    handleNoCloseAlertDialog,
    handleYesCloseAlertDialog,
    openAlertDialog,
  } = props;

  return (
    <div>
      {/* <Button
        variant="outlined"
        color="primary"
        onClick={handleClickOpenAlertDialog}
      >
        Open alert dialog
      </Button> */}
      <Dialog
        open={openAlertDialog}
        onClose={handleNoCloseAlertDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Revert"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to revert this activity ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleYesCloseAlertDialog} color="primary">
            Yes
          </Button>
          <Button onClick={handleNoCloseAlertDialog} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
