export const parseFromBackend = function ({ id, name, permissions }) {
  return {
    roleId: id,
    name,
    permissions: JSON.parse(permissions).actions,
  };
};

export const getOptions = () => {
  const menus = [
    { value: "view-home", label: "Home" },
    {
      value: "vehicles",
      label: "Vehicles",
      children: [
        {
          value: "create-vehicles",
          label: "Create",
        },
        {
          value: "view-vehicles",
          label: "View",
        },
        {
          value: "update-vehicles",
          label: "Update",
        },
        {
          value: "delete-vehicles",
          label: "Delete",
        },
        {
          value: "sync-vehicles",
          label: "Sync",
        },
      ],
    },
    {
      value: "sites",
      label: "Sites",
      children: [
        {
          value: "create-sites",
          label: "Create",
        },
        {
          value: "view-sites",
          label: "View",
        },
        {
          value: "update-sites",
          label: "Update",
        },
        {
          value: "delete-sites",
          label: "Delete",
        },
        {
          value: "sync-sites",
          label: "Sync",
        }
      ],
    },
    {
      value: "batteries",
      label: "Batteries",
      children: [
        {
          value: "create-batteries",
          label: "Create",
        },
        {
          value: "view-batteries",
          label: "View",
        },
        {
          value: "update-batteries",
          label: "Update",
        },
        {
          value: "delete-batteries",
          label: "Delete",
        },
      ],
    },
    {
      value: "locks",
      label: "Locks",
      children: [
        {
          value: "create-locks",
          label: "Create",
        },
        {
          value: "view-locks",
          label: "View",
        },
        {
          value: "update-locks",
          label: "Update",
        },
        {
          value: "delete-locks",
          label: "Delete",
        },
      ],
    },
    {
      value: "cities",
      label: "Cities",
      children: [
        {
          value: "create-cities",
          label: "Create",
        },
        {
          value: "view-cities",
          label: "View",
        },
        {
          value: "update-cities",
          label: "Update",
        },
        {
          value: "delete-cities",
          label: "Delete",
        },
      ],
    },
    {
      value: "routers",
      label: "Routers",
      children: [
        {
          value: "create-routers",
          label: "Create",
        },
        {
          value: "view-routers",
          label: "View",
        },
        {
          value: "update-routers",
          label: "Update",
        },
        {
          value: "delete-routers",
          label: "Delete",
        },
      ],
    },
    {
      value: "sim-cards",
      label: "Sim Cards",
      children: [
        {
          value: "create-sim-cards",
          label: "Create",
        },
        {
          value: "view-sim-cards",
          label: "View",
        },
        {
          value: "update-sim-cards",
          label: "Update",
        },
        {
          value: "delete-sim-cards",
          label: "Delete",
        },
      ],
    },
    {
      value: "vehicle-types",
      label: "Vehicle Types",
      children: [
        {
          value: "create-vehicle-types",
          label: "Create",
        },
        {
          value: "view-vehicle-types",
          label: "View",
        },
        {
          value: "update-vehicle-types",
          label: "Update",
        },
        {
          value: "delete-vehicle-types",
          label: "Delete",
        },
      ],
    },
    {
      value: "piles",
      label: "Piles",
      children: [
        {
          value: "create-piles",
          label: "Create",
        },
        {
          value: "view-piles",
          label: "View",
        },
        {
          value: "update-piles",
          label: "Update",
        },
        {
          value: "delete-piles",
          label: "Delete",
        },
      ],
    },
    {
      value: "pile-types",
      label: "PileTypes",
      children: [
        {
          value: "create-pile-types",
          label: "Create",
        },
        {
          value: "view-pile-types",
          label: "View",
        },
        {
          value: "update-pile-types",
          label: "Update",
        },
        {
          value: "delete-pile-types",
          label: "Delete",
        },
      ],
    },
    {
      value: "connectivity",
      label: "Connectivity",
      children: [
        {
          value: "create-connectivity",
          label: "Create",
        },
        {
          value: "view-connectivity",
          label: "View",
        },
        {
          value: "update-connectivity",
          label: "Update",
        },
        {
          value: "delete-connectivity",
          label: "Delete",
        },
      ],
    },
    {
      value: "user-groups",
      label: "User Groups",
      children: [
        {
          value: "create-user-groups",
          label: "Create",
        },
        {
          value: "view-user-groups",
          label: "View",
        },
        {
          value: "update-user-groups",
          label: "Update",
        },
        {
          value: "delete-user-groups",
          label: "Delete",
        },
      ],
    },
    {
      value: "discount-management",
      label: "Discount Management",
      children: [
        {
          value: "create-discount-management",
          label: "Create",
        },
        {
          value: "view-discount-management",
          label: "View",
        },
        {
          value: "update-discount-management",
          label: "Update",
        },
        {
          value: "delete-discount-management",
          label: "Delete",
        },
      ],
    },
    {
      value: "station-groups",
      label: "Station Groups",
      children: [
        {
          value: "create-station-groups",
          label: "Create",
        },
        {
          value: "view-station-groups",
          label: "View",
        },
        {
          value: "update-station-groups",
          label: "Update",
        },
        {
          value: "delete-station-groups",
          label: "Delete",
        },
      ],
    },
    {
      value: "roles",
      label: "Roles",
      children: [
        {
          value: "create-roles",
          label: "Create",
        },
        {
          value: "view-roles",
          label: "View",
        },
        {
          value: "update-roles",
          label: "Update",
        },
        {
          value: "delete-roles",
          label: "Delete",
        },
      ],
    },
    {
      value: "users",
      label: "Users",
      children: [
        {
          value: "create-users",
          label: "Create",
        },
        {
          value: "view-users",
          label: "View",
        },
        {
          value: "update-users",
          label: "Update",
        },
        {
          value: "delete-users",
          label: "Delete",
        },
      ],
    },
    {
      value: "partners",
      label: "Partners",
      children: [
        {
          value: "create-partners",
          label: "Create",
        },
        {
          value: "view-partners",
          label: "View",
        },
        {
          value: "update-partners",
          label: "Update",
        },
        {
          value: "delete-partners",
          label: "Delete",
        },
      ],
    },
    {
      value: "log-viewer",
      label: "Log Viewer",
      children: [
        {
          value: "view-log-viewer",
          label: "View",
        },
      ],
    },
    {
      value: "admin-activities",
      label: "Admin Activities",
      children: [
        {
          value: "view-admin-activities",
          label: "View",
        },
      ],
    },
    {
      value: "firmware-mgmt",
      label: "Firmware Management",
      children: [
        {
          value: "view-firmware-mgmt",
          label: "View",
        },
      ],
    },
    {
      value: "bulk-ota-site",
      label: "Bulk OTA Firmware Updates Site",
      children: [
        {
          value: "view-bulk-ota-site",
          label: "View",
        },
      ],
    },
    {
      value: "bulk-ota-track",
      label: "Track OTA Site Request",
      children: [
        {
          value: "view-bulk-ota-track",
          label: "View",
        },
      ],
    },
  ];

  const allMenus = [
    {
      value: "menus-actions",
      label: "Menus",
      children: menus,
    },
    {
      value: "router-restart",
      label: "Router Restart"      
    },
    {
      value: "wifi-master-restart",
      label: "Wifi Master Restart"
    },
    {
      value: "sonoff-restart",
      label: "Sonoff Restart",
    },
    {
      value: "clear-error-on-pile",
      label: "Clear Error on Pile"
    },
  ];

  return allMenus;
};
