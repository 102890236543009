import {
  ERROR,
  SUCCESS,
  WARNING,
} from 'utils/constants/statuses';

const getStatusFromEnergy = energyLevel => {
  if (energyLevel <= 20) return ERROR;
  if (energyLevel <= 40) return WARNING;
  return SUCCESS;
};

const initOptions = (bikes) => {
  let options = [];

  if (bikes && bikes.items) {
    bikes.items.forEach(({license_plate, mobile_bike_id}) =>
      options.push({ id: parseInt(mobile_bike_id), name: license_plate })
    );
  }

  return options;
};


export {
  getStatusFromEnergy,
  initOptions  
};
