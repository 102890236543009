import {
  SET_LOADER,
  SET_MODAL_DATA,
  SET_WS_LOCK_HEARTBEAT,
} from '../constants/common';

const initialState = {
  isLoading: false,
  modalData: null,
  lockHeartbeat: {},
};

function commonReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADER:
      return {
        ...state,
        isLoading: action.payload,
      };
    
    case SET_MODAL_DATA:
      return {
        ...state,
        modalData: action.payload,
      };
      
    case SET_WS_LOCK_HEARTBEAT:
      return {
        ...state,
        lockHeartbeat: {
          ...state.lockHeartbeat,
          [action.payload.id]: action.payload.ts,
        },
      };

    default:
      return state
  }
}

export default commonReducer;
