import get from 'lodash/get';

const parseDataFromBackend = rawData => {
  return {
    ...rawData,
    site: { id: get(rawData, 'site.id'), name: get(rawData, 'site.address', '') },
    sim_card: { id: get(rawData, 'sim_card.id'), name: get(rawData, 'sim_card.iccid', '') },
  };
};

const parseDataToBackend = rawData => {
  return {
    ...rawData,
    mac_address: rawData.mac_address.trim(),
    serial_number: rawData.serial_number.trim(),
    site_id: get(rawData, 'site.id'),
    sim_card_id: get(rawData, 'sim_card.id'),
  };
};

export {
  parseDataFromBackend,
  parseDataToBackend,
};
