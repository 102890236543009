import { COLOR_DEFAULT_TEXT } from 'utils/constants/colors';

export default {
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: COLOR_DEFAULT_TEXT,
    fontSize: 20,
  },
};
