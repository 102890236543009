import {
  COLOR_DEFAULT_TEXT,
  COLOR_PRIMARY_TEXT_2,
} from 'utils/constants/colors';

export default {
  location: {
    fontSize: 10,
    color: COLOR_PRIMARY_TEXT_2,
    textTransform: 'uppercase',
  },
  address: {
    fontWeight: 500,
    fontSize: 18,
    color: COLOR_DEFAULT_TEXT,
  },
};
