import React from 'react';
import { createUseStyles } from 'react-jss';
import noop from 'lodash/noop';
import Divider from '@material-ui/core/Divider';
import AppModal from 'components/Modals/AppModal';
import ButtonDefault from 'components/Buttons/ButtonDefault';
import styles from './styles';

const useStyles = createUseStyles(styles);

const ConfirmationModal = ({
  title,
  body,
  subModal = true,
  onApprove = noop,
  onReject = noop,
}) => {
  const classes = useStyles();

  return (
    <AppModal subModal={subModal}>
      <div className={classes.wrapperHeader}>
        <div className={classes.wrapperLeftHeader}>
          <div className={classes.title}>
            {title}
          </div>
        </div>
        <div className={classes.wrapperRightHeader}>
          <ButtonDefault onClick={onApprove}>
            Yes
          </ButtonDefault>
          <ButtonDefault onClick={onReject}>
            No
          </ButtonDefault>
        </div>
      </div>
      <Divider />
      <div className={classes.wrapperBody}>
        {body}
      </div>
    </AppModal>
  )
};

export default ConfirmationModal;
