import CRUModal from "components/Modals/CRUModal";
import React from "react";
import User from "../User";
import { usePermissions } from "react-admin";

const UsersModal = ({
  onClose,
  onDelete,
  onUpdate,
  disabled,
  onCreate,
  id,
  title,
}) => {
  const { permissions } = usePermissions();

  return (
    <CRUModal
      title={title}
      entity="User"
      disabled={disabled}
      onDelete={
        title !== "Add New User"
          ? permissions && permissions.indexOf("delete-users") > -1
            ? onDelete
            : false
          : undefined
      }
      onUpdate={title !== "Add New User" ? permissions && permissions.indexOf("update-users") > -1
      ? onUpdate
      : false : undefined}
      onCreate={title === "Add New User" ? permissions && permissions.indexOf("create-users") > -1
      ? onCreate
      : false : undefined}
      onClose={onClose}
    >
      <User id={id} disabled={disabled} />
    </CRUModal>
  );
};

export default UsersModal;
