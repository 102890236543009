import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import CustomDatePicker from "../CustomDatePicker";
import Chip from '@material-ui/core/Chip';
const data = {
  id: "ALL",
  name: "All Activities",
  children: [
    {
      id: "HEARTBEAT",
      name: "Heartbeats",
      children: [
        { id: "SITE_HEARTBEAT", name: "Site heartbeat" },
        { id: "BIKE_STATE", name: "Bike heartbeat" },
      ],
    },
    {
      id: "RENTAL",
      name: "Rental",
      children: [
        { id: "SITE_BIKE_RETURN", name: "Rental End" },
        { id: "RENTAL_START", name: "Rental Start" },
        { id: "SITE_PILE_UNLOCK", name: "Site Unlock" },
        { id: "FORCE_UNLOCK", name: "Site Force Unlock" },
        { id: "PARKING_END", name: "Parking End" },
        { id: "PARKING_START", name: "Parking Start" },
        { id: "RENTAL_START_FORCE", name: "Rental Start Force" },
        { id: "BIKE_UNLOCK", name: "Bike Unlock" },
        {
          id: "VEHICLE_PICK_UP_CONFIRMATION",
          name: "Picked Using Service Card",
        },
      ],
    },
    {
      id: "ROUTER",
      name: "Router",
      children: [{ id: "SITE_SETTING", name: "Site Restart" }],
    },
    {
      id: "OFFLINEONLINE",
      name: "Offline/Online",
      children: [
        { id: "SITE_ONLINE", name: "Site Online" },
        { id: "SITE_OFFLINE", name: "Site Offline" },
        { id: "BIKE_LOGIN", name: "Bike Login" },
      ],
    },
    {
      id: "MISC",
      name: "Misc",
      children: [
        { id: "SITE_FAULT_CLEARING", name: "Site Fault Clearing" },
        { id: "LOCK_DETECTION_STATUS", name: "Lock Dectection Status" },
        { id: "PILE_FAILURE_REPORT", name: "Pile Failure Report" },
        { id: "BIKE_LOCK_REPORT", name: "Bike Lock Report" },
      ],
    },
  ],
};

const useStyles = makeStyles({
  container: {
    width: 500,
  },
  root: {
    // height: 110,
    // flexGrow: 1,
    maxHeight: 400,
    overflowY: 'auto'
  },
  bar: {
    display: "flex",
    alignContent: "center",
    justifyContent: "space-between",
    // marginTop:"10px"
  },
});

export default function RecursiveTreeView(props) {
  const [selected, setSelected] = React.useState(props.events);
  const classes = useStyles();
  function getChildById(node, id) {
    let array = [];

    function getAllChild(nodes = null) {
      if (nodes === null) return [];
      array.push(nodes.id);
      if (Array.isArray(nodes.children)) {
        nodes.children.forEach((node) => {
          array = [...array, ...getAllChild(node)];
          array = array.filter((v, i) => array.indexOf(v) === i);
        });
      }
      return array;
    }

    function getNodeById(nodes, id) {
      if (nodes.id === id) {
        return nodes;
      } else if (Array.isArray(nodes.children)) {
        let result = null;
        nodes.children.forEach((node) => {
          if (!!getNodeById(node, id)) {
            result = getNodeById(node, id);
          }
        });
        return result;
      }

      return null;
    }

    return getAllChild(getNodeById(node, id));
  }

  function getOnChange(checked, nodes) {
    const allNode = getChildById(data, nodes.id);
    let array = checked
      ? [...selected, ...allNode]
      : selected.filter((value) => !allNode.includes(value));

    array = array.filter((v, i) => array.indexOf(v) === i);
    // additional logic to handle checked/unchecked of all (parent node)
    if (array.length < 25 && nodes.id != 'ALL' && array.indexOf('ALL') !=-1) {
      array = array.filter(v => v != 'ALL');
    } else if (array.length == 24 && nodes.id != 'ALL' && array.indexOf('ALL') == -1) {
      array.push('ALL');
    }
    setSelected(array);
    props.onChange({ events: array });
  }

  const renderTree = (nodes) => (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id}
      label={
        <FormControlLabel
          control={
            <Checkbox
              checked={selected.some((item) => item === nodes.id)}
              onChange={(event) =>
                getOnChange(event.currentTarget.checked, nodes)
              }
              onClick={(e) => e.stopPropagation()}
            />
          }
          label={<>{nodes.name}</>}
          key={nodes.id}
        />
      }
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  return (
    <div className={classes.bar}>
      <div className={classes.container}>
       
        <TreeView
          className={classes.root}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpanded={["0", "3", "4"]}
          defaultExpandIcon={<ChevronRightIcon />}
        >
          {renderTree(data)}
        </TreeView>
        {props.fromLocalPref &&  <Chip  color="primary" size="small" label="Loaded last used filters." />}
      </div>
      <div>
        <CustomDatePicker
          startDate={props.startDate}
          endDate={props.endDate}
          onChange={props.onChange}
          dpBtnText={props.dpBtnText}
          onClickSearch={props.onClickSearch}
          filterBy={props.filterBy}
        />
      </div>
    </div>
  );
}
