import React from 'react';
import { createUseStyles } from 'react-jss';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-final-form';
import AppFormGap from 'components/AppFormGap';
import TextInputForm from 'components/Inputs/TextInputForm';
import AppSelectForm from 'components/Inputs/AppSelectForm';
import { STATUSES } from 'utils/constants/lock/statuses';
import styles from './styles';

const useStyles = createUseStyles(styles);

const FormContentCreatable = ({ item }) => {
  const classes = useStyles();
  const form = useForm();
  
  React.useEffect(() => {
    if (!!item.id) {
      form.reset(item);
    }
  }, [ item ]);
  
  return (
    <div className={classes.wrapper}>
      <Typography variant="h5">
        Lock
      </Typography>
      <AppFormGap />
      <TextInputForm
        name="id"
        label="Id"
        disabled
      />
      <AppFormGap />
      <AppSelectForm
        name="status"
        label="Status"
        options={STATUSES}
      />
    </div>
  )
};

export default FormContentCreatable;
