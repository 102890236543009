import React from 'react';
import { createUseStyles } from 'react-jss';
import CurrentFilter from '../CurrentFilter';
import styles from './styles';

const useStyles = createUseStyles(styles);

const CurrentFilters = ({
  items,
  onDelete,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      {items.map(i => (
        <CurrentFilter
          id={i.id}
          fieldKey={i.key}
          fieldValue={i.value.label}
          onDelete={onDelete}
        />
      ))}
    </div>
  )
};

export default CurrentFilters;
