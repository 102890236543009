import React from 'react';
import { createUseStyles } from 'react-jss';
import VehicleTypeModal from 'components/Modals/VehicleTypeModal';
import ListVehicleTypes from './components/ListVehicleTypes';
import {
  useFetch,
  useCreateItem,
  useDeleteItems,
} from './utils/hooks';
import styles from './styles';

const useStyles = createUseStyles(styles);

const VehicleTypes = () => {
  const classes = useStyles();
  const [ modalData, setModalData ] = React.useState(null);
  const { items, fetch } = useFetch();
  const { deleteItems } = useDeleteItems();
  const { createItem } = useCreateItem();
  const handleDelete = React.useCallback(indexes => {
    const ids = indexes.map(i => items[+i].id);

    deleteItems(ids)
      .then(() => {
        fetch();
      });
  }, [ items ]);
  const handleCreate = React.useCallback(() => {
    setModalData({});
  }, []);
  const hideModal = React.useCallback(() => {
    setModalData();
  }, []);
  const handleCreateVehicleType = React.useCallback(({ values }) => {
    createItem(values.id)
      .then(() => {
        fetch();
        hideModal();
      })
      .catch(() => {

      });
  }, []);

  React.useEffect(() => {
    fetch();
  }, []);

  return (
    <div className={classes.wrapper}>
      <ListVehicleTypes
        items={items}
        onCreate={handleCreate}
        onDelete={handleDelete}
      />
      {!!modalData && (
        <VehicleTypeModal
          modalInitialData={modalData}
          onClose={hideModal}
          onCreate={handleCreateVehicleType}
        />
      )}
    </div>
  )
};

export default VehicleTypes;
