const FILTER_SORT_MAP = {
  id: {
    isFilterable: false,
  },
  site_id: {
    isSortable: false,
    isFilterable: false,
  },
  lat: {
    isSortable: false,
    isFilterable: false,
  },
  lon: {
    isSortable: false,
    isFilterable: false,
  },
  license_plate: {
    isSortable: false,
    isFilterable: false,
  },
  status: {
    isSortable: false,
  },
  docking_type_id: {
    isSortable: false,
  },
};

const LABEL_MAP = {
  docking_type_id: {
    label: 'Type',
  },
  status: {
    label: 'Status',
  },
};

export {
  FILTER_SORT_MAP,
  LABEL_MAP,
};
