import React from 'react';
import noop from 'lodash/noop';
import { createUseStyles } from 'react-jss';
import TextInput from 'components/Inputs/TextInput';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import styles from './styles';

const useStyles = createUseStyles(styles);

const TextInputSearch = ({
  value,
  onEnterKey= noop,
  onChange = noop,
  onReset = noop,
  placeholder = 'Search: type & press Enter.'
}) => {
  const text = React.useRef(null);
  const classes = useStyles();
  const handleChange = React.useCallback(e => {
    onChange(e.target.value);
  }, []);
  const params = React.useMemo(() => ({
    inputRef: text,
    value,
    placeholder,
    onChange: handleChange,
    onKeyPress: e => {
      if (e.key === 'Enter') {
        onEnterKey(e.target.value);
      }
    },
  }), [ value ]);
  const paramsInputProps = React.useMemo(() => ({
    value: value,
    endAdornment: value ? (
      <div
        className={classes.closeIcon}
        onClick={onReset}
      >
        <CloseIcon />
      </div>
    ) : (
      <SearchIcon />
    ),
  }), [ value ]);

  React.useEffect(() => {
    if (!value) text.current.value = '';
  }, [ value ]);


  return (
    <TextInput
      params={params}
      paramsInputProps={paramsInputProps}
    />
  );
};

export default TextInputSearch;
