import React from 'react';
import { useSelector } from 'react-redux';
import BatteryModal from 'components/Modals/BatteryModal';
import VehicleModal from 'components/Modals/VehicleModal';
import LockModal from 'components/Modals/LockModal';
import SiteModal from 'components/Modals/SiteModal';
import RouterModal from 'components/Modals/RouterModal';
import SimCardModal from 'components/Modals/SimCardModal';
import UserGroupModal from 'components/Modals/UserGroupModal';

import {
  BATTERY_MODAL,
  SITE_MODAL,
  VEHICLE_MODAL,
  LOCK_MODAL,
  ROUTER_MODAL,
  SIM_CARD_MODAL,
  USER_GROUP_MODAL,
} from 'utils/constants/modals';

const Modals = ({
  children,
  ...props
}) => {
  const modalData = useSelector(state => state.commonReducer.modalData);
  const modalProps = { ...modalData, ...props };
  
  if (!modalData) return <></>;
  if (modalData.type === LOCK_MODAL) return <LockModal { ...modalProps } />;
  if (modalData.type === BATTERY_MODAL) return <BatteryModal { ...modalProps } />;
  if (modalData.type === VEHICLE_MODAL) return <VehicleModal { ...modalProps } />;
  if (modalData.type === SITE_MODAL) return <SiteModal { ...modalProps } />;
  if (modalData.type === ROUTER_MODAL) return <RouterModal { ...modalProps } />;
  if (modalData.type === USER_GROUP_MODAL) return <UserGroupModal { ...modalProps } />;
  if (modalData.type === SIM_CARD_MODAL) return <SimCardModal { ...modalProps } />;
  return <></>;
};

export default Modals;
