import React from 'react';
import { createUseStyles } from 'react-jss';
import Link from '@material-ui/core/Link';
import CellHeartbeat from 'components/Cells/CellHeartbeat';
import SignalIcon from 'components/Icons/SignalIcon';
import styles from './styles';
import CellRouter from 'components/Cells/CellRouter';

const useStyles = createUseStyles(styles);

const CellHeartbeatStatus = ({ row }) => {
  const classes = useStyles();
  
  return !!row.original.router && (
    <div className={classes.wrapper}>
      <span className={classes.wrapperIconSignal}>
        <SignalIcon />
      </span>
      <div className={classes.wrapperInfo}>
        <CellHeartbeat ts={row.original.site_heartbeat_timestamp} />
        <div className={classes.routerInfo}>
          <CellRouter id={row.original.router.id}>
            {row.original.router.serial_number}
          </CellRouter>
        </div>
      </div>
    </div>
  )
};

export default CellHeartbeatStatus;
