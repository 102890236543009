import React from "react";
import { createUseStyles } from "react-jss";
import AppFormGap from "components/AppFormGap";
import ItsMyBikeInfo from "components/Info/ItsMyBikeInfo";
import ItsMyBikeRaw from "components/Info/ItsMyBikeRaw";
import styles from "./styles";
const useStyles = createUseStyles(styles);

const ItsMyBikeGeneral = ({ item }) => {
  const classes = useStyles();
  const handleCureError = React.useCallback(() => {}, []);

  return (
    <div className={classes.wrapper}>
      <div className={classes.wrapperHeader}>
        <div className={classes.headerWithIcon}>
          <div className={classes.header}>Its My Bike Info</div>
          
        </div>
      </div>
      
      <AppFormGap />

      <div className={classes.wrapperBody50}>
        <ItsMyBikeInfo
          trackerId={JSON.parse(item?.tracker_json).serialnumber}
          sampledAt={item?.tracker_position?.sampledAt}
          lat={item?.tracker_position?.lat}
          lon={item?.tracker_position?.lon}
        />
        {/* <ItsMyBikeRaw
        
        trackerData={item?.tracker_json}
        /> */}
      </div>
    </div>
  );
};

export default ItsMyBikeGeneral;
