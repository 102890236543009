import Logo from "components/SigoAppBar/components/Logo";
import React, { useEffect, useState } from "react";
import { useLogin } from "react-admin";
import GoogleLogin from "react-google-login";
import { createUseStyles, ThemeProvider } from "react-jss";
import { useHistory } from "react-router-dom";
import { getRole, searchRole } from "utils/helpers/request/api/auth";
import styles from "./styles";

const useStyles = createUseStyles(styles);

const LoginPage = ({ theme }) => {
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const login = useLogin();
  const history = useHistory();

  useEffect(() => {
    const idToken = localStorage.getItem("idToken");

    if (idToken) {
      history.push("/home");
    }
  }, [history]);

  const submit = (e) => {
    e.preventDefault();

    login({ username, password }).catch(() => {
      setErrorMessage("Invalid username or password!");
    });
  };

  const successResponse = async (googleUser) => {
    const idToken = googleUser.getAuthResponse().id_token;
    const { name, email } = googleUser.profileObj;

    try {
      const response = await searchRole({ email });
      console.log('response', response)

      const { role, permissions, id } = response.data;

      localStorage.setItem("name", name);
      localStorage.setItem("email", email);
      localStorage.setItem("idToken", idToken);
      localStorage.setItem("role", role);
      localStorage.setItem("permissions", permissions);
      localStorage.setItem("userId", id)

      history.push("/home");
    } catch (e) {
      const message = e.response.data;
      setErrorMessage(message);
    }
  };

  const setErrorMessage = (msg) => {
    setError(msg);
    setTimeout(() => setError(""), 10000);
  };

  const failureResponse = (error) => {
    console.log("Error occured while login to google account - ", error);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.container}>
        <div className={classes.logo}>
          <Logo />
        </div>

        {/* <form onSubmit={submit}>
          <div className={classes.formInput}>
            <label className={classes.label}> Username </label>
            <input
              name="username"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className={classes.input}
            />
          </div>
          <div className={classes.formInput}>
            <label className={classes.label}> Password </label>
            <input
              name="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={classes.input}
            />
          </div>

          <div className={classes.login}>
            <input
              type="submit"
              value="Login"
              className={classes.submit}
            ></input>
          </div>
        </form> */}

        <div className={classes.googleButton}>
          <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
            buttonText="Login with Google"
            onSuccess={successResponse}
            onFailure={failureResponse}
            cookiePolicy={"single_host_origin"}
          />
        </div>

        <div className={classes.error}>{error}</div>
      </div>
    </ThemeProvider>
  );
};

export default LoginPage;
