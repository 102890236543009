import React from 'react';
import ReactDOM from 'react-dom';
import { createUseStyles } from 'react-jss';
import mapboxgl from 'mapbox-gl';
import MarkerFactory from './components/MarkerFactory';
import styles from './styles';
import itsMyBikeMarker from '../../itmybike.png';
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const useStyles = createUseStyles(styles);
const mapState = {
  lng: 5,
  lat: 34,
  zoom: 12
};

const AppMap = ({
  lat,
  lon,
  markers,
}) => {
  const classes = useStyles();
  const mapContainerRef = React.useRef(null);
  const [ map, setMap ] = React.useState(null);
  const geojson = React.useMemo(() => {
    return markers.length && {
      type: 'FeatureCollection',
      features: markers.map(m => ({
        type: m.type,
        color: m.color,
        href: m.href,
        geometry: {
          type: 'Point',
          coordinates: [m.lon, m.lat]
        },
        properties: {title: (m.type ? m.type : '')}
      }))
    };
  }, [ markers ]);

  React.useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [mapState.lng, mapState.lat],
      zoom: mapState.zoom,
    });
    
    map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
    map.addImage('cs',itsMyBikeMarker);
    map.on('load', function () {
      setTimeout(() => {
        map.resize();
      }, 1500);
    });
    
    setMap(map);
    
    return () => map.remove();
  }, []);

  React.useEffect(() => {
    if (map && lat && lon) {
      map.panTo([lon, lat], { duration: 3000 });
    }
  }, [ lat, lon, map ]);
  
  React.useEffect(() => {
    if (!geojson) return;
    if (!map) return;
    geojson.features.forEach(function (marker) {
      const markerNode = document.createElement('div');
      
      ReactDOM.render(<MarkerFactory
        color={marker.color}
        href={marker.href}
        type={marker.type}
      />, markerNode);

      if (marker.properties.title == "itsmybike") {
        // custom marker for its my bike
        const el = document.createElement("div");
        const width = 30;
        const height = 30;
        el.className = "marker";
        el.style.backgroundImage = `url(${itsMyBikeMarker})`;
        el.style.width = `${width}px`;
        el.style.height = `${height}px`;
        el.style.backgroundSize = "100%";

        new mapboxgl.Marker(el)
          .setLngLat(marker.geometry.coordinates)
          .addTo(map);
      } else {
        new mapboxgl.Marker(markerNode)
          .setLngLat(marker.geometry.coordinates)
          .addTo(map);
      }

    });
  }, [ geojson ]);
  
  return (
    <div
      className={classes.wrapper}
      ref={mapContainerRef}
    />
  );
};

export default AppMap;
