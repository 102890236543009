import React from 'react';
import { createUseStyles } from 'react-jss';
import { SimpleForm } from 'react-admin';
import AppFormHeader from 'components/AppFormHeader';
import styles from './styles';

const useStyles = createUseStyles(styles);

const AppForm = ({
  children,
  entity,
  title,
  onCreate,
  onTrigger,
  onClose,
  onUpdate,
  onDelete,
  onEdit,
  onView,
  onUpdateAndClose,
  disabled,
  onLogView,
  operation,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <SimpleForm
        { ...rest }
        toolbar={false}
      >
        {operation == "view"? <></> : <AppFormHeader
          entity={entity}
          title={title}
          status={rest.initialValues?.status}
          disabled={disabled}
          onCreate={onCreate}
          onTrigger={onTrigger}
          onClose={onClose}
          onDelete={onDelete}
          onUpdate={onUpdate}
          onEdit={onEdit}
          onView={onView}
          onUpdateAndClose={onUpdateAndClose}
          onLogView={onLogView}
        />}
        {children}
      </SimpleForm>
    </div>
  )
};

export default AppForm;
