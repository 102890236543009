import React from 'react';
// import ButtonDefault from 'components/Buttons/ButtonDefault';
// import { useTrigger } from './utils/hooks';
import Button from "@material-ui/core/Button";
import ReplayIcon from '@material-ui/icons/Replay';
import { NotificationManager } from "react-notifications";
import { sendSMS } from "utils/helpers/request/api/routers";
import { usePermissions } from "react-admin";
/**
 * Component that triggers restart site.
 * @param {id} id 
 * @returns Restart site button
 */
const ButtonSiteRebootRouter = ({ item ,classStyle,setModalData}) => {
  const { permissions } = usePermissions();
  const handleModalApprove = (title, body, action) => {
    setModalData({
      title,
      body,
      onApprove: () => {
        action();
        setModalData(null);
      },
    });
  };
  const rebootRouter = async () => {
    try {
      const iccid = item?.sim_card?.name;

      if (iccid) {
        const res = await sendSMS(iccid, {
          payload: `fuvpac-gohco9-bocQyw reboot`,
        });
        // console.log(res);
        NotificationManager.success("sms send successfully");
      }
    } catch (error) {
      console.log(error);
      NotificationManager.error("Rebooting router failed");
    }
  };

  let disabled = false;
  if (permissions && permissions.indexOf("router-restart") > -1 && item?.sim_card?.name !== ""){
    disabled = false;
  } else {
    disabled = true;
  }
  return (
    <Button 
    onClick={() =>
      handleModalApprove(
        "Reboot Router",
        "Are you sure you want to continue?",
        rebootRouter
      )
    }
    className={classStyle} 
    variant="contained"
    disabled= {disabled}  
    >
      Reboot Router
    </Button>
  )
};

export default ButtonSiteRebootRouter;
