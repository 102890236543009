import { useDispatch } from 'react-redux';
import { setLoader } from 'store/actions/common';
import { removeSiteFromUserGroup } from 'utils/helpers/request/api/userGroups';

const useTrigger = (site, userGroupId) => {
  const dispatch = useDispatch();

  const trigger = async function() {
    dispatch(setLoader(true))

    try {
      await removeSiteFromUserGroup(site.id, userGroupId)
      
      window.location.reload(true)

    } catch (err) {
      console.log('error: ', err )
    }
    
    dispatch(setLoader(false))
  };
  
  return {
    trigger,
  };
};

export {
  useTrigger,
};
