import React from 'react';
import { createUseStyles } from 'react-jss';
import AppFormGap from 'components/AppFormGap';
import BikeInfoGeneral from './components/BikeInfoGeneral';
import LockInfoGeneral from './components/LockInfoGeneral';
import StationInfoGeneral from './components/StationInfoGeneral';
import BikeLocation from './components/BikeLocation';
import styles from './styles';
import ItsMyBikeGeneral from './components/ItsMyBikeGeneral';
const useStyles = createUseStyles(styles);

const FormContentViewable = ({ item,id }) => {
  const classes = useStyles();
  
  return (
    <div className={classes.wrapper}>
      <div className={classes.wrapperRow}>
        <div className={classes.wrapperLeft}>
          <BikeInfoGeneral item={item} id={id} />
          <AppFormGap />
          <AppFormGap />
          <LockInfoGeneral item={item} />
        </div>
        <div className={classes.wrapperRight}>
          <BikeLocation item={item} />
        </div>
      </div>
      {!!item?.site && (
        <>
          <AppFormGap />
          <AppFormGap />
          <StationInfoGeneral item={item} />
        </>
      )}
      {!!item?.tracker_json && (
        <>
          <AppFormGap />
          <AppFormGap />
          <ItsMyBikeGeneral item={item} />
        </>
      )}
    </div>
  )
};

export default FormContentViewable;
