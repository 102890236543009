import React from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import LinkView from 'components/Links/LinkView';
import { setModalData } from 'store/actions/common';
import { LOCK_MODAL } from 'utils/constants/modals';
import styles from './styles';

const useStyles = createUseStyles(styles);

const LinkLock = ({ id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleClick = React.useCallback(() => {
    dispatch(setModalData({ type: LOCK_MODAL, id }));
  }, []);
  
  return (
    <LinkView onClick={handleClick}>
      Lock
    </LinkView>
  )
};

export default LinkLock;
