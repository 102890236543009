import React from 'react';
import { useDelete } from 'utils/hooks/battery';
import ListWrapper from 'components/PageWrapper';
import ListBatteries from './components/ListBatteries';
import { useFetchItems } from './utils/hooks';

const Batteries = () => {
  const { items, fetch } = useFetchItems();
  const { deleteItems } = useDelete();
  const handleDeleteItems = React.useCallback(indexes => {
    const ids = indexes.map(i => items[+i].id);
    
    deleteItems(ids)
      .then(() => {
        fetch();
      });
  }, [ items ]);

  React.useEffect(() => {
    fetch();
  }, []);

  return (
    <ListWrapper fetchItems={fetch}>
      <ListBatteries
        items={items}
        onDelete={handleDeleteItems}
      />
    </ListWrapper>
  )
};

export default Batteries;
