import get from 'lodash/get';

const parseFromBackend = rawData => {
  const data = {
    id: rawData.id,
    lat: rawData.lat,
    lon: rawData.lon,
    street: rawData.street,
    address: rawData.address,
    version: rawData.version,
    status: rawData.status,
    icpu: rawData.icpu && rawData.icpu.length === 0 ? 'http://' : rawData.icpu.startsWith('http://') || rawData.icpu.startsWith('https://') ? rawData.icpu : 'http://' + rawData.icpu,
    display: rawData.display,
    wifi_master: rawData.wifi_master,
    reset_controller: rawData.reset_controller,
    year_of_construction: rawData.year_of_construction,
    partner: rawData.partner,
    city: !!rawData.city ? {
      id: rawData.city.id,
      name: rawData.city.name,
    } : {
      id: null,
      name: ''
    },
    sim_card: !!rawData.sim_card ? {
      id: rawData.sim_card.id,
      name: rawData.sim_card.iccid,
    } : {
      id: null,
      name: ''
    },
    router: !!rawData.router ? {
      id: rawData.router.id,
      name: rawData.router.serial_number,
    } : {
      id: null,
      name: ''
    },
    piles: rawData.piles,
    userGroups: rawData.userGroups,
    onlineStatus: rawData.onlineStatus ? 'ONLINE' : 'OFFLINE',
    msv : rawData.msv ,
    mhv : rawData.mhv ,
    sdsv : rawData.sdsv,
    sdhv : rawData.sdhv,
    rsv : rawData.rsv ,
    rhv : rawData.rhv ,
  }

  // console.log(data)
  return data
};

const parseToBackend = rawData => {
  return {
    id: rawData.id,
    lat: rawData.lat,
    lon: rawData.lon,
    street: rawData.street,
    status: rawData.status,
    icpu: rawData.icpu,
    display: rawData.display,
    wifi_master: rawData.wifi_master,
    reset_controller: rawData.reset_controller,
    year_of_construction: rawData.year_of_construction,
    version: rawData.version,
    partner: rawData.partner,
    username: rawData.username,
    city_id: get(rawData, 'city.id'),
    sim_card_id: get(rawData, 'sim_card.id'),
    router_id: get(rawData, 'router.id'),
  };
};

export {
  parseToBackend,
  parseFromBackend,
};
