import React from 'react';
import CellStatusCommon from 'components/Cells/CellStatus';

const CellStatus = ({ row }) => {
  return (
    <CellStatusCommon type={row.original.status}/>
  );
};

export default CellStatus;
