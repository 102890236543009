import React from 'react';
import ButtonDefault from 'components/Buttons/ButtonDefault';
import { useTrigger } from './utils/hooks';

const ButtonSiteForceUnlock = ({ id }) => {
  const { trigger } = useTrigger(id);
  const onClick = React.useCallback(() => {
    trigger();
  }, []);
  
  return (
    <ButtonDefault onClick={onClick}>
      Force unlock
    </ButtonDefault>
  )
};

export default ButtonSiteForceUnlock;
