import { useDispatch } from 'react-redux';
import {
  deleteBike,
  getBike,
  getBikes,
} from 'utils/helpers/request/api/bikes';
import { useFetchItemWrapper } from 'utils/hooks/fetch';
import { parseFromBackend } from 'utils/helpers/vehicle';
import { useDeleteItemsWrapper } from 'utils/hooks/delete';
import { useFetchItemsWrapper } from 'utils/hooks/fetch';
import { useState } from 'react';
import { setLoader } from 'store/actions/common';
import { initOptions } from 'pages/Vehicles/utils/helpers';

const useFetchItems = () => {
  return useFetchItemsWrapper(getBikes);
};

const getCombinedRequest = id => {
  // return getBikes()
  //   .then(({ data: { items: bikes } }) => {
  //     return Promise.all([getBike(id), Promise.resolve(bikes)]);
  //   })
  //   .then(responses => {
  //     const [{ data: bike }, bikes] = responses;

  //     return { data: [bike, bikes] };
  //   });

    return getBike(id)
    .then(({ data: bike }) => {
      // return Promise.all([getBike(id), Promise.resolve(bikes)]);
      return [{ data: bike },[]]
    })
    .then(responses => {
      const [{ data: bike }, bikes] = responses;

      return { data: [bike, bikes] };
    });
};

const useFetchItem = id => useFetchItemWrapper(getCombinedRequest, id, {
  id: null,
  hardware_id: null,
  mobile_id: null,
  vehicle_type: null,
  gps_tracker_id: null,
  controller_id: null,
  converter: null,
  year_of_construction: null,
  akku_id: null,
  bms_id: null,
  open_vpn_ip: null,
  motor_id: null,
  frame_number: null,
  used_vehicle_types: [],
  battery: {
    id: null,
    energy_level: 0,
  },
  site: {
    id: null,
    name: '',
  },
  lock: {
    id: null,
    name: '',
  },
  router: {
    id: null,
    name: '',
  },
  tracker_battery:null,
  tracker_position:null,
  tracker_json:null,
  default_site_address:null,
  is_inside_pile:null,
  home_station: null,
  last_used: null,
}, parseFromBackend);

const useDelete = () => useDeleteItemsWrapper(deleteBike);

export const useOptionsItem = () => {
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();

  const fetchBikeOptions = async () => {
    dispatch(setLoader(true));

    try {
      console.log('1')
      const { data } = await getBikes();

      const result = initOptions(data)

      setOptions(result);

    } catch (error) {
      console.log("Something unexpected happened. Error: ", error);
    }

    dispatch(setLoader(false));
  };

  return {
    options,
    fetchBikeOptions,
  };
};


export {
  useDelete,
  useFetchItem,
  useFetchItems,
};
