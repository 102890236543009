import {
  COLOR_SECONDARY,
  COLOR_PRIMARY_BACKGROUND,
} from 'utils/constants/colors';

export default {
  wrapper: {
    backgroundColor: COLOR_PRIMARY_BACKGROUND,
    color: COLOR_SECONDARY,
    fontSize: 10,
    height: 32,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: 10,
  },
};
