import React from 'react';
import { createUseStyles } from 'react-jss';
import noop from 'lodash/noop';
import Link from '@material-ui/core/Link';
import { OPERATION } from 'utils/constants/statuses';
import styles from './styles';

const useStyles = createUseStyles(styles);

const CellIconLink = ({
  href,
  Icon,
  children,
  target,
  onClick = noop,
  status = OPERATION,
}) => {
  const classes = useStyles({ status });
  
  return (
    <Link
      href={href}
      onClick={onClick}
      className={classes.wrapper}
      target={target}
    >
      <span className={classes.wrapperIcon}>
        <Icon />
      </span>
      {children}
    </Link>
  )
};

export default CellIconLink;
