import React from "react";
import { Grid } from "@mui/material";
import Typography from "@material-ui/core/Typography";
import Divider from '@material-ui/core/Divider';
const WUNDER_BASE_URL =  process.env.REACT_APP_WUNDER_BASE_URL ? process.env.REACT_APP_WUNDER_BASE_URL: '';

export default function BikeInfoGeneralV2({ item, id }) {
  return (
    <Grid container spacing={3}>
    <Grid item xs={12}>
      <Typography gutterBottom variant="h6" component="h2">
       Informations
      </Typography>
      <Divider />
    </Grid>
    <Grid item xs={6}>
      <Typography gutterBottom variant="body1" component="h2">
        Vehicle Type
      </Typography>
    </Grid>
    <Grid item xs={6}>
      <Typography gutterBottom variant="body1" component="h2">
        {item?.vehicle_type?.id ? item?.vehicle_type.id : "NA"}
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
        Hardware Id
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
        {item?.hardware_id}
      </Typography>
    </Grid>

    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
        Wunder Id
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
        {item?.mobile_id ? <a href={`${WUNDER_BASE_URL}/car/${item?.mobile_id}`} target="_blank"> {item?.mobile_id}</a>:'NA'}
      </Typography>
    </Grid>
  </Grid>
  );
}
