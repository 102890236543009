import client from '../clientTest';

const getTestData  = () =>
  client
    .get('/records.json?page-size=5000')
    .then(({ data }) => ({
      data: {
        items: Object.keys(data).map((key) => data[key].item[0]),
      },
    }));

export {
  getTestData,
};
