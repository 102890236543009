import React from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import { setModalData } from 'store/actions/common';
import { LOCK_MODAL } from 'utils/constants/modals';
import CellView from 'components/Cells/CellView';
import { ReactComponent as LockIcon } from './images/icon.svg';
import { ReactComponent as UnlockIcon } from './images/icon_open.svg';
import styles from './styles';

const useStyles = createUseStyles(styles);

const CellLock = ({
  id,
  locked,
  children,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleClick = React.useCallback(e => {
    e.preventDefault();
    dispatch(setModalData({ type: LOCK_MODAL, id }));
  }, []);
  
  return (
    <CellView
      Icon={locked ? LockIcon : UnlockIcon}
      onClick={handleClick}
    >
      {children}
    </CellView>
  );
};

export default CellLock;
