import get from 'lodash/get';

const parseFromBackend = function (rawData) {
  return {
    id: rawData.id,
    status: rawData.status,
    bike: rawData.bike.id && {
      id: rawData.bike.id,
      name: rawData.bike.license_plate,
      status: rawData.bike.status,
    },
  };
};

const parseToBackend = function (rawData) {
  return {
    ...rawData,
  };
};

export {
  parseFromBackend,
  parseToBackend,
};
