import React from 'react';
import { createUseStyles } from 'react-jss';
import { usePermissions } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-final-form';
import AppFormGap from 'components/AppFormGap';
import TextInputForm from 'components/Inputs/TextInputForm';
import { requiredValidation } from 'utils/helpers/validations';
import styles from './styles';
import Forbidden from 'pages/Forbidden';

const useStyles = createUseStyles(styles);

const FormContentCreatable = ({ item }) => {
  const classes = useStyles();
  const form = useForm();
  const { permissions } = usePermissions()
  
  React.useEffect(() => {
    if (!!item.id) {
      form.reset(item);
    }
  }, [ item ]);
  
  return permissions && permissions.indexOf('create-sim-cards') > -1 ? (
    <div className={classes.wrapper}>
      <TextInputForm
        name="iccid"
        label="ICCID"
        fieldValidation={requiredValidation}
      />
    </div>
  ) : (
    <div className={classes.wrapper}>
      <Forbidden/>
    </div>
  )
};

export default FormContentCreatable;
