import React from 'react';
import { useDispatch } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import {
  deleteSimCard,
  getSimCard,
  getSimCards,
} from 'utils/helpers/request/api/simCards';
import { setLoader } from 'store/actions/common';
import { parseFromBackend } from 'utils/helpers/simCard';

const useFetchItems = () => {
  const [ items, setItems ] = React.useState([]);
  const dispatch = useDispatch();
  
  const fetch = async function() {
    dispatch(setLoader(true))
    
    try {
      const { data: { items: simCards } } = await getSimCards();
      
      setItems(simCards);
    } catch (e) {}
    
    dispatch(setLoader(false))
  };
  
  return {
    items,
    fetch,
  };
};

const useDelete = () => {
  const dispatch = useDispatch();
  
  const deleteItems = async function(ids) {
    dispatch(setLoader(true))
    
    try {
      for (const id of ids) {
        await deleteSimCard(id);
      }
      NotificationManager.success('Successfully  removed!');
    } catch (e) {}
    
    dispatch(setLoader(false))
  };
  
  return {
    deleteItems,
  };
};

const useFetchItem = () => {
  const [ item, setItem ] = React.useState({
    iccid: null,
    status: null,
    lock: null,
    router: null,
  });
  const dispatch = useDispatch();
  
  const fetch = async function(id) {
    dispatch(setLoader(true))
    
    try {
      const { data } = await getSimCard(id);
      if (!!data) {
        setItem(parseFromBackend(data));
      } else {
        NotificationManager.error('Not found');
      }
    } catch (e) {
      if (e.response.status === 404) window.location.href = '#/not-found';
    }
    
    dispatch(setLoader(false))
  };
  
  return {
    item,
    fetch,
  };
};

export {
  useFetchItem,
  useFetchItems,
  useDelete,
};
