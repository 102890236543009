import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import { Dropzone, FileMosaic } from "@files-ui/react";
import { uploadFirmware } from "../../../../utils/helpers/request/api/ota";
import FormData from "form-data";
import { NotificationManager } from "react-notifications";
import { setLoader } from "store/actions/common";
import { CircularProgress } from "@mui/material";
import Backdrop from "@material-ui/core/Backdrop";
import Fab from "@material-ui/core/Fab";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  loader: {
    width: "100%",
    height: "100%",
    color: "#fff",
    zIndex: "999 !important",
    backgroundColor: "rgba(255, 255, 255, 0.35) !important",
  },
  wrapper: {
    color: "#fff",
    zIndex: "999 !important",
    backgroundColor: "rgba(255, 255, 255, 0.35) !important",
  },
  wrapperIcon: {
    "& svg": {
      // color: COLOR_PRIMARY,
    },
  },
  uploadFirmwareBtn: {
    backgroundColor: "#2a966a",
    color: "#FFF",
  },
}));

const UploadFirmware = (props) => {
  const classes = useStyles();
  const [componentType, setComponentType] = React.useState("");
  const [firmwareFile, setFirmwareFile] = React.useState(null);
  const [fileVersion, setFileVersion] = React.useState("");
  const [fileDesc, setFileDesc] = React.useState("");
  const [age, setAge] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const handleFileVersionInputChange = (event) => {
    setFileVersion(event.target.value);
  };
  const handleFileDescInputChange = (event) => {
    setFileDesc(event.target.value);
  };
  const handleChange = (event) => {
    setComponentType(event.target.value);
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // clear select firmware file on close
    setFiles([]);
    setOpen(false);
  };
  const handleUpload = async () => {
    setIsLoading(true);
    // console.log(componentType);
    // console.log(fileVersion);
    // console.log(fileDesc);
    // console.log(files);
    let data = new FormData();
    data.append("ota_file", files[0].file);
    data.append("component_type", componentType);
    data.append("ota_file_ver", fileVersion);
    data.append("ota_file_desc", fileDesc);
    // let data = {
    //   "ota_file" : files,
    //   "component_type":componentType,
    //   "ota_file_ver": fileVersion,
    //   "ota_file_desc":fileDesc
    // }

    let result = await uploadFirmware(data);
    if (result == true) {
      NotificationManager.success("File uploaded to S3 successfully.");
      props.refresh();
    } else {
      NotificationManager.error("File upload Failed.Please try again");
    }
    setIsLoading(false);
    setOpen(false);

    // console.log(result);
  };
  const [files, setFiles] = React.useState([]);
  const updateFiles = (incommingFiles) => {
    //do something with the files
    setFiles(incommingFiles);
    //even your own upload implementation
  };
  const removeFile = (id) => {
    setFiles(files.filter((x) => x.id !== id));
  };
  return (
    <>
      <div>
        <div className={classes.root}>
          <Fab
            variant="extended"
            className={classes.uploadFirmwareBtn}
            onClick={handleClickOpen}
          >
            <CloudUploadIcon className={classes.extendedIcon} />
            <span style={{marginLeft:'5px'}}>Upload Firmware</span>
          </Fab>
        </div>
        {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          Open alert dialog
        </Button> */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className={classes.loader}>
            <Backdrop className={classes.wrapper} open={isLoading}>
              <div className={classes.wrapperIcon}>
                <CircularProgress />
              </div>
            </Backdrop>
          </div>
          <DialogTitle id="alert-dialog-title">{"Upload Firmware"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <FormControl variant="outlined" className={classes.formControl}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputLabel id="demo-simple-select-outlined-label">
                      Component Type
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={componentType}
                      onChange={handleChange}
                      label="Component Type"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {/* <MenuItem value={"terminal"}>Terminal</MenuItem>
                    <MenuItem value={"icpu"}>ICPU</MenuItem>
                    <MenuItem value={"display"}>Display</MenuItem>
                    <MenuItem value={"eicc"}>EICC</MenuItem>
                    <MenuItem value={"fontlibrary"}>Font Library</MenuItem>
                    <MenuItem value={"pcrd"}>PCRD</MenuItem>
                    <MenuItem value={"bdsf"}>BDS-F (BDS ICPU)</MenuItem>
                    <MenuItem value={"bdsd"}>BDS-D(BDS Site display controller)</MenuItem> */}
                      <MenuItem value={"msv"} selected={componentType == "msv"}>
                        Terminal
                      </MenuItem>
                      <MenuItem value={"psv"} selected={componentType == "psv"}>
                        ICPU
                      </MenuItem>
                      <MenuItem value={"dsv"} selected={componentType == "dsv"}>
                        Display
                      </MenuItem>
                      <MenuItem value={"esv"} selected={componentType == "esv"}>
                        EICC
                      </MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12}>
                    <Dropzone
                      onChange={updateFiles}
                      value={files}
                      maxFiles={1}
                      accept=".tlz"
                      label="Drop firmware file here"
                      autoClean
                    >
                      {files.map((file) => (
                        <FileMosaic
                          key={file.id}
                          {...file}
                          onDelete={removeFile}
                          info
                        />
                      ))}
                    </Dropzone>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label="Expected Version Post Update"
                      variant="outlined"
                      value={fileVersion}
                      onChange={handleFileVersionInputChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      multiline
                      rows={2}
                      id="outlined-basic"
                      label="Description"
                      variant="outlined"
                      value={fileDesc}
                      onChange={handleFileDescInputChange}
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="alert">
              Cancel
            </Button>
            <Button onClick={handleUpload} color="primary" autoFocus>
              Upload
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default UploadFirmware;
