import {
  ERROR,
  MAINTENANCE,
  ACTIVE,
  INVENTORY,
  TESTING,
  PRIVATE,
} from 'utils/constants/statuses';

const STATUSES = [
  { id: INVENTORY, name: INVENTORY },
  { id: TESTING, name: TESTING },
  { id: MAINTENANCE, name: MAINTENANCE },
  { id: ERROR, name: ERROR },
  { id: ACTIVE, name: ACTIVE },
  { id: PRIVATE, name: PRIVATE}
];

export {
  STATUSES,
};

