import {
  getBattery,
  deleteBattery,
} from 'utils/helpers/request/api/batteries';
import { useDeleteItemsWrapper } from 'utils/hooks/delete';
import { useFetchItemWrapper } from 'utils/hooks/fetch';
import { parseFromBackend } from 'utils/helpers/battery';

const useFetchItem = (id) => {
  return useFetchItemWrapper(getBattery, id, {}, parseFromBackend);
};

const useDelete = () => {
  return useDeleteItemsWrapper(deleteBattery);
};

export {
  useDelete,
  useFetchItem,
};
