import React from 'react';
import { createUseStyles } from 'react-jss';

import MUIDataTable from 'mui-datatables';
import { SimpleForm } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import AppSelectForm from 'components/Inputs/AppSelectForm';
import styles from './styles';
import DatePicker from 'components/Inputs/DatePicker';
import TextInputForm from 'components/Inputs/TextInputForm';
import AppSelect from 'components/Inputs/AppSelect';
import TextInput from 'components/Inputs/TextInput';
import ButtonDefault from 'components/Buttons/ButtonDefault';
import AppForm from 'components/AppForm';
import AsyncAutocompleteForm from 'components/Inputs/AsyncAutocompleteForm';
import { getTestData } from 'utils/helpers/request/api/test';
import Toolbar from 'components/List/Toolbar';
import CRUModal from 'components/Modals/CRUModal';
const validateUserCreation = (values) => {
  const errors = {};
  if (!values.test) {
    errors.test = 'The firstName is required';
  }
  return errors
};
const useStyles = createUseStyles(styles);

const columns = ["Name", "Company", "City", "State"];

const data = [
  ["Joe James", "Test Corp", "Yonkers", "NY"],
  ["John Walsh", "Test Corp", "Hartford", "CT"],
  ["Bob Herm", "Test Corp", "Tampa", "FL"],
  ["James Houston", "Test Corp", "Dallas", "TX"],
];

const options = {
  filterType: 'checkbox',
};

const UI = () => {
  const classes = useStyles();
  const [ isVisibleCRUModal, setIsVisibleCRUModal ] = React.useState(false);

  const handleSubmit = (values) => {
    console.log(values);
  };

  return (
    <div className={classes.wrapper}>
      {/*<Toolbar*/}
      {/*  label="Vehicles"*/}
      {/*  fields={[*/}
      {/*    {*/}
      {/*      id: 1,*/}
      {/*      label: 'Test1',*/}
      {/*      values: [*/}
      {/*        { id: 11, label: 'value1ForTest1' },*/}
      {/*        { id: 12, label: 'value2ForTest1' },*/}
      {/*        { id: 13, label: 'value3ForTest1' },*/}
      {/*      ],*/}
      {/*    },*/}
      {/*    {*/}
      {/*      id: 2,*/}
      {/*      label: 'Test2',*/}
      {/*      values: [*/}
      {/*        { id: 21, label: 'value1ForTest2' },*/}
      {/*        { id: 22, label: 'value2ForTest2' },*/}
      {/*        { id: 23, label: 'value3ForTest2' },*/}
      {/*      ],*/}
      {/*    },*/}
      {/*    {*/}
      {/*      id: 3,*/}
      {/*      label: 'Test3',*/}
      {/*      values: [*/}
      {/*        { id: 31, label: 'value1ForTest3' },*/}
      {/*        { id: 32, label: 'value2ForTest3' },*/}
      {/*        { id: 33, label: 'value3ForTest3' },*/}
      {/*      ],*/}
      {/*    },*/}
      {/*  ]}*/}
      {/*/>*/}
      <AppForm
        initialValue={{
          test: null,
        }}
        save={handleSubmit}
        validate={validateUserCreation}
      >
        <Typography variant="h5">
          Sub header 1
        </Typography>
        <DatePicker
          name="test1"
          label="Gender"
          classnameWrapper={classes.classnameWrapper1}
          options={[
            { id: 'M', name: 'Male' },
            { id: 'F', name: 'Female' },
          ]}
        />
        <div className={classes.gap} />
        <AsyncAutocompleteForm
          label="Test async autocomplete"
          asyncRequestFn={getTestData}
          onAddClick={() => {
            alert('create triggered');
          }}
          name="test3"
          initAsync
        />
        <div className={classes.gap} />
        <AppSelectForm
          name="test1"
          label="Gender"
          classnameWrapper={classes.classnameWrapper1}
          options={[
            { id: 'M', name: 'Male' },
            { id: 'F', name: 'Female' },
          ]}
        />
        <div className={classes.gap} />
        <AppSelect
          name="test1"
          label="Gender Error"
          error="Incorrect choice"
          classnameWrapper={classes.classnameWrapper1}
          options={[
            { id: 'M', name: 'Male' },
            { id: 'F', name: 'Female' },
          ]}
        />
        <div className={classes.gap} />
        <Typography variant="h5">
          Sub header 2
        </Typography>
        <TextInputForm
          name="test"
          label="Test label"
        />
        <div className={classes.gap} />
        <TextInput
          name="test2"
          error="Incorrect input"
          label="Test Error Label"
        />
        <div className={classes.gap} />
        <div>
          Buttons like this
        </div>
      </AppForm>
      <div className={classes.gap} />
      <div>
        Or buttons like this
      </div>
      <ButtonDefault>
        Save
      </ButtonDefault>
      <div className={classes.gap} />
      <ButtonDefault>
        Cancel
      </ButtonDefault>
      <div className={classes.gap} />
      {/*<MUIDataTable*/}
      {/*  title={"Employee List"}*/}
      {/*  data={data}*/}
      {/*  columns={columns}*/}
      {/*  options={options}*/}
      {/*/>*/}
      <ButtonDefault
        onClick={() => {
          setIsVisibleCRUModal(true);
        }}>
        Trigger CRU Modal
      </ButtonDefault>
      {isVisibleCRUModal &&
        <CRUModal
          title="Edit vehicle"
          entity="Vehicle"
          initialValues={{
            test: 'Test content',
          }}
          onDelete={() => {
            alert('delete');
          }}
          onUpdate={() => {
            alert('save');
          }}
          onClose={() => {
            setIsVisibleCRUModal(false);
          }}
        >
          <TextInputForm
            name="test"
            label="Test label"
          />
        </CRUModal>
      }
    </div>
  )
};

export default UI;
