import { CELL_LINK } from 'utils/constants/styles';

export default {
  wrapper: {
    ...CELL_LINK,
  },
  wrapperIconSignal: {
    '& svg': {
      width: '35px !important',
      height: '35px !important',
    },
  },
};
