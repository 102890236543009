import React from "react";
import { createUseStyles } from "react-jss";
import { usePermissions } from "react-admin";
import TableUncontrolled from "components/List/TableUncontrolled";
import { getFieldsFrom } from "utils/helpers/list";
import CellStatus from "components/Cells/CellStatus";
import { SIM_CARD_MODAL } from "utils/constants/modals";
import { FILTER_SORT_MAP, LABEL_MAP } from "../../utils/constants";
import CellRouter from "../CellRouter";
import CellICCID from "../CellICCID";
import CellLock from "../CellLock";
import styles from "./styles";

const useStyles = createUseStyles(styles);

const ListSimCards = ({ items, onDelete }) => {
  const classes = useStyles();
  const { permissions } = usePermissions();
  const fields = React.useMemo(
    () => getFieldsFrom(items, FILTER_SORT_MAP, LABEL_MAP),
    [items]
  );
  const columns = React.useMemo(
    () => [
      {
        accessor: "iccid",
        Cell: ({ row }) => <CellICCID row={row} />,
      },
      {
        accessor: "status",
        Cell: ({ row }) => <CellStatus type={row.original.status} />,
      },
      {
        accessor: "router",
        disableGlobalFilter: true,
        Cell: ({ row }) => <CellRouter row={row} />,
      },
      {
        accessor: "lock",
        disableGlobalFilter: true,
        Cell: ({ row }) => <CellLock row={row} />,
      },
    ],
    []
  );

  return (
    <TableUncontrolled
      isVisibleSort
      isVisibleFilter
      isCreatable={false}
      columns={columns}
      caption="All SIM Cards"
      entityType="sim-cards"
      totalCount={items.length}
      modalType={SIM_CARD_MODAL}
      onDelete={onDelete}
      fields={fields}
      rowsPerPage={20}
      data={items}
      isEditable={permissions && permissions.indexOf("update-sim-cards") > -1}
      isRemovable={permissions && permissions.indexOf("delete-sim-cards") > -1}
      isViewable={permissions && permissions.indexOf("view-sim-cards") > -1}
    />
  );
};

export default ListSimCards;
