import CRUModal from "components/Modals/CRUModal";
import { usePermissions } from "react-admin";
import React from "react";
import StationGroup from "../StationGroup";

const StationGroupModal = ({
  onClose,
  onDelete,
  onUpdate,
  disabled,
  onCreate,
  id,
  title,
}) => {
  const { permissions } = usePermissions();

  return (
    <CRUModal
      title={title}
      entity="StationGroup"
      disabled={disabled}
      onDelete={
        permissions && permissions.indexOf("delete-station-groups") > -1
          ? title !== "Create Station Group"
            ? onDelete
            : undefined
          : undefined
      }
      onUpdate={
        permissions && permissions.indexOf("update-station-groups") > -1
          ? title !== "Create Station Group"
            ? onUpdate
            : undefined
          : undefined
      }
      onCreate={
        permissions && permissions.indexOf("create-station-groups") > -1
          ? title === "Create Station Group"
            ? onCreate
            : undefined
          : undefined
      }
      onClose={onClose}
    >
      <StationGroup id={id} disabled={disabled} />
    </CRUModal>
  );
};

export default StationGroupModal;
