const parseFromBackend = function (rawData) {
  return rawData;
};

const parseToBackend = function (rawData) {
  return rawData;
};

export {
  parseFromBackend,
  parseToBackend,
};
