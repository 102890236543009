import React from 'react';
import { createUseStyles } from 'react-jss';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextInput from 'components/Inputs/TextInput';
import styles from './styles';

const filter = createFilterOptions();

const useStyles = createUseStyles(styles);

const AsyncInitAutocomplete = ({
  label,
  error,
  disabled,
  renderLink,
  value: valueArg,
  emptyMessage = 'None',
  asyncRequestFn,
  onChange,
  onUpdate,
  onAddClick,
}) => {
  const [ value, setValue ] = React.useState(valueArg);
  const [ open, setOpen ] = React.useState(false);
  const [ options, setOptions ] = React.useState([]);
  const loading = open && options.length === 0;
  const classes = useStyles();
  const renderInput = React.useCallback(params => {
    return (
      <div className={classes.wrapperInput}>
        <TextInput
          params={params}
          label={label}
          error={error}
          variant="outlined"
          paramsInputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {renderLink && (
                  <div className={classes.wrapperLink}>
                    {renderLink(params)}
                  </div>
                )}
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
        
      </div>
    );
  }, [ loading, error ]);
  const handleChange = React.useCallback((event, newValue) => {
    onUpdate(newValue);
    if (newValue && newValue.inputValue) {
      setOpen(true);
      setValue({
        ...newValue,
        name: newValue.inputValue,
      });
      onChange({
        name: newValue.inputValue,
      });
      onAddClick(newValue.inputValue);
    } else {
      setValue(newValue);
      onChange(newValue);
    }
  }, []);
  const handleOpen = React.useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);
  const handleGetOptionSelected = React.useCallback((option, value) => option.name === value.name, []);
  const handleGetOptionDisabled = React.useCallback(option => option.id === 'none', []);
  const handleFilterOptions = React.useCallback((options, params) => {
    const filtered = filter(options, params);

    if (params.inputValue !== '' && !filtered.find(f => f.name === params.inputValue)) {
      onAddClick && filtered.unshift({
        inputValue: params.inputValue,
        name: `Add "${params.inputValue}"`,
      });
    }

    return filtered;
  }, []);
  const handleGetOptionLabel = React.useCallback((option) => {
    return option ? option.name : '';
  }, []);

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const { data: { items } } = await asyncRequestFn();

      if (active) {
        setOptions(items.length === 0 ?  [ { id: 'none', name: emptyMessage } ] : items);
      }
    })();

    return () => {
      active = false;
    };
  }, [ loading ]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  React.useEffect(() => {
    setValue(valueArg);
  }, [ valueArg ]);
  
  return (
    <Autocomplete
      open={open}
      value={value}
      autoComplete
      disabled={disabled}
      includeInputInList
      onChange={handleChange}
      onOpen={handleOpen}
      onClose={handleClose}
      getOptionDisabled={handleGetOptionDisabled}
      getOptionSelected={handleGetOptionSelected}
      filterOptions={handleFilterOptions}
      getOptionLabel={handleGetOptionLabel}
      options={options}
      loading={loading}
      renderInput={renderInput}
    />
  )
};

export default AsyncInitAutocomplete;
