import {
  COLOR_DEFAULT_TEXT,
  COLOR_PRIMARY_BACKGROUND,
} from 'utils/constants/colors';

export default {
  wrapper: {

  },
  wrapperMore: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: COLOR_DEFAULT_TEXT,
    cursor: 'pointer',
  },
  wrapperPopover: {
    backgroundColor: `${COLOR_PRIMARY_BACKGROUND} !important`,
  },
};
