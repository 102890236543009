import { getColorForStatus } from 'utils/helpers/colorGetters';
import { COLOR_SECONDARY } from 'utils/constants/colors';

export default {
  wrapper: {
    // backgroundColor: ({ type }) => getColorForStatus(type),
    // color: COLOR_SECONDARY,
    // borderRadius: 4,
    // color: ({ type }) => getColorForStatus(type),
    // fontSize: 14,
    // width: 126,
    // height: 31,
    // display: 'flex',
    // flexDirection: 'row',
    // justifyContent: 'center',
    // alignItems: 'center',
    // textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  statusColor: {
    color: ({ type }) => getColorForStatus(type),
  }
};
