import { COLOR_PRIMARY } from 'utils/constants/colors';

export default {
  wrapper: {
    color: COLOR_PRIMARY,
    '&:hover': {
      textDecoration: 'underline',
    },
    fontWeight: 500,
    fontSize: 10,
  },
};
