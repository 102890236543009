import React from 'react';
import { createUseStyles } from 'react-jss';
import TableUncontrolled from 'components/List/TableUncontrolled';
import { getFieldsFrom } from '../../utils/helpers';
import styles from './styles';

const useStyles = createUseStyles(styles);

const ListPileTypes = ({
  items,
  onDelete,
  onCreate,
}) => {
  const classes = useStyles();
  const fields = React.useMemo(() => getFieldsFrom(items), [ items ]);
  const columns = React.useMemo(
    () => [
      {
        accessor: 'id',
      },
    ],
    [  ],
  );

  return (
    <TableUncontrolled
      isVisibleSort
      isEditable={false}
      isViewable={false}
      columns={columns}
      caption="All Vehicle Types"
      createCaption="Add Vehicle Type"
      entityType="vehicle-types"
      totalCount={items.length}
      onDelete={onDelete}
      onCreate={onCreate}
      fields={fields}
      rowsPerPage={20}
      data={items}
    />
  );
};

export default ListPileTypes;
