import React from "react";
import { createUseStyles } from "react-jss";
import Typography from "@material-ui/core/Typography";
import { useForm } from "react-final-form";
import AppFormGap from "components/AppFormGap";
import TextInputForm from "components/Inputs/TextInputForm";
import { requiredValidation } from "utils/helpers/validations";
import styles from "./styles";

const useStyles = createUseStyles(styles);

const FormContent = ({ item, disabled }) => {
  const classes = useStyles();
  const form = useForm();

  React.useEffect(() => {
    if (!!item.id) {
      form.reset(item);
    }
  }, [item]);

  return (
    <div className={classes.wrapper}>
      <AppFormGap />
      <Typography variant="h5">Connectivity</Typography>
      <AppFormGap />
      <TextInputForm name="id" type="hidden" />
      <AppFormGap />
      <TextInputForm
        disabled={disabled}
        name="mobile_base_url_api"
        label="Wunder URL"
        fieldValidation={requiredValidation}
      />
      <AppFormGap />
      <TextInputForm
        disabled={disabled}
        name="mobile_webhook_key"
        label="Wunder webhook key"
        fieldValidation={requiredValidation}
      />
      <AppFormGap />
      <TextInputForm
        disabled={disabled}
        name="mobile_hardware_key"
        label="Wunder hardware key"
        fieldValidation={requiredValidation}
      />
      <AppFormGap />
      <TextInputForm
        disabled={disabled}
        name="mobile_api_key"
        label="Wunder API key"
        fieldValidation={requiredValidation}
      />
      <AppFormGap />
      <TextInputForm
        disabled={disabled}
        name="api_key"
        label="API key"
        fieldValidation={requiredValidation}
      />
      <AppFormGap />
      <TextInputForm
        disabled={disabled}
        name="site_queue_url"
        label="SQS site url"
        fieldValidation={requiredValidation}
      />
      <AppFormGap />
      <TextInputForm
        disabled={disabled}
        name="bike_queue_url"
        label="SQS bike url"
        fieldValidation={requiredValidation}
      />
      <AppFormGap />
      <TextInputForm
        disabled={disabled}
        name="connector_queue_url"
        label="SQS connector url"
        fieldValidation={requiredValidation}
      />
      <AppFormGap />
      <TextInputForm
        disabled={disabled}
        name="cloudwatch_alerts_queue_url"
        label="SQS cloudwatch alerts url"
        fieldValidation={requiredValidation}
      />
    </div>
  );
};

export default FormContent;
