import React from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CustomizeIcon from 'components/Icons/CustomizeIcon';
import styles from './styles';
import { setModalData } from 'store/actions/common';

const useStyles = createUseStyles(styles);

const CellEditIcon = ({
  id = '#',
  entityType,
  isViewable,
  isEditable,
  onEdit,
  modalType,
  otherData,
  onView,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const editLink = React.useMemo(() => onEdit ? '#' : `#${entityType}/edit/${id}`, [ id, onEdit ]);
  const viewLink = React.useMemo(() => onView ? '#' : `#${entityType}/view/${id}`, [ id, onView ]);
  const handleEdit = React.useCallback(e => {
    e.preventDefault();
    onEdit(id);
  }, [ id ]);
  const handleView = React.useCallback(e => {
    e.preventDefault();
    
    if (modalType) {
      dispatch(setModalData({ type: modalType, ...otherData }));
    } else {
      onView(id);
    }
  }, [ id ]);
  
  return (
    <div className={classes.wrapper}>
      {isEditable && (
        onEdit ? (<div
          className={classes.wrapperIcon}
          onClick={handleEdit}
        >
          <a href={editLink}>
            <CustomizeIcon />
          </a>
        </div>) : (
          <div className={classes.wrapperIcon}>
            <a href={editLink}>
              <CustomizeIcon/>
            </a>
          </div>
        )
      )}
      {isViewable && (
        (!!onView || !!modalType) ? (
          <div
            className={classes.wrapperIcon}
            onClick={handleView}
          >
            {modalType ? (
              <ArrowForwardIosIcon/>
            ) : (
              <a href={viewLink}>
                <ArrowForwardIosIcon/>
              </a>
            )}
          </div>
        ) : (
          <div className={classes.wrapperIcon}>
            <a href={viewLink}>
              <ArrowForwardIosIcon/>
            </a>
          </div>
        )
      )}
    </div>
  )
};

export default CellEditIcon;
