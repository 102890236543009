import React from 'react';
import AsyncAutocompleteForm from 'components/Inputs/AsyncAutocompleteForm';
import { asyncRequestForSites } from 'utils/helpers/form';

const PileAddress = () => {
  return (
    <AsyncAutocompleteForm
      label="Address"
      asyncRequestFn={asyncRequestForSites}
      name="siteAddress"
      disabled
      initAsync
    />
  );
};

export default PileAddress;
