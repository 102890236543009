const AVAILABLE = 'AVAILABLE';
const RESERVED = 'RESERVED';
const RENTED = 'RENTED';
const IN_TRANSIT = 'IN_TRANSIT';
const UNKNOWN = 'UNKNOWN';
export {
    AVAILABLE,
    RESERVED,
    RENTED,
    IN_TRANSIT,
    UNKNOWN
};
