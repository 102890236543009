import React from "react";
import { Grid } from "@mui/material";
import Typography from "@material-ui/core/Typography";
import Divider from '@material-ui/core/Divider';
import EvStationIcon from '@material-ui/icons/EvStation';
import TvIcon from '@material-ui/icons/Tv';
import RefreshIcon from '@material-ui/icons/Refresh';
import { margin } from "@mui/system";
export default function StationVersionInfoV2({item}){
   const defaultValue = 'NA';
  return (
    <Grid container spacing={3}>
    <Grid item xs={12}>
      <EvStationIcon style={{float:"left",margin:'5px'}}/>
      <Typography gutterBottom variant="h6" component="h2">
       Site ({item.id})
      </Typography>
      <Divider />
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body2" component="h2">
        Software Version:
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body2" component="h2">
        {item.msv ? item.msv : defaultValue}
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body2" component="h2">
        Hardware Version:
      </Typography >
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body2" component="h2">
        {item.mhv ? item.mhv : defaultValue}
      </Typography>
    </Grid>
    {/* <Grid item xs={12} style={{ "padding-top": 0 }}>
    <TvIcon style={{float:"left",margin:'5px'}}/>
      <Typography gutterBottom variant="h6" component="h2">
        Display Controller
      </Typography>
      <Divider />
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body2" component="h2">
        Software Version:
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body2" component="h2">
        {item.sdsv? item.sdsv: defaultValue}
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body2" component="h2">
        Hardware Version:
      </Typography >
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body2" component="h2">
      {item.sdhv? item.sdhv: defaultValue}
      </Typography>
    </Grid> */}
    <Grid item xs={12} style={{ "padding-top": 0 }}>
    <RefreshIcon style={{float:"left",margin:'5px'}}/>
      <Typography gutterBottom variant="h6" component="h2">
        Reset Controller
      </Typography>
      <Divider />
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body2" component="h2">
        Software Version:
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body2" component="h2">
      {item.rsv? item.rsv: defaultValue}
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body2" component="h2">
        Hardware Version:
      </Typography >
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body2" component="h2">
      {item.rhv? item.rhv: defaultValue}
      </Typography>
    </Grid>

    
  </Grid>
  );
}