import { COLOR_PRIMARY_TEXT_2 } from 'utils/constants/colors';

export default {
  wrapper: {
  
  },
  wrapperContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    '& svg': {
      width: 40,
      height: 40,
    },
  },
  wrapperLabel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  label: {
    textTransform: 'uppercase',
    fontSize: 10,
    color: COLOR_PRIMARY_TEXT_2,
  },
  level: {
    fontWeight: 500,
    fontSize: 15,
  }
};
