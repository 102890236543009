import React from "react";
import { Grid } from "@mui/material";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ButtonCureError from "components/Buttons/ButtonCureError";
import ButtonSiteUnlock from "components/Buttons/ButtonSiteUnlock";
import ButtonSiteForceUnlock from "components/Buttons/ButtonSiteForceUnlock";

export default function PileInfoGeneralV2({ item }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography gutterBottom variant="h6" component="h2">
          Pile Informations
        </Typography>
        <Divider />
      </Grid>
      {item.piles.map((p) => (
        <>
          <Grid item xs={6} style={{ "padding-top": 0 }}>
            <Typography gutterBottom variant="body1" component="h2">
              Pile ID:
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ "padding-top": 0 }}>
            <Typography gutterBottom variant="body1" component="h2">
              {p.pile_id}
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ "padding-top": 0 }}>
            <Typography gutterBottom variant="body1" component="h2">
              License Plate:
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ "padding-top": 0 }}>
            <Typography gutterBottom variant="body1" component="h2">
              {p.bike_uuid ? <a href={`/#/vehicles/view/${p.bike_uuid}`} target="_blank">{p.bike_license_plate}</a> : "No Bike"}
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ "padding-top": 0 }}>
            <Typography gutterBottom variant="body1" component="h2">
              Action Cure:
            </Typography>
          </Grid>

          <Grid item xs={6} style={{ "padding-top": 0 }}>
            <ButtonCureError dockingId={p.pile_id} siteId={item.id} />
          </Grid>
          {!!p.bike_license_plate ? (
            <>
              <Grid item xs={6} style={{ "padding-top": 0 }}>
                <Typography gutterBottom variant="body1" component="h2">
                  Action Unlock:
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ "padding-top": 0 }}>
                <ButtonSiteUnlock id={item.id} />
              </Grid>
              <Grid item xs={6} style={{ "padding-top": 0 }}>
                <Typography gutterBottom variant="body1" component="h2">
                  Action Force Unlock:
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ "padding-top": 0 }}>
                <ButtonSiteForceUnlock id={item.id} />
              </Grid>
            </>
          ) : (
            <></>
          )}
          <Grid item xs={12} style={{ "padding-top": 0 }}>
            <Divider />
          </Grid>
        </>
      ))}
      {/* <Grid item xs={6}>
      <Typography gutterBottom variant="body1" component="h2">
        Sonoff:
      </Typography>
    </Grid>
    <Grid item xs={6}>
      <Typography gutterBottom variant="body1" component="h2">
        {item.sonoff? item.sonoff:'NA'}
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
        Street:
      </Typography >
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
        {item.street}
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
        City:
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
        {item?.city?.name ? item?.city?.name : 'NA'}
      </Typography>
    </Grid>

    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
        Latitude:
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
        {item?.lat}
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
        Longitude:
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
        {item?.lon}
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
        Partner:
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
        {item.partner ? item.partner: 'NA'}
      </Typography>
    </Grid>
    {/* <RouterInfo id={item?.router?.name} iccid={item?.sim_card?.name} /> */}
      {/*<Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
        Router:
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
        {item?.router?.name ? item?.router?.name: 'NA'}
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
        Sim Card:
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
        {item?.sim_card?.name ? item?.sim_card?.name: 'NA'}
      </Typography>
    </Grid> */}
    </Grid>
  );
}
