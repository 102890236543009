import { getColorForStatus } from 'utils/helpers/colorGetters';
import { COLOR_SECONDARY } from 'utils/constants/colors';

export default {
  wrapperOnline: {
    backgroundColor: ({}) => getColorForStatus('ONLINE'),
    color: COLOR_SECONDARY,
    borderRadius: 4,
    fontSize: 14,
    width: 126,
    height: 31,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  wrapperOffline: {
    backgroundColor: ({ }) => getColorForStatus('OFFLINE'),
    color: COLOR_SECONDARY,
    borderRadius: 4,
    fontSize: 14,
    width: 126,
    height: 31,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
};
