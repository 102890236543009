import { COLOR_BORDER } from 'utils/constants/colors';

export default {
  wrapper: {
    width: 2,
    height: 25,
    backgroundColor: COLOR_BORDER,
    margin: '0 30px',
  },
};
