import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import styles from './styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = createUseStyles(styles);

const CellStatusV2 = ({ type }) => {
  const classes = useStyles({ type });
  return (
    <div className={classes.wrapper}>
      {/* <FiberManualRecordIcon className={classes.statusColor}/> */}
      {type}
    </div>
   
  );
};

export default CellStatusV2;
