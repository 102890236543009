import React from "react";
import { createUseStyles } from "react-jss";
import { useHistory, useParams } from "react-router-dom";
import { usePermissions } from "react-admin";

import AppForm from "components/AppForm";
import PageWrapper from "components/PageWrapper";
import { isEditable, isViewable } from "utils/helpers/form";
import { useFetchItem, useDelete } from "utils/hooks/vehicle";
import { parseToBackend } from "utils/helpers/vehicle";
import { useFetchUsername } from "utils/hooks/fetch";
import { useUpdate, useCreate } from "./utils/hooks";
import FormContentEditable from "./components/FormContentEditable";
import FormContentViewable from "./components/FormContentViewable";
import FormContentCreatable from "./components/FormContentCreatable";
import styles from "./styles";

const useStyles = createUseStyles(styles);

const Vehicle = () => {
  const classes = useStyles();
  const { permissions } = usePermissions();
  const { id, operation } = useParams();
  const history = useHistory();
  const { item: username, fetch: fetchUserData } = useFetchUsername();
  const { item, fetch } = useFetchItem(id);
  const { deleteItems } = useDelete();
  const { updateItem } = useUpdate();
  const { createItem } = useCreate();
  const handleClose = React.useCallback(() => {
    history.push("/vehicles");
  }, []);
  const handleUpdateAndClose = React.useCallback(
    ({ values }) => {
      updateItem(parseToBackend({ ...values, username, id }))
        .then(() => {
          history.push("/vehicles");
        })
        .catch(() => {});
    },
    [username, id]
  );
  const handleUpdate = React.useCallback(
    ({ values }) => {
      updateItem(parseToBackend({ ...values, username, id })).catch(() => {});
    },
    [username, id]
  );
  const handleCreate = React.useCallback(
    ({ values }) => {
      createItem(parseToBackend({ ...values, username }))
        .then((id) => {
          history.push(`/vehicles/edit/${id}`);
        })
        .catch(() => {});
    },
    [username]
  );
  const handleDelete = React.useCallback(() => {
    deleteItems([id]).then(() => {
      handleClose();
    });
  }, [id]);

  React.useEffect(() => {
    if (id) {
      fetch();
    }
    fetchUserData();
  }, [id]);

  return (
    <PageWrapper>
      <AppForm
        title="Bike"
        entity="Vehicle"
        onClose={handleClose}
        onCreate={!id && handleCreate}
        onUpdate={
          permissions && permissions.indexOf("update-vehicles") > -1
            ? isEditable(operation) && handleUpdate
            : null
        }
        onUpdateAndClose={
          permissions && permissions.indexOf("update-vehicles") > -1
            ? isEditable(operation) && handleUpdateAndClose
            : null
        }
        onDelete={
          permissions && permissions.indexOf("delete-vehicles") > -1
            ? (isEditable(operation) || isViewable()) && handleDelete
            : null
        }
        operation={operation}
      >
        {operation === 'view' ?
          <FormContentViewable item={item} id={id} 
            title="Bike"
            entity="Vehicle"
          /> :
          (operation === 'edit' ? <FormContentEditable item={item} /> : <FormContentCreatable />)
        }
        
      </AppForm>
    </PageWrapper>
  );
};

export default Vehicle;
