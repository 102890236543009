import React from 'react';
import { createUseStyles } from 'react-jss';
import styles from './styles';

const useStyles = createUseStyles(styles);


const CellName = ({ row, onClick }) => {
  const classes = useStyles();

  return (
    <div 
    className={classes.wrapper}
    onClick={onClick} >
      {row}
    </div>
  );
};

export default CellName;
