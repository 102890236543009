import React from 'react';
import AsyncAutocompleteForm from 'components/Inputs/AsyncAutocompleteForm';
import { asyncRequestForBikes } from '../../utils/helpers';

const PileLicensePlate = ({ items }) => {
  return (
    <AsyncAutocompleteForm
      label="License plate"
      disabled
      asyncRequestFn={asyncRequestForBikes(items)}
      name="bikeLicensePlate"
      initAsync
    />
  )
};

export default PileLicensePlate;
