import React from 'react';
import { createUseStyles } from 'react-jss';
import AppMap from 'components/AppMap';
import { getColorForStatus } from 'utils/helpers/colorGetters';
import { MARKER_STATION } from 'utils/constants/markers';
import styles from './styles';

const useStyles = createUseStyles(styles);

const StationLocation = ({ item }) => {
  const classes = useStyles();
  const markers = React.useMemo(() => {
    if (!item.lat) return [];
    
    return [{
      color: getColorForStatus(item.status),
      type: MARKER_STATION,
      lat: item.lat,
      lon: item.lon,
    }];
  }, [ item ]);

  return (
    <div className={classes.wrapper}>
      <AppMap
        lat={item.lat}
        lon={item.lon}
        markers={markers}
      />
    </div>
  )
};

export default StationLocation;
