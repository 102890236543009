import React from 'react';
import {createUseStyles} from 'react-jss';
import styles from './styles';

const useStyles = createUseStyles(styles);

const CellReservationStatus = ({type}) => {
    const classes = useStyles({type});
    return (
        (type ? <div className={classes.wrapper}>
            {type}
        </div> : <div className={classes.textCenter}>
            N/A
        </div>)

    );
};

export default CellReservationStatus;
