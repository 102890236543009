import React from 'react';
import { createUseStyles } from 'react-jss';
import CellViewBike from 'components/Cells/CellVehicle';
import styles from './styles';

const useStyles = createUseStyles(styles);

const CellVehicle = ({ row }) => {
  const classes = useStyles();
  
  return row.original.bike && (
    <CellViewBike
      id={row.original.bike.id}
      status={row.original.bike.status}
    >
      <span className={classes.text}>
        {row.original.bike.license_plate || 'No license plate defined'}
      </span>
    </CellViewBike>
  );
};

export default CellVehicle;
