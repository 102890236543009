import React from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
    // maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const options = [
  "Status",
  "Connection State",
  "Number of Piles",
  "City",
  "Site",
];

const statusOptions = [
  "MAINTENANCE",
  "ACTIVE",
  "SHIPPING_MODE",
  "TESTING",
  "OUT_OF_ORDER",
  "FUEL_LEVEL_LOW",
  "INTERNAL_RESERVATION",
  "OFFLINE",
  "OPERATION",
];

const connectionStateOptions = ["ONLINE", "OFFLINE"];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function AdvancedSearchSites(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const [selectedStatus, setSelectedStatus] = React.useState([]);
  const [selectedConnectionState, setSelectedConnectionState] = React.useState(
    []
  );
  const [minPC, setMinPC] = React.useState(0);
  const [maxPC, setMaxPC] = React.useState(10);
  const [selectedCity, setSelectedCity] = React.useState([]);

  const cityOptionsSet = new Set(
    props.citiesFromDb
      .map((city) => city.name)
      .filter((city) => city != null)
  );
  // console.log(cityOptionsSet);
  const cityOptionsArray = new Array(...cityOptionsSet);
  // console.log(cityOptionsArray)
  const [selectedSites, setSelectedSites] = React.useState([]);
  let allObj = { id: "all", address: "All Sites" };
  // let siteList = props.sitesFromDb ? [allObj, ...props.sitesFromDb] : [];
  let siteList = props.siteAddressFromDb ? props.siteAddressFromDb  : []

  const handleClearFilter = () => {
    setSelectedOptions([]);
    setSelectedStatus([]);
    setSelectedConnectionState([]);
    setMinPC(0);
    setMaxPC(10);
    setSelectedCity([]);
    setSelectedSites([]);
    props.handleClearAdvSearch(['status','onlineStatus','piles','bikes_num','city_name','address']);
  };
  const handleSearch = () => {
    let filterObj = [];
    if (selectedOptions.length == 0){
      // no option selected for filter
      props.handleClearAdvSearch(['status','onlineStatus','piles','bikes_num','city_name','address']);

      return;
    }
    // status
    if (selectedOptions.includes('Status') &&
      selectedStatus.length > 0
    ) {
      filterObj.push({id:'status',value : selectedStatus});
    } else {
      filterObj.push({id:'status',value : undefined});
    }
    // connection state
    if (selectedOptions.includes('Connection State') &&
      selectedConnectionState.length > 0
    ) {
      filterObj.push({id:'onlineStatus',value:selectedConnectionState})
    } else  {
      filterObj.push({id:'onlineStatus',value:undefined})

    }
    // number of piles
    if (selectedOptions.includes('Number of Piles') &&
      (minPC != 0 || maxPC != 10)
    ) {
      filterObj.push({id:'bikes_num',value:[parseInt(minPC,10),parseInt(maxPC,10)]});
    } else {
      filterObj.push({id:'bikes_num',value:undefined});
    }
    // city
    if (selectedOptions.includes('City') &&
      selectedCity.length > 0
    ) {
      filterObj.push({id:'city_name',value:selectedCity})
    } else {
      filterObj.push({id:'city_name',value:undefined})
    }
    // sites
    if (selectedOptions.includes('Site') &&
      selectedSites.length > 0
    ) {
      let siteAddressList = [];
      selectedSites.forEach((site)=>{
        siteAddressList.push(site.address);
      })
      if (siteAddressList.includes('All Sites')){
        // siteAddressList = [];
        // props.sitesFromDb.forEach((site)=>{
        //   siteAddressList.push(site.address);
        // })
      } else {
        filterObj.push({id:'address',value:siteAddressList})
      }
    }
    // console.log(filterObj)
    props.handleAdvSearch(filterObj);
  };



  const handleChange = (event) => {
    setSelectedOptions(event.target.value);
    // console.log(event.target.value);
  };

  const handleStatusOptionChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleConnectionStateOptionChange = (event) => {
    setSelectedConnectionState(event.target.value);
  };

  const handleMinPCChange = (event) => {
    if (event.target.value < 0) {
      setMinPC(0);
    } else {
      setMinPC(event.target.value);
    }
  };

  const handleMaxPCChange = (event) => {
    if (event.target.value > 10) {
      setMaxPC(10);
    } else {
      setMaxPC(event.target.value);
    }
  };

  const handleCityOptionChange = (event) => {
    setSelectedCity(event.target.value);
  };

  const handleSiteOptionChange = (event, value) => {
    setSelectedSites(value);
  };

 
  return (
    <Container>
      <Paper elevation={1} style={{ margin: "10px", padding: "10px" }}>
        <Grid continer spacing={3}>
          <Grid item xs={12}>
            <MultiSelect
              value={selectedOptions}
              onChange={handleChange}
              label={"Advanced Search Filters"}
              options={options}
            />
          </Grid>
        </Grid>

        {selectedOptions.includes("Status") && (
          <Grid item xs={12}>
            <MultiSelect
              value={selectedStatus}
              onChange={handleStatusOptionChange}
              label={"Status"}
              options={statusOptions}
            />
          </Grid>
        )}

        {selectedOptions.includes("Connection State") && (
          <Grid item xs={12}>
            <MultiSelect
              value={selectedConnectionState}
              onChange={handleConnectionStateOptionChange}
              label={"Connection State"}
              options={connectionStateOptions}
            />
          </Grid>
        )}

        {selectedOptions.includes("Number of Piles") && (
          <Grid item xs={12}>
            <TextField
              id="minPC"
              label="Min Number of Piles"
              type="number"
              value={minPC}
              onChange={handleMinPCChange}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ marginLeft: "10px" }}
            />
            <span style={{ marginLeft: "10px" }}>To</span>
            <TextField
              id="maxPC"
              label="Max Number of Piles"
              type="number"
              value={maxPC}
              onChange={handleMaxPCChange}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ marginLeft: "10px" }}
            />
          </Grid>
        )}

        {selectedOptions.includes("City") && (
          <Grid item xs={12}>
            <MultiSelect
              value={selectedCity}
              onChange={handleCityOptionChange}
              label={"City"}
              options={cityOptionsArray}
            />
          </Grid>
        )}

        {selectedOptions.includes("Site") && (
          <Grid item xs={12}>
            <Autocomplete
              style={{ marginLeft: "10px" }}
              multiple
              id="tags-standard"
              options={siteList}
              getOptionLabel={(option) =>
                option.address ? option.address : "No Address"
              }
              // defaultValue={[bikeList[0]]}
              // value={selectedBikes}
              onChange={handleSiteOptionChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Select Sites"
                  placeholder="Sites"
                />
              )}
            />
          </Grid>
        )}

        <Grid item xs={12} style={{ padding: "10px" }}>
          <Button variant="contained" color="primary" onClick={handleSearch}>
            Search
          </Button>
          <Button
            variant="contained"
            style={{ marginLeft: "10px" }}
            onClick={handleClearFilter}
          >
            Clear Filters
          </Button>
        </Grid>
      </Paper>
    </Container>
  );
}

function MultiSelect(props) {
  const classes = useStyles();
  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="ads-state-mutiple-checkbox-label">
        {props.label}
      </InputLabel>
      <Select
        labelId="ads-state-mutiple-checkbox-label"
        id="ads-state-mutiple-checkbox"
        multiple
        value={props.value}
        onChange={props.onChange}
        input={<Input />}
        renderValue={(selected) => selected.join(", ")}
        MenuProps={MenuProps}
      >
        {props.options.map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={props.value.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
