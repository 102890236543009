import React from "react";
import moment from 'moment';
import { Grid } from "@mui/material";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import CellStatusV2 from "components/Cells/CellStatusV2";

export default function BikeCardInfoV2({ item, id }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography gutterBottom variant="h6" component="h2">
          Key Informations
        </Typography>

        <Divider />
      </Grid>
      <Grid item xs={6}>
        <Typography gutterBottom variant="body1" component="h2">
          Status:
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography gutterBottom variant="body1" component="h2">
          <CellStatusV2 type={item.status} />
        </Typography>
      </Grid>
      {item.tracker_json == null ? (
        <>
          <Grid item xs={6} style={{ "padding-top": 0 }}>
            <Typography gutterBottom variant="body1" component="h2">
              Tracker Status:
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ "padding-top": 0 }}>
            <Typography gutterBottom variant="body1" component="h2">
              No Tracker
            </Typography>
          </Grid>
        </>
      ) : (
        <></>
      )}

      <Grid item xs={6} style={{ "padding-top": 0 }}>
        <Typography gutterBottom variant="body1" component="h2">
          Battery:
        </Typography>
      </Grid>
      <Grid item xs={6} style={{ "padding-top": 0 }}>
        <Typography gutterBottom variant="body1" component="h2">
          {Math.floor(item?.energy_level || 0)}%
        </Typography>
      </Grid>
      {!!item.tracker_json && (
        <>
          <Grid item xs={6} style={{ "padding-top": 0 }}>
            <Typography gutterBottom variant="body1" component="h2">
              Tracker Battery:
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ "padding-top": 0 }}>
            <Typography gutterBottom variant="body1" component="h2">
              {Math.floor(item.tracker_battery)}%
            </Typography>
          </Grid>
        </>
      )}
      <>
        <Grid item xs={6} style={{ "padding-top": 0 }}>
          <Typography gutterBottom variant="body1" component="h2">
            Last Used:
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ "padding-top": 0 }}>
          <Typography gutterBottom variant="body1" component="h2">
            {item.last_used ? moment(item.last_used).fromNow() : 'N/A'}
          </Typography>
        </Grid>
      </>
    </Grid>
  );
}
