import {
  MAINTENANCE,
  ACTIVE,
  SHIPPING_MODE,
  TESTING,
  OUT_OF_ORDER,
  FUEL_LEVEL_LOW,
  INTERNAL_RESERVATION,
  OFFLINE,
  OPERATION,
} from 'utils/constants/statuses';

const STATUSES = [
  { id: ACTIVE, name: ACTIVE },
  { id: OPERATION, name: OPERATION },
  { id: MAINTENANCE, name: MAINTENANCE },
  { id: INTERNAL_RESERVATION, name: INTERNAL_RESERVATION },
  { id: OFFLINE, name: OFFLINE },
  { id: FUEL_LEVEL_LOW, name: FUEL_LEVEL_LOW },
  { id: OUT_OF_ORDER, name: OUT_OF_ORDER },
  { id: TESTING, name: TESTING },
  { id: SHIPPING_MODE, name: SHIPPING_MODE },
];

const WUNDER_RESERVATION_STATUS = [
  { id: 'AVAILABLE', name: 'AVAILABLE' },
  { id: 'IN_TRANSIT', name: 'IN_TRANSIT' },
  { id: 'RESERVED', name: 'RESERVED' },
  { id: 'RENTED', name: 'RENTED' },
  { id: 'UNKNOWN', name: 'UNKNOWN' },
];

export {
  STATUSES,
  WUNDER_RESERVATION_STATUS
};
