import React, { useEffect } from "react";
import { setLoader } from "store/actions/common";
import { useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { DataGrid } from "@mui/x-data-grid";
import { getSites } from "utils/helpers/request/api/sites";
import {
  listRequests,
  trackRequests,
  refreshRequestStatus,
} from "utils/helpers/request/api/ota";
import Chip from "@mui/material/Chip";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { Paper } from "@mui/material";
import Button from "@mui/material/Button";
import CachedIcon from "@material-ui/icons/Cached";
import { NotificationManager } from "react-notifications";
const UPGRADE_COMPONENT_NAME_TO_ID_MAPPING = {
  msv: { id: 1, name: "terminal" },
  sdsv: { id: 3, name: "site display" },
  rsv: { id: 6, name: "pcrd power reset controller" },
  psv: { id: 2, name: "site icpu" },
  dsv: { id: 3, name: "dock site display" },
  esv: { id: 4, name: "site eicc" },
};
const BulkOTATrack = (props) => {
  const [rid, setRid] = React.useState("");
  const [ridStatus, setRidStatus] = React.useState(null);
  const [ridInfo, setRidInfo] = React.useState([]);
  const [reqList, setReqList] = React.useState([]);
  const [sitesFromDb, setSitesFromDb] = React.useState([]);
  const [siteIdToDetailsMapping, setSiteIdToDetailsMapping] = React.useState(
    {}
  );
  const [ridToDetailsMapping, setRidToDetailsMapping] = React.useState({});
  const [alertVariant, setAlertVariant] = React.useState("");
  const [alertMsg, setAlertMsg] = React.useState([]);
  const [alertTitle, setAlertTitle] = React.useState("");
  const [showAlert, setShowAlert] = React.useState(false);
  const FIFTEEN_MINUTES = 1000 * 60 * 15;
  const dispatch = useDispatch();
  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    dispatch(setLoader(true));
    let data = await listRequests();
    setReqList(data);
    let tempRidToDetailMapping = generateRidToDetailsMapping(data);
    let response = await getSites();
    if (
      response &&
      response.status == 200 &&
      typeof response.data != "string"
    ) {
      setSitesFromDb(response.data.items);
      generateSiteMappingInfo(response.data.items);
    }

    let { rid, rtime } = getUrlParams();
    if (rid) {
      // rid passed in url
      setRid(rid);
      if (
        tempRidToDetailMapping[rid] &&
        tempRidToDetailMapping[rid].status == false
      ) {
        displayInProgressAlert();
      }
      fetchRidInformation(rid, tempRidToDetailMapping[rid]);
    }
    dispatch(setLoader(false));
  };

  const getUrlParams = () => {
    let result = { rid: null, rtime: null };
    let url = window.location.href;
    if (url.indexOf("?rid=") > -1) {
      let splitData = url.split("rid=");
      if (splitData.length == 2) {
        result.rid = splitData[1];
      }
    }
    return result;
  };
  const handleRidChange = (event) => {
    if (event.target.value){
      setRid(event.target.value);
      fetchRidInformation(event.target.value);
    }
  };

  const fetchRidInformation = async (rid, rData = null) => {
    dispatch(setLoader(true));
    let data = await trackRequests({ rid });
    setRidInfo(data);

    // corner case when rid is passed through url
    if (rData && rData.status === true) {
      displaySummaryAlert(data, rData);
      return;
    }

    if (ridToDetailsMapping[rid] && ridToDetailsMapping[rid].status === false) {
      displayInProgressAlert();
    } else if (
      ridToDetailsMapping[rid] &&
      ridToDetailsMapping[rid].status === true
    ) {
      displaySummaryAlert(data, ridToDetailsMapping[rid]);
    }
    dispatch(setLoader(false));
  };
  const columns = [
    // { field: "siteId", headerName: "Site ID", width: 150 },
    {
      field: "address",
      headerName: "Site Address",
      width: 350,
      renderCell: (params) =>{
        let address = siteIdToDetailsMapping[params.row.siteId]?.address
        return (
          <a href={`#/sites/view/${params.row.siteId}`} target="_blank">
            {address}
          </a>
        );
        // return ;
      },
    },
    {
      field: "status",
      headerName: "OTA Update Status",
      width: 180,
      renderCell: (params) => {
        if (params.row.status == null) {
          return <Chip label="In Progress" color="primary" />;
        } else if (params.row.status == true) {
          return <Chip label="Success" color="success" />;
        } else if (params.row.status == false) {
          return <Chip label="Failed" color="error" />;
        }
      },
    },
    { field: "created_at", headerName: "Trigger At", width: 180 ,
    renderCell:(param)=>{
      let date = new Date(param.row.created_at);
      return (`${date.toDateString()} ${date.getHours()}:${date.getMinutes()}`)
    }},
    { field: "updated_at", headerName: "Updated At", width: 180 ,
    renderCell:(param)=>{
      let date = new Date(param.row.updated_at);
      return (`${date.toDateString()} ${date.getHours()}:${date.getMinutes()}`)
    } 
  },
  { field: "triggeredBy", headerName: "Triggered By", width: 180 ,
    renderCell:(param)=>{
      let name =  ridToDetailsMapping[rid]?.triggeredBy;
      return name;
    } 
  },
  ];

  const generateSiteMappingInfo = (data) => {
    let result = {};
    data.map((site) => {
      result[site.site_id] = site;
    });
    setSiteIdToDetailsMapping(result);
  };

  const generateRidToDetailsMapping = (data) => {
    let result = {};
    data.map((req) => {
      result[req.id] = req;
    });
    setRidToDetailsMapping(result);
    return result;
  };

  const displayInProgressAlert = () => {
    setAlertVariant("info");
    setAlertTitle("Firmware OTA Request in progress");
    setAlertMsg([`Please wait for 15 minutes for status on request`]);
    setShowAlert(true);
  };

  const displaySummaryAlert = (data, rData) => {
    let totalCount = data.length;
    let successCount = 0;
    let failureCount = 0;
    const ftype = UPGRADE_COMPONENT_NAME_TO_ID_MAPPING[rData.ftype]
      ? UPGRADE_COMPONENT_NAME_TO_ID_MAPPING[rData.ftype].name
      : rData.ftype;
    let msg = [
      `Selected Firmware Name : ${rData.fname} Version : ${rData.fversion} Type : ${ftype}`,
    ];
    data.map((item) => {
      if (item.status) {
        successCount++;
      } else {
        failureCount++;
      }
    });
    if (failureCount === totalCount) {
      setAlertVariant("error");
      msg.push(`All ${totalCount} selected site failed in OTA update.`);
    } else if (successCount === totalCount) {
      setAlertVariant("success");
      msg.push(`All ${totalCount} selected site updated successfully.`);
    } else {
      setAlertVariant("warning");
      msg.push(
        `Only ${successCount} out of ${totalCount} selected site  updated successfully`
      );
    }
    setAlertTitle("Firmware OTA Request is completed.");
    setAlertMsg(msg);
    setShowAlert(true);
  };

  const handleRefreshStatus = () => {
    try {
      refreshRequestStatus(rid)
        .then((result) => {
          if (result) {
            NotificationManager.success(
              "Refresh Status request send successfuly"
            );
            setAlertVariant("info");
            setAlertTitle("Refresh Request Sent.");
            setAlertMsg([
              `Firmware OTA Refresh Status Request has send successfully.It may take upto 5 minutes for completion`,
            ]);
            setShowAlert(true);
          } else {
            NotificationManager.error("Refresh Status request send failed");
          }
        })
        .catch((error) => {
          console.log(error);
          NotificationManager.error("Refresh Status request send failed");
        });
    } catch (error) {
      console.log(error);
      NotificationManager.error("Refresh Status request send failed");
    }
  };

  return (
    <Container>
      <Paper elevation={1} style={{ margin: "10px", padding: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}></Grid>
          <Grid item xs={8}>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-select-ota-rid-label">
                  Request ID
                </InputLabel>
                <Select
                  labelId="demo-select-ota-rid-label"
                  id="demo-select-ota-rid"
                  value={rid}
                  label="Request ID"
                  onChange={handleRidChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {reqList.map((option) => {
                    return (
                      <MenuItem value={option.id} selected={option.id == rid}>
                        {option.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              size="large"
              disabled={rid ? false : true}
              onClick={handleRefreshStatus}
            >
              <CachedIcon style={{ padding: "1px" }} />
              <span style={{ marginLeft: "5px" }}>Refersh Status</span>
            </Button>
          </Grid>
          <Grid item xs={12}>
            {showAlert && (
              <>
                <Alert severity={alertVariant}>
                  <AlertTitle>{alertTitle}</AlertTitle>
                  {alertMsg.map((alert) => {
                    return <p>{alert}</p>;
                  })}
                </Alert>
              </>
            )}
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ height: 400, width: "100%" }}>
              <DataGrid
                getRowId={(row) => row.siteId}
                rows={ridInfo}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5]}
                disableRowSelectionOnClick
              />
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default BulkOTATrack;
