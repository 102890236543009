import React from 'react';
import { createUseStyles } from 'react-jss';
import CellVehicle from 'components/Cells/CellVehicle';
import styles from './styles';

const useStyles = createUseStyles(styles);

const CellId = ({ row }) => {
  const classes = useStyles();
  
  return (
    <div className={classes.wrapper}>
      <CellVehicle id={row.original.id}>
        {row.original.license_plate}
      </CellVehicle>
    </div>
  );
};

export default CellId;
