import React from 'react';
import { createUseStyles } from "react-jss";

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';
import styles from './styles';
import { Grid } from '@mui/material';
import Divider from '@material-ui/core/Divider';
import BikeInfoGeneralV2 from '../BikeInfoGeneralV2';
import LockInfoGeneralV2 from '../LockInfoGeneralV2';
import StationInfoGeneralV2 from '../StationInfoGeneralV2';
import ItsMyBikeGeneralV2 from '../ItsMyBikeInfoGeneralV2';
import LogViewer from '../../../../../LogViewer';
// const useStyles = createUseStyles(styles);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    minWidth: 500,
    minHeight:500
    // boxShadow: "10px 10px",
  },
  appbar : {
    backgroundColor: "transparent",
    boxShadow: "none",
    color: "black",
  }
}));

export default function BikeDetailsTabs({ item, id }) {
  const classes = useStyles();
  let tabCachedValue = 0;
  if (localStorage.getItem("BIKE_VIEW_TAB")){
    tabCachedValue = Number(localStorage.getItem("BIKE_VIEW_TAB"));
  }
  const [value, setValue] = React.useState(tabCachedValue);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem("BIKE_VIEW_TAB",newValue);
  };
  // console.log(item)
  return (
    <Card className={classes.root}>
      <CardContent>
        <AppBar position="static" className={classes.appbar}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab label="Details" {...a11yProps(0)} />
            <Tab label="Logs" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <BikeInfoGeneralV2 item={item} id={id}/>
            </Grid>
            <Grid item xs={6}>
              <LockInfoGeneralV2 item={item} id={id}/>
            </Grid>
            <Grid item xs={6}>
             {
              item.site == null ? (<></>) : (<StationInfoGeneralV2 item={item} id={id}/>)
             }
            </Grid>
            <Grid item xs={6}>
              {
                item.tracker_json == null ? (<></>) : (<ItsMyBikeGeneralV2 item={item} id={id}/>)
              }
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
        <LogViewer ctgr="bike" ctgrId={id} />
        </TabPanel>
      </CardContent>
    </Card>
  );
}
