import React from 'react';
import { createUseStyles } from 'react-jss';
import Link from '@material-ui/core/Link';
import styles from './styles';

const useStyles = createUseStyles(styles);

const CellName= ({ row, onClick }) => {
  const classes = useStyles();
  const handleClick = React.useCallback(e => {
    e.preventDefault();
    onClick(row.original);
  }, [ row.original ]);

  return (
    <Link
      href='#'
      onClick={handleClick}
    >
      {row.original.name}
    </Link>
  );
};

export default CellName;
