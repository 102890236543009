import {
  COLOR_PRIMARY_BACKGROUND,
  COLOR_SECONDARY,
} from 'utils/constants/colors';

export default {
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  wrapperMore: {
    fontSize: 16,
    borderRadius: 20,
    width: 20,
    height: 20,
    marginLeft: 10,
    backgroundColor: COLOR_PRIMARY_BACKGROUND,
    color: COLOR_SECONDARY,
    display: 'inline-block',
    padding: 5,
  },
};
