import React from "react";
import { Grid } from "@mui/material";
import Typography from "@material-ui/core/Typography";
import Divider from '@material-ui/core/Divider';
import ButtonDefault from 'components/Buttons/ButtonDefault';
import ButtonCureError from 'components/Buttons/ButtonCureError';

export default function StationInfoGeneralV2 ({item,id}){
  return (
    <Grid container spacing={3}>
    <Grid item xs={12}>
      <Typography gutterBottom variant="h6" component="h2">
       Station Informations
      </Typography>
      <Divider />
    </Grid>
    <Grid item xs={6} >
      <Typography gutterBottom variant="body1" component="h2">
        ID:
      </Typography>
    </Grid>
    <Grid item xs={6} >
      <Typography gutterBottom variant="body1" component="h2">
      {item?.site?.id ? <a href={`/#/sites/view/${item?.site?.id}`} target="_blank">{item?.site?.id}</a>: 'NA'}
      </Typography>
    </Grid>
    <Grid item xs={6} >
      <Typography gutterBottom variant="body1" component="h2">
        Home Station:
      </Typography>
    </Grid>
    <Grid item xs={6} >
      <Typography gutterBottom variant="body1" component="h2">
      {item?.homeStation?.id ? <a href={`/#/sites/view/${item?.homeStation?.id}`} target="_blank">{item?.homeStation?.id}</a>: 'NA'}
      </Typography>
    </Grid>
    <Grid item xs={6}style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
        Sonoff:
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
      {item?.site?.icpu ? <a href={item?.site?.icpu} target="_blank">{item?.site?.icpu}</a>: 'NA'}
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
        Address:
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
        {item?.site?.street}
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
        City:
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
        {item?.city?.name}
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
        Latitude:
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
        {item?.site?.lat}
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
        Longitude:
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
        {item?.site?.lon}
      </Typography>
    </Grid>

    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
        Router:
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
        {item?.router?.serial_number}
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
        Sim Card:
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
        {item?.router?.sim_card?.iccid}
      </Typography>
    </Grid>

    <Grid item xs={6} style={{ "padding-top": 0 }}>
    <Typography gutterBottom variant="body1" component="h2">
        Cure Error:
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
    {!!item.docking_id && (
            <ButtonCureError
              dockingId={item.docking_id}
              siteId={item?.site?.id}
            />
          )}
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 10 }}>
    <Typography gutterBottom variant="body1" component="h2">
        Action Restart:
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 10 }}>
    <ButtonDefault disabled>
            Restart
          </ButtonDefault>
    </Grid>

  </Grid>
  );
}