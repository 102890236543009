import React from 'react';
import { createUseStyles } from 'react-jss';
import TableUncontrolled from 'components/List/TableUncontrolled';
import { getFieldsFrom } from 'utils/helpers/list';
import { SITE_MODAL } from 'utils/constants/modals';
import CellPile1 from '../CellPile1';
import CellPile2 from '../CellPile2';
import CellAddress from '../CellAddress';
import CellHeartbeatStatus from '../CellHeartbeatStatus';
import CellStatus from 'components/Cells/CellStatus';
import CellOnlineStatus from 'components/Cells/CellOnlineStatus'
import {
  FILTER_SORT_MAP,
  LABEL_MAP,
} from '../../utils/constants';
import styles from './styles';
import { usePermissions } from 'react-admin'
import CellCopyLink from '../CellCopyLink';
// import { getSitesV2} from 'utils/helpers/request/api/sites';
// import { useDispatch } from 'react-redux';
// import { setLoader } from 'store/actions/common';

const useStyles = createUseStyles(styles);


const ListSites = ({
  items,
  onDelete,
  getSitesPagination,
  pageCount,
  pageSize,
  totalCount,
  pageIndex,
  citiesFromDb,
  siteAddressFromDb,
  lastSearchParam
}) => {
  const classes = useStyles();
  const hiddenColumns = React.useMemo(() => [ 'city_name', 'router','bikes_num' ], []);
  const fields = React.useMemo(() => getFieldsFrom(items, FILTER_SORT_MAP, LABEL_MAP), [ items ]);
  function filterInMultipleSelectedValues(rows, id, filterValue) {
    return rows.filter(row => {
      const rowValue = row.values[id]
      return filterValue.includes(rowValue);
    })
  }
  const columns = React.useMemo(
    () => [
      // {
      //   label:'',
      //   accessor:'id'
      // },
      {
        label:'Address',
        accessor: 'address',
        disableGlobalFilter: false,
        Cell: ({ row }) => <CellAddress row={row} />,
        filter: filterInMultipleSelectedValues,
      },
      {
        label:'Ver.',
        accessor: 'version',
        disableGlobalFilter: true,
      },
      {
        label:'Status',
        accessor: 'status',
        disableGlobalFilter: true,
        Cell: ({ row }) => <CellStatus type={row.original.status} />,
        filter: filterInMultipleSelectedValues,
      },
      {
        label:'Connection State',
        accessor: 'onlineStatus',
        disableGlobalFilter: true,
        Cell: ({ row }) => <CellOnlineStatus type={row.original.onlineStatus} />,
        filter: filterInMultipleSelectedValues,
      },
      {
        label:'SONOFF',
        accessor: 'icpu',
        disableGlobalFilter: true,
        Cell: ({ row }) => { return (<div><a href={row.original.icpu} target="_blank">{row.original.icpu}</a> </div>)}
      },
      {
        label:'Piles',
        accessor :'piles',
        Cell: ({ row }) => <CellPile1 row={row} />,
      },
      // {
      //   id: 'cellPile2',
      //   Cell: ({ row }) => <CellPile2 row={row} />,
      // },
      {
        label:'Router & Heartbeat',
        accessor :'site_heartbeat_timestamp',
        id: 'site_heartbeat_timestamp',
        Cell: ({ row }) => <CellHeartbeatStatus row={row} />,
      },
      {
        label:'Link',
        accessor :'link',
        Cell: ({ row }) => <CellCopyLink row={row} />,
      },
      {
        label:'City',
        accessor: 'city_name',
        disableGlobalFilter: true,
        filter: filterInMultipleSelectedValues,
      },
      {
        label:'Router',
        accessor: 'router',
        disableGlobalFilter: true,
      },
      {
        label:'Pile Count',
        accessor:'bikes_num',
        filter:'between',
      },
    ],
    [  ],
  );

  const { permissions } = usePermissions()
  // console.log(permissions);
  // const dispatch = useDispatch();
  // const [data, setData] = React.useState([])
  // const [loading, setLoading] = React.useState(false)
  // const [pageCount, setPageCount] = React.useState(0)
  // const fetchIdRef = React.useRef(0)
  // const pageSize = 1;
  // const getSitesPagination = React.useCallback(async ({pageIndex, pageSize}) => {
  //     dispatch(setLoader(true))
  //   console.log('fetch call',fetchIdRef.current,pageIndex,pageSize)
  //   // Give this fetch an ID
  //   const fetchId = ++fetchIdRef.current;
  //   if (fetchId === fetchIdRef.current) {
  //     let responses = await getSitesV2({offset:pageIndex, limit:pageSize});
  //     // {items,currentPage,totalItems,totalPages,totalItemsPerPage}
  //     if (responses.status == 200) {
  //       setData(responses.data.items);
  //       if (pageIndex==0){
  //         setPageCount(responses.data.total)
  //       }
  //     }
  //     setLoading(false)
  //   }
  //   dispatch(setLoader(false))
  // }, []);
  // console.log('list sites',pageIndex)
  return (
    <TableUncontrolled
      isVisibleSort
      isVisibleFilter
      isvisibleSync={permissions && permissions.indexOf('sync-sites') > -1}
      isCreatable={true}
      columns={columns}
      hiddenColumns={hiddenColumns}
      createCaption="Add Site"
      caption="All Sites"
      entityType="sites"
      totalCount={totalCount}
      modalType={SITE_MODAL}
      onDelete={onDelete}
      fields={fields}
      rowsPerPage={pageSize}
      data={items}
      isEditable={permissions && permissions.indexOf('update-sites') > -1}
      isRemovable={permissions && permissions.indexOf('delete-sites') > -1}
      isViewable={permissions && permissions.indexOf('view-sites') > -1 }
      fetchData={getSitesPagination}
      pageCount={pageCount}
      pageIndex={pageIndex}
      citiesFromDb={citiesFromDb}
      siteAddressFromDb={siteAddressFromDb}
      lastSearchParam={lastSearchParam}

    />
  );
};

export default ListSites;
