import React from 'react';
import { createUseStyles } from 'react-jss';
import PageWrapper from 'components/PageWrapper';
import {
  useFetchItems,
  useDelete,
} from 'utils/hooks/simCard';
import ListSimCards from './components/ListSimCards';
import styles from './styles';

const useStyles = createUseStyles(styles);

const SimCards = () => {
  const classes = useStyles();
  const { items, fetch } = useFetchItems();
  const { deleteItems } = useDelete();
  const handleDeleteItems = React.useCallback(indexes => {
    const ids = indexes.map(i => items[+i].id);
    
    deleteItems(ids)
      .then(() => {
        fetch();
      });
  }, [ items ]);
  
  React.useEffect(() => {
    fetch();
  }, []);

  return (
    <PageWrapper fetchItems={fetch}>
      <ListSimCards
        items={items}
        onDelete={handleDeleteItems}
      />
    </PageWrapper>
  )
};

export default SimCards;
