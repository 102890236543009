import React from 'react';
import { createUseStyles } from 'react-jss';
import { useForm } from 'react-final-form';
import Typography from '@material-ui/core/Typography';
import AppFormGap from 'components/AppFormGap';
import TextInputForm from 'components/Inputs/TextInputForm';
import AppSelectForm from 'components/Inputs/AppSelectForm';
import { STATUSES } from '../../utils/constants';
import styles from './styles';

const useStyles = createUseStyles(styles);

const FormContent = ({
  disabled,
  item,
  id,
}) => {
  const classes = useStyles();
  const form = useForm();

  React.useEffect(() => {
    if (!!id && !!item.id) {
      form.reset(item);
    }
  }, [ item ]);
  
  return (
    <div className={classes.wrapper}>
      <AppFormGap />
      <Typography variant="h5">
        User Group
      </Typography>
      <AppFormGap />
      <TextInputForm
        name="name"
        label="Name"
        disabled={disabled}
        initialValue=""
        required
      />
      <AppFormGap />
      <TextInputForm
        name="wunder_promotion_code_id"
        label="Wunder Promotion Code Id"
        disabled={disabled}
        initialValue=""
        required
      />
      <AppFormGap />
      {!disabled && (
        <>
          <AppFormGap />
          <AppSelectForm
            name="status"
            label="Status"
            options={STATUSES}
            isNullable={false}
            initialValue={STATUSES[0].id}
          />
        </>
      )}
    </div>
  )
};

export default FormContent;
