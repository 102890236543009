import React from "react";
import { usePermissions } from "react-admin";
import { createUseStyles } from "react-jss";
import CRUModal from "components/Modals/CRUModal";
import TextInputForm from "components/Inputs/TextInputForm";
import ListPileTypes from "./components/ListPileTypes";
import { useFetch, useCreateItem, useDeleteItems } from "./utils/hooks";
import { validateForm } from "./utils/helpers";
import styles from "./styles";

const useStyles = createUseStyles(styles);

const PileTypes = () => {
  const classes = useStyles();
  const [isVisibleCreateModal, setIsVisibleCreateModal] = React.useState(false);
  const { permissions } = usePermissions();
  const { items, fetch } = useFetch();
  const { deleteItems } = useDeleteItems();
  const { createItem } = useCreateItem();
  const handleDelete = React.useCallback(
    (indexes) => {
      const ids = indexes.map((i) => items[+i].id);

      deleteItems(ids).then(() => {
        fetch();
      });
    },
    [items]
  );
  const handleCreate = React.useCallback(() => {
    setIsVisibleCreateModal(true);
  }, []);
  const hideModal = React.useCallback(() => {
    setIsVisibleCreateModal(false);
  }, []);
  const handleCreatePileType = React.useCallback(({ values }) => {
    createItem(values.pileType)
      .then(() => {
        fetch();
        hideModal();
      })
      .catch(() => {});
  }, []);

  React.useEffect(() => {
    fetch();
  }, []);

  return (
    <div className={classes.wrapper}>
      <ListPileTypes
        items={items}
        onCreate={handleCreate}
        onDelete={handleDelete}
      />
      {permissions &&
        permissions.indexOf("create-pile-types") > -1 &&
        isVisibleCreateModal && (
          <CRUModal
            title="Add Pile Type"
            validate={validateForm}
            onClose={hideModal}
            onCreate={handleCreatePileType}
          >
            <TextInputForm name="pileType" label="Name" />
          </CRUModal>
        )}
    </div>
  );
};

export default PileTypes;
