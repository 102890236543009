import React from "react";
import { createUseStyles } from "react-jss";
import styles from "./styles";

const useStyles = createUseStyles(styles);

const Definition = ({ term, value, link,externalLink }) => {
  const classes = useStyles();

  if (externalLink) {
    return (
      <div className={classes.wrapper}>
        {term && <div className={classes.term}>{term}:</div>}
        {value && externalLink ? (
          <a href={externalLink} target="_blank">
            {" "}
            {value}{" "}
          </a>
        ) : (
          <div className={classes.value}>{value}</div>
        )}
      </div>
    );
  } else {
    return (
      <div className={classes.wrapper}>
        {term && <div className={classes.term}>{term}:</div>}
        {value && link ? (
          <a href={value} target="_blank">
            {" "}
            {value}{" "}
          </a>
        ) : (
          <div className={classes.value}>{value}</div>
        )}
      </div>
    );
  }

};

export default Definition;
