import React from "react";
import { createUseStyles } from "react-jss";
import Definition from "components/Definition";
import AppFormGap from "components/AppFormGap";
import styles from "./styles";
import moment from 'moment-timezone';
const useStyles = createUseStyles(styles);

const ItsMyBikeRaw = ({ trackerData }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <AppFormGap />
      <Definition term="Raw Json" value={""} />
      <pre>
      {JSON.stringify(JSON.parse(trackerData),null,2)}
      </pre>
     
    </div>
  );
};

export default ItsMyBikeRaw;
