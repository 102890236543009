import Typography from '@material-ui/core/Typography';
import React from 'react';
import { createUseStyles } from 'react-jss';
import styles from './styles';

const useStyles = createUseStyles(styles);

const Forbidden = () => {
  const classes = useStyles();
  
  return (
    <div className={classes.wrapper}>
      <Typography variant="h1">
        403
      </Typography>
      <Typography variant="h5">
        Access to that resource is forbidden.
      </Typography>      
    </div>
  )
};

export default Forbidden;
