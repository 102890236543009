import React from 'react';
import { useDispatch } from 'react-redux';
import {
  deleteCity,
  getCities, updateCity,
} from 'utils/helpers/request/api/cities';
import { setLoader } from 'store/actions/common';
import { NotificationManager } from 'react-notifications';

const useFetch = () => {
  const [ items, setItems ] = React.useState([]);
  const dispatch = useDispatch();

  const fetch = async function() {
    dispatch(setLoader(true))

    try {
      const { data: { items: cities } } = await getCities();

      setItems(cities);
    } catch (e) {}

    dispatch(setLoader(false))
  };

  return {
    items,
    fetch,
  };
};

const useDeleteItems = () => {
  const dispatch = useDispatch();
  
  const deleteItems = async function(ids) {
    dispatch(setLoader(true))
    
    try {
      for (const id of ids) {
        await deleteCity(id);
      }
      NotificationManager.success('Successfully  removed!');
    } catch (e) {}
    
    dispatch(setLoader(false))
  };
  
  return {
    deleteItems,
  };
};

const useUpdateItem = () => {
  const dispatch = useDispatch();
  
  const updateItem = async function(data) {
    dispatch(setLoader(true))
    
    try {
      await updateCity(data);
      NotificationManager.success('Successfully  updated!');
    } catch (e) {}
    
    dispatch(setLoader(false))
  };
  
  return {
    updateItem,
  };
};

export {
  useFetch,
  useUpdateItem,
  useDeleteItems,
};
