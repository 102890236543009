import Typography from "@material-ui/core/Typography";
import AppFormGap from "components/AppFormGap";
import CellStatus from "components/Cells/CellStatus";
import AppSelectForm from "components/Inputs/AppSelectForm";
import AsyncAutocompleteForm from "components/Inputs/AsyncAutocompleteForm";
import AutocompleteSimCard from "components/Inputs/AutocompleteSimCard";
import TextInputForm from "components/Inputs/TextInputForm";
import Forbidden from "pages/Forbidden";
import React from "react";
import { usePermissions } from "react-admin";
import { useForm } from "react-final-form";
import { createUseStyles } from "react-jss";
import { simpleMemoize } from "utils/helpers/fn";
import { asyncRequestForSites } from "utils/helpers/form";
import {
  getRouterByMacAddress,
  getRouterBySerialNumber,
} from "utils/helpers/request/api/routers";
import { STATUSES } from "../../utils/constants";
import styles from "./styles";

const useStyles = createUseStyles(styles);

const FormContent = ({ disabled, item, id }) => {
  const classes = useStyles();
  const form = useForm();
  const { permissions } = usePermissions();
  const filterForSimCard = React.useMemo(() => {
    return function (simCard) {
      if (!!simCard.router) {
        return simCard.router.id === item.id;
      }
      if (!simCard.router) return true;
      if (!simCard.lock) return true;
    };
  }, [item]);
  const serialNumberAvailable = React.useCallback(
    simpleMemoize(async (value = "") => {
      if (value.trim().length === 0) return "Required";

      if (value.trim() === item.serial_number) return undefined;

      const routerItem = await getRouterBySerialNumber(value);

      if (!!routerItem) return "Serial number taken";
    }),
    [item.serial_number]
  );
  const macAddressAvailable = React.useCallback(
    simpleMemoize(async (value = "") => {
      if (value.trim().length === 0) return "Required";

      if (value.trim() === item.mac_address) return undefined;

      const routerItem = await getRouterByMacAddress(value);

      if (!!routerItem) return "MAC address taken";
    }),
    [item.mac_address]
  );

  React.useEffect(() => {
    if (!!id && !!item.id) {
      form.reset(item);
    }
  }, [item]);

  return (
    <div className={classes.wrapper}>
      <AppFormGap />
      <Typography variant="h5">
        Router {disabled && <CellStatus type={item.status} />}
      </Typography>
      <AppFormGap />
      <TextInputForm
        name="serial_number"
        label="Serial Number"
        disabled={disabled}
        initialValue=""
        fieldValidation={serialNumberAvailable}
        required
      />
      <AppFormGap />
      <TextInputForm
        name="mac_address"
        label="MAC Address"
        disabled={disabled}
        initialValue=""
        fieldValidation={macAddressAvailable}
        required
      />
      {!disabled && (
        <>
          <AppFormGap />
          <AppSelectForm name="status" label="Status" options={STATUSES} />
        </>
      )}
      <AppFormGap />
      <AppFormGap />
      <Typography variant="h5">Site</Typography>
      <AppFormGap />
      <AsyncAutocompleteForm
        label="Address"
        disabled={disabled}
        asyncRequestFn={asyncRequestForSites}
        name="site"
        initAsync
      />
      <AppFormGap />
      <AppFormGap />
      <Typography variant="h5">SIM</Typography>
      <AppFormGap />
      <AutocompleteSimCard
        disabled={disabled}
        form={form}
        filterFn={filterForSimCard}
      />
    </div>
  )
};

export default FormContent;
