export default {
  wrapper: {},
  wrapperRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  wrapperRight: {
    position: 'relative',
    flex: 1,
    alignSelf: 'stretch',
  },
  wrapperLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: 20,
  },
  root: {
    // maxWidth:500
    position: 'relative',
  },
  siteInfoCard: {
  },
  media: {
  },
  siteInfoMap : {
    // display: 'flex',
    // overflow: 'hidden',
    position: 'relative',
    flex: 1,
    // width:,
    height:300
  },
  noPaddingTop : {
    paddingTop:0
  },
  siteNameAndLogo : {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  copyIcon :{
    marginLeft:'10px',
    '&:hover':{
      color:'red',
      cursor: 'pointer'
    }
  },
  copiedIcon :{
      color:'green',
  },
  copyLinkText :{
    float:'right'
  },
  redText : {
    color:'red'
  },
  cardActionBtn:{
    width:'100%'
  }
};
