export default {
  wrapper: {
  },
  wrapperIcon: {
    '& svg': {
      width: 48,
      height: 48,
    },
  },
};
