import {
  WIDTH_MAX,
  WIDTH_MIN,
} from 'utils/constants/resolutions';

export default {
  wrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  wrapperInner: {
    width: WIDTH_MAX,
    minWidth: WIDTH_MIN,
  },
  [`@media (max-width: ${WIDTH_MAX}px)`]: {
    wrapperInner: {
      width: '100%',
    },
  },
};
