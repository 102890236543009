import { useState } from "react";
import { NotificationManager } from "react-notifications";
import { useDispatch } from "react-redux";
import { setLoader } from "store/actions/common";
import { getPartners, getAllPartners, updatePartnerBikeMapping, getPartnerBikeMapping } from "utils/helpers/request/api/partners";

export const useFetchPartners = () => {
  const [partners, setPartners] = useState([]);
  const dispatch = useDispatch();

  const fetchPartners = async () => {
    dispatch(setLoader(true));

    try {
      const { data } = await getPartners();

      setPartners(data.items);
    } catch (error) {
      console.log("Something unexpected happened. Error: ", error);
    }

    dispatch(setLoader(false));
  };

  return {
    partners,
    fetchPartners,
  };
};

export const useFetchAllPartners = () => {
  const [partners, setPartners] = useState([]);
  const dispatch = useDispatch();

  const fetchAllPartners = async () => {
    dispatch(setLoader(true));

    try {
      const { data } = await getAllPartners();

      setPartners(data.items);
    } catch (error) {
      console.log("Something unexpected happened. Error: ", error);
    }

    dispatch(setLoader(false));
  };

  return {
    partners,
    fetchAllPartners,
  };
};

export const useUpdateItem = () => {
  const dispatch = useDispatch();

  const updateItem = async (data) => {
    dispatch(setLoader(true));

    try {
      await updatePartnerBikeMapping(data);
      NotificationManager.success("Successfully updated!");
    } catch (e) {}

    dispatch(setLoader(false));
  };

  return {
    updateItem,
  };
};

export const useFetchItem = (id) => {
  const [item, setItem] = useState({});
  const dispatch = useDispatch();

  const fetch = async () => {
    dispatch(setLoader(true));

    try {
      const { data: item } = await getPartnerBikeMapping(id);

      setItem(item);
    } catch (e) {}

    dispatch(setLoader(false));
  };
  return {
    item,
    fetch,
  };
};
