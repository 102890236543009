import React from 'react';
import { createUseStyles } from 'react-jss';
import styles from './styles';

const useStyles = createUseStyles(styles);

const CellPileType = ({ row }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      {row.original.docking_type_id}
    </div>
  )
};

export default CellPileType;
