import React from 'react';
import { createUseStyles } from 'react-jss';
import { useField } from 'react-final-form';
import AsyncAutocomplete from 'components/Inputs/AsyncAutocomplete';
import AsyncInitAutocomplete from 'components/Inputs/AsyncInitAutocomplete';
import styles from './styles';

const useStyles = createUseStyles(styles);

const AsyncAutocompleteForm = ({
  name,
  label,
  disabled,
  onUpdate = () => {},
  renderLink,
  onAddClick,
  asyncRequestFn,
  emptyMessage = 'None',
  initAsync = false,
}) => {
  const classes = useStyles();
  const {
    input: { onChange, value },
    meta: { touched, error }
  } = useField(name);
  
  return initAsync ? (
    <AsyncInitAutocomplete
      label={label}
      value={value}
      disabled={disabled}
      renderLink={renderLink}
      onAddClick={onAddClick}
      error={touched && error}
      onChange={onChange}
      onUpdate={onUpdate}
      emptyMessage={emptyMessage}
      asyncRequestFn={asyncRequestFn}
    />
  ) : (
    <AsyncAutocomplete
      label={label}
      value={value}
      disabled={disabled}
      onAddClick={onAddClick}
      error={touched && error}
      onChange={onChange}
      onUpdate={onUpdate}
      emptyMessage={emptyMessage}
      asyncRequestFn={asyncRequestFn}
    />
  )
};

export default AsyncAutocompleteForm;
