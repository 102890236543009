import React from 'react';
import { createUseStyles } from 'react-jss';
import AsyncAutocompleteForm from 'components/Inputs/AsyncAutocompleteForm';
import { asyncRequestForUsergroups } from 'utils/helpers/form';
import { useCreate } from 'utils/hooks/userGroup';
import styles from './styles';
import UserGroupModal from 'components/Modals/UserGroupModal';

const useStyles = createUseStyles(styles);

const AutocompleteUserGroup = ({
  form,
  disabled,
  userGroups
}) => {
  const classes = useStyles();
  const { createItem } = useCreate();
  const [ isVisibleModal, setIsVisibleModal ] = React.useState();
  const asyncRequest = React.useMemo(() => {
    return function () {
      return asyncRequestForUsergroups(userGroups);
    };
  }, [ userGroups ]);

  const [ modalInitialData, setModalInitialData ] = React.useState(null);

  const showModal = React.useCallback(() => {
    const formState = form.getState();
    
    setIsVisibleModal(true);
    setModalInitialData({ serial_number: formState.values.router.name });
  }, [ form ]);

  const hideModal = React.useCallback(() => {
    form.change('userGroup', { id: null, name: '' });
    setIsVisibleModal(false);
  }, []);

  const handleCreate = React.useCallback(({ values }) => {
    createItem(values)
      .then(newId => {
        form.change('user-group', { id: newId, name: values.name });
        setIsVisibleModal(false);
      });
  }, [ form ]);

  return (
    <div className={classes.wrapper}>
      <AsyncAutocompleteForm
        label="Name"
        asyncRequestFn={asyncRequest}
        onAddClick={showModal}
        disabled={disabled}
        name="userGroup"
        initAsync
      />
      {isVisibleModal && modalInitialData && (
        <UserGroupModal
          modalInitialData={modalInitialData}
          onCreate={handleCreate}
          onClose={hideModal}
        />
      )}
    </div>
  )
};

export default AutocompleteUserGroup;
