import React from 'react';
import PageWrapper from 'components/PageWrapper';
import ListSites from './components/ListSites';
import { getSitesV2 ,getAllSiteAddresses} from 'utils/helpers/request/api/sites';
import { getCities } from 'utils/helpers/request/api/cities';
import { useDispatch } from 'react-redux';
import { setLoader } from 'store/actions/common';
import { useDelete } from 'utils/hooks/site'

const Sites = () => {

  const [citiesFromDb,setCitiesFromDb] = React.useState([]);
  const [siteAddressFromDb,setSiteAddressFromDb] = React.useState([]);
  const { deleteItems } = useDelete();

  React.useEffect(() => {
    getCities()
    .then(response=>{
      if (response.data && response.data.items){
        setCitiesFromDb(response.data.items);
      }
    }).catch(error=>{
      console.log(error);
    })

    getAllSiteAddresses()
    .then(response=>{
      if (response.data && response.data.items){
        response.data.items = response.data.items.map(item=>{return {'id':item.address,'address':item.address}});
        setSiteAddressFromDb(response.data.items);
      }
    }).catch(error=>{
      console.log(error);
    })
  }, []);

  const dispatch = useDispatch();
  const [data, setData] = React.useState([])
  const [pageCount, setPageCount] = React.useState(0);
  const pageSize = 10;
  const [totalCount,setTotalCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);
  const lastSearchParam = React.useRef(null);
  const lastSortParam = React.useRef(null);
  const [pageIndex,setPageIndex] = React.useState(0);
  const getSitesPagination = React.useCallback(
    async ({
      pageIndex: pageIndexParam,
      pageSize: pageSizeParam,
      search = [],
      isFreshReqParam,
      sortParam =null
    }) => {
      dispatch(setLoader(true));
      // cache and use same search parameters till new fresh request is not made
      if (isFreshReqParam) {
        lastSearchParam.current = search;
        lastSortParam.current = sortParam;
      }

      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;
      if (fetchId === fetchIdRef.current) {
        // offset: pageIndexParam*pageSizeParam as this is not handle in
        let responses = await getSitesV2({
          offset: pageIndexParam * pageSizeParam,
          limit: pageSizeParam,
          search: lastSearchParam.current,
          sort:lastSortParam.current
        });

        // {items,currentPage,totalItems,totalPages,totalItemsPerPage}
        if (responses.status == 200) {
          let r = responses.data.items.map((i) => ({
            ...i,
            id: i.site_id,
            onlineStatus: i.onlineStatus ? "ONLINE" : "OFFLINE",
          }));
          // console.log(r);

          setData(
            responses.data.items.map((i) => ({
              ...i,
              id: i.site_id,
              onlineStatus: i.onlineStatus ? "ONLINE" : "OFFLINE",
            }))
          );
          if (pageIndexParam == 0) {
            setPageCount(Math.ceil(responses.data.total / pageSizeParam));
            setTotalCount(responses.data.total);
          }
        }
        setPageIndex(pageIndexParam);
      }
      dispatch(setLoader(false));
    },
    []
  );

  const handleDeleteItems = React.useCallback(indexes => {
    const ids = indexes.map(i => data[+i].id);

    deleteItems(ids)
        .then(() => {
          fetch();
        });
  }, [ data ]);


  const fetch = getSitesPagination.bind(this,{pageIndex, pageSize})
  return (
    <PageWrapper fetchItems={fetch}>
      <ListSites
        items={data}
        getSitesPagination={getSitesPagination}
        pageCount={pageCount}
        pageSize={pageSize}
        totalCount={totalCount}
        pageIndex={pageIndex}
        citiesFromDb={citiesFromDb}
        siteAddressFromDb={siteAddressFromDb}
        lastSearchParam={lastSearchParam}
        onDelete={handleDeleteItems}
      />
    </PageWrapper>
  )
};

export default Sites;
