import React from "react";
import { createUseStyles } from "react-jss";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import styles from "./styles";
import { Checkbox, ListItemText } from "@material-ui/core";

const useStyles = createUseStyles(styles);

const AppMultiSelect = ({
  name,
  value,
  label,
  error,
  onChange,
  width,
  disabled,
  isNullable = true,
  options = [],
  classnameWrapper,
  renderValue,
}) => {
  const classes = useStyles();
  const isError = React.useMemo(() => !!error, [error]);
  const labelId = React.useMemo(() => `${name}-select`, [name]);

  return (
    <FormControl
      error={isError}
      variant="outlined"
      className={classnameWrapper}
    >
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        labelId={labelId}
        value={value}
        disabled={disabled}
        onChange={onChange}
        width={width}
        label={label}
        multiple={true}
        renderValue={renderValue}
      >
        {isNullable && <MenuItem value="">None</MenuItem>}
        {options && options.map((o) => (
          <MenuItem key={o.id} value={o.id}>
            <Checkbox checked={value.indexOf(o.id) > -1} />
            <ListItemText primary={o.name} />
          </MenuItem>
        ))}
      </Select>
      {isError && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default AppMultiSelect;
