import React from 'react';
import CellLock from 'components/Cells/CellLock';

const CellId = ({ row }) => {
  return (
    <CellLock
      id={row.original.id}
      status={row.original.status}
    >
      {row.original.id}
    </CellLock>
  );
};

export default CellId;
