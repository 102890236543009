import {
  ERROR,
  INVENTORY,
  MAINTENANCE,
  OPERATION,
} from 'utils/constants/statuses';

const STATUSES = [
  { id: MAINTENANCE, name: MAINTENANCE },
  { id: ERROR, name: ERROR },
  { id: OPERATION, name: OPERATION },
  { id: INVENTORY, name: INVENTORY },
];

export {
  STATUSES,
};
