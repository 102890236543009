import React from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CancelIcon from "@material-ui/icons/Cancel";
import UpdateIcon from "@material-ui/icons/Update";
import AddIcon from "@material-ui/icons/Add";
import { createUseStyles } from "react-jss";
import { useFormState } from "react-final-form";
import ButtonDefault from "components/Buttons/ButtonDefault";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import { getConfirmationParams } from "./utils/helpers";
import { DELETE } from "./utils/constants";
import CellStatus from "components/Cells/CellStatus";
import styles from "./styles";
import { usePermissions } from "react-admin";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@material-ui/lab/Alert';
const useStyles = createUseStyles(styles);

const AppFormHeader = ({
  title,
  status,
  disabled,
  entity,
  onCreate,
  onTrigger,
  onClose,
  onDelete,
  onUpdate,
  onEdit,
  onView,
  onUpdateAndClose,
  onLogView,
}) => {
  const classes = useStyles();
  const formState = useFormState();
  const [modalData, setModalData] = React.useState(null);
  const handleRejectConfirmation = React.useCallback(() => {
    setModalData(null);
  }, []);
  const handleDelete = React.useCallback(() => {
    setModalData({
      ...getConfirmationParams({ entity, action: DELETE }),
      onApprove: () => {
        onDelete(formState);
        setModalData(null);
      },
    });
  }, [entity, formState, onDelete]);
  const { permissions } = usePermissions();

  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal, open } = state;
  const handleClick = (newState) => () => {
    // console.log('inside handle click')
    setState({ open: true, ...newState });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  
  return (
    <div className={classes.wrapper}>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        // message="I love snacks"
        key={vertical + horizontal}
        severity="error"
        autoHideDuration={6000}
      >
        <Alert severity="error">Error : Please fill all Required fields (*)</Alert> 
      </Snackbar>  
      <div className={classes.wrapperLeftHeader}>
        <div className={classes.title}>{title} &nbsp;</div>
        {!!status && !onCreate && !onUpdate && <CellStatus type={status} />}
      </div>
      <div className={classes.wrapperRightHeader}>
        { onLogView && permissions && permissions.indexOf("view-log-viewer") > -1 && (
          <ButtonDefault
            onClick={() => {
              if (!formState.valid) return;
              onLogView(formState);
            }}
          >
            <OpenInNewIcon /> &nbsp; Admin Logs View
          </ButtonDefault>
        )}
        {onCreate && !disabled && (
          <ButtonDefault
            onClick={() => {
              // console.log(formState)
              if (!formState.valid) {
                // console.log('inside submit');
                handleClick({
                  vertical: 'top',
                  horizontal: 'center',
                }).call();
       
                return;
              }
              onCreate(formState);
            }}
          >
            <AddIcon /> &nbsp; Add
          </ButtonDefault>
        )}
        {onUpdateAndClose && !disabled && (
          <ButtonDefault
            onClick={() => {
              if (!formState.valid) return;
              onUpdateAndClose(formState);
            }}
          >
            <UpdateIcon /> &nbsp; Update & Close
          </ButtonDefault>
        )}
        {onView && !disabled && (
          <ButtonDefault
            onClick={() => {
              if (!formState.valid) return;
              onView(formState);
            }}
          >
            <VisibilityIcon /> &nbsp; View
          </ButtonDefault>
        )}
        {onEdit && !disabled && (
          <ButtonDefault
            onClick={() => {
              if (!formState.valid) return;
              onEdit(formState);
            }}
          >
            <EditIcon /> &nbsp; Edit
          </ButtonDefault>
        )}
        {onUpdate && !disabled && (
          <ButtonDefault
            onClick={() => {
              if (!formState.valid) return;
              onUpdate(formState);
            }}
          >
            <UpdateIcon /> &nbsp; Update
          </ButtonDefault>
        )}
        {onDelete && !disabled && (
          <ButtonDefault onClick={handleDelete}>
            <DeleteIcon /> &nbsp; Delete
          </ButtonDefault>
        )}
        {onTrigger && !disabled && (
          <ButtonDefault
            onClick={() => {
              onTrigger(formState);
            }}
          >
            Trigger
          </ButtonDefault>
        )}
        {onClose && (
          <ButtonDefault onClick={onClose}>
            <CancelIcon /> &nbsp; Cancel
          </ButtonDefault>
        )}
      </div>
      {!!modalData && (
        <ConfirmationModal {...modalData} onReject={handleRejectConfirmation} />
      )}
    </div>
  );
};

export default AppFormHeader;
