import React from "react";
import { createUseStyles } from "react-jss";
import { usePermissions } from "react-admin";
import TableUncontrolled from "components/List/TableUncontrolled";
import { getFieldsFrom } from "utils/helpers/list";
import { ROUTER_MODAL } from "utils/constants/modals";
import { FILTER_SORT_MAP, LABEL_MAP } from "../../utils/constants";
import CellSerialNumber from "../CellSerialNumber";
import CellSimCard from "../CellSimCard";
import CellStatus from "../CellStatus";
import CellSite from "../CellSite";
import styles from "./styles";

const useStyles = createUseStyles(styles);

const ListRouters = ({ items, onDelete }) => {
  const classes = useStyles();
  const { permissions } = usePermissions();

  const fields = React.useMemo(
    () => getFieldsFrom(items, FILTER_SORT_MAP, LABEL_MAP),
    [items]
  );
  const columns = React.useMemo(
    () => [
      {
        accessor: "serial_number",
        Cell: ({ row }) => <CellSerialNumber row={row} />,
      },
      {
        accessor: "status",
        disableGlobalFilter: true,
        Cell: ({ row }) => <CellStatus row={row} />,
      },
      {
        accessor: "sim_card",
        disableGlobalFilter: true,
        Cell: ({ row }) => <CellSimCard row={row} />,
      },
      {
        accessor: "site",
        disableGlobalFilter: true,
        Cell: ({ row }) => <CellSite row={row} />,
      },
    ],
    []
  );

  return (
    <TableUncontrolled
      isVisibleSort
      isVisibleFilter
      columns={columns}
      createCaption="Add Router"
      caption="All Routers"
      entityType="routers"
      totalCount={items.length}
      modalType={ROUTER_MODAL}
      onDelete={onDelete}
      fields={fields}
      rowsPerPage={20}
      data={items}
      isRemovable={permissions && permissions.indexOf("delete-routers") > -1}
      isCreatable={permissions && permissions.indexOf("create-routers") > -1}
      isEditable={permissions && permissions.indexOf("update-routers") > -1}
      isViewable={permissions && permissions.indexOf("view-routers") > -1}
    />
  );
};

export default ListRouters;
