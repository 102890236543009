import React from 'react';
import { createUseStyles } from 'react-jss';
import CellRouterGlobal from 'components/Cells/CellRouter';
import styles from './styles';

const useStyles = createUseStyles(styles);

const CellRouter = ({ row }) => {
  const classes = useStyles();
  
  return !!row.original.router && (
    <CellRouterGlobal
      withIcon
      id={row.original.router.id}
      status={row.original.router.status}
    >
      {row.original.router.serial_number}
    </CellRouterGlobal>
  );
};

export default CellRouter;
