import {
  updateBike,
  createBike,
} from 'utils/helpers/request/api/bikes';
import { useUpdateWrapper } from 'utils/hooks/update';
import { useCreateWrapper } from 'utils/hooks/create';

const useUpdate = () => useUpdateWrapper(updateBike)

const useCreate = () => useCreateWrapper(createBike)

export {
  useUpdate,
  useCreate,
};
