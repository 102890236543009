import React from 'react';
import { createUseStyles } from 'react-jss';
import AsyncAutocompleteForm from 'components/Inputs/AsyncAutocompleteForm';
import { asyncRequestForBikes } from 'utils/helpers/form';
import styles from './styles';

const useStyles = createUseStyles(styles);

const AutocompleteVehicle = ({
  disabled,
  filterFn,
  label = 'License Plate',
}) => {
  const classes = useStyles();
  const asyncRequest = React.useMemo(() => {
    return function () {
      return asyncRequestForBikes(filterFn);
    };
  }, [ filterFn ]);
  
  return (
    <div className={classes.wrapper}>
      <AsyncAutocompleteForm
        label={label}
        asyncRequestFn={asyncRequest}
        disabled={disabled}
        name="bike"
        initAsync
      />
    </div>
  )
};

export default AutocompleteVehicle;
