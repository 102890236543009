import React from 'react';
import { createUseStyles } from 'react-jss';
import AsyncAutocompleteForm from 'components/Inputs/AsyncAutocompleteForm';
import { asyncRequestForSites } from 'utils/helpers/form';
import styles from './styles';

const useStyles = createUseStyles(styles);

const AutocompleteDefaultSite = ({ disabled, onUpdate, name="defaultSite" }) => {
  const classes = useStyles();
  const asyncRequest = React.useMemo(() => {
    return () => asyncRequestForSites();
  }, []);
  
  return (
    <div className={classes.wrapper}>
      <AsyncAutocompleteForm
        label='Address'
        asyncRequestFn={asyncRequest}
        disabled={disabled}
        onUpdate={onUpdate}
        emptyMessage="No empty sites"
        name={name}
        initAsync
      />
    </div>
  )
};

export default AutocompleteDefaultSite;
