import client from '../client';

const addSimCard  = data =>
  client.post('/sim-card', data);

const getSimCards  = () =>
  client.get('/sim-cards');

const getSimCard  = id =>
  client.get(`/sim-cards/${id}`);

const deleteSimCard  = id =>
  client.delete(`/sim-cards/${id}`);

const updateSimCard  = data =>
  client.put(`/sim-cards/${data.id}`, data);

export {
  addSimCard,
  getSimCard,
  getSimCards,
  updateSimCard,
  deleteSimCard,
};
