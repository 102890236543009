export default {
  wrapper: {},
  wrapperPileHeader: {
    display: 'flex',
    flexDirection: 'row',
  },
  wrapperWunderReservation: {
    width: '200px'
  }
};
