import FormLabel from "@material-ui/core/FormLabel";
import Typography from "@material-ui/core/Typography";
import AppFormGap from "components/AppFormGap";
import TextInputForm from "components/Inputs/TextInputForm";
import React, { useEffect, useState } from "react";
import CheckboxTree from "react-checkbox-tree";
import { useForm, Field } from "react-final-form";
import { createUseStyles } from "react-jss";
import { useFetchItem, useOptions } from "utils/hooks/roles";
import styles from "./styles";

const useStyles = createUseStyles(styles);

const Role = ({ id, disabled, onChangePermissions }) => {
  const classes = useStyles();
  const form = useForm();
  const { item, fetch: fetchItem } = useFetchItem(id);
  const { options, fetchOptions } = useOptions();

  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);

  useEffect(() => {
    if (id !== "-1") {
      fetchItem();
    }
    fetchOptions();
  }, []);

  useEffect(() => {
    if (!!item.roleId) {
      form.reset(item);
    }
  }, [item]);

  useEffect(() => {
    if(item && item.permissions) {
      setChecked(item.permissions)
    }
  }, [item])

  const CheckboxTreeWrapper = () => (
    <CheckboxTree
      checked={checked}
      expanded={expanded}
      nodes={options}
      onCheck={(checked) => {
        setChecked(checked);
        onChangePermissions(checked);
      }}
      onExpand={setExpanded}
      showNodeIcon={false}
      disabled={disabled}      
    />
  );

  return (
    <div className={classes.wrapper}>
      <Typography variant="h5">Role</Typography>
      <AppFormGap />
      <TextInputForm name="name" label="Name" disabled={disabled} />
      <AppFormGap />

      <FormLabel> Permissions </FormLabel>
      <AppFormGap />
      <div className={classes.permissionsContainer}>
        <Field name="permissions" component={CheckboxTreeWrapper} />
      </div>
    </div>
  );
};

export default Role;
