import React from 'react';
import { createUseStyles } from 'react-jss';
import Button from '@material-ui/core/Button';

import styles from './styles';

const useStyles = createUseStyles(styles);

const ButtonDefault = ({
  children,
  ...rest
}) => {
  const classes = useStyles();
  const classesButton = React.useMemo(() => ({
    label: classes.label,
  }), [ classes ]);

  return (
    <Button
      variant="contained"
      classes={classesButton}
      disableElevation
      {...rest}
    >
      {children}
    </Button>
  )
};

export default ButtonDefault;
