import React from 'react';
import { createUseStyles } from 'react-jss';
import EvStationIcon from '@material-ui/icons/EvStation';
import CellVehicle from 'components/Cells/CellVehicle';
import styles from './styles';

const useStyles = createUseStyles(styles);

const CellPile = ({ bike }) => {
  const classes = useStyles();
    
  return (
    <div className={classes.wrapper}>
      <EvStationIcon />
      <div className={classes.wrapperInfo}>
        <div className={classes.label}>
         Bike (Pile: {bike.docking_id})
        </div>
        <div className={classes.plate}>
          {!!bike.id_absolute ? (
            <CellVehicle id={bike.id_absolute}>
              {bike.plate}
            </CellVehicle>
          ) : (
            <>
              No bike
            </>
          )}
        </div>
      </div>
    </div>
  )
};

export default CellPile;
