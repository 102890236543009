export default {
  wrapper: {},
  wrapperItems: {

  },
  label: {
    fontSize: 28,
    fontWeight: 'bold',
  },
};
