import { useDispatch } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { setLoader } from 'store/actions/common';
import { updateSite, unlock, forceUnlock } from 'utils/helpers/request/api/sites';
import { addSiteToUserGroup } from 'utils/helpers/request/api/userGroups';
import { parseToBackend } from 'utils/helpers/site';

const useUpdate = () => {
  const dispatch = useDispatch();
  
  const updateItem = async function(data) {
    dispatch(setLoader(true))
    
    try {
      await updateSite(parseToBackend(data));

      if(data.userGroup)
        await addSiteToUserGroup(data.id, data.userGroup.id)
        
      NotificationManager.success('Site successfully updated!');
    } catch (e) {
      console.log('error:', e)
    }
    
    dispatch(setLoader(false))
  };
  
  return {
    updateItem,
  };
};

const useUnlockActions = (id) => {
  const dispatch = useDispatch();
  
  const softUnlock = async function() {
    dispatch(setLoader(true))
    
    try {
      await unlock(id);
    } catch (e) {}
    
    dispatch(setLoader(false))
  };
  
  const hardUnlock = async function() {
    dispatch(setLoader(true))
    
    try {
      await forceUnlock(id);
    } catch (e) {}
    
    dispatch(setLoader(false))
  };
  
  return {
    hardUnlock,
    softUnlock,
  };
};

export {
  useUpdate,
  useUnlockActions,
};
