import React from 'react';
import { createUseStyles } from 'react-jss';
import RoomIcon from '@material-ui/icons/Room';
import CellIconLink from 'components/Cells/CellIconLink';
import styles from './styles';

const useStyles = createUseStyles(styles);

const PinPointIcon = () => {
  const classes = useStyles();
  
  return (
    <div className={classes.wrapperIcon}>
      <RoomIcon/>
    </div>
  );
}

const CellViewSite = ({
  id,
  children,
  status,
}) => {
  return (
    <CellIconLink
      href={`#sites/view/${id}`}
      Icon={PinPointIcon}
      status={status}
    >
      {children}
    </CellIconLink>
  );
};

export default CellViewSite;
