import React from "react";
import { createUseStyles } from "react-jss";
import { useLocation } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import Fade from "@material-ui/core/Fade";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { NAVIGATION_LEFT_MORE } from "utils/constants/menus";
import NavigationItem from "../NavigationItem";
import NavigationMenuItem from "../NavigationMenuItem";
import styles from "./styles";
import { COLOR_DEFAULT_TEXT } from "utils/constants/colors";
import { usePermissions } from "react-admin";
import { filter } from "lodash";

const useStyles = createUseStyles(styles);

const NavigationMore = () => {
  const classes = useStyles();
  const location = useLocation();
  const [menuTitle, setMenuTitle] = React.useState("More");
  const [menuId, setMenuId] = React.useState("more");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuClasses = React.useMemo(
    () => ({
      paper: classes.wrapperPopover,
    }),
    []
  );
  const open = Boolean(anchorEl);
  const handleClick = React.useCallback((event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClickMenu = React.useCallback((event, id) => {
    setAnchorEl(null);
    setMenuTitle(NAVIGATION_LEFT_MORE.find((i) => i.id === id).title);
    setMenuId(NAVIGATION_LEFT_MORE.find((i) => i.id === id).id);
  }, []);
  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const { permissions } = usePermissions();
  const [filteredMenus, setFilteredMenus] = React.useState([]);

  React.useEffect(() => {
    const currentLocation = location.pathname.split("/")[1];
    const foundItem = NAVIGATION_LEFT_MORE.find(
      (i) => i.id === currentLocation
    );

    setMenuTitle(foundItem ? foundItem.title : "More ");
    setMenuId(foundItem ? foundItem.id : "more");
  }, [location]);

  React.useEffect(() => {
    if (permissions) {
      // console.log(NAVIGATION_LEFT_MORE);
      const filtered = NAVIGATION_LEFT_MORE.filter((nav) =>
        permissions.includes("view-" + nav.id)
      );
      // console.log(filtered);
      setFilteredMenus(filtered);
    }
  }, [permissions]);

  return (
    <div className={classes.wrapper}>
      {filteredMenus.length > 0 ? (
        <div className={classes.wrapperMore} onClick={handleClick}>
          <NavigationItem id={menuId} title={menuTitle} />
          <ExpandMoreIcon />
        </div>
      ) : (
        <></>
      )}
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        classes={menuClasses}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {filteredMenus.map((i) => (
          <NavigationMenuItem
            key={i.id}
            id={i.id}
            title={i.title}
            onClick={handleClickMenu}
          />
        ))}
      </Menu>
    </div>
  );
};

export default NavigationMore;
