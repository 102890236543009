import React from 'react';
import { createUseStyles } from 'react-jss';
import CellSiteGlobal from 'components/Cells/CellSite';
import styles from './styles';

const useStyles = createUseStyles(styles);

const CellSite = ({ row }) => {
  const classes = useStyles();

  if (row.original.site) {
    return row.original.site && (
      <CellSiteGlobal
        withIcon
        id={row.original.site.id}
        status={row.original.site.status}
      >
        <div className={classes.wrapperContent}>
          {/* <div className={classes.location}>
            Location ({row.original.site.lat}, {row.original.site.lon})
          </div> */}
          <div className={classes.address}>
            {row.original.site.address}
          </div>
        </div>
      </CellSiteGlobal>
    );
  } else {

    return  (
      <CellSiteGlobal
      >
        <div className={classes.wrapperContent}>
          {/* <div className={classes.location}>
            Location ({row.original.site.lat}, {row.original.site.lon})
          </div> */}
          <div className={classes.address}>
            {row.original.default_site_address ? row.original.default_site_address : ``} 
          </div>
        </div>
      </CellSiteGlobal>
    );
  }



};

export default CellSite;
