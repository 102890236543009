import React from 'react';
import { createUseStyles } from 'react-jss';
import styles from './styles';

const useStyles = createUseStyles(styles);

const LinkView = ({
  children,
  onClick,
}) => {
  const classes = useStyles();
  
  return (
    <div
      className={classes.wrapper}
      onClick={onClick}
    >
      {children}
    </div>
  )
};

export default LinkView;
