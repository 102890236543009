import React from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './styles';

const useStyles = createUseStyles(styles);

const AppLoader = () => {
  const classes = useStyles();
  const isLoading = useSelector(state => state.commonReducer.isLoading);

  return (
    <Backdrop
      className={classes.wrapper}
      open={isLoading}
    >
      <div className={classes.wrapperIcon}>
        <CircularProgress />
      </div>
    </Backdrop>
  )
};

export default AppLoader;
