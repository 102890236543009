import React from "react";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import styles from "./styles";
import { createUseStyles } from "react-jss";
import moment from "moment-timezone";
import { AccessTime, Search } from "@material-ui/icons";
import RefreshIcon from "@material-ui/icons/Replay";

moment.tz.setDefault("Europe/Berlin");
const useStyles = createUseStyles(styles);

export default function CustomDatePicker(props) {
  const DATETIME_FORMAT = "YYYY-MM-DD hh:mm";
  const DATETIME_T_FORMAT = "YYYY-MM-DDThh:mm";
  const INTERVAL_7_DAY = "7d";
  const INTERVAL_7_DAY_TEXT = "Last 7 Day";
  const INTERVAL_1_DAY = "1d";
  const INTERVAL_1_DAY_TEXT = "Last 1 Day";
  const INTERVAL_1_HOUR = "1hr";
  const INTERVAL_1_HOUR_TEXT = "Last 1 Hour";
  const INTERVAL_30_MIN = "30m";
  const INTERVAL_30_MIN_TEXT = "Last 30 Minutes";
  const INTERVAL_15_MIN = "15m";
  const INTERVAL_15_MIN_TEXT = "Last 15 Minutes";
  const INTERVAL_5_MIN = "5m";
  const INTERVAL_5_MIN_TEXT = "Last 5 Minutes";
  const INTERVAL_CUSTOM = "custom";
  const MINUTES = "minutes";
  const DAY = "day";
  const HOUR = "hour";
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [startDate, setStartDate] = React.useState(moment(props.startDate));
  const [endDate, setEndDate] = React.useState(moment(props.endDate));
  let tempStartDate = moment(props.startDate);
  let tempEndDate = moment(props.endDate);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleStartDateChange = (event) => {
    let startDate = moment(event.target.value);
    tempStartDate = startDate;
  };
  const handleEndDateChange = (event) => {
    let endDate = moment(event.target.value);
    tempEndDate = endDate;
  };

  const handleAbsDateChange = (event) => {
    if (tempEndDate.diff(tempStartDate) >= 0) {
      props.onChange({
        startDate: tempStartDate,
        endDate: tempEndDate,
        dpBtnText: INTERVAL_CUSTOM,
      });

    } else {
      alert("Start Date must be greater than End Date");
    }
    setStartDate(moment(props.startDate));
    setEndDate(moment(props.endDate));
    setAnchorEl(null);
  };
  const handleRelDateChange = (event) => {
    let endDate = moment();
    let startDate = null;
    if (event == INTERVAL_5_MIN) {
      startDate = moment(endDate).subtract(5, MINUTES);
    } else if (event == INTERVAL_15_MIN) {
      startDate = moment(endDate).subtract(15, MINUTES);
    } else if (event == INTERVAL_30_MIN) {
      startDate = moment(endDate).subtract(30, MINUTES);
    } else if (event == INTERVAL_1_HOUR) {
      startDate = moment(endDate).subtract(1, HOUR);
    } else if (event == INTERVAL_1_DAY) {
      startDate = moment(endDate).subtract(1, DAY);
    } else if (event == INTERVAL_7_DAY) {
      startDate = moment(endDate).subtract(7, DAY);
    }


    props.onChange({
      startDate: startDate,
      endDate: endDate,
      dpBtnText: event,
    });
    setStartDate(moment(props.startDate));
    setEndDate(moment(props.endDate));
    setAnchorEl(null);
    tempStartDate = startDate;
    tempEndDate = endDate;
  };

  const getDatePickerBtnText = () => {
    if (props.dpBtnText == INTERVAL_CUSTOM) {
      return `${moment(props.startDate).format(DATETIME_FORMAT)} To ${moment(
        props.endDate
      ).format(DATETIME_FORMAT)}`;
    } else if (props.dpBtnText == INTERVAL_5_MIN) {
      return INTERVAL_5_MIN_TEXT;
    } else if (props.dpBtnText == INTERVAL_15_MIN) {
      return INTERVAL_15_MIN_TEXT;
    } else if (props.dpBtnText == INTERVAL_30_MIN) {
      return INTERVAL_30_MIN_TEXT;
    } else if (props.dpBtnText == INTERVAL_1_HOUR) {
      return INTERVAL_1_HOUR_TEXT;
    } else if (props.dpBtnText == INTERVAL_1_DAY) {
      return INTERVAL_1_DAY_TEXT;
    } else if (props.dpBtnText == INTERVAL_7_DAY) {
      return INTERVAL_7_DAY_TEXT;
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = () => {
    // console.log('inside search');
    // console.log(props.dpBtnText);
    if (props.dpBtnText == INTERVAL_CUSTOM) {
      props.onClickSearch({ filterBy: props.filterBy });
    } else {
      handleRelDateChange(props.dpBtnText);
    }
  };
  const handleRefresh = () =>{
    if (props.dpBtnText == INTERVAL_CUSTOM) {
      handleAbsDateChange();
    } else {
      handleRelDateChange(props.dpBtnText);
    }
  }
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className={classes.customDatePickerBar}>
      <div className={classes.customDatePicker}>
        <Button
          aria-describedby={id}
          variant="contained"
          color="primary"
          onClick={handleClick}
        >
         <AccessTime/> &nbsp; {getDatePickerBtnText()}
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{marginLeft:'10px'}}
          onClick={handleRefresh}
        >
          <RefreshIcon style={{margin:'10px'}}/> Refresh
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Grid container alignItems="center" className={classes.root}>
            <div className={classes.absDateWrapper}>
              <div> Absolute time range</div>
              <form className={classes.container} noValidate>
                <TextField
                  id="startDate"
                  label="From"
                  type="datetime-local"
                  defaultValue={moment(props.startDate).format(
                    DATETIME_T_FORMAT
                  )}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleStartDateChange}
                />
              </form>

              <form className={classes.container} noValidate>
                <TextField
                  id="endDate"
                  label="To"
                  type="datetime-local"
                  defaultValue={moment(props.endDate).format(DATETIME_T_FORMAT)}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleEndDateChange}
                />
              </form>
              <Button color="primary" onClick={handleAbsDateChange}>
                {" "}
                Apply time range{" "}
              </Button>
            </div>
            <Divider orientation="vertical" flexItem />
            <div className={classes.relDateWrapper}>
              <div> Relative time range</div>
              <Button
                color="primary"
                onClick={handleRelDateChange.bind(this, INTERVAL_5_MIN)}
              >
                {`${INTERVAL_5_MIN_TEXT}`}
              </Button>
              <Button
                color="primary"
                onClick={handleRelDateChange.bind(this, INTERVAL_15_MIN)}
              >
                {`${INTERVAL_15_MIN_TEXT}`}
              </Button>
              <Button
                color="primary"
                onClick={handleRelDateChange.bind(this, INTERVAL_30_MIN)}
              >
                {`${INTERVAL_30_MIN_TEXT}`}
              </Button>
              <Button
                color="primary"
                onClick={handleRelDateChange.bind(this, INTERVAL_1_HOUR)}
              >
                {`${INTERVAL_1_HOUR_TEXT}`}
              </Button>
              <Button
                color="primary"
                onClick={handleRelDateChange.bind(this, INTERVAL_1_DAY)}
              >
                {`${INTERVAL_1_DAY_TEXT}`}
              </Button>
              <Button
                color="primary"
                onClick={handleRelDateChange.bind(this, INTERVAL_7_DAY)}
              >
                {`${INTERVAL_7_DAY_TEXT}`}
              </Button>
            </div>
          </Grid>
        </Popover>
      </div>
      {props.onClickSearch && (<div className={classes.customDatePicker}>
        <Button
            aria-describedby={id}
            variant="contained"
            color="primary"
            onClick={handleSearch}
        >
          Search
        </Button>
      </div>)
      }
    </div>
  );
}
