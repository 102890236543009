import React from 'react';
import { useDispatch } from 'react-redux';
import { setLoader } from 'store/actions/common';
import { getSites } from 'utils/helpers/request/api/sites';

const useFetchStations = () => {
  const [ items, setItems ] = React.useState([]);
  const dispatch = useDispatch();
  
  const fetch = async function() {
    dispatch(setLoader(true))
    
    try {
      const { data: { items: sites } } = await getSites();
      
      setItems(sites.map((i) => ({
        ...i,
        id: i.site_id,
      })));
    } catch (e) {}
    
    dispatch(setLoader(false))
  };
  
  return {
    items,
    fetch,
  };
};

export {
  useFetchStations,
};
