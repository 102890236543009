import React from "react";
import { DataGrid ,GridToolbar } from "@mui/x-data-grid";
import {eventDescriptions} from "./eventDescriptions";
import Divider from '@material-ui/core/Divider';
import moment from "moment-timezone";
moment.tz.setDefault("Europe/Berlin");

class LogView extends React.Component {
  LOCALSTORAGE_KEY_COLUMNS_HIDE_LIST = "columnsHideList";
  // list of columns with there visiblity
  // false - hide= false
  // true  - hide= true
  columnsHideListObj = {
    deviceId: false,
    event: false,
    code: true,
    bikeId: true,
    pileId: true,
    epochtime: false,
    longitude: true,
    latitude: true,
    bikeStatus: true,
    lockStatus: true,
    capacity: true,
    reason: true,
    pickUpTheCarKey: true,
    userId: true,
    timestamp: true,
    isForced: true,
    controllerFaultCode: true,
    lockFaultCode: true,
    wirelessChargingFaultCode: true,
    pileDisplayControlFaultCode: true,
    siteId: true,
    pilePosition: true,
    pileFaultCode: true,
    batteryFaultCode: true,
    carRentalId: true,
    pickUpTheCar: true,
    lockId: true,
    minBattery: true,
    faultCode: true,
    heart: true,
    emptyHeart: true,
    timeOfPowerReset: true,
    unlockTime: true,
    promptSound: true,
    monitor: true,
    isSend: true,
    payload_data:true,
  };
  columns = [];
  constructor(props) {
    super(props);

    if (localStorage.getItem(this.LOCALSTORAGE_KEY_COLUMNS_HIDE_LIST)) {
      this.columnsHideListObj = JSON.parse(
        localStorage.getItem(this.LOCALSTORAGE_KEY_COLUMNS_HIDE_LIST)
      );
    } else {
      localStorage.setItem(
        this.LOCALSTORAGE_KEY_COLUMNS_HIDE_LIST,
        JSON.stringify(this.columnsHideListObj)
      );
    }
    this.columns = [
      {
        field: "epochtime",
        hide: this.columnsHideListObj["epochtime"],
        headerName: "Timestamp",
        width: 200,
        valueGetter: (param) => {
          try {
            return `${moment(Number(param.value)).format(
              "DD/MM/YYYY h:mm:ss a"
            )}`;
          } catch (error) {
            console.log(error);
            return "";
          }
        },
      },
      {
        field: "deviceId",
        hide: this.columnsHideListObj["deviceId"],
        headerName: "Device",
        width: 200,
        renderCell: (param) => {
          let id = this.props.siteViewLookUp[param.row.deviceId];
          if (id == undefined) {
            id = this.props.bikeViewLookUp[param.row.deviceId];
          }
          if (id) {
            return <div>{id}</div>;
          } else {
            return <div>{param.row.deviceId}</div>;
          }
        },
      },
      {
        field: "event",
        hide: this.columnsHideListObj["event"],
        headerName: "Event",
        width: 200,
      },
      {
        field : "description",
        headerName: "Description",
        width:300,
        valueGetter : (param) => {
          return eventDescriptions[param.row.event];
        }
      },

      {
        field: "code",
        hide: this.columnsHideListObj["code"],
        headerName: "code",
        width: 200,
      },
      {
        field: "bikeId",
        hide: this.columnsHideListObj["bikeId"],
        headerName: "bikeId",
        width: 200,
        renderCell: (param) => {
          let id = this.props.siteViewLookUp[param.row.bikeId];
          if (id == undefined) {
            id = this.props.bikeViewLookUp[param.row.bikeId];
          }
          if (id) {
            return <div>{id}</div>;
          } else {
            return <div>{param.row.bikeId}</div>;
          }
        },
      },
      {
        field: "pileId",
        hide: this.columnsHideListObj["pileId"],
        headerName: "pileId",
        width: 200,
      },
      {
        field: "longitude",
        hide: this.columnsHideListObj["longitude"],
        headerName: "longitude",
        width: 200,
      },
      {
        field: "latitude",
        hide: this.columnsHideListObj["latitude"],
        headerName: "latitude",
        width: 200,
      },
      {
        field: "bikeStatus",
        hide: this.columnsHideListObj["bikeStatus"],
        headerName: "bikeStatus",
        width: 200,
      },
      {
        field: "lockStatus",
        hide: this.columnsHideListObj["lockStatus"],
        headerName: "lockStatus",
        width: 200,
      },
      {
        field: "capacity",
        hide: this.columnsHideListObj["capacity"],
        headerName: "capacity",
        width: 200,
      },
      {
        field: "reason",
        hide: this.columnsHideListObj["reason"],
        headerName: "reason",
        width: 200,
      },
      {
        field: "pickUpTheCarKey",
        hide: this.columnsHideListObj["pickUpTheCarKey"],
        headerName: "pickUpTheCarKey",
        width: 200,
      },
      {
        field: "userId",
        hide: this.columnsHideListObj["userId"],
        headerName: "userId",
        width: 200,
      },
      {
        field: "timestamp",
        hide: this.columnsHideListObj["timestamp"],
        headerName: "timestamp",
        width: 200,
      },
      {
        field: "isForced",
        hide: this.columnsHideListObj["isForced"],
        headerName: "isForced",
        width: 200,
      },
      {
        field: "controllerFaultCode",
        hide: this.columnsHideListObj["controllerFaultCode"],
        headerName: "controllerFaultCode",
        width: 200,
      },
      {
        field: "lockFaultCode",
        hide: this.columnsHideListObj["lockFaultCode"],
        headerName: "lockFaultCode",
        width: 200,
      },
      {
        field: "wirelessChargingFaultCode",
        hide: this.columnsHideListObj["wirelessChargingFaultCode"],
        headerName: "wirelessChargingFaultCode",
        width: 200,
      },
      {
        field: "pileDisplayControlFaultCode",
        hide: this.columnsHideListObj["pileDisplayControlFaultCode"],
        headerName: "pileDisplayControlFaultCode",
        width: 200,
      },
      {
        field: "siteId",
        hide: this.columnsHideListObj["siteId"],
        headerName: "siteId",
        width: 200,
      },
      {
        field: "pilePosition",
        hide: this.columnsHideListObj["pilePosition"],
        headerName: "pilePosition",
        width: 200,
      },
      {
        field: "pileFaultCode",
        hide: this.columnsHideListObj["pileFaultCode"],
        headerName: "pileFaultCode",
        width: 200,
      },
      {
        field: "batteryFaultCode",
        hide: this.columnsHideListObj["batteryFaultCode"],
        headerName: "batteryFaultCode",
        width: 200,
      },
      {
        field: "carRentalId",
        hide: this.columnsHideListObj["carRentalId"],
        headerName: "carRentalId",
        width: 200,
      },
      {
        field: "pickUpTheCar",
        hide: this.columnsHideListObj["pickUpTheCar"],
        headerName: "pickUpTheCar",
        width: 200,
      },
      {
        field: "lockId",
        hide: this.columnsHideListObj["lockId"],
        headerName: "lockId",
        width: 200,
      },
      {
        field: "minBattery",
        hide: this.columnsHideListObj["minBattery"],
        headerName: "minBattery",
        width: 200,
      },
      {
        field: "faultCode",
        hide: this.columnsHideListObj["faultCode"],
        headerName: "faultCode",
        width: 200,
      },
      {
        field: "heart",
        hide: this.columnsHideListObj["heart"],
        headerName: "heart",
        width: 200,
      },
      {
        field: "emptyHeart",
        hide: this.columnsHideListObj["emptyHeart"],
        headerName: "emptyHeart",
        width: 200,
      },
      {
        field: "timeOfPowerReset",
        hide: this.columnsHideListObj["timeOfPowerReset"],
        headerName: "timeOfPowerReset",
        width: 200,
      },
      {
        field: "unlockTime",
        hide: this.columnsHideListObj["unlockTime"],
        headerName: "unlockTime",
        width: 200,
      },
      {
        field: "promptSound",
        hide: this.columnsHideListObj["promptSound"],
        headerName: "promptSound",
        width: 200,
      },
      {
        field: "monitor",
        hide: this.columnsHideListObj["monitor"],
        headerName: "monitor",
        width: 200,
      },
      {
        field: "isSend",
        hide: this.columnsHideListObj["isSend"],
        headerName: "isSend",
        width: 200,
      },
      {
        field: "payload_data",
        hide: this.columnsHideListObj["payload_data"],
        headerName: "Payload",
        width: -1,
        renderCell: (param) => {
          if (!param.row.payload_data || param.row.payload_data == 'NA') {
            return <>NA</>;
          }
          let data = JSON.parse(param.row.payload_data);
          let newData = data.map(item=>{
            let id = item.bikeId == "0"? "NO BIKE" : this.props.bikeViewLookUp[item.bikeId];
            item.bikeId = id;
            return item;
          })
          return <div>{JSON.stringify(newData)}</div>
        },
      },
    ];
    this.getNextLogPage = this.props.getNextLogPage;
  }

  render() {
    let rows = this.props.rows;
    let columns = this.columns;

    return (
      <div style={{ height: 500, width: "100%", marginBottom: 50}}>
          <Divider/>
        <h4>Events Table</h4>

        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          loading={this.props.loading}
          // checkboxSelection
          disableSelectionOnClick
          pagination
          paginationMode="server"
          rowCount={100}
          onPageChange={(newPage) => {this.props.getNextLogPage(newPage)}}
          onColumnVisibilityChange={(params) => {
            // console.log(`Column State Changed: ${params}`);
            // console.log(params);
            // below is sample output of above command
            // {field: 'longitude', colDef: {…}, isVisible: true}

            // update the local storage data with latest value
            // if local storage data is present fetch it and then update
            if (localStorage.getItem(this.LOCALSTORAGE_KEY_COLUMNS_HIDE_LIST)) {
              this.columnsHideListObj = JSON.parse(
                localStorage.getItem(this.LOCALSTORAGE_KEY_COLUMNS_HIDE_LIST)
              );
              this.columnsHideListObj[params.field] = params.isVisible ? false:true;
            } else {
              // if local storage is not present , set default and then update
              this.columnsHideListObj[params.field] = params.isVisible ? false:true;
            }
            localStorage.setItem(
              this.LOCALSTORAGE_KEY_COLUMNS_HIDE_LIST,
              JSON.stringify(this.columnsHideListObj)
            );

          }

        }
        components={{
          Toolbar: GridToolbar,
        }}
        density='compact'
        />
      </div>
    );
  }
}

export default LogView;
