import { COLOR_PRIMARY } from 'utils/constants/colors';

export default {
  wrapper: {
    color: COLOR_PRIMARY,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
};
