export default {
  wrapperInput: {
    position: 'relative',
  },
  wrapperLink: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
