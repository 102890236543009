import React from 'react';
import { createUseStyles } from 'react-jss';
import noop from 'lodash/noop';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Fade from '@material-ui/core/Fade';
import Divider from '@material-ui/core/Divider';
import FilterIcon from 'components/Icons/FilterIcon';
import CurrentFilters from './components/CurrentFilters';
import ButtonFilter from './components/ButtonFilter';
import styles from './styles';

const useStyles = createUseStyles(styles);

const Filter = ({
  fields = [],
  onFilter = noop,
  onRemoveFilter = noop,
}) => {
  const classes = useStyles();
  const filteredFields = React.useMemo(() => fields.filter(f => f.isFilterable !== false), [ fields ]);
  const [ anchorEl, setAnchorEl ] = React.useState(null);
  const [ currentField, setCurrentField ] = React.useState(null);
  const [ filters, setFilters ] = React.useState([]);
  const [ currentFilters, setCurrentFilters ] = React.useState([]);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const triggerFilter = id => {
    const foundField = filteredFields.find(f => f.id === id);

    if (foundField) {
      setCurrentField(foundField)
      setCurrentFilters(prevCurrentFilters => {
        const foundFilter = prevCurrentFilters.find(f => f.id === foundField.id);

        if (!!foundFilter) {
          return prevCurrentFilters.map(f => {
            if (f.id === foundField.id) {
              return {
                ...f,
                value: { label: '' },
              };
            }

            return { ...f };
          });
        } else {
          return [ ...prevCurrentFilters, { id: foundField.id, key: foundField.label, value: { label: '' } } ];
        }
      });
      setFilters(foundField.values);
    } else {
      const foundValue = filteredFields
        .find(f => f.id === currentField.id)
        .values
        .find(v => v.id === id);

      setCurrentFilters(prevCurrentFilters => {
        return prevCurrentFilters.map(f => {
          if (f.id === currentField.id) {
            const newFilter = {
              ...f,
              value: foundValue,
            };
            onFilter(newFilter);
            return newFilter;
          }

          return { ...f };
        });
      });
      setFilters(filteredFields)
      setCurrentField(null);
    }
  };
  const handleDeleteCurrentFilter = React.useCallback(id => {
    setCurrentFilters(prevCurrentFilters => prevCurrentFilters.filter(f => f.id !== id));
    setFilters(filteredFields)
    setCurrentField(null);
    onRemoveFilter(id);
  },  [ filteredFields ]);

  React.useEffect(() => {
    setFilters(filteredFields);
  }, [ filteredFields ]);

  return (
    <div className={classes.wrapper}>
      <ButtonFilter
        filters={currentFilters}
        onClick={handleClick}
      />
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <div className={classes.wrapperTitle}>
          <FilterIcon />
          <div>
            Filter by:
          </div>
          <CurrentFilters
            items={currentFilters}
            onDelete={handleDeleteCurrentFilter}
          />
        </div>
        <Divider />
        {filters.map(f => (
          <MenuItem
            id={f.id}
            onClick={() => {
              triggerFilter(f.id);
            }}
          >
            {f.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
};

export default Filter;
