export default {
  wrapper: {},
  wrapperArrows: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 48,
  },
};
