import React from 'react';
import { createUseStyles } from 'react-jss';
import AppMap from 'components/AppMap';
import { getColorForStatus } from 'utils/helpers/colorGetters';
import { MARKER_STATION } from 'utils/constants/markers';
import { useFetchStations } from './utils/hooks';
import styles from './styles';

const useStyles = createUseStyles(styles);
const lat = 49.862934;
const lon = 8.622394;

const Home = () => {
  const classes = useStyles();
  const [ markers, setMarkers ] = React.useState([]);
  const { items, fetch } = useFetchStations();
  
  React.useEffect(() => {
    fetch();
  }, []);
  
  React.useEffect(() => {
    if (items.length) {
      setMarkers(items.map(i => ({
        color: getColorForStatus(i.status),
        type: MARKER_STATION,
        href: `#sites/view/${i.id}`,
        lat: i.lat,
        lon: i.lon,
      })));
    }
  }, [ items ]);
  
  return (
    <div className={classes.wrapper}>
      <AppMap
        lat={markers[0]?.lat || lat}
        lon={markers[0]?.lon || lon}
        markers={markers}
      />
    </div>
  )
};

export default Home;
