import React from 'react';
import { createUseStyles } from 'react-jss';
import CellStatus from 'components/Cells/CellStatus';
import styles from './styles';

const useStyles = createUseStyles(styles);

const CellPileStatus = ({ row }) => {
  const classes = useStyles();
  
  return (
    <div className={classes.wrapper}>
      <CellStatus type={row.original.status}/>
    </div>
  )
};

export default CellPileStatus;
