import React from 'react';
import { w3cwebsocket as W3CWebSocket } from 'websocket';

const AppWS = ({ onMessage }) => {
  const maxAttempts = 40;
  let wsTimeout = 500;
  let attempts = 1;
  
  const connect = React.useCallback(() => {
    const wsLocal = new W3CWebSocket(process.env.REACT_APP_WSS_URL);
    const checkLocal = () => {
      if ((!wsLocal || wsLocal.readyState == WebSocket.CLOSED) && attempts++ < maxAttempts) {
        connect()
      }
    };
    let connectInterval;
  
    wsLocal.onopen = () => {
      console.log('connected websocket');
      wsTimeout = 500;
      clearTimeout(connectInterval);
  
      wsLocal.send(JSON.stringify({
        event: 'CONNECT',
        data: {
          id: 'ADMIN_DATA',
        },
      }));
    };
    
    wsLocal.onclose = e => {
      console.log(
        `Socket is closed. Reconnect will be attempted in ${Math.min(
          10000 / 1000,
          (wsTimeout + wsTimeout) / 1000
        )} second.`,
        e.reason
      );
  
      wsTimeout = wsTimeout + wsTimeout;
      connectInterval = setTimeout(checkLocal, Math.min(10000, wsTimeout));
    };
    
    wsLocal.onerror = err => {
      console.error(
        "Socket encountered error: ",
        err.message,
        "Closing socket"
      );
  
      wsLocal.close();
    };
    
    wsLocal.onmessage = onMessage;
  }, [ ]);
  
  React.useEffect(() => {
    connect();
  }, []);
  
  return <></>;
};

export default AppWS;
