import client from "../client";

export const getDiscounts = () => client.get("/discounts");

export const deleteDiscount = (id) => client.delete(`/discounts/${id}`);

export const updateDiscount = (data) => client.put(`/discounts/${data.id}`, data);

export const getDiscount = (id) => client.get(`/discounts/${id}`);

export const addDiscount = (data) => client.post("/discounts", data);
