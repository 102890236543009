import React from 'react';
import { createUseStyles } from 'react-jss';
import TextInputForm from 'components/Inputs/TextInputForm';
import styles from './styles';

const useStyles = createUseStyles(styles);

const PileCoordinates = ({ disabled }) => {
  const classes = useStyles();
  
  return (
    <div className={classes.wrapper}>
      <TextInputForm
        name="pileLat"
        disabled={disabled}
        label="Lat"
      />
      <TextInputForm
        name="pileLon"
        disabled={disabled}
        label="Lon"
      />
    </div>
  )
};

export default PileCoordinates;
