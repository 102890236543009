import { COLOR_PRIMARY_BACKGROUND } from 'utils/constants/colors';

export default {
  wrapperBody: {
    marginTop: 32,
  },
  wrapperInner: {
    minWidth: ({ minWidth=600 }) => minWidth,
    minHeight: 100,
  },
  wrapperForm: {
    maxHeight: 600,
    maxWidth: 1200,
    overflowY: 'auto',
    padding: '20px',
    width: '1200px'
  },
  divider: {
    position: 'absolute',
    bottom: -16,
    left: -16,
    right: -16,
    height: 1,
    backgroundColor: COLOR_PRIMARY_BACKGROUND,
  },
};
