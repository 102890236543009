import CRUModal from "components/Modals/CRUModal";
import { usePermissions } from "react-admin";
import React from "react";
import Partner from "../Partner";

const PartnerModal = ({
  onClose,
  onDelete,
  onUpdate,
  disabled,
  onCreate,
  id,
  title,
}) => {
  const { permissions } = usePermissions();

  return (
    <CRUModal
      title={title}
      entity="Partner"
      disabled={disabled}
      onDelete={
        permissions && permissions.indexOf("delete-partners") > -1
          ? title !== "Create Partner"
            ? onDelete
            : undefined
          : undefined
      }
      onUpdate={
        permissions && permissions.indexOf("update-partners") > -1
          ? title !== "Create Partner"
            ? onUpdate
            : undefined
          : undefined
      }
      onCreate={
        permissions && permissions.indexOf("create-partners") > -1
          ? title === "Create Partner"
            ? onCreate
            : undefined
          : undefined
      }
      onClose={onClose}
    >
      <Partner id={id} disabled={disabled} />
    </CRUModal>
  );
};

export default PartnerModal;
