import React from "react";
import { useDispatch } from "react-redux";
import { usePermissions } from "react-admin";
import CRUModal from "components/Modals/CRUModal";
import { useFetchItem, useDelete } from "utils/hooks/lock";
import { setModalData } from "store/actions/common";
import FormContentViewable from "./components/FormContentViewable";
import FormContentCreatable from "./components/FormContentCreatable";

const LockModal = ({
  id,
  fetchItems = () => {},
  onCreate,
  modalInitialData,
}) => {
  const dispatch = useDispatch();
  const { deleteItems } = useDelete();
  const { permissions } = usePermissions();
  const title = React.useMemo(
    () => (onCreate ? "Add Lock" : "Lock"),
    [onCreate]
  );
  const handleDelete = React.useCallback(() => {
    deleteItems([id]).finally(() => {
      fetchItems();
      dispatch(setModalData());
    });
  }, []);
  const handleClose = React.useCallback(() => {
    dispatch(setModalData());
  }, []);
  const { item, fetch } = useFetchItem(id);

  React.useEffect(() => {
    if (id) {
      fetch();
    }
  }, [id]);

  return (
    <CRUModal
      title={title}
      entity="Lock"
      initialValues={modalInitialData}
      onCreate={
        permissions && permissions.indexOf('create-locks') > -1 ? onCreate : undefined
      }
      onDelete={
        permissions && permissions.indexOf('delete-locks') > -1
          ? !onCreate && handleDelete
          : undefined
      }
      onClose={handleClose}
    >
      {onCreate ? (
        <FormContentCreatable item={item} />
      ) : (
        <FormContentViewable item={item} />
      )}
    </CRUModal>
  );
};

export default LockModal;
