import client from '../client';

const getBikes = () =>
  client.get('/bikes');

const getBike = id =>
  client.get(`/bikes/${id}`);

const updateBike = data =>
  client.put(`/bikes/${data.id}`, data);

const createBike = data =>
  client.post('/bike', data);

const deleteBike  = id =>
  client.delete(`/bikes/${id}`);

const getVehicleTypes  = () =>
  client.get('/vehicle-types');

const deleteVehicleType  = id =>
  client.delete(`/vehicle-types/${id}`);

const addVehicleType  = id =>
  client
    .post(
      '/vehicle-type',
      { id }
    );

const syncBikesToMetabase = () => {
  client.get(`/bikes/sync-to-metabase`);
}

const uploadNewVehicleList = async (data)=>{

  try {
    let result = await client.post(`/bikes/file`,data,{
      headers: {
        'Content-Type': 'multipart/form-data',
        'accept':'*/*'
      }});
    if (result.status == '200'){
      return result.data;
    } else {
      return false;
    }
    
  } catch (error){
    console.log(error);
    return false;
  }
}

const getBikesV2 =(data) =>
  client.post('/bikes',data); 

const getAllLicensePlates = ()=>
  client.get('/licensePlates')

export {
  getBike,
  getBikes,
  createBike,
  updateBike,
  deleteBike,
  getVehicleTypes,
  deleteVehicleType,
  addVehicleType,
  syncBikesToMetabase,
  uploadNewVehicleList,
  getBikesV2,
  getAllLicensePlates
};
