import React from 'react';
import { createUseStyles } from 'react-jss';
import Definition from 'components/Definition';
import AppFormGap from 'components/AppFormGap';
import styles from './styles';

const useStyles = createUseStyles(styles);

const RouterInfo = ({
  id,
  iccid,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Definition
        term="Router"
        value={id}
      />
      <AppFormGap />
      <Definition
        term="SIM Card"
        value={iccid}
      />
    </div>
  )
};

export default RouterInfo;
