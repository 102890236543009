import React from "react";
import { Grid } from "@mui/material";
import Typography from "@material-ui/core/Typography";
import Divider from '@material-ui/core/Divider';
import { createUseStyles } from 'react-jss';
import styles from './styles';
import { useUnlockLock } from 'utils/hooks/unlock';
import { useSelector } from 'react-redux';
import ButtonDefault from 'components/Buttons/ButtonDefault';
import moment from 'moment';
const useStyles = createUseStyles(styles);

export default function LockInfoGeneralV2({item,id}){
  const classes = useStyles();
  const { unlock } = useUnlockLock();
  const lockHeartbeatWS = useSelector(state => state.commonReducer.lockHeartbeat[item?.lock?.id]);
  const formattedHeartbeat = React.useMemo(() => {
    const ts = lockHeartbeatWS || item?.lock?.heartbeat;
    
    return moment.unix(+ts).format('DD/MM/YYYY HH:mm:ss');
  }, [ lockHeartbeatWS, item ]);
  
  const handleUnlock = React.useCallback(() => {
    if (item?.lock) {
      unlock(item.lock.id);
    }
  }, [ unlock, item ]);
  return (
    <Grid container spacing={3}>
    <Grid item xs={12}>
      
      <Typography gutterBottom variant="h6" component="h2">
       Smart Lock Informations
      </Typography>
      <Divider />
    </Grid>
    <Grid item xs={6}>
      <Typography gutterBottom variant="body1" component="h2">
        ID:
      </Typography>
    </Grid>
    <Grid item xs={6}>
      <Typography gutterBottom variant="body1" component="h2">
        {item?.lock?.id}
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
        ICCID:
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
        {item?.lock?.sim_card?.iccid}
      </Typography>
    </Grid>

    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
        Last Heartbeat:
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
        {formattedHeartbeat}
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
        Unlock:
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
    <ButtonDefault onClick={handleUnlock}>
          Unlock
    </ButtonDefault>
    </Grid>
  </Grid>
  );
}