const parseDataToBackend = rawData => {
  return {
    id: rawData.pileId,
    site_id: rawData.siteAddress.id,
    lat: rawData.pileLat,
    lon: rawData.pileLon,
    status: rawData.pileStatus,
    mobile_bike_id: rawData.bikeLicensePlate.id,
    docking_type_id: rawData.pileType.id,
  };
};

export {
  parseDataToBackend,
};
