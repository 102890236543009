import { initOptions, parseFromBackend } from "pages/StationGroups/utils/helpers";
import { useState } from "react";
import { NotificationManager } from "react-notifications";
import { useDispatch } from "react-redux";
import { setLoader } from "store/actions/common";
import { addStationGroup, deleteStationGroup, getStationGroup, getStationGroups, updateStationGroup } from "utils/helpers/request/api/stationGroups";

export const useFetchStationGroups = () => {
  const [stationGroups, setStationGroups] = useState([]);
  const dispatch = useDispatch();

  const fetchStationGroups = async () => {
    dispatch(setLoader(true));

    try {
      const { data } = await getStationGroups();

      setStationGroups(data.items);
    } catch (error) {
      console.log("Something unexpected happened. Error: ", error);
    }

    dispatch(setLoader(false));
  };

  return {
    stationGroups,
    fetchStationGroups,
  };
};


export const useDelete = () => {
  const dispatch = useDispatch();

  const deleteItems = async (ids) => {
    dispatch(setLoader(true));
    try {
      for (const id of ids) {
        await deleteStationGroup(id);
      }
    } catch (error) {
      console.log("Something unexpected happened. Error: ", error);
    }
  };

  return {
    deleteItems,
  };
};

export const useDeleteItem = () => {
  const dispatch = useDispatch();

  const deleteItem = async (id) => {
    dispatch(setLoader(true));

    try {
      await deleteStationGroup(id);
      NotificationManager.success("Successfully removed!");
    } catch (e) {}

    dispatch(setLoader(false));
  };

  return {
    deleteItem,
  };
};

export const useUpdateItem = () => {
  const dispatch = useDispatch();

  const updateItem = async (data) => {
    dispatch(setLoader(true));

    try {
      await updateStationGroup(data);
      NotificationManager.success("Successfully updated!");
    } catch (e) {}

    dispatch(setLoader(false));
  };

  return {
    updateItem,
  };
};

export const useFetchItem = (id) => {
  const [item, setItem] = useState({
    name: "",
    stationGroupId: "",
    status: "",
    stationIds: [],
  });
  const dispatch = useDispatch();

  const fetch = async () => {
    dispatch(setLoader(true));

    try {
      const { data: item } = await getStationGroup(id);

      setItem(parseFromBackend(item));
    } catch (e) {}

    dispatch(setLoader(false));
  };
  return {
    item,
    fetch,
  };
};

export const useCreateItem = () => {
  const dispatch = useDispatch();

  const createItem = async (data) => {
    dispatch(setLoader(true));

    try {
      await addStationGroup(data);
      NotificationManager.success("Successfully added!");
    } catch (e) {}

    dispatch(setLoader(false));
  };

  return {
    createItem,
  };
};
