import React from 'react';
import { useDispatch } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import {
  deleteSimCard,
  updateSimCard,
} from 'utils/helpers/request/api/simCards';
import { setLoader } from 'store/actions/common';

const useDelete = id => {
  const dispatch = useDispatch();

  const deleteItem = async function() {
    dispatch(setLoader(true))

    try {
      await deleteSimCard(id);
    } catch (e) {}

    dispatch(setLoader(false))
  };

  return {
    deleteItem,
  };
};

const useUpdate = () => {
  const dispatch = useDispatch();

  const updateItem = async function(data) {
    dispatch(setLoader(true))

    try {
      await updateSimCard(data);
      NotificationManager.success('Successfully  updated!');
    } catch (e) {}

    dispatch(setLoader(false))
  };

  return {
    updateItem,
  };
};

export {
  useDelete,
  useUpdate,
};
