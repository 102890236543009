import React from 'react';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import { usePermissions } from "react-admin";

import { createUseStyles } from 'react-jss';
import AppForm from 'components/AppForm';
import FormContent from './components/FormContent';
import FormContentViewable from './components/FormContentViewable';
import { isDisabledPage } from 'utils/helpers/form';
import { useFetchUsername } from 'utils/hooks/fetch';
import {
  useFetchItem,
  useDelete,
} from 'utils/hooks/site';
import {
  getPiles,
  deletePile,
  updatePile,
} from 'utils/helpers/request/api/dockings';
import { useUpdate } from './utils/hooks';
import styles from './styles';
import { useCreate } from 'utils/hooks/userGroup';

const useStyles = createUseStyles(styles);

const Site = () => {
  const classes = useStyles();
  const { permissions } = usePermissions();
  const { id, operation } = useParams();
  const history = useHistory();
  const { item: username, fetch: fetchUserData } = useFetchUsername();
  const { item, fetch } = useFetchItem(id);
  const { deleteItems } = useDelete();
  const { updateItem } = useUpdate();
  const { associateSiteToUserGroup } = useCreate();
  const handleClose = React.useCallback(() => {
    history.push('/sites');
  }, []);
  const handleUpdateAndClose = React.useCallback(({ values }) => {
    updateItem({ ...values, id, username }).then(() => {
      history.push('/sites');
    }).catch(() => { });
  }, [id, username]);

  const handleUpdate = React.useCallback(({ values }) => {
    values.piles.map((v, i) => {
      updatePile({
        id: v.pile_id,
        lat: values.lat,
        lon: values.lon,
      }).then(() => { });
    });

    updateItem({ ...values, id, username }).catch(() => { });
    if (values.userGroup && values.userGroup.id) {
      associateSiteToUserGroup({ userGroupId: values.userGroup.id, siteId: id });
    }

  }, [id, username]);

  const handleDelete = React.useCallback(() => {
    deleteItems([id])
      .then(() => {
        history.push('/sites');
      });
  }, []);

  React.useEffect(() => {
    fetch();
    fetchUserData();
  }, []);

  return (
    <AppForm
      entity="Site"
      disabled={isDisabledPage(operation)}
      onClose={handleClose}
      onUpdate={
        permissions && permissions.indexOf("update-sites") > -1
          ? handleUpdate
          : null
      }
      onUpdateAndClose={
        permissions && permissions.indexOf("update-sites") > -1
          ? handleUpdateAndClose
          : null
      }
      onDelete={
        permissions && permissions.indexOf("delete-sites") > -1
          ? handleDelete
          : null
      }
      operation={operation}
    >
      {isDisabledPage(operation) ? <FormContentViewable item={item} /> : <FormContent item={item} />}
    </AppForm>
  )
};

export default Site;
