import React from "react";
import { createUseStyles } from "react-jss";
import { useLocation } from "react-router-dom";
import noop from "lodash/noop";
import styles from "./styles";
import { useLogout } from "react-admin";

const useStyles = createUseStyles(styles);

const NavigationItem = ({ id, title, onClick = noop }) => {
  const location = useLocation();
  const isCurrent = React.useMemo(
    () => location.pathname.split("/")[1] === id,
    [location, id]
  );
  const classes = useStyles(isCurrent);
  const href = React.useMemo(() => `#${id}`, [id]);
  const logout = useLogout()

  const handleClick = React.useCallback(
    (e) => {
      if (id === "logout") {
        e.preventDefault();
        logout();
      } else {
        onClick(e, id);
      }
    },
    [id]
  );

  return (
    <a className={classes.wrapper} onClick={handleClick} href={href}>
      {title}
    </a>
  );
};

export default NavigationItem;
