import React from 'react';
import { createUseStyles } from 'react-jss';
import styles from './styles';

const useStyles = createUseStyles(styles);

const AppFooter = () => {
  const classes = useStyles();

  return (
    <footer className={classes.wrapper}>
      Copyright © 2023 Sigo. All rights reserved
    </footer>
  )
};

export default AppFooter;
