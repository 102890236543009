import React from 'react';
import { Grid } from "@mui/material";
import Typography from "@material-ui/core/Typography";
import Divider from '@material-ui/core/Divider';
import CellStatusV2 from 'components/Cells/CellStatusV2';

export default function StationCardInfoV2({item,id}){
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
      <Typography gutterBottom variant="h6" component="h2">
        Key Informations
        </Typography>
        
        <Divider />
      </Grid>
      <Grid item xs={6} style={{ "padding-top": 0 }}>
        <Typography gutterBottom variant="body1" component="h2">
          Status:
        </Typography>
      </Grid>
      <Grid item xs={6} style={{ "padding-top": 0 }}>
        <Typography gutterBottom variant="body1" component="h2">
          {item.status}
        </Typography>
      </Grid>
      <Grid item xs={6} style={{ "padding-top": 0 }}>
        <Typography gutterBottom variant="body1" component="h2">
          Connection Status:
        </Typography>
      </Grid>
      <Grid item xs={6} style={{ "padding-top": 0 }}>
        <Typography gutterBottom variant="body1" component="h2">
        <CellStatusV2 type={item.onlineStatus} />
        </Typography>
      </Grid>
    </Grid>
  );
}