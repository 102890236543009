import React from 'react';
import { createUseStyles } from 'react-jss';
import CellSimCard from 'components/Cells/CellSimCard';
import styles from './styles';

const useStyles = createUseStyles(styles);

const CellICCID = ({ row }) => {
  const classes = useStyles();
  
  return (
    <CellSimCard
      id={row.original.id}
      status={row.original.status}
    >
      {row.original.iccid}
    </CellSimCard>
  );
};

export default CellICCID;
