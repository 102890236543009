import React from 'react';
import CellNameCommon from 'components/Cells/CellName';
import { useDispatch } from 'react-redux';
import { setModalData } from 'store/actions/common';
import { USER_GROUP_MODAL } from 'utils/constants/modals';

const CellName = ({ row, id }) => {
  const dispatch = useDispatch();

  const handleClick = React.useCallback(e => {
    e.preventDefault();
    dispatch(setModalData({ type: USER_GROUP_MODAL, id }));
  }, []);

  return (
    <CellNameCommon 
      row={row.original.name}
      onClick={handleClick}
      />
  );
};

export default CellName;
