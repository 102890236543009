import React from 'react';
import moment from 'moment';

const CellLastUsed = ({ row }) => {
  if (row.original.last_used == null) {
    return <> N/A </>;
  } else {
    return <> {moment(row.original.last_used).fromNow()} </>;
  }
};

export default CellLastUsed;
