// const COLOR_PRIMARY_BACKGROUND = '#C4C4C4';
const COLOR_PRIMARY_BACKGROUND = '#00CC77';

const COLOR_PRIMARY_TEXT = '#c9c9c9';
const COLOR_PRIMARY = '#00cc77';
const COLOR_WARNING = '#ff9800';
const COLOR_MAINTENANCE = '#F72E2E';
const COLOR_INFO = '#2196f3';
const COLOR_ERROR = '#f44336';
const COLOR_DEFAULT_TEXT = '#444444';
const COLOR_SECONDARY = '#FFFFFF';
const COLOR_BORDER = '#999999';
const COLOR_PRIMARY_TEXT_2 = '#999999';
const COLOR_NO_TRACKER = '#F72E2E';

export {
  COLOR_PRIMARY_BACKGROUND,
  COLOR_PRIMARY_TEXT,
  COLOR_PRIMARY,
  COLOR_WARNING,
  COLOR_MAINTENANCE,
  COLOR_INFO,
  COLOR_DEFAULT_TEXT,
  COLOR_PRIMARY_TEXT_2,
  COLOR_SECONDARY,
  COLOR_BORDER,
  COLOR_ERROR,
  COLOR_NO_TRACKER
};
