import React from 'react';
import { createUseStyles } from 'react-jss';
import styles from './styles';

const useStyles = createUseStyles(styles);

const AppFormGap = () => {
  const classes = useStyles();
  
  return (
    <div className={classes.wrapper} />
  )
};

export default AppFormGap;
