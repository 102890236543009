import CRUModal from 'components/Modals/CRUModal';
import React from 'react';
import { usePermissions } from 'react-admin';
import { useDispatch } from 'react-redux';
import { setModalData } from 'store/actions/common';
import {
  useDelete, useFetchItem
} from 'utils/hooks/router';
import FormContentCreatable from './components/FormContentCreatable';
import FormContentViewable from './components/FormContentViewable';

const RouterModal = ({
  id,
  fetchItems = () => {},
  onCreate,
  modalInitialData,
}) => {
  const dispatch = useDispatch();
  const { item, fetch } = useFetchItem(id);
  const { deleteItems } = useDelete();
  const { permissions } = usePermissions();
  const title = React.useMemo(() => onCreate ? 'Add Router' : 'Router', [ onCreate ]);
  const handleClose = React.useCallback(() => {
    dispatch(setModalData());
  }, []);
  const handleDelete = React.useCallback(() => {
    deleteItems([ id ])
      .finally(() => {
        fetchItems();
        dispatch(setModalData());
      });
  },[ id ]);
  
  React.useEffect(() => {
    if (id) {
      fetch();
    }
  }, [ id ]);
  
  return (
    <CRUModal
      title={title}
      entity="Router"
      initialValues={modalInitialData}
      onCreate={permissions && permissions.indexOf('create-routers') > -1 ? onCreate : undefined}
      onDelete={permissions && permissions.indexOf('delete-routers') > -1 ? !onCreate && handleDelete : undefined}
      onClose={handleClose}
    >
      {onCreate ?
        <FormContentCreatable item={item} /> :
        <FormContentViewable item={item} />
      }
    </CRUModal>
  );
};

export default RouterModal;
