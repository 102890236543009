import React from "react";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 500,
    },
  },
};

export default function SelectFirmwareStep(props) {
  const [firmwareName, setFirmwareName] = React.useState(
    props.selectedFirmwareName
  );
  const [firmwareId, setFirmwareId] = React.useState(props.selectedFirmwareId);

  const handleChangeFirmware = (event) => {
    const {
      target: { value, name },
    } = event;
    setFirmwareId(value);
    setFirmwareName(value);
    props.handleFirmwareChange(value);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <FormControl sx={{ m: 1, width: 600 }}>
            <InputLabel id="select-firmware-name-label">
              Firmware Name
            </InputLabel>
            <Select
              labelId="select-firmware-name-label"
              id="select-firmware-name"
              value={firmwareName}
              onChange={handleChangeFirmware}
              input={<OutlinedInput label="Firmware Name" />}
              MenuProps={MenuProps}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {props.firmwareOptions.map((option) => {
                return (
                  <MenuItem
                    id={option.id}
                    value={option.id}
                    selected={option.id == firmwareId}
                  >
                    {option.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}
