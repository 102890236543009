import CRUModal from "components/Modals/CRUModal";
import React from "react";
import { usePermissions } from 'react-admin'
import Discount from "../Discount";

const DiscountModal = ({
  onClose,
  onDelete,
  onUpdate,
  disabled,
  onCreate,
  id,
  title,
}) => {
  const { permissions } = usePermissions()
  return (
    <CRUModal
      title={title}
      entity="Discount"
      disabled={disabled}
      onDelete={permissions && permissions.indexOf("delete-discount-management") > -1 ? title !== "Create New Discount" ? onDelete : undefined : undefined}
      onUpdate={permissions && permissions.indexOf("delete-discount-management") > -1 ? title !== "Create New Discount" ? onUpdate : undefined : undefined}
      onCreate={permissions && permissions.indexOf("delete-discount-management") > -1 ? title === "Create New Discount" ? onCreate : undefined: undefined}
      onClose={onClose}
    >
      <Discount id={id} disabled={disabled} />
    </CRUModal>
  );
};

export default DiscountModal;
