import React from 'react';
import CellPile from '../CellPile';

const CellPile1 = ({ row }) => {
  // return !!row.original.bikes[0] && (
  //   <CellPile bike={row.original.bikes[0]} />
  // );
  const pileContent = [];
  if (row.original.bikes.length == 0){
    return (<></>)
  } else {
    row.original.bikes.forEach(bike=>{
      pileContent.push(<CellPile bike={bike} />)
    })
    return(<>
    {pileContent}
    </>)
  }
};

export default CellPile1;
