import React from 'react';
import { createUseStyles } from 'react-jss';
import styles from './styles';

const useStyles = createUseStyles(styles);

const CellOnlineStatus = ({ type }) => {
  const classes = useStyles({ type });
  if (type == 'ONLINE') {
   
    return (
      <div className={classes.wrapperOnline}>
       ONLINE
      </div>
    );
  } else {
    return (
      <div className={classes.wrapperOffline}>
        OFFLINE
      </div>
    );
  }

};

export default CellOnlineStatus;
