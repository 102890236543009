import React from 'react';
import { createUseStyles } from 'react-jss';
import ButtonCureError from 'components/Buttons/ButtonCureError';
import ButtonSiteUnlock from 'components/Buttons/ButtonSiteUnlock';
import ButtonSiteForceUnlock from 'components/Buttons/ButtonSiteForceUnlock';
import { getColorForStatus } from 'utils/helpers/colorGetters';
import styles from './styles';

const useStyles = createUseStyles(styles);

const PileInfo = ({
  id,
  siteId,
  bikeId,
  status,
  licensePlate,
}) => {
  const classes = useStyles({ color: getColorForStatus(status) });
  
  return (
    <div className={classes.wrapper}>
      <div className={classes.wrapperInfo}>
        <div className={classes.wrapperLabel}>
          <div className={classes.label}>
            {id}
          </div>
          <div className={classes.indicator} />
        </div>
        <div className={classes.bike}>
          {licensePlate || 'No bike'}
        </div>
      </div>
      <div className={classes.wrapperActions}>
        <ButtonCureError
          dockingId={id}
          siteId={siteId}
        />
        {
          !!licensePlate && (
            <>
              <ButtonSiteUnlock id={bikeId} />
              <ButtonSiteForceUnlock id={bikeId} />
            </>
          )
        }
      </div>
    </div>
  );
};

export default PileInfo;
