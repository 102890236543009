import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import AppFormGap from 'components/AppFormGap';
import CellStatus from 'components/Cells/CellStatus';
import AppSelectForm from 'components/Inputs/AppSelectForm';
import AutocompleteSimCard from 'components/Inputs/AutocompleteSimCard';
import AutocompleteVehicle from 'components/Inputs/AutocompleteVehicle';
import TextInputForm from 'components/Inputs/TextInputForm';
import Forbidden from 'pages/Forbidden';
import React from 'react';
import { usePermissions } from 'react-admin';
import { useForm } from 'react-final-form';
import { createUseStyles } from 'react-jss';
import { simpleMemoize } from 'utils/helpers/fn';
import { isCreatable, isViewable } from 'utils/helpers/form';
import { getLock } from 'utils/helpers/request/api/locks';
import { STATUSES } from '../../utils/constants';
import styles from './styles';

const useStyles = createUseStyles(styles);

const FormContent = ({
  operation,
  item,
}) => {
  const classes = useStyles();
  const form = useForm();
  const { permissions } = usePermissions()
  const idAvailable = React.useCallback(simpleMemoize(async (value = '') => {
    if (value.trim().length === 0) return 'Required';
    
    if (value.trim() === item.id) return undefined;
    
    const lockItem = await getLock(value);
    
    if (!!lockItem) return 'Id taken';
  }), [ item.id ]);
  const filterForSimCard = React.useMemo(() => {
    return function (simCard) {
      if (!!simCard.lock) {
        return simCard.lock.id === item.id;
      }
      if (!simCard.router) return true;
      if (!simCard.lock) return true;
    };
  }, [ item ]);
  const filterForVehicle = React.useMemo(() => {
    return function (vehicle) {
      if (vehicle.lock_id) return vehicle.lock_id === item.id;
  
      return true;
    };
  }, [ item ]);
  
  React.useEffect(() => {
    if (!!item.id) {
      form.reset(item);
    }
  }, [ item ]);
  
  return (permissions && permissions.indexOf('create-locks') > -1) || (permissions && permissions.indexOf('update-locks') > -1) ? (
    <div className={classes.wrapper}>
      <AppFormGap />
      <Typography variant="h5">
        Lock {isViewable(operation) && <CellStatus type={item.status}/>}
      </Typography>
      <AppFormGap />
      <TextInputForm
        name="id"
        label="Id"
        initialValue=""
        disabled={isViewable(operation)}
        fieldValidation={idAvailable}
      />
      {!isViewable(operation) && (
        <>
          <AppFormGap />
          <AppSelectForm
            name="status"
            label="Status"
            options={STATUSES}
          />
        </>
      )}
      <AppFormGap />
      <AppFormGap />
      <Typography variant="h5">
        {(isViewable(operation) && !!item.sim_card) ? (
          <Link
            href={`#/sim-cards/edit/${item.sim_card.id}`}
            target="_blank"
          >
            SIM Card
          </Link>
        ) : 'SIM Card'}
      </Typography>
      <AppFormGap />
      <AutocompleteSimCard
        form={form}
        disabled={isViewable(operation)}
        filterFn={filterForSimCard}
      />
      {!isCreatable(operation) && (
        <>
          <AppFormGap />
          <AppFormGap />
          <Typography variant="h5">
            {(isViewable(operation) && !!item.bike) ? (
              <Link
                href={`#/vehicles/view/${item.bike.id}`}
                target="_blank"
              >
                Vehicle
              </Link>
            ) : 'Vehicle'}
          </Typography>
          <AppFormGap />
          <AutocompleteVehicle
            form={form}
            disabled={isViewable(operation)}
            filterFn={filterForVehicle}
          />
        </>
      )}
    </div>
  ) : (
    <div className={classes.wrapper}>
      <Forbidden/>
    </div>
  )
};

export default FormContent;
