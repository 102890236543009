import React from 'react';
import { createUseStyles } from 'react-jss';
import CellSimCard from 'components/Cells/CellSimCard';
import styles from './styles';

const useStyles = createUseStyles(styles);

const CellVehicle = ({ row }) => {
  const classes = useStyles();
  
  return row.original.sim_card && (
    <CellSimCard
      id={row.original.sim_card.id}
      status={row.original.sim_card.status}
    >
      {row.original.sim_card.iccid}
    </CellSimCard>
  );
};

export default CellVehicle;
