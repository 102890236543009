import React from 'react';
import {
  FormSpy,
  Field,
} from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';

const PileFormSpy = ({ field, becomesNotEqual, set, to }) => (
  <Field name={set} subscription={{}}>
    {(
      { input: { onChange } }
    ) => (
      <FormSpy subscription={{}}>
        {({ form }) => (
          <OnChange name={field}>
            {value => {
              if (value?.id !== becomesNotEqual.id) {
                onChange(to);
              }
            }}
          </OnChange>
        )}
      </FormSpy>
    )}
  </Field>
);

export default PileFormSpy;
