import React from 'react';
import { createUseStyles } from 'react-jss';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import styles from './styles';

const useStyles = createUseStyles(styles);

const NotFound = () => {
  const classes = useStyles();
  
  return (
    <div className={classes.wrapper}>
      <Typography variant="h1">
        404
      </Typography>
      <Typography variant="h5">
        Sorry :( Page you are looking not found
      </Typography>
      <Link href="#/">
        Go Home
      </Link>
    </div>
  )
};

export default NotFound;
