import React from 'react';
import { createUseStyles } from 'react-jss';
import { useForm } from 'react-final-form';
import Divider from '@material-ui/core/Divider';
import AppFormGap from 'components/AppFormGap';
import StationInfo from 'components/Info/StationInfo';
import RouterInfo from 'components/Info/RouterInfo';
import StationInfoGeneral from './components/StationInfoGeneral';
import StationLocation from './components/StationLocation';
import PileInfoGeneral from './components/PileInfoGeneral';
import styles from './styles';
import UserGroupInfoGeneral from 'pages/Site/components/FormContentViewable/components/UserGroupInfoGeneral';

const useStyles = createUseStyles(styles);

const FormContent = ({ item }) => {
  const classes = useStyles();
  const form = useForm();
  
  React.useEffect(() => {
    form.reset(item);
  }, [ item ]);
  
  return (
    <div className={classes.wrapper}>
      <div className={classes.wrapperRow}>
        <div className={classes.wrapperLeft}>
          <StationInfoGeneral item={item} />
          <AppFormGap />
          <AppFormGap />
          <StationInfo
            street={item.street}
            city={item?.city?.name}
            lat={item.lat}
            lon={item.lon}
            partner={item.partner}
            sonoff={item.icpu}
          />
          <AppFormGap />
          <AppFormGap />
          <RouterInfo
            id={item?.router?.name}
            iccid={item?.sim_card?.name}
          />
        </div>
        <div className={classes.wrapperRight}>
          <StationLocation item={item} />
        </div>
      </div>
      <AppFormGap />
      <AppFormGap />
      <PileInfoGeneral item={item} />

      {/* Show hr if piles and user groups are defined */}
      {(item.piles && item.userGroups) && ( <Divider width='80%' style={{margin: '0 auto', }}/> )}

      <AppFormGap />

      {/* Show user group list if user group - site relationship exists */}
      { item.userGroups && ( <UserGroupInfoGeneral userGroups={item.userGroups} item={item} /> ) }
      
    </div>
  )
};

export default FormContent;
