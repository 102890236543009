import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import SelectSitesStep from "../StepSelectSites";
import SelectFirmwareStep from "../StepSelectFirmware";
import DataGridSites from "../DatGridSites";
import { getSites } from "utils/helpers/request/api/sites";
import { NotificationManager } from "react-notifications";
import { setLoader } from "store/actions/common";
import { useDispatch } from "react-redux";
import {
  getFirmwareVersions,
  listFirmware,
  triggerOta,
} from "utils/helpers/request/api/ota";
import StepConfirmOTA from "../StepConfirmOTA";
import ButtonDefault from "../../../../components/Buttons/ButtonDefault";
const steps = ["Select Sites", "Select Firmware", "Trigger Bulk OTA Updates"];

export default function SiteStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [siteInfo, setSiteInfo] = React.useState([]);
  const [selectedComponent, setSelectedComponent] = React.useState(null);
  const [selectedComponentVersion, setSelectedComponentVersion] =
    React.useState(null);
  const [filteredSites, setFilteredSite] = React.useState([]);
  const [showAlert, setShowAlert] = React.useState(false);
  const [alertVariant, setAlertVariant] = React.useState();
  const [alertMessage, setAlertMessage] = React.useState("");
  const [selectedSites, setSelectedSites] = React.useState([]);
  const [msvOptions, setMsvOptions] = React.useState([]);
  const [sdsvOptions, setSdsvOptions] = React.useState([]);
  const [rsvOptions, setRsvOptions] = React.useState([]);
  const [psvOptions, setPsvOptions] = React.useState([]);
  const [dsvOptions, setDsvOptions] = React.useState([]);
  const [esvOptions, setEsvOptions] = React.useState([]);
  const [vOptions, setVOptions] = React.useState([]);
  const [selectedFirmwareName, setSelectedFirmwareName] = React.useState("");
  const [selectedFirmwareId, setSelectedFirmwareId] = React.useState([]);
  const [firmwareOptions, setFirmwareOptions] = React.useState([]);
  const [firmwareIdToDetailsMapping, setFirmwareIdToDetailsMapping] =
    React.useState({});
  const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);
  const [reqId, setReqId] = React.useState("");
  const [rid, setRid] = React.useState("");
  const dispatch = useDispatch();
  const cTypeToNameMapping = {
    msv: "Terminal",
    sdsv: "Site Display",
    rsv: "Site Reset Controller",
    psv: "ICPU",
    dsv: "Dock Display",
    esv: "EICC",
    undefined: "",
  };

  React.useEffect(() => {
    init();
  }, []);
  const init = async () => {
    dispatch(setLoader(true));
    let response = [];
    // fetch all site info
    response = await getSites();
    if (
      response &&
      response.status == 200 &&
      typeof response.data != "string"
    ) {
      setSiteInfo(response.data.items);
    }
    // fetch all distinct firmware version info
    response = await getFirmwareVersions();
    if (response && response.status == 200) {
      generateComponentWiseVersionOptions(response.data);
    }

    // fetch all firmware list
    let result = await listFirmware(0, 100);
    if (result.length > 0) {
      setFirmwareOptions(result);
      generateFirmwareIdToDetailsMapping(result);
    }
    dispatch(setLoader(false));
  };

  const generateFirmwareIdToDetailsMapping = (firmwareData) => {
    let result = {};
    firmwareData.map((data) => {
      result[data.id] = data;
    });
    setFirmwareIdToDetailsMapping(result);
  };
  const generateComponentWiseVersionOptions = (data) => {
    setMsvOptions(data.msv);
    setSdsvOptions(data.sdsv);
    setRsvOptions(data.rsv);
    setPsvOptions(data.psv);
    setDsvOptions(data.dsv);
    setEsvOptions(data.esv);
  };

  const handleFirmwareChange = (value) => {
    setSelectedFirmwareId(value);
    if (value) {
      setSelectedFirmwareName(firmwareIdToDetailsMapping[value].name);
      setAlertVariant("success");
      setAlertMessage(
        `${selectedSites.length} sites currently on component name : ${cTypeToNameMapping[selectedComponent]} and version : ${selectedComponentVersion} will be updated to firmware file name : ${firmwareIdToDetailsMapping[value].name} Version : ${firmwareIdToDetailsMapping[value].version}`
      );
      setShowAlert(true);
    }
  };
  const handleCompTypeChange = (value) => {
    setSelectedComponent(value);
  };
  const handleCompTypeVerChange = (value) => {
    setSelectedComponentVersion(value);
  };
  const handleSearchSite = (selectedComponent, selectedComponentVersion) => {
    if (selectedComponent == "") {
      NotificationManager.error("Please select component type.");
      setFilteredSite([]);
      setShowAlert(false);
      return;
    }
    dispatch(setLoader(true));
    let result = siteInfo.filter((site) => {
      if (selectedComponentVersion == "all") {
        return site;
      } else if (
        site[selectedComponent] &&
        site[selectedComponent] == selectedComponentVersion
      ) {
        return site;
      }
    });
    if (result.length == 0) {
      setAlertVariant("error");
      setAlertMessage(
        `No sites found for component name : ${cTypeToNameMapping[selectedComponent]} and version : ${selectedComponentVersion}`
      );
    } else {
      setAlertVariant("success");
      setAlertMessage(
        `${result.length} sites found for component name : ${cTypeToNameMapping[selectedComponent]} and version : ${selectedComponentVersion}`
      );
    }
    setShowAlert(true);
    setFilteredSite(result);
    dispatch(setLoader(false));
  };

  const handleSiteSelected = (data) => {
    setSelectedSites(data);
    if (data.length == 0) {
      setAlertVariant("error");
    } else {
      setAlertVariant("success");
    }
    setAlertMessage(
      `${data.length} sites selected of component name : ${cTypeToNameMapping[selectedComponent]} and version : ${selectedComponentVersion} for OTA update`
    );
  };

  const isStepOptional = (step) => {
    return false;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const isStep1Valid = () => {};
  const isStep2Valid = () => {
    if (!selectedFirmwareName) {
      NotificationManager.error("Please select firmware for OTA Updates");
      return false;
    }
    return true;
  };
  const isSitesSelectedValid = () => {
    if (selectedSites.length == 0) {
      NotificationManager.error("Please select sites for OTA Updates");
      return false;
    }
    return true;
  };
  const handleNext = async () => {
    // setShowSnackBar(true);
    if (activeStep === 0 && isSitesSelectedValid() === false) {
      // validate sites selected.
      return;
    } else if (activeStep === 1 && isSitesSelectedValid() == false) {
      // no site selected;
      return;
    } else if (activeStep === 1 && isStep2Valid() === false) {
      // no firmware selected;
      return;
    }

    // display trigger confirmation
    if (activeStep === 1) {
      // set confirm text
      // open dialoag
      setShowConfirmDialog(true);
      return;
    }

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setSelectedComponent(null);
    setSelectedComponentVersion(null);
    setSelectedSites([]);
    setSelectedFirmwareId(null);
    setSelectedFirmwareName(null);
    setShowAlert(false);
    setFilteredSite([]);
    setActiveStep(0);
  };

  const handleTriggerYes = async () => {
    // set loader
    dispatch(setLoader(true));
    // send api request
    let data = {};
    data["firmwareFileId"] = selectedFirmwareId;
    data["siteIds"] = selectedSites;
    data["ctype"] = selectedComponent;
    data["cversion"] = selectedComponentVersion;
    let responseData = await triggerOta(data);
    if (responseData) {
      setReqId(responseData.id);
      setRid(responseData.rid);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      NotificationManager.error(
        `Failed to trigger OTA update request.Please try after sometime.`
      );
    }
    // get trigger id from api response
    // display trigger id and on next step
    setShowConfirmDialog(false);

    // unset loader
    dispatch(setLoader(false));
  };
  const handleTriggerCancel = () => {
    setShowConfirmDialog(false);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {/** step 0 : display component type and version filters */}
      {activeStep === 0 ? (
        <SelectSitesStep
          handleCompTypeChange={handleCompTypeChange}
          handleCompTypeVerChange={handleCompTypeVerChange}
          handleSearchSite={handleSearchSite}
          selectedComponent={selectedComponent}
          selectedComponentVersion={selectedComponentVersion}
          msvOptions={msvOptions}
          sdsvOptions={sdsvOptions}
          rsvOptions={rsvOptions}
          psvOptions={psvOptions}
          dsvOptions={dsvOptions}
          esvOptions={esvOptions}
          vOptions={vOptions}
          setVOptions={setVOptions}
        />
      ) : (
        <></>
      )}

      {/** step 1 : display firmware filters */}
      {activeStep === 1 ? (
        <SelectFirmwareStep
          firmwareOptions={firmwareOptions}
          handleFirmwareChange={handleFirmwareChange}
          selectedFirmwareName={selectedFirmwareName}
          selectedFirmwareId={selectedFirmwareId}
        />
      ) : (
        <></>
      )}

      {/** comman for step 0 & 1 */}
      {activeStep !== 2 ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {showAlert && (
              <>
                <Alert severity={alertVariant}>{alertMessage}</Alert>
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            {filteredSites.length > 0 ? (
              <DataGridSites
                filteredSites={filteredSites}
                handleSiteSelected={handleSiteSelected}
                cType={selectedComponent}
                cTypeName={cTypeToNameMapping[selectedComponent]}
              />
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      ) : (
        <>
          <Box
            sx={{
              p: 2,
              border: "1px grey",
              background: "#edf7ed",
              color: "#408844",
              margin: "10px",
              borderRadius: "5px",
            }}
          >
            {/** step 2 : trigger bulk  */}

            <Grid container spacing={2} style={{}}>
              <Grid item xs={12}>
                <h2>Request Submitted Successfully.</h2>
              </Grid>
              <Grid item xs={12}>
                Bulk Request ID for future reference is <b>{reqId}</b>.
              </Grid>
              <Grid item xs={12}>
                You can track the progress of OTA Request{" "}
                <b>
                  <a href={`#/bulk-ota-track?rid=${rid}`}>here</a>
                </b>
                .
              </Grid>
              <Grid item xs={12}>
                <i>
                  OTA request takes around — <strong>15 minutes</strong> to
                  complete.
                </i>
              </Grid>
            </Grid>
          </Box>
        </>
      )}

      {/** confirmation box */}
      <StepConfirmOTA
        showConfirmDialog={showConfirmDialog}
        handleTriggerYes={handleTriggerYes}
        confirmTriggerMsg={alertMessage}
        handleTriggerCancel={handleTriggerCancel}
      />

      {activeStep === steps.length - 1 ? (
        <React.Fragment>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <ButtonDefault onClick={handleReset}>Reset</ButtonDefault>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <ButtonDefault
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </ButtonDefault>
            <Box sx={{ flex: "1 1 auto" }} />
            {isStepOptional(activeStep) && (
              <ButtonDefault color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </ButtonDefault>
            )}

            <ButtonDefault onClick={handleNext}>
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </ButtonDefault>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}
