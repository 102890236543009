import client from "../client";

export const getStationGroups = () => client.get("/station-groups");

export const deleteStationGroup = (id) => client.delete(`/station-groups/${id}`);

export const updateStationGroup = (data) => client.put(`/station-groups/${data.id}`, data);

export const getStationGroup = (id) => client.get(`/station-groups/${id}`);

export const addStationGroup = (data) => client.post("/station-groups", data);