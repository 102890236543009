import React from 'react';
import { createUseStyles } from 'react-jss';
import SimCardIcon from '@material-ui/icons/SimCard';
import CellIconLink from 'components/Cells/CellIconLink';
import styles from './styles';

const useStyles = createUseStyles(styles);

const CellEditSimCard = ({
  id,
  children,
  status,
}) => {
  const classes = useStyles({ status });
  
  return (
    <CellIconLink
      href={`#sim-cards/view/${id}`}
      Icon={SimCardIcon}
      status={status}
    >
      {children}
    </CellIconLink>
  );
};

export default CellEditSimCard;
