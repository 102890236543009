import {
  ERROR,
  MAINTENANCE,
  TRIP,
  OPERATION,
  ACTIVE,
  CHARGING,
  INVENTORY,
} from 'utils/constants/statuses';

const STATUSES = [
  { id: MAINTENANCE, name: MAINTENANCE },
  { id: INVENTORY, name: INVENTORY },
  { id: ERROR, name: ERROR },
  { id: TRIP, name: TRIP },
  { id: OPERATION, name: OPERATION },
  { id: ACTIVE, name: ACTIVE },
  { id: CHARGING, name: CHARGING },
];

export {
  STATUSES,
};
