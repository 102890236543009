import { COLOR_DEFAULT_TEXT } from 'utils/constants/colors';

export default {
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 80,
  },
  wrapperIcon: {
    cursor: 'pointer',
    display: 'flex',
    '& svg': {
      strokeWidth: 2,
    },
    '& a': {
      color: COLOR_DEFAULT_TEXT,
      display: 'flex',
    },
  },
};
