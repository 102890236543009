export default {
  wrapper: {},
  wrapperHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: 300,
    padding: '10px 20px',
  },
  wrapperLeftHeader: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  wrapperRightHeader: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& > button': {
      marginLeft: 10,
    },
  },
  wrapperBody: {
    padding: 20,
  },
  title: {
    fontWeight: 'bold',
  },
};
