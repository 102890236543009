import { useDispatch } from 'react-redux';
import { setLoader } from 'store/actions/common';
import { NotificationManager } from 'react-notifications';

const useUpdateWrapper = asyncRequest => {
  const dispatch = useDispatch();
  
  const updateItem = async function (data) {
    dispatch(setLoader(true))
    
    try {
      await asyncRequest(data);
      NotificationManager.success('Successfully  updated!');
    } catch (e) {
    }
    
    dispatch(setLoader(false))
  };
  
  return {
    updateItem,
  };
};

export {
  useUpdateWrapper,
};
