import React from 'react';
import { createUseStyles } from "react-jss";

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';
import { Grid } from '@mui/material';
import Divider from '@material-ui/core/Divider';

import LogViewer from '../../../../../LogViewer';
import StationInfoV2 from '../StationInfoV2';
import StationVersionInfoV2 from '../StationVersionInfoV2';
import PileInfoGeneralV2 from '../PileInfoGeneralV2';
import PileInfoVersionv2 from '../PileInfoVersionV2';
// const useStyles = createUseStyles(styles);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    minWidth: 500,
    minHeight:500
    // boxShadow: "10px 10px",
  },
  appbar : {
    backgroundColor: "transparent",
    boxShadow: "none",
    color: "black",
  }
}));

export default function SiteDetailsTabs({ item, id }) {
  const classes = useStyles();
  let tabCachedValue = 0;
  if (localStorage.getItem("SITE_VIEW_TAB")){
    tabCachedValue = Number(localStorage.getItem("SITE_VIEW_TAB"));
  }

  const [value, setValue] = React.useState(tabCachedValue);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem("SITE_VIEW_TAB",newValue);
  };
  // console.log('hello', item)
  return (
    <Card className={classes.root}>
      <CardContent>
        <AppBar position="static" className={classes.appbar}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab label="General Details" {...a11yProps(0)} />
            <Tab label="Version Details" {...a11yProps(1)} />
            <Tab label="Logs" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <StationInfoV2 item = {item}/>
            </Grid>
            <Grid item xs={6}>
              <PileInfoGeneralV2 item={item}/>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <StationVersionInfoV2 item = {item}/>
            </Grid>
            <Grid item xs={6}>
              <PileInfoVersionv2 item={item}/>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={2}>
          {
            item && item.id ? <LogViewer ctgr="site" ctgrId={item.id} /> : <></>
          }
        </TabPanel>
      </CardContent>
    </Card>
  );
}
