import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import { Dropzone, FileMosaic } from "@files-ui/react";
import { uploadNewVehicleList } from "../../utils/helpers/request/api/bikes";
import FormData from "form-data";
import { NotificationManager } from "react-notifications";
import { CircularProgress } from "@mui/material";
import Backdrop from "@material-ui/core/Backdrop";
import ButtonDefault from "../../components/Buttons/ButtonDefault";
import Alert from '@material-ui/lab/Alert';
import { DataGrid } from '@mui/x-data-grid';
const columns = [
  { field: 'index', headerName: 'Index', width: 70 },
  { field: 'license_plate', headerName: 'License Plate', width: 130 },
  { field: 'hardware_bike_id', headerName: 'Hardware Id', width: 130 },
  { field: 'wunder_id', headerName: 'Wunder Id', width: 130 },
  { field: 'error', headerName: 'Status', width: 130 ,
    valueGetter: (params) =>
  `${params.row.error ? 'Failed' : 'Success'}`,
  },
  { field: 'error_message', headerName: 'Error Desc.', width: 650 ,
    valueGetter: (params) => {
      if (params.row.error) {
        if (params.row.error_message.indexOf('Sigo Db Error') > -1) {
          return params.row.error_message.split(' - ')[1];
        } else {
          return params.row.error_message
        }
      } else {
        return '';
      }
    }
  },

];
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: "500px",
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
  loader: {
    width: "100%",
    height: "100%",
    color: "#fff",
    zIndex: "999 !important",
    backgroundColor: "rgba(255, 255, 255, 0.35) !important",
  },
  wrapper: {
    color: "#fff",
    zIndex: "999 !important",
    backgroundColor: "rgba(255, 255, 255, 0.35) !important",
  },
  wrapperIcon: {
    "& svg": {
      // color: COLOR_PRIMARY,
    },
  },
  uploadFirmwareBtn: {
    backgroundColor: "#2a966a",
    color: "#FFF",
  },
}));

const AddMultipleVehicleDialog = (props) => {
  const classes = useStyles();
  // add multiple vehicle
  const [openAddMultipleVehicle, setOpenAddMultipleVehicle] = React.useState(
    props.open
  );
  const [addMultipleVehicleFile, setAddMultipleVehicleFile] = React.useState(
    []
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [resp, setResp] = React.useState(null);

  const handleOnCloseAddMultipleVehicle = () => {
    setAddMultipleVehicleFile([]);
    setOpenAddMultipleVehicle(false);
    props.close();
  };

  const handleUploadMultipleVehicleFile = async () => {

    if (addMultipleVehicleFile.length == 0) {
      NotificationManager.error("Please upload file");
      return;
    }
    setIsLoading(true);
    let data = new FormData();
    data.append("vehicle_file", addMultipleVehicleFile[0].file);
    // console.log(data);
    let result = await uploadNewVehicleList(data);
    
    if (result.status == 'ok') {
      setResp(result.data);
      NotificationManager.success("Vehicles created using file");
    } 
    setIsLoading(false);
  };

  const updateFiles = (incommingFiles) => {
    //do something with the files
    setAddMultipleVehicleFile(incommingFiles);
    //even your own upload implementation
  };
  const removeFile = (id) => {
    setAddMultipleVehicleFile(
      addMultipleVehicleFile.filter((x) => x.id !== id)
    );
  };
  return (
    <>
      <Dialog
        open={openAddMultipleVehicle}
        onClose={handleOnCloseAddMultipleVehicle}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth = {resp == null ? false: true}
        maxWidth = {resp == null ? 'sm':'xl'}
      >
        <div className={classes.loader}>
          <Backdrop className={classes.wrapper} open={isLoading}>
            <div className={classes.wrapperIcon}>
              <CircularProgress />
            </div>
          </Backdrop>
        </div>
        <DialogTitle id="alert-dialog-title">
          {resp ? "Operation Summary " : "Add Multiple Vehicle using File"}
        </DialogTitle>
        {!resp && (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <FormControl variant="outlined" className={classes.formControl}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Dropzone
                      onChange={updateFiles}
                      value={addMultipleVehicleFile}
                      maxFiles={1}
                      accept=".xlsx"
                      label="Drop Vehicle List Excel file here"
                      autoClean
                    >
                      {addMultipleVehicleFile.map((file) => (
                        <FileMosaic
                          key={file.id}
                          {...file}
                          onDelete={removeFile}
                          info
                        />
                      ))}
                    </Dropzone>
                  </Grid>
                  <Grid item xs={12}>
                    <a href="https://sigo-dev-ota-firmware.s3.eu-central-1.amazonaws.com/sample-excel-file-to-add-multiple-vehicle.xlsx" target="_blank">Download Sample Excel File</a>
                  </Grid>
                </Grid>
              </FormControl>
            </DialogContentText>
          </DialogContent>
        )}

        {resp && (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            <div style={{ height: 400, width: '100%' }}>
              <DataGrid
              getRowId={(row) => row.index}
                rows={resp}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                pageSizeOptions={[5, 10]}
              />
            </div>

            </DialogContentText>
          </DialogContent>
        )}

        <DialogActions>
          <ButtonDefault onClick={handleOnCloseAddMultipleVehicle} color="alert">
            {resp ? 'Close' :'Cancel'}
          </ButtonDefault>
          {!resp && (
            <ButtonDefault
              onClick={handleUploadMultipleVehicleFile}
              color="primary"
              autoFocus
            >
              Upload
            </ButtonDefault>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddMultipleVehicleDialog;