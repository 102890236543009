import React from 'react';
import { createUseStyles } from 'react-jss';
import noop from 'lodash/noop';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Fade from '@material-ui/core/Fade';
import SortIcon from 'components/Icons/SortIcon';
import styles from './styles';

const useStyles = createUseStyles(styles);

const Sorter = ({
  fields = [],
  onSort = noop,
}) => {
  const classes = useStyles();
  const filteredFields = React.useMemo(() => fields.filter(f => f.isSortable !== false), [ fields ]);
  const [ anchorEl, setAnchorEl ] = React.useState(null);
  const [ currentField, setCurrentField ] = React.useState(null);
  const [ isAsc, setIsAsc ] = React.useState(true);
  const open = Boolean(anchorEl);
  const triggerSort = React.useCallback(id => {
    if (currentField.id === id) {
      setIsAsc(!isAsc);
      onSort({
        isAsc: !isAsc,
        field: currentField,
      });
    } else {
      setCurrentField(filteredFields.find(f => f.id === id));
      setIsAsc(true);
      onSort({
        isAsc: true,
        field: filteredFields.find(f => f.id === id),
      });
    }
    handleClose();
  }, [ isAsc, currentField, filteredFields ]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    setCurrentField(filteredFields[0]);
  }, [ filteredFields[0] ]);

  return (
    <div className={classes.wrapper}>
      <Button
        aria-controls="fade-menu"
        aria-haspopup="true"
        disabled={!currentField}
        onClick={handleClick}
      >
        <div className={classes.wrapperArrows}>
          <div />
          <SortIcon />
        </div>
        {currentField && <div className={classes.field}>
          {currentField.label}
        </div>}
      </Button>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {filteredFields.map(f => (
          <MenuItem
            id={f.id}
            onClick={() => {
              triggerSort(f.id);
            }}
          >
            {f.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
};

export default Sorter;
