export default {
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& > a': {
      marginRight: 30,
    },
    '& > a:last-child': {
      marginRight: 0,
    },
  },
};
