import React from 'react';
import { useDispatch } from 'react-redux';
import { setWSLockHeartbeat } from 'store/actions/common';
import PageWrapper from 'components/PageWrapper';
import AppWS from 'components/AppWS';
import {
  useDelete,
  useFetchItems,
} from 'utils/hooks/vehicle';
import ListVehicles from './components/ListVehicles';
import { getBikesV2 ,getAllLicensePlates} from 'utils/helpers/request/api/bikes';
// import { useDispatch } from 'react-redux';
import { setLoader } from 'store/actions/common';
const Vehicles = () => {
  const dispatch = useDispatch();
  // const { items, fetch } = useFetchItems();
  const { deleteItems } = useDelete();

  const onWSMessage = React.useCallback(e => {
    try {
      const message = JSON.parse(e.data);

      if (message.event === 'LOCK_HEARTBEAT') {
        dispatch(setWSLockHeartbeat({
          id: message.data.deviceId,
          ts: message.data.ts,
        }));
      }
    } catch (e) {

    }
  }, [ dispatch ]);

  const [licensePlatesFromDb,setLicensePlateFromDb] = React.useState([]);
  React.useEffect(() => {
      getAllLicensePlates()
      .then(response=>{
        if (response.data && response.data.items){
          setLicensePlateFromDb(response.data.items);
          // console.log(response.data.items)
        }
      }).catch(error=>{
        console.log(error);
      })
  }, []);




  // for pagination
  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [pageCount, setPageCount] = React.useState(0)
  const fetchIdRef = React.useRef(0)
  const pageSize = 10;
  const [pageIndex,setPageIndex] = React.useState(0);
  const [totalCount,setTotalCount] = React.useState(0);
  const lastSearchParam = React.useRef([]);
  const lastSortParam = React.useRef(null);
  const getBikesPagination = React.useCallback(
    async ({
      pageIndex: pageIndexParam,
      pageSize: pageSizeParam,
      search = [],
      isFreshReqParam,
      sortParam=null
    }) => {
      try {
        dispatch(setLoader(true));
        // cache and use same search parameters till new fresh request is not made
        if (isFreshReqParam) {
          lastSearchParam.current = search;
          lastSortParam.current = sortParam;
        }
        // Give this fetch an ID
        const fetchId = ++fetchIdRef.current;
        if (fetchId === fetchIdRef.current) {
          let responses = await getBikesV2({
            offset: pageIndexParam * pageSizeParam,
            limit: pageSizeParam,
            search: lastSearchParam.current,
            sort:lastSortParam.current
          });
          if (responses.status == 200) {
            setData(responses.data.items);
            if (pageIndexParam == 0) {
              setPageCount(Math.ceil(responses.data.total / pageSizeParam));
              setTotalCount(responses.data.total);
            }
          }
          setPageIndex(pageIndexParam);
          setLoading(false);
        }
        dispatch(setLoader(false));
      } catch (error) {
        dispatch(setLoader(false));
        console.log(error);
      }
    },
    []
  );

  const fetch = getBikesPagination.bind(this,{pageIndex,pageSize,search:[],isFreshReqParam:true});
  const refreshData = getBikesPagination.bind(this,{pageIndex,pageSize,search:[],isFreshReqParam:true})

  const handleDeleteItems = React.useCallback(indexes => {
    const ids = indexes.map(i => data[+i].id);

    deleteItems(ids)
        .then(() => {
          fetch();
        });
  }, [ data ]);

  return (
    <PageWrapper fetchItems={fetch}>
      <ListVehicles
        items={data}
        onDelete={handleDeleteItems}
        refreshData={refreshData}
        getBikesPagination={getBikesPagination}
        pageSize={pageSize}
        pageCount={pageCount}
        pageIndex={pageIndex}
        totalCount={totalCount}
        licensePlatesFromDb={licensePlatesFromDb}
        lastSearchParam={lastSearchParam}
      />
      <AppWS onMessage={onWSMessage} />
    </PageWrapper>
  )
};

export default Vehicles;
