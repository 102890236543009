import React from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import CellView from 'components/Cells/CellView';
import BatteryIcon from 'components/BatteryIcon';
import { setModalData } from 'store/actions/common';
import { BATTERY_MODAL } from 'utils/constants/modals';
import styles from './styles';

const useStyles = createUseStyles(styles);

const CellBattery = ({
  id,
  level = 0,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleClick = React.useCallback(e => {
    e.preventDefault();
    dispatch(setModalData({ type: BATTERY_MODAL, id }));
  }, [ id ]);
  
  return (
    <CellView onClick={handleClick}>
      <div className={classes.wrapperContent}>
        <BatteryIcon level={level} />
        <div className={classes.wrapperLabel}>
          <div className={classes.label}>
            {/* Battery */}
          </div>
          <div className={classes.level}>
            {level}
          </div>
        </div>
      </div>
    </CellView>
  );
};

export default CellBattery;
