import React from "react";
import LogView from "./components/LogView";
import GraphView from "./components/GraphView";
import moment from "moment-timezone";
import {
  retriveSigoEvents,
  retriveSigoBatteryEvents,
  retriveSiteHeartbeatGraphData,
  retriveEventWiseCount,
} from "utils/helpers/request/api/logEvents";
import { getBikes } from "utils/helpers/request/api/bikes";
import { getSites } from "utils/helpers/request/api/sites";
import TabSearchBar from "./components/TabSearchBar";

moment.tz.setDefault("Europe/Berlin");

class LogViewer extends React.Component {
  // constants
  CACHED_BIKE_TS = "cached_bike_timestamp";
  CACHED_BIKE_FROM_DB = "cached_bikes_from_db";
  CACHED_BIKE_VIEW_LOOKUP = "cached_bike_view_lookup";
  CACHED_SITE_TS = "cached_site_timestamp";
  CACHED_SITE_FROM_DB = "cached_sites_from_db";
  CACHED_SITE_VIEW_LOOKUP = "cached_site_view_lookup";
  LOCALSTORAGE_KEY_SAVED_FILTER_DATA = "filterDataSaved";
  INTERVAL_1_DAY = "1d";
  TEXT_1_DAYS = "days";
  INTERVAL_5_MIN = "5m";
  TEXT_5_MINS = "minutes";
  FILTER_BY_ACTIVITIES = "activities";
  FILTER_BY_BIKEIDS = "bikeIds";
  FILTER_BY_SITEIDS = "siteIds";
  ALL_EVENTS = [
    "ALL",
    "HEARTBEAT",
    "SITE_HEARTBEAT",
    "BIKE_STATE",
    "RENTAL",
    "SITE_BIKE_RETURN",
    "RENTAL_START",
    "SITE_PILE_UNLOCK",
    "FORCE_UNLOCK",
    "PARKING_END",
    "PARKING_START",
    "RENTAL_START_FORCE",
    "BIKE_UNLOCK",
    "VEHICLE_PICK_UP_CONFIRMATION",
    "ROUTER",
    "SITE_SETTING",
    "OFFLINEONLINE",
    "SITE_ONLINE",
    "SITE_OFFLINE",
    "BIKE_LOGIN",
    "MISC",
    "SITE_FAULT_CLEARING",
    "LOCK_DETECTION_STATUS",
    "PILE_FAILURE_REPORT",
    "BIKE_LOCK_REPORT",
  ];
  DP_BTN_TEXT = this.INTERVAL_5_MIN;
  MOMENT_AMOUNT = 5;
  MOMENT_DURATION = this.TEXT_5_MINS;
  FROM_LOCAL_PREF = false;

  constructor(props) {
    super(props);
    // load cache data from local storage if present
    if (localStorage.getItem(this.LOCALSTORAGE_KEY_SAVED_FILTER_DATA)) {
      let filterDataSaved = JSON.parse(
        localStorage.getItem(this.LOCALSTORAGE_KEY_SAVED_FILTER_DATA)
      );
      this.ALL_EVENTS = filterDataSaved.events;
      this.DP_BTN_TEXT = filterDataSaved.dpBtnText;
      this.MOMENT_AMOUNT = filterDataSaved.amount;
      this.MOMENT_DURATION = filterDataSaved.duration;
      this.FROM_LOCAL_PREF = true;
    } else {
      this.FROM_LOCAL_PREF = false;
    }
    this.state = {
      filterBy: this.FILTER_BY_ACTIVITIES,
      events: this.ALL_EVENTS,
      bikeIds: [],
      siteIds: [],
      endDate: moment(),
      startDate: moment().subtract(this.MOMENT_AMOUNT, this.MOMENT_DURATION),
      offset: 0,
      limit: 10,
      logs: [],
      batterylogs: [],
      dpBtnText: this.DP_BTN_TEXT,
      bikesFromDb: [],
      sitesFromDb: [],
      bikeViewLookUp: [],
      siteViewLookUp: [],
      hideMenu: true,
      src: props.ctgr ? props.ctgr : null,
      ctgrId: props.ctgrId,
      loading: true,
      fromLocalPref: this.FROM_LOCAL_PREF,
      interval: "min",
      heartbeatGraphData: [],
      eventWiseCountData: [],
      excludeEventsForBike: ["SITE_HEARTBEAT"],
      excludeEventsForSite: ["BIKE_STATE"],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  } // end

  getMomentIntervalUsingDpBtnText(dpBtnText) {
    const INTERVAL_7_DAY = "7d";
    const INTERVAL_1_DAY = "1d";
    const INTERVAL_1_HOUR = "1hr";
    const INTERVAL_30_MIN = "30m";
    const INTERVAL_15_MIN = "15m";
    const INTERVAL_5_MIN = "5m";
    const INTERVAL_CUSTOM = "custom";
    let amount = 0,
      duration = "minutes";
    if (dpBtnText == INTERVAL_CUSTOM) {
      amount = 5;
      duration = "minutes";
    } else if (dpBtnText == INTERVAL_7_DAY) {
      amount = 7;
      duration = "days";
    } else if (dpBtnText == INTERVAL_1_DAY) {
      amount = 1;
      duration = "days";
    } else if (dpBtnText == INTERVAL_1_HOUR) {
      amount = 1;
      duration = "hours";
    } else if (dpBtnText == INTERVAL_30_MIN) {
      amount = 30;
      duration = "minutes";
    } else if (dpBtnText == INTERVAL_15_MIN) {
      amount = 15;
      duration = "minutes";
    } else if (dpBtnText == INTERVAL_5_MIN) {
      amount = 5;
      duration = "minutes";
    }
    return { amount, duration };
  }

  async handleChange(data) {
    try {
      // updating filter parameters
      // this.setState(data);
      this.handleSearch(data);
    } catch (error) {
      console.log(error);
    }
  }

  async handleSearch(data) {
    try {
      data.loading = true;
      await this.setState(data);
      // fetching latest data for new filters
      let req = {
        startDate: moment(this.state.startDate).valueOf().toString(),
        endDate: moment(this.state.endDate).valueOf().toString(),
        offset: this.state.offset,
        limit: this.state.limit,
        interval: this.getMomentTimeDiff(
          this.state.startDate,
          this.state.endDate,
          this.state.dpBtnText
        ),
      };

      if (this.state.filterBy === this.FILTER_BY_ACTIVITIES) {
        let events = this.state.events;

        if (events.length === 0) {
          this.setState({ logs: [], loading: false });
          return;
        }

        if (events.indexOf("ALL") === -1) {
          req.events = this.state.events;
        }

        if (this.state.src === "bike") {
          // filter: bike id + activities
          req.bikeIds = [this.state.ctgrId];
        } else if (this.state.src === "site") {
          // filter: site id + activities
          req.siteIds = [this.state.ctgrId];
        }
      } else {
        // Handle other cases for filterBy
      }

      if (
        (this.state.filterBy == this.FILTER_BY_BIKEIDS &&
          this.state.bikeIds.length > 0 &&
          this.state.src == null) ||
        !this.state.hideMenu
      ) {
        // filter : bike id
        req.bikeIds = this.state.bikeIds;
      }

      if (
        (this.state.filterBy == this.FILTER_BY_SITEIDS &&
          this.state.siteIds.length > 0 &&
          this.state.src == null) ||
        !this.state.hideMenu
      ) {
        // filter : site id
        req.siteIds = this.state.siteIds;
      }

      // fetch battery logs only in case of bike
      if (this.state.src === "bike") {
        this.retriveSigoBatteryEventsV2(req);
      } else if (this.state.src === "site") {
        this.retriveSiteHeartbeatGraphDataV2(req);
      }

      // fetch event wise count
      this.retriveEventWiseCountV2(req);

      // fetch all events
      req.events = this.filterExcludedEvents(this.state.events, this.state.src);
      this.retriveSigoEventsV2(req);

      // save preference
      let filterDataToSave = this.getMomentIntervalUsingDpBtnText(
        this.state.dpBtnText
      );
      filterDataToSave.dpBtnText = this.state.dpBtnText;
      filterDataToSave.events = this.state.events;
      localStorage.setItem(
        this.LOCALSTORAGE_KEY_SAVED_FILTER_DATA,
        JSON.stringify(filterDataToSave)
      );
    } catch (error) {
      console.log(error);
    }
  }

  async getUrlParams() {
    if (this.props.ctgr && this.props.ctgrId) {
      return { ctgr: this.props.ctgr, id: this.props.ctgrId };
    }
    let url = window.location.href;
    let ctgr = null,
      id = null;

    if (url.includes("sites/view")) {
      id = url.split("view/")[1];
      return { ctgr: "site", id: id };
    }

    // if (!url.endsWith("log-viewer")) {
    //   return { ctgr, id };
    // }

    let urlArray = url
      .split("log-viewer")[1]
      .split("/")
      .filter((item) => item != "");
    if (urlArray.length < 2) {
      return { ctgr, id };
    }

    return {
      ctgr: urlArray[0],
      id: urlArray[1],
    };
  }

  getCachedTimeDiff(cacheKey) {
    let currentTs = moment();
    let lastTs = moment(Number(localStorage.getItem(cacheKey)));
    let diff = currentTs.diff(lastTs, "minute");

    return diff;
  }
  async init() {
    try {
      let { ctgr, id } = await this.getUrlParams();
      if (
        localStorage.getItem(this.CACHED_BIKE_TS) &&
        this.getCachedTimeDiff(this.CACHED_BIKE_TS) < 5
      ) {
        // used cached info
        let bikesFromDb = JSON.parse(
          localStorage.getItem(this.CACHED_BIKE_FROM_DB)
        );
        let bikeViewLookUp = JSON.parse(
          localStorage.getItem(this.CACHED_BIKE_VIEW_LOOKUP)
        );
        this.setState({
          bikesFromDb,
          bikeViewLookUp,
        });
      } else {
        // fetch latest info
        getBikes()
          .then((response) => {
            if (
              response &&
              response.status == 200 &&
              typeof response.data != "string"
            ) {
              response.data = this.filterBikesWithNullLicensePlate(
                response.data
              );
              const bikeViewLookUp = this.generateBikeLookUp(response.data);
              this.setState({
                bikesFromDb: response.data,
                bikeViewLookUp: bikeViewLookUp,
              });
              localStorage.setItem(
                this.CACHED_BIKE_TS,
                moment().valueOf().toString()
              );
              localStorage.setItem(
                this.CACHED_BIKE_FROM_DB,
                JSON.stringify(response.data)
              );
              localStorage.setItem(
                this.CACHED_BIKE_VIEW_LOOKUP,
                JSON.stringify(bikeViewLookUp)
              );
              console.log("bike info fetched from db");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }

      if (
        localStorage.getItem(this.CACHED_SITE_TS) &&
        this.getCachedTimeDiff(this.CACHED_SITE_TS) < 5
      ) {
        // used from cached
        let sitesFromDb = JSON.parse(
          localStorage.getItem(this.CACHED_SITE_FROM_DB)
        );
        let siteViewLookUp = JSON.parse(
          localStorage.getItem(this.CACHED_SITE_VIEW_LOOKUP)
        );
        this.setState({
          sitesFromDb,
          siteViewLookUp,
        });
        // console.log('site info used from cached');
      } else {
        // fetch from db
        getSites()
          .then((response) => {
            if (
              response &&
              response.status == 200 &&
              typeof response.data != "string"
            ) {
              const siteViewLookUp = this.generateSiteLookUp(
                response.data.items
              );
              this.setState({
                sitesFromDb: response.data,
                siteViewLookUp: siteViewLookUp,
              });
              localStorage.setItem(
                this.CACHED_SITE_TS,
                moment().valueOf().toString()
              );
              localStorage.setItem(
                this.CACHED_SITE_FROM_DB,
                JSON.stringify(response.data)
              );
              localStorage.setItem(
                this.CACHED_SITE_VIEW_LOOKUP,
                JSON.stringify(siteViewLookUp)
              );
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }

      let req = {
        startDate: moment(this.state.startDate).valueOf().toString(),
        endDate: moment(this.state.endDate).valueOf().toString(),
        offset: this.state.offset,
        limit: this.state.limit,
      };
      let hideMenu = false;
      if (ctgr === null && id === null) {
        req.events = this.state.events;
      } else if (ctgr === "bike") {
        req.bikeIds = [id];
        // filter site heartbeat from bike event list
        req.events = this.filterExcludedEvents(
          this.state.events,
          this.state.src
        );
        hideMenu = true;
      } else if (ctgr === "site") {
        req.siteIds = [id];
        req.events = this.filterExcludedEvents(
          this.state.events,
          this.state.src
        );
        hideMenu = true;
      }
      this.setState({ hideMenu });

      // fetch battery event only in case of bike
      if (this.state.src === "bike") {
        req.interval = this.getMomentTimeDiff(
          this.state.startDate,
          this.state.endDate,
          this.state.dpBtnText
        );
        // convert sync to async
        this.retriveSigoBatteryEventsV2(req);
      } else if (this.state.src === "site") {
        req.interval = this.getMomentTimeDiff(
          this.state.startDate,
          this.state.endDate,
          this.state.dpBtnText
        );
        // convert sync to async
        this.retriveSiteHeartbeatGraphDataV2(req);
      }

      // fetch event wise count
      this.retriveEventWiseCountV2(req);

      // fetch all sigo events
      this.retriveSigoEventsV2(req);
    } catch (e) {
      console.log(e);
    }
  }

  filterBikesWithNullLicensePlate(data) {
    return data ? data.filter((data) => data.license_plate != null) : [];
  }

  generateBikeLookUp(data) {
    let result = {};
    data.forEach((bike) => {
      result[bike.hardware_bike_id] = bike.license_plate;
      if (bike.lock) {
        result[bike.lock.id] = bike.license_plate;
      }
    });
    return result;
  }

  generateSiteLookUp(data) {
    let result = {};
    data.forEach((site) => {
      result[site.site_id] = site.street;
    });
    return result;
  }
  getMomentTimeDiff(sd, ed, dpBtnText) {
    const minutesInterval = "5m,15m,30m";
    const hourInterval = "1hr,1d";
    const dayInterval = "7d";
    let interval = "";
    if (dayInterval.indexOf(dpBtnText) > -1) {
      interval = "day";
    } else if (hourInterval.indexOf(dpBtnText) > -1) {
      interval = "hour";
    } else if (minutesInterval.indexOf(dpBtnText) > -1) {
      interval = "min";
    } else if (dpBtnText === "custom") {
      let startDate = moment(sd);
      let endDate = moment(ed);
      let diffInDays = endDate.diff(startDate, "days");
      let diffInHour = endDate.diff(startDate, "hour");
      let diffInMinute = endDate.diff(startDate, "minute");

      if (diffInDays === 0 && diffInHour === 0 && diffInMinute !== 0) {
        interval = "min";
      } else if (diffInDays === 0 && diffInHour !== 0) {
        interval = "hour";
      } else if (diffInDays !== 0) {
        interval = "day";
      }
    }
    // console.log(interval,dpBtnText);

    return interval;
  }

  async getNextLogPage(reqestedPage) {
    try {
      let calcOffset = 0;
      if (reqestedPage <= this.state.offset) {
        calcOffset = this.state.offset - 1;
        // console.log('back',reqestedPage,calcOffset)
      } else {
        calcOffset = this.state.offset * this.state.limit;
        // console.log('next',reqestedPage,calcOffset)
      }

      // fetching latest data for new filters
      this.setState({ loading: true });
      let { ctgr, id } = await this.getUrlParams();
      let req = {
        startDate: moment(this.state.startDate).valueOf().toString(),
        endDate: moment(this.state.endDate).valueOf().toString(),
        offset: calcOffset,
        limit: this.state.limit,
        interval: this.getMomentTimeDiff(
          this.state.startDate,
          this.state.endDate,
          this.state.dpBtnText
        ),
        events: this.state.events,
      };
      let hideMenu = false;
      if (ctgr === null && id === null) {
        req.events = this.state.events;
      } else if (ctgr === "bike") {
        req.bikeIds = [id];
        req.events = this.filterExcludedEvents(
          this.state.events,
          this.state.src
        );
        hideMenu = true;
      } else if (ctgr === "site") {
        req.siteIds = [id];
        req.events = this.filterExcludedEvents(
          this.state.events,
          this.state.src
        );
        hideMenu = true;
      } else {
        req.events = this.state.events;
      }
      // fetch all events
      let response = [],
        responseLogs = [];
      response = await retriveSigoEvents(req);
      if (response && response.status == 200) {
        responseLogs = response.data;
        this.setState({
          logs: responseLogs,
          loading: false,
          offset: reqestedPage,
        });
      } else {
        this.setState({ logs: [], loading: false });
      }
    } catch (e) {
      console.log(e);
    }
  }

  filterExcludedEvents(events, src) {
    let excludeEvents = [];
    if (src === "bike") {
      excludeEvents = this.state.excludeEventsForBike;
    } else if (src === "site") {
      excludeEvents = this.state.excludeEventsForSite;
    }
    let result = events.filter((event) => {
      if (!excludeEvents.includes(event)) {
        return event;
      }
    });
    return result;
  }

  // this is fix for twice render issue of class component.
  // this way init is called only once
  componentDidMount() {
    this.init();
  }

  // common function to call in init and search
  retriveSigoBatteryEventsV2(req) {
    retriveSigoBatteryEvents(req)
      .then((response) => {
        let responseBatteryLogs = [];
        if (response && response.status == 200) {
          responseBatteryLogs = response.data;
          this.setState({
            batterylogs: responseBatteryLogs,
            loading: false,
            interval: req.interval,
          });
        } else {
          this.setState({
            batterylogs: responseBatteryLogs,
            loading: false,
            interval: req.interval,
          });
        }
      })
      .catch((error) => {
        console.log("error in handle searcg of retrive sigo battery");
        console.log(error);
      });
  }

  retriveSiteHeartbeatGraphDataV2(req) {
    retriveSiteHeartbeatGraphData(req)
      .then((response) => {
        let responseSiteHbGraphData = [];
        if (response && response.status === 200) {
          responseSiteHbGraphData = response.data;
          this.setState({
            heartbeatGraphData: responseSiteHbGraphData,
            loading: false,
            interval: req.interval,
          });
        } else {
          this.setState({
            heartbeatGraphData: responseSiteHbGraphData,
            loading: false,
            interval: req.interval,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  retriveEventWiseCountV2(req) {
    retriveEventWiseCount(req).then((response) => {
      if (response && response.status == 200) {
        let data = response.data;
        this.setState({ eventWiseCountData: data, loading: false });
      } else {
        this.setState({ eventWiseCountData: [], loading: false });
      }
    });
  }

  retriveSigoEventsV2(req) {
    retriveSigoEvents(req)
      .then((response) => {
        let responseLogs = [];
        if (response && response.status == 200) {
          responseLogs = response.data;
          this.setState({ logs: responseLogs, loading: false });
        } else {
          this.setState({ logs: [], loading: false });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <div style={{ marginTop: 30 }}>
        <TabSearchBar
          events={this.state.events}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          onChange={this.handleChange}
          dpBtnText={this.state.dpBtnText}
          bikesFromDb={this.state.bikesFromDb}
          sitesFromDb={this.state.sitesFromDb}
          // onClickSearch={this.handleSearch}
          bikeIds={this.state.bikeIds}
          siteIds={this.state.siteIds}
          hideMenu={this.state.hideMenu}
          fromLocalPref={this.state.fromLocalPref}
        />

        {/* {this.state.logs && this.state.logs.length > 0 && ( */}
        <GraphView
          data={this.state.logs}
          events={this.state.events}
          dpBtnText={this.state.dpBtnText}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          src={this.state.src}
          batteryData={this.state.batterylogs}
          interval={this.state.interval}
          heartbeatGraphData={this.state.heartbeatGraphData}
          loading={this.state.loading}
          eventWiseCountData={this.state.eventWiseCountData}
        />
        {/* )} */}

        <LogView
          rows={this.state.logs}
          loading={this.state.loading}
          bikeViewLookUp={this.state.bikeViewLookUp}
          siteViewLookUp={this.state.siteViewLookUp}
          getNextLogPage={this.getNextLogPage.bind(this)}
        />
      </div>
    );
  }
}

export default LogViewer;
