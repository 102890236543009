import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import CRUModal from "components/Modals/CRUModal";
import { useFetchItem } from "utils/hooks/vehicle";
import { setModalData } from "store/actions/common";
import { useDelete } from "utils/hooks/vehicle";
import FormContent from "./components/FormContent";
import { usePermissions } from "react-admin";

const VehicleModal = ({ id, fetchItems }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { deleteItems } = useDelete();
  const { fetch, item } = useFetchItem(id);
  const { permissions } = usePermissions();

  const handleEdit = React.useCallback(() => {
    history.push(`/vehicles/edit/${id}`);
    dispatch(setModalData());
  }, [id]);
  const handleView = React.useCallback(() => {
    history.push(`/vehicles/view/${id}`);
    dispatch(setModalData());
  }, [id]);
  const handleDelete = React.useCallback(() => {
    deleteItems([id]).finally(() => {
      fetchItems();
      dispatch(setModalData());
    });
  }, [id, dispatch]);
  const handleClose = React.useCallback(() => {
    dispatch(setModalData());
  }, [id]);

  const handleLogView = React.useCallback(()=>{
    history.push(`/log-viewer/bike/${id}`);
    dispatch(setModalData());
  }, [id]);

  React.useEffect(() => {
    if (id) {
      fetch(id);
    }
  }, [id]);

  return (
    <CRUModal
      // title="View Vehicle"
      entity="Vehicle"
      minWidth={1200}
      onDelete={
        permissions && permissions.indexOf("delete-vehicles") > -1
          ? handleDelete
          : false
      }
      onEdit={
        permissions && permissions.indexOf("update-vehicles") > -1
          ? handleEdit
          : false
      }
      onClose={handleClose}
      onView={handleView}
      onLogView={handleLogView}
      ctgr='bike'
      ctgrId={id}
      item={item} id={id}
    >
      <FormContent item={item} id={id}/>
    </CRUModal>
  );
};

export default VehicleModal;
