import React from 'react';
import { useDispatch } from 'react-redux';
import { getPile } from 'utils/helpers/request/api/dockings';
import {
  forceUnlock,
  getSiteInfo,
  unlock,
} from 'utils/helpers/request/api/sites';
import { setLoader } from 'store/actions/common';
import { parseFromBackend } from './helpers';

const useFetch = id => {
  const [ item, setItem ] = React.useState({
    licensePlates: [],
    pileType: { id: null, name: '' },
    bikeLicensePlate: { id: null, name: '' },
    siteAddress: { id: null, name: '' },
    pileStatus: '',
  });
  const dispatch = useDispatch();

  const fetch = async function() {
    dispatch(setLoader(true))

    try {
      const { data } = await getPile(id);

      if (!!data.site_id) {
        const { data: siteInfo } = await getSiteInfo(data.site_id);
        
        setItem(parseFromBackend({
          ...data,
          siteInfo,
        }));
      } else {
        console.log('test', parseFromBackend(data));
        setItem(parseFromBackend(data));
      }
    } catch (e) {
      // if (e.response.status === 404) window.location.href = '#/not-found';
    }

    dispatch(setLoader(false))
  };

  return {
    item,
    fetch,
  };
};

const useUnlockActions = (id) => {
  const dispatch = useDispatch();
  
  const softUnlock = async function() {
    dispatch(setLoader(true))
    
    try {
      await unlock(id);
    } catch (e) {}
    
    dispatch(setLoader(false))
  };
  
  const hardUnlock = async function() {
    dispatch(setLoader(true))
    
    try {
      await forceUnlock(id);
    } catch (e) {}
    
    dispatch(setLoader(false))
  };
  
  return {
    hardUnlock,
    softUnlock,
  };
};

export {
  useFetch,
  useUnlockActions,
};
