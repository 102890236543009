import React from 'react';
import { createUseStyles } from 'react-jss';
import styles from './styles';

const useStyles = createUseStyles(styles);

const CellView = ({
  onClick,
  Icon,
  status,
  children,
}) => {
  const classes = useStyles({ status });
  
  return (
    <div
      className={classes.wrapper}
      onClick={onClick}
    >
      <div className={classes.wrapperIcon}>
        {Icon && <Icon />}
      </div>
      {children}
    </div>
  );
};

export default CellView;
