import React from 'react';
import { createUseStyles } from 'react-jss';
import isEmpty from 'lodash/isEmpty';
import Sorter from 'components/List/Sorter';
import Divider from 'components/Divider';
import Filter from 'components/List/Filter';
import ButtonDelete from 'components/Buttons/ButtonDelete';
import styles from './styles';

const useStyles = createUseStyles(styles);

const Toolbar = ({
  label,
  fields,
  selectedRowIds,
  onDelete,
  onSort,
  onFilter,
  onRemoveFilter,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.label}>
        {label}
      </div>
      {
        onSort && (
          <>
            {/* <Divider classNameWrapper={classes.wrapperDivider} /> */}
            {/* <Sorter
              fields={fields}
              onSort={onSort}
            /> */}
          </>
        )
      }
      {
        onFilter && (
          <>
            {/* <Divider />
            <Filter
              fields={fields}
              onFilter={onFilter}
              onRemoveFilter={onRemoveFilter}
            /> */}
          </>
        )
      }
      {!isEmpty(selectedRowIds) && (
        <div className={classes.wrapperTrash}>
           <Divider />
          <ButtonDelete
            onClick={onDelete}
            subModal={false}
            ids={selectedRowIds}
          />
        </div>
      )}
    </div>
  )
};

export default Toolbar;
