import { ACTIVE, INACTIVE } from 'utils/constants/statuses';

const STATUSES = [
  { id: ACTIVE, name: ACTIVE },
  { id: INACTIVE, name: INACTIVE },
];

export {
  STATUSES,
};

