import { getSites } from 'utils/helpers/request/api/sites';
import { getSimCards } from 'utils/helpers/request/api/simCards';
import { getCities } from 'utils/helpers/request/api/cities';
import { getBatteries } from 'utils/helpers/request/api/batteries';
import { getRouters } from 'utils/helpers/request/api/routers';
import { getUserGroups } from 'utils/helpers/request/api/userGroups';
import {
  getPiles,
  getPileTypes,
} from 'utils/helpers/request/api/dockings';
import {
  getBikes,
  getVehicleTypes,
} from 'utils/helpers/request/api/bikes';
import { getLocks } from 'utils/helpers/request/api/locks';

const isDisabledPage = operation => {
  return operation === 'view';
};

const isCreatable = operationId => operationId === 'add';

const isEditable = operationId => operationId === 'edit';

const isViewable = operationId => operationId === 'view';

const asyncRequestForSites = async () => {
  const { data: { items: sites } } = await getSites();
  const items = sites.map(site => ({
    id: site.site_id,
    name: site.address,
  }));
  return { data: { items }};
};

const asyncRequestForLocks = function (filterFn = function() { return true; }) {
  return getLocks().then(({ data: { items } }) => ({
    data: {
      items: items.filter(filterFn).map(i => ({
        id: i.id,
        name: i.id,
      })),
    },
  }));
};

const asyncRequestForSimCards = function (filterFn = function() { return true; }) {
  return getSimCards().then(({ data: { items } }) => ({
    data: {
      items: items.filter(filterFn).map(i => ({
        id: i.id,
        name: i.iccid,
      })),
    },
  }));
};

const asyncRequestForVehicleTypes = function (filterFn = function() { return true; }) {
  return getVehicleTypes().then(({ data: { items } }) => ({
    data: {
      items: items.filter(filterFn).map(i => ({
        id: i.id,
        name: i.id,
      })),
    },
  }));
};

const asyncRequestForBikes = function (filterFn = function() { return true; }) {
  return getBikes().then(({ data: { items } }) => ({
    data: {
      items: items.filter(filterFn).map(i => ({
        id: i.id,
        name: i.license_plate || 'No license plate provided',
      })),
    },
  }));
};

const asyncRequestForCities = function () {
  return getCities().then(({ data: { items } }) => ({
    data: {
      items: items.map(i => ({
        id: i.id,
        name: i.name,
      })),
    },
  }));
};

const asyncRequestForRouters = function (filterFn = function() { return true; }) {
  return getRouters().then(({ data: { items } }) => ({
    data: {
      items: items.filter(filterFn).map(i => ({
        id: i.id,
        name: i.serial_number,
      })),
    },
  }));
};

const asyncRequestForPileType = function () {
  return getPileTypes().then(({ data: { items } }) => ({
    data: {
      items: items.map(i => ({
        id: i.id,
        name: i.id,
      })),
    },
  }));
};

const asyncRequestForPiles = function (filterFn = function() { return true; }) {
  return getPiles().then(({ data: { items } }) => ({
    data: {
      items: items.filter(filterFn).map(i => ({
        id: i.id,
        name: i.id,
        meta: {
          ...i,
        },
      })),
    },
  }));
};

const asyncRequestForBatteries = function (filterFn = function() { return true; }) {
  return getBatteries().then(({ data: { items } }) => ({
    data: {
      items: items.filter(filterFn).map(i => ({
        id: i.id,
        name: i.id,
      })),
    },
  }));
};

/**
 * 
 * Get user groups that are not associated to the site Id
 * 
 * @param {Array} userGroupIds
 * @returns {Object}
 */
const asyncRequestForUsergroups = async (userGroupIds) => {

  const { data: { items: userGroups } } = await getUserGroups();

  // Check if user group id is included in user groups related to the site
  const items = userGroups.filter( userGroup => !userGroupIds.includes(userGroup.id)).map( userGroup => ({
      id: userGroup.id,
      name: userGroup.name,
    }))

  return { data: { items }};
};

export {
  isEditable,
  isViewable,
  isCreatable,
  isDisabledPage,
  asyncRequestForPiles,
  asyncRequestForSimCards,
  asyncRequestForSites,
  asyncRequestForRouters,
  asyncRequestForPileType,
  asyncRequestForBikes,
  asyncRequestForCities,
  asyncRequestForLocks,
  asyncRequestForBatteries,
  asyncRequestForVehicleTypes,
  asyncRequestForUsergroups
};
