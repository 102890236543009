import React from "react";

import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  wordWrap:{
    overflowWrap:'break-word'
  }
}));
const UPGRADE_COMPONENT_NAME_TO_ID_MAPPING = {
  msv: { id: 1, name: "terminal" },
  sdsv: { id: 3, name: "site display" },
  rsv: { id: 6, name: "pcrd power reset controller" },
  psv: { id: 2, name: "site icpu" },
  dsv: { id: null, name: "dock site display" },
  esv: { id: 4, name: "site eicc" },
};
// [
//   {
//       "id": 1,
//       "version": "20220318",
//       "ctype": "terminal",
//       "name": "TEST_1680846792784_V8152_TERM_B_20220318.tlz",
//       "uploadedBy": "sumeet@sigo.green",
//       "url": "https://sigo-dev-ota-firmware.s3.eu-central-1.amazonaws.com/TEST_1680846792784_V8152_TERM_B_20220318.tlz",
//       "desc": "7th april test",
//       "created_at": "2023-04-07T05:53:14.611Z",
//       "updated_at": "2023-04-07T05:53:14.611Z"
//   }
// ]
const ListFirmware = (props) => {
  const classes = useStyles();
  const columns = [
    // { field: "id", headerName: "ID", width: 90 },

    {
      field: "created_at",
      headerName: "Uploaded At",
      width: 200,
      editable: true,
      renderCell:(param)=>{
        let date = new Date(param.row.created_at);
        return (`${date.toDateString()} ${date.getHours()}:${date.getMinutes()}`)
      }
    },
    {
      field: "name",
      headerName: "File Name",
      width: 400,
      editable: true,
      renderCell:(param)=>{
        return (
          <div className={classes.wordWrap}><a href={param.row.url} target="_blank">{param.row.name}</a></div>
        )
      }
      
    },
    {
      field: "ctype",
      headerName: "Component Type",
      width: 150,
      editable: true,
      renderCell:(params)=>{
        let value = "";
        if (UPGRADE_COMPONENT_NAME_TO_ID_MAPPING[params.row.ctype]){
          value = UPGRADE_COMPONENT_NAME_TO_ID_MAPPING[params.row.ctype].name;
        } else {
          value = params.row.ctype;
        }
        return value;
      }
    },
    {
      field: "version",
      headerName: "Version",
      width: 100,
      editable: true,
    },
    {
      field: "uploadedBy",
      headerName: "Uploader",
      width: 200,
      editable: true,
    },
    {
      field: "desc",
      headerName: "Description",
      width: 300,
      editable: true,
    },
    
    // {
    //   field: "updated_at",
    //   headerName: "updated_at",
    //   width: 150,
    //   editable: true,
    // },

  ];

  const rows = props.firmwareList;
  return (
    <>
              <div style={{ height: 600, width: "100%" }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
               
              />
            </div>
    </>
  );
}

export default ListFirmware;