import { login } from "utils/helpers/request/api/auth";

export default {
  login: async ({ username, password }) => {
    // console.log("authProvider login()");

    try {
      const response = await login({ username, password });
      const { username: responseUsername, email, role, token } = response.data;

      localStorage.setItem("username", responseUsername);
      localStorage.setItem("email", email);
      localStorage.setItem("role", role);
      localStorage.setItem("idToken", token);

      return Promise.resolve();
    } catch (e) {
      return Promise.reject();
    }
  },

  logout: () => {
    // console.log("authProvider logout()");

    localStorage.clear()
    return Promise.resolve();
  },

  checkAuth: () => {
    // console.log("authProvider checkAuth()");

    return localStorage.getItem("idToken") && localStorage.getItem('role') && localStorage.getItem('permissions') ? Promise.resolve() : Promise.reject();
  },

  getPermissions: () => {
    // console.log('authProvider getPermissions()')
    const permissions = localStorage.getItem("permissions");
    return permissions ? Promise.resolve(JSON.parse(permissions).actions) : Promise.reject('No Permission');
  },
};
