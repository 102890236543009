import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import { NotificationManager } from 'react-notifications';
import { setLoader } from 'store/actions/common';
import { addRouter } from 'utils/helpers/request/api/routers';
import { addSimCard } from 'utils/helpers/request/api/simCards';
import { addCity } from 'utils/helpers/request/api/cities';
import { addVehicleType } from 'utils/helpers/request/api/bikes';
import { addLock } from 'utils/helpers/request/api/locks';
import { addBattery } from 'utils/helpers/request/api/batteries';

const useCreateWrapper = asyncRequest => {
  const dispatch = useDispatch();
  
  const createItem = async function(dataToSend) {
    dispatch(setLoader(true))
    
    try {
      const { data } = await asyncRequest(dataToSend);
      NotificationManager.success('Successfully added!');
      
      dispatch(setLoader(false))
      
      return get(data, 'id');
    } catch (e) {
      dispatch(setLoader(false))
    }
  };
  
  return {
    createItem,
  };
};


const useCreateRouter = () => {
  const dispatch = useDispatch();
  
  const createItem = async function(data) {
    dispatch(setLoader(true))
    
    try {
      const { data: { id } } = await addRouter(data);
      NotificationManager.success('Successfully added!');
      
      dispatch(setLoader(false))
      
      return id;
    } catch (e) {
      dispatch(setLoader(false))
    }
    
  };
  
  return {
    createItem,
  };
};

const useCreateSimCard = () => {
  const dispatch = useDispatch();
  
  const createItem = async function(data) {
    dispatch(setLoader(true))
    
    try {
      const { data: { id } } = await addSimCard(data);
      NotificationManager.success('Successfully added!');
      
      dispatch(setLoader(false))
      
      return id;
    } catch (e) {
      dispatch(setLoader(false))
    }
    
  };
  
  return {
    createItem,
  };
};

const useCreateCity = () => {
  const dispatch = useDispatch();
  
  const createItem = async function(data) {
    dispatch(setLoader(true))
    
    try {
      const { data: { id } } = await addCity(data);
      NotificationManager.success('Successfully  added!');

      dispatch(setLoader(false))
      return id;
    } catch (e) {
      dispatch(setLoader(false))
    }
  };
  
  return {
    createItem,
  };
};

const useCreateVehicleType = () => {
  return useCreateWrapper(addVehicleType)
};

const useCreateLock = () => {
  return useCreateWrapper(addLock)
};

const useCreateBattery = () => {
  return useCreateWrapper(addBattery)
};

export {
  useCreateWrapper,
  useCreateCity,
  useCreateRouter,
  useCreateSimCard,
  useCreateLock,
  useCreateBattery,
  useCreateVehicleType,
};
