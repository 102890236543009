import React,{useState} from 'react';
import { createUseStyles } from 'react-jss';
import Link from '@material-ui/core/Link';
import CellHeartbeat from 'components/Cells/CellHeartbeat';
import SignalIcon from 'components/Icons/SignalIcon';
import styles from './styles';
import CellRouter from 'components/Cells/CellRouter';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
const useStyles = createUseStyles(styles);

const CellCopyLink = ({ row }) => {
  const classes = useStyles();
  const [copied,setCopied] = useState(false);
  const handleCopyViewLink = async ()=>{
    let baseUrl = window.location.origin;
    let viewUrl = `${baseUrl}/#/sites/view/${row.original.id}`
    await navigator.clipboard.writeText(viewUrl);
    setCopied(true);
  };

  return (
    
        <div onClick={handleCopyViewLink} className={copied ? classes.copiedIcon : classes.copyIcon}>
          <FilterNoneIcon titleAccess='Copy View Link' />
          <div className={classes.copyLinkText}>{copied ? 'View Link Copied!' : ''}</div>
        </div>
  );
  
};

export default CellCopyLink;
