import { useDispatch } from 'react-redux';
import { setLoader } from 'store/actions/common';
import { restartSite } from 'utils/helpers/request/api/sites';
import { NotificationManager } from 'react-notifications';

const useTrigger = (id) => {
  const dispatch = useDispatch();
  
  const trigger = async function() {
    dispatch(setLoader(true));
    
    try {
      await restartSite(id);
      NotificationManager.success('Restarting site.');
    } catch (e) {}
    
    dispatch(setLoader(false));
  };
  
  return {
    trigger,
  };
};

export {
  useTrigger,
};
