import { COLOR_PRIMARY } from 'utils/constants/colors';

export default {
  wrapper: {
    color: '#fff',
    zIndex: '999 !important',
    backgroundColor: 'rgba(255, 255, 255, 0.35) !important',
  },
  wrapperIcon: {
    '& svg': {
      color: COLOR_PRIMARY,
    },
  },
};
