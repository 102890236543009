import React from 'react';
import noop from 'lodash/noop';
import { createUseStyles } from 'react-jss';
import MenuItem from '@material-ui/core/MenuItem';
import NavigationItem from '../NavigationItem';
import styles from './styles';

const useStyles = createUseStyles(styles);

const NavigationMenuItem = ({
  id,
  title,
  onClick = noop,
}) => {
  const classes = useStyles();
  const menuClasses = React.useMemo(() => ({
    root: classes.wrapper,
  }), []);

  return (
    <MenuItem classes={menuClasses}>
      <NavigationItem
        id={id}
        title={title}
        onClick={onClick}
      />
    </MenuItem>
  )
};

export default NavigationMenuItem;
