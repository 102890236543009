export default {
    customDatePickerBar:{
        display:"flex"
    },
    customDatePicker:{
        display: "flex",
        "align-items": "center",
        "justify-content": "space-around",
        margin:'10px'
    },
    absDateWrapper:{
        with:'200px',
        display: "flex",
        "align-items": "baseline",
        "justify-content": "space-around",
        margin:'10px',
        "flex-direction":"column"
    },
    relDateWrapper:{
        display: "flex",
        "align-items": "baseline",
        "justify-content": "space-around",
        margin:'10px',
        "flex-direction":"column"
    },
    marginLeft :{
        display: "flex",
        "align-items": "center",
        "justify-content": "space-around",
        margin:'10px'
    }
}