import React from 'react';
import { useField } from 'react-final-form';
import { createUseStyles } from 'react-jss';
import AppMultiSelect from '../AppMultiSelect';
import styles from './styles';

const useStyles = createUseStyles(styles);

const AppMultiSelectForm = ({
  name,
  label,
  width,
  disabled,
  initialValue,
  isNullable = true,
  classnameWrapper,
  options = [],
  renderValue
}) => {
  const classes = useStyles();
  const {
    input: { onChange, value },
    meta: { touched, error }
  } = useField(name, { initialValue });
  const handleChange = React.useCallback(e => {
    onChange(e.target.value);
  }, [ ]);

  return (
    <AppMultiSelect
      value={value}
      label={label}
      disabled={disabled}
      width={width}
      options={options}
      isNullable={isNullable}
      classnameWrapper={classnameWrapper}
      onChange={handleChange}
      error={touched && error}
      renderValue={renderValue}
    />
  );
};

export default AppMultiSelectForm;
