import React from "react";
import { createUseStyles } from "react-jss";
import NavigationItem from "../NavigationItem";
import styles from "./styles";
import { usePermissions } from "react-admin";

const useStyles = createUseStyles(styles);

const Navigation = ({ items }) => {
  const classes = useStyles();
  const { permissions } = usePermissions();
  const excludedMenus = ["settings", "logout"];

  return (
    <div className={classes.wrapper}>
      {items.map((i) =>
        excludedMenus.indexOf(i.id) > -1 ? (
          <NavigationItem key={i.id} id={i.id} title={i.title} />
        ) : permissions && permissions.indexOf("view-" + i.id) > -1 ? (
          <NavigationItem key={i.id} id={i.id} title={i.title} />
        ) : (
          <></>
        )
      )}
    </div>
  );
};

export default Navigation;
