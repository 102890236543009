import React from "react";
import { usePermissions } from "react-admin";
import { createUseStyles } from "react-jss";
import AppForm from "components/AppForm";
import { useFetch, useUpdate } from "./utils/hooks";
import FormContent from "./components/FormContent";
import styles from "./styles";

const useStyles = createUseStyles(styles);

const Connectivity = () => {
  const classes = useStyles();
  const { permissions } = usePermissions();
  const { item, fetch } = useFetch();
  const { updateItem } = useUpdate();
  const handleUpdate = React.useCallback(({ values }) => {
    updateItem(values);
  }, []);

  React.useEffect(() => {
    fetch();
  }, []);

  return (
    <AppForm
      entity="Connectivity"
      onUpdate={
        permissions && permissions.indexOf("update-connectivity") > -1
          ? handleUpdate
          : undefined
      }
    >
      <FormContent item={item} disabled={permissions && permissions.indexOf("update-connectivity") === -1}/>
    </AppForm>
  );
};

export default Connectivity;
