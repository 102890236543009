const FILTER_SORT_MAP = {
  id: {
    isFilterable: false,
    isSortable: false,
  },
  site_id: {
    isFilterable: false,
    isSortable: false,
  },
  address: {
    isFilterable: true,
    isSortable: true,
  },
  city_name: {
    isFilterable: true,
    isSortable: false,
  },
  status: {
    isFilterable: true,
    isSortable: true,
  },
  onlineStatus: {
    isFilterable: true,
    isSortable: true,
  },
  site_heartbeat_timestamp: {
    isFilterable: true,
    isSortable: false,
  },
  lat: {
    isFilterable: false,
    isSortable: false,
  },
  lon: {
    isFilterable: false,
    isSortable: false,
  },
  bikes_num: {
    isFilterable: false,
    isSortable: false,
  },
  bikes: {
    isFilterable: false,
    isSortable: false,
  },
  router: {
    isFilterable: true,
    isSortable: true,
  },
};

const LABEL_MAP = {
  status: {
    label: 'Status',
  },
  city_name: {
    label: 'City',
  },
  address: {
    label: 'Address',
  },
};

export {
  FILTER_SORT_MAP,
  LABEL_MAP,
};
