import React from 'react';
import { useDispatch } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { setModalData } from 'store/actions/common';
import { VEHICLE_MODAL } from 'utils/constants/modals';
import CellView from 'components/Cells/CellView';
import styles from './styles';

const useStyles = createUseStyles(styles);

const CellVehicle = ({
  id,
  children,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleClick = React.useCallback(e => {
    e.preventDefault();
    dispatch(setModalData({ type: VEHICLE_MODAL, id }));
  }, [ id ]);
  
  return (
    <CellView onClick={handleClick}>
      {children}
    </CellView>
  );
};

export default CellVehicle;
