import React from 'react';
import { useDispatch } from 'react-redux';
import { usePermissions } from 'react-admin';
import CRUModal from 'components/Modals/CRUModal';
import { setModalData } from 'store/actions/common';
import {
  useFetchItem,
  useDelete,
} from 'utils/hooks/simCard';
import FormContentCreatable from './components/FormContentCreatable';
import FormContentViewable from './components/FormContentViewable';

const SimCardModal = ({
  id,
  fetchItems = () => {},
  onCreate,
  modalInitialData,
}) => {
  const title = React.useMemo(() => ( onCreate ? 'Add SIM Card' : 'SIM Card' ), [ onCreate ]);
  const dispatch = useDispatch();
  const { permissions } = usePermissions();
  const { item, fetch } = useFetchItem();
  const { deleteItems } = useDelete();
  const handleClose = React.useCallback(() => {
    dispatch(setModalData());
  }, []);
  const handleDelete = React.useCallback(() => {
    deleteItems([ id ])
      .finally(() => {
        fetchItems();
        dispatch(setModalData());
      });
  }, [ id ]);
  
  React.useEffect(() => {
    if (id) {
      fetch(id);
    }
  }, [ id ]);
  
  console.log('test', id, item);
  return (
    <CRUModal
      title={title}
      entity="SIM Card"
      initialValues={modalInitialData}
      onCreate={onCreate}
      onDelete={
        permissions && permissions.indexOf("delete-sim-cards") > -1
          ? handleDelete
          : undefined
      }
      onClose={handleClose}
    >
      {onCreate ?
        <FormContentCreatable item={item} /> :
        <FormContentViewable item={item} />
      }
    </CRUModal>
  );
};

export default SimCardModal;
