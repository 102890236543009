import { COLOR_DEFAULT_TEXT } from 'utils/constants/colors';

export default {
  wrapper: {
    color: COLOR_DEFAULT_TEXT,
    borderRadius: 4,
    fontSize: 14,
    width: 126,
    height: 31,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
};
