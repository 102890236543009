import React from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import RouterIcon from '@material-ui/icons/Router';
import { setModalData } from 'store/actions/common';
import { ROUTER_MODAL } from 'utils/constants/modals';
import CellView from 'components/Cells/CellView';
import styles from './styles';

const useStyles = createUseStyles(styles);

const CellRouter = ({
  id,
  children,
  status,
  withIcon,
}) => {
  const classes = useStyles() ;
  const dispatch = useDispatch();
  const handleClick = React.useCallback(e => {
    e.preventDefault();
    dispatch(setModalData({ type: ROUTER_MODAL, id }));
  }, []);
  
  return (
    <div className={classes.wrapper}>
      <CellView
        status={status}
        Icon={withIcon && RouterIcon}
        onClick={handleClick}
      >
        {children}
      </CellView>
    </div>
  );
};

export default CellRouter;
