import Typography from "@material-ui/core/Typography";
import AppFormGap from "components/AppFormGap";
import AppSelectForm from "components/Inputs/AppSelectForm";
import TextInputForm from "components/Inputs/TextInputForm";
import { STATUS } from "pages/Users/utils/constants";
import React, { useEffect } from "react";
import { useForm } from "react-final-form";
import { createUseStyles } from "react-jss";
import { useFetchItem } from "utils/hooks/users";
import { useFetchItems } from "utils/hooks/roles";

import styles from "./styles";

const useStyles = createUseStyles(styles);

const User = ({ id, disabled }) => {
  const classes = useStyles();
  const form = useForm();
  const { item, fetch: fetchItem } = useFetchItem(id);
  const { items: roles, fetch: fetchRoles } = useFetchItems();

  useEffect(() => {
    if (id !== "-1") {
      fetchItem();
    }

    fetchRoles();
  }, []);

  useEffect(() => {
    if (!!item.id) {
      form.reset(item);
    }
  }, [item]);

  return (
    <div className={classes.wrapper}>
      <Typography variant="h5">User</Typography>
      <AppFormGap />
      <TextInputForm name="email" label="Email" disabled={disabled} />
      <AppFormGap />
      <AppSelectForm
        name="role"
        label="Role"
        options={roles}
        disabled={disabled}
      />
      <AppFormGap />
      <AppSelectForm
        name="status"
        label="Status"
        options={STATUS}
        disabled={disabled}
      />
      <AppFormGap />
    </div>
  );
};

export default User;
