import React from 'react';
import { createUseStyles } from 'react-jss';
import moment from 'moment-timezone';
import { ReactComponent as HeartIcon } from './images/heart-rate.svg';
import styles from './styles';

const useStyles = createUseStyles(styles);

const CellHeartbeat = ({ ts }) => {
  const classes = useStyles();
  const formattedTimestamp = React.useMemo(() =>
      moment(+ts * 1000).tz('Europe/Berlin').format('DD/MM/YYYY HH:mm:ss'),
    [ ts ]);
  
  return (
    <div className={classes.label}>
      <HeartIcon />
      <div className={classes.heartbeat}>
        {formattedTimestamp}
      </div>
    </div>
  );
};

export default CellHeartbeat;
