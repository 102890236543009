import React from 'react';
import { createUseStyles } from 'react-jss';
import BatteryFullIcon from '@material-ui/icons/BatteryFull';
import styles from './styles';

const useStyles = createUseStyles(styles);

const BatteryIcon = ({ level }) => {
  const classes = useStyles({ level });
  
  return (
    <div className={classes.wrapper}>
      <BatteryFullIcon />
    </div>
  )
};

export default BatteryIcon;
