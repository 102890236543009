export default {
  wrapper: {},
  wrapperTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0 50px 5px 10px',
    '& > div:first-child': {
      marginRight: 10,
    },
  },
};
