import client from '../client';

const login = (data) => client.post('/users/signin', data);

const register = (data) => client.post('/users/signup', data);

const searchRole = (data) => client.post('/role-mappings/search', data)

const getRoles = () => client.get('/roles');

const addRole = (data) => client.post('/roles', data)

const updateRole = (data) =>  client.put(`/roles/${data.id}`, data);

const getRole = (id) => client.get(`/roles/${id}`);

const deleteRole = (id) => client.delete(`/roles/${id}`);

const getAssignedRoles = () => client.get('/role-mappings/assigned');

const assignRole = (data) => client.put('/role-mappings/assign', data)

const getRoleMapping = (id) => client.get(`/role-mappings/${id}`);

const deleteRoleMapping = (id) => client.delete(`/role-mappings/${id}`);


export {
  login,
  register,
  searchRole,
  getRoles,
  getRole,
  addRole,
  updateRole,
  deleteRole,
  getAssignedRoles,
  assignRole,
  getRoleMapping,
  deleteRoleMapping
};
