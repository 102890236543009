import { COLOR_PRIMARY } from 'utils/constants/colors';
import { getColorForStatus } from 'utils/helpers/colorGetters';

export default {
  wrapper: {
    color: COLOR_PRIMARY,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '&:hover': {
      textDecoration: 'underline',
    },
    fontWeight: 500,
    fontSize: 18,
  },
  wrapperIcon: {
    color: ({ status }) => status ? getColorForStatus(status) : 'initial',
    '& svg': {
      marginRight: 15,
    },
  },
};
