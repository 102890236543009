const FILTER_SORT_MAP = {
  iccid: {
    isFilterable: false,
  },
  status: {
    isSortable: false,
  },
  id: {
    isSortable: false,
    isFilterable: false,
  },
  lock: {
    isSortable: false,
    isFilterable: false,
  },
  router: {
    isSortable: false,
    isFilterable: false,
  },
};

const LABEL_MAP = {
  iccid: {
    label: 'ICCID',
  },
  status: {
    label: 'Status',
  },
};

export {
  FILTER_SORT_MAP,
  LABEL_MAP,
};
