import { parseFromBackend } from "pages/Users/utils/helpers";
import { useState } from "react";
import { NotificationManager } from "react-notifications";
import { useDispatch } from "react-redux";
import { setLoader } from "store/actions/common";
import {
  assignRole, deleteRoleMapping,
  getAssignedRoles,
  getRoleMapping,
  updateRole
} from "utils/helpers/request/api/auth";

export const useFetchItems = () => {
  const [items, setItems] = useState([]);
  const dispatch = useDispatch();

  const fetch = async () => {
    dispatch(setLoader(true));

    try {
      const { data: assignedRoles } = await getAssignedRoles();

      setItems(assignedRoles);
    } catch (error) {
      console.log("Something unexpected happened. Error: ", error);
    }

    dispatch(setLoader(false));
  };

  return {
    items,
    fetch,
  };
};

export const useCreateItem = () => {
  const dispatch = useDispatch();

  const createItem = async (data) => {
    dispatch(setLoader(true));

    try {
      await assignRole(data);
      NotificationManager.success("Successfully added!");
    } catch (e) {}

    dispatch(setLoader(false));
  };

  return {
    createItem,
  };
};

export const useUpdateItem = () => {
  const dispatch = useDispatch();

  const updateItem = async (data) => {
    dispatch(setLoader(true));

    try {
      await assignRole(data);
      NotificationManager.success("Successfully updated!");
    } catch (e) {}

    dispatch(setLoader(false));
  };

  return {
    updateItem,
  };
};

export const useFetchItem = (id) => {
  const [item, setItem] = useState({
    id: "",
    email: "",
    role: "",
    status: "",
  });
  const dispatch = useDispatch();

  const fetch = async () => {
    dispatch(setLoader(true));

    try {
      let { data } = await getRoleMapping(id);

      const roleMapping = parseFromBackend(data);

      setItem(roleMapping);
    } catch (e) {
      console.log("error occurred", e);
    }

    dispatch(setLoader(false));
  };
  return {
    item,
    fetch,
  };
};

export const useDeleteItem = () => {
  const dispatch = useDispatch();

  const deleteItem = async (id) => {
    dispatch(setLoader(true));

    try {
      await deleteRoleMapping(id);
      NotificationManager.success("Successfully removed!");
    } catch (e) {
      NotificationManager.error(e.response ? e.response.data : null);
    }

    dispatch(setLoader(false));
  };

  return {
    deleteItem,
  };
};

export const useDelete = () => {
  const dispatch = useDispatch();

  const deleteItems = async (ids) => {
    dispatch(setLoader(true));
    try {
      for (const id of ids) {
        await deleteRoleMapping(id);
      }
    } catch (error) {
      console.log("Something unexpected happened. Error: ", error);
    }
  };

  return {
    deleteItems,
  };
};
