const CELL_LINK = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  '& svg': {
    height: 37,
    width: 37,
    marginRight: 5,
  },
};

export {
  CELL_LINK,
};
