import React from 'react';
import CRUModal from 'components/Modals/CRUModal';
import TextInputForm from 'components/Inputs/TextInputForm';
import { requiredValidation } from 'utils/helpers/validations';

const VehicleTypeModal = ({
    modalInitialData,
    onCreate,
    onUpdate,
    onClose,
  }) => {
  const title = React.useMemo(() => onUpdate ? 'Edit Vehicle Type' : 'Add Vehicle Type', []);
  
  return (
    <CRUModal
      title={title}
      entity="Vehicle Type"
      initialValues={modalInitialData}
      onCreate={onCreate}
      onUpdate={onUpdate}
      onClose={onClose}
    >
      <TextInputForm
        name="id"
        label="Name"
        fieldValidation={requiredValidation}
      />
    </CRUModal>
  );
};

export default VehicleTypeModal;
