import PageWrapper from "components/PageWrapper";
import React from "react";
import {
  useFetchItems,
  useCreateItem,
  useUpdateItem,
  useDeleteItem,
  useDelete,
} from "utils/hooks/roles";
import ListRoles from "./components/ListRoles";
import RolesModal from "./components/RolesModal";

const Roles = () => {
  const { items, fetch } = useFetchItems();
  const { deleteItems } = useDelete();
  const { deleteItem } = useDeleteItem();
  const { updateItem } = useUpdateItem();
  const { createItem } = useCreateItem();
  const [roleId, setRoleId] = React.useState(null);
  const [isViewOnly, setIsViewOnly] = React.useState(false);
  const [modalTitle, setModalTitle] = React.useState("");
  const [permissions, setPermissions] = React.useState([])

  React.useEffect(() => {
    fetch();
  }, []);

  const handleDeleteItems = React.useCallback(
    (indexes) => {
      const ids = indexes.map((i) => items[+i].id);

      deleteItems(ids).then(() => {
        fetch();
      });
    },
    [items]
  );

  const hideModal = React.useCallback(() => {
    setRoleId(null);
    setIsViewOnly(false);
  }, []);

  const handleDeleteItem = React.useCallback(() => {
    deleteItem(roleId).then(() => {
      hideModal();
      fetch();
    });
  }, [roleId]);

  const handleClickCreate = React.useCallback(() => {
    setRoleId("-1");
    setModalTitle("Create New Role");
  }, []);

  const handleUpdate = React.useCallback(async ({ values }) => {
    console.log(values)
    const role = {
      id: values.roleId,
      name: values.name,
      permissions: JSON.stringify({ actions: permissions }),
    };
    updateItem(role).then(() => {
      hideModal();
      fetch();
    });
  }, [fetch, hideModal, updateItem, permissions]);

  const onChangePermissions = React.useCallback((permissions) => {
    setPermissions(permissions)
  }, [])

  const handleCreate = React.useCallback(({ values }) => {

    const role = {
      role: values.name,
      permissions: JSON.stringify({ actions: permissions }),
    };
    createItem(role)
      .then(() => {
        return fetch();
      })
      .then(() => {
        hideModal();
      });
  }, [createItem, fetch, hideModal, permissions]);

  const handleEdit = React.useCallback(
    (id) => {
      setRoleId(id);
      setModalTitle("Edit Role");
    },
    [setRoleId]
  );

  const handleView = React.useCallback((id) => {
    setRoleId(id);
    setModalTitle("View Role");
    setIsViewOnly(true);
  }, []);

  return (
    <PageWrapper>
      <ListRoles
        items={items}
        onEdit={handleEdit}
        onView={handleView}
        onDelete={handleDeleteItems}
        onCreate={handleClickCreate}
      />
      {!!roleId && (
        <RolesModal
          id={roleId}
          disabled={isViewOnly}
          title={modalTitle}
          onClose={hideModal}
          onDelete={handleDeleteItem}
          onUpdate={handleUpdate}
          onCreate={handleCreate}
          onChangePermissions={onChangePermissions}
        />
      )}
    </PageWrapper>
  );
};

export default Roles;
