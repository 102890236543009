import React from 'react';
import { createUseStyles } from 'react-jss';
import Modals from './components/Modals';
import styles from './styles';

const useStyles = createUseStyles(styles);

const PageWrapper = ({
  children,
  ...rest
}) => {
  const classes = useStyles();
  
  return (
    <div className={classes.wrapper}>
      {children}
      <Modals {...rest} />
    </div>
  )
};

export default PageWrapper;
