import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import AppForm from 'components/AppForm';
import { isDisabledPage } from 'utils/helpers/form';
import {
  useFetchItem,
  useCreate,
  useUpdate,
  useDelete,
} from 'utils/hooks/userGroup';
import FormContent from './components/FormContent';

const UserGroup = () => {
  const { id, operation } = useParams();
  const history = useHistory();
  const { item, fetch } = useFetchItem(id);

  const { createItem } = useCreate();
  const { deleteItems } = useDelete();
  const { updateItem } = useUpdate();

  const handleCreate = React.useCallback( async ({ values }) => {
    try {
      await createItem({...values})
      history.push(`/user-groups/edit/${id}`);
    } catch (error) {
      console.log(error)
    }
  }, []);
  
  const handleUpdate = React.useCallback( async({ values }) => {
    try {
      await updateItem(id, { ...values });
    } catch (error) {
      console.log(error)
    }
	}, [ id ]);
  
  const handleUpdateAndClose = React.useCallback( async({ values }) => {
    try {
      await updateItem( id, { ...values });
      history.push('/user-groups');
    } catch (error) {
      console.log(error)
    }
  }, [ id ]);

  const handleDelete = React.useCallback( async() => {
    try {
      await deleteItems([ id ]);
      history.push('/user-groups');
    } catch (error) {
      console.log(error)
    }
  }, [ id ]);

  const handleCancel = React.useCallback(() => {
    history.push('/user-groups');
  }, []);

  React.useEffect(() => {
    fetch();
  }, []);

  return (
    <AppForm
      entity="User Group"
      disabled={isDisabledPage(operation)}
      onCreate={!id && handleCreate}
      onUpdate={!!id && handleUpdate}
      onUpdateAndClose={!!id && handleUpdateAndClose}
      onDelete={!!id && handleDelete}
      onClose={handleCancel}
    >
      <FormContent
        id={id}
        item={item}
        disabled={isDisabledPage(operation)}
      />
    </AppForm>
  )
};

export default UserGroup;
