import React from 'react';
import {
  useFetchItems,
  useDelete,
} from 'utils/hooks/userGroup';
import PageWrapper from 'components/PageWrapper';
import ListUserGroups from './components/ListUserGroups';

const UserGroups = () => {
  const { items, fetch } = useFetchItems();
  const { deleteItems } = useDelete();
  const handleDeleteItems = React.useCallback(indexes => {
    const ids = indexes.map(i => items[+i].id);
    
    deleteItems(ids)
      .then(() => {
        fetch();
      });
  }, [ items ]);
  
  React.useEffect(() => {
    fetch();
  }, []);

  return (
    <PageWrapper fetchItems={fetch}>
      <ListUserGroups
        items={items}
        onDelete={handleDeleteItems}
      />
    </PageWrapper>
  )
};

export default UserGroups;
