import { Container } from '@mui/system';
import React from 'react';
import SiteStepper from './components/SiteStepper/index'
import { Paper } from '@mui/material';
const BulkOTASite = (props)=>{


  return (
    <>
    <Container>
        <Paper elevation={1} style={{margin:'10px',padding:'10px'}} >
          <SiteStepper/>
        </Paper>
    </Container>
    
    </>
  )
};

export default BulkOTASite;