import React from 'react';
import { createUseStyles } from 'react-jss';
import Definition from 'components/Definition';
import AppFormGap from 'components/AppFormGap';
import styles from './styles';

const useStyles = createUseStyles(styles);

const BikeInfo = ({
  vehicleType,
  hardwareId,
  wunderId,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Definition
        term="Vehicle Type"
        value={vehicleType}
      />
      <AppFormGap />
      <Definition
        term="Hardware ID"
        value={hardwareId}
      />
      <AppFormGap />
      <Definition
        term="Wunder ID"
        value={wunderId}
      />
    </div>
  )
};

export default BikeInfo;
