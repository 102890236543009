import React from 'react';
import { createUseStyles } from 'react-jss';
import CRUModal from 'components/Modals/CRUModal';
import AppSelectForm from 'components/Inputs/AppSelectForm';
import { OPTIONS } from './utils/constants';
import styles from './styles';

const useStyles = createUseStyles(styles);

const ErrorCodeModal = ({
  modalInitialData,
  onTrigger,
  onClose,
}) => {
  const classes = useStyles();
  
  return (
    <CRUModal
      title="Trigger Cure Error Code"
      entity="Error"
      initialValues={modalInitialData}
      onTrigger={onTrigger}
      onClose={onClose}
    >
      <AppSelectForm
        name="code"
        label="Code"
        classnameWrapper={classes.wrapperSelect}
        options={OPTIONS}
      />
    </CRUModal>
  );
};

export default ErrorCodeModal;
