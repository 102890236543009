import React,{useState} from "react";
import { createUseStyles } from "react-jss";
import { useForm ,useFormState} from "react-final-form";
import StationLocation from "./components/StationLocation";
import styles from "./styles";
import { usePermissions } from "react-admin";
import Forbidden from "pages/Forbidden";
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import StationCardInfoV2 from "./components/StationCardInfoV2";
import { CardActions } from "@mui/material";
import ButtonSiteRestartV2 from 'components/Buttons/ButtonSiteRestartV2';
import SiteDetailsTabs from "./components/SiteDetailsTabs";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { getConfirmationParams } from "./utils/helpers";
import { useDelete } from "utils/hooks/site";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import ButtonSiteRebootRouterV2 from "components/Buttons/ButtonSiteRebootRouterV2";

const useStyles = createUseStyles(styles);

const FormContentViewable = ({ item,id=item.id }) => {
  const entity = 'Site';
  const classes = useStyles();
  const form = useForm();
  const formState = useFormState();
  const { permissions } = usePermissions();
  const [copied,setCopied] = useState(false);
  const history = useHistory();
  const [modalData, setModalData] = React.useState(null);
  const {deleteItems} = useDelete();
  const handleRejectConfirmation = React.useCallback(() => {
    setModalData(null);
  }, []);
  const handleCopyViewLink = async ()=>{
    let baseUrl = window.location.origin;
    let viewUrl = `${baseUrl}/#/sites/view/${item.id}`
    await navigator.clipboard.writeText(viewUrl);
    setCopied(true);
  };
  const handleEdit = React.useCallback(() => {
    history.push(`/sites/edit/${item.id}`);
  }, [id]);

  const handleDelete = React.useCallback(() => {
    console.log('click delete')
    setModalData({
      ...getConfirmationParams({ entity: entity, action: "DELETE" }),
      onApprove: () => {
        deleteItems([id]).then(() => {
          history.push(`/sites`);
          // setModalData(null);
        });
        history.push(`/sites`);
      },
    });
  }, [entity, formState, false]);

  React.useEffect(() => {
    form.reset(item);
  }, [item]);
  // console.log(item);
  return permissions && permissions.indexOf("view-sites") > -1 ? (
    <div className={classes.wrapper}>
       {!!modalData && (
        <ConfirmationModal {...modalData} onReject={handleRejectConfirmation} />
      )}
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            
            <Card className={classes.root}>
              <CardContent>
                <div className={classes.siteInfoMap}>
                  <StationLocation item={item}/>
                </div>
                <div>
                  <div className={classes.siteNameAndLogo}>
                  <Typography gutterBottom variant="h5" component="h2">
                    {item.address ? item.address.split(',')[0]:''}
                    
                  </Typography>
                  {
                        !!id && (
                          <div onClick={handleCopyViewLink} className={copied?classes.copiedIcon:classes.copyIcon}>
                            <FilterNoneIcon titleAccess='Copy View Link' />
                            <div className={classes.copyLinkText}>{copied?'View Link Copied!':''}</div>
                          </div>
                        )
                    }
                  </div>
                  <StationCardInfoV2 item={item} id={id}/>
                </div>
              </CardContent>
              <CardActions>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <ButtonSiteRestartV2 id={item.id} classStyle={classes.cardActionBtn} setModalData={setModalData}/>
                    </Grid>
                    <Grid item xs={6}>
                    <ButtonSiteRebootRouterV2 item={item} id={item.id} classStyle={classes.cardActionBtn} setModalData={setModalData}/>
                    </Grid>
                    <Grid item xs={6}>
                    {permissions &&
                    permissions.indexOf("update-sites") > -1 ? (
                      <Button
                        variant="contained"
                        className={classes.cardActionBtn}
                        onClick={handleEdit}
                      ><EditIcon/>
                        Edit
                      </Button>
                    ) : (
                      <></>
                    )}
                    </Grid>
                    <Grid item xs={6}>
                    {permissions &&
                    permissions.indexOf("delete-sites") > -1 ? (
                      <Button
                        variant="contained"
                        className={classes.cardActionBtn}
                        onClick={handleDelete}
                      > <DeleteIcon/>
                        Delete
                      </Button>
                    ) : (
                      <></>
                    )}
                    </Grid>
                  </Grid>
              </CardActions>
            </Card>




          </Grid>
          <Grid item xs={8}>
            <SiteDetailsTabs item={item}/>
          </Grid>
        </Grid>
    </Container>
    </div>
  ) : (
    <div className={classes.wrapper}>
      <Forbidden />
    </div>
  );
};

export default FormContentViewable;
