import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useForm, Controller } from 'react-hook-form';
import { TextField, Button, Container, Grid, InputAdornment } from '@material-ui/core';
import { Person, Email, Lock, Phone, Cake } from '@material-ui/icons';
import { CircularProgress, Typography } from '@mui/material';
import styles from './styles'
import { createWunderUser } from 'utils/helpers/request/api/wunderUser';

const useStyles = createUseStyles(styles)

export default function SwuUser() {
  const { control, handleSubmit, clearErrors, setValue, formState: { errors }, reset } = useForm();
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const onSubmit = async (data) => {
    setErrorMessage('')
    if (Object.keys(errors).length === 0) {
      try {
        setLoading(true)    
        const response = await createWunderUser(data);

        if (response.status === 200) {
          setSuccessMessage('User created successfully!')

          setTimeout(() => {
            setSuccessMessage('')
            reset()

            Object.keys(data).forEach((field) => {
              clearErrors(field);
              setValue(field, '');
            });

          }, 5000);
        } else {
          setErrorMessage('Something went wrong while processing your request. Please try again later!')          
        }
      } catch (error) {
        setErrorMessage(getError(error))
      } finally {
        setLoading(false)
      }
    }
  };

  const getError = (error) => {
    if(error.response.data && error.response.data.messages && error.response.data.messages.email) {
      return error.response.data.messages.email[0]
    } else {
      return 'Something went wrong while processing your request. Please try again later!'
    }
  }

  return (
    <Container>
      <div className={classes.title}>
        <Typography variant="h4" align="center" gutterBottom>
          SWU User Registration
        </Typography>
      </div>
      <div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} justify="center">
            <Grid item xs={12}>
              <Controller
                name="firstName"
                control={control}
                rules={{ required: 'First name is required' }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="First Name"
                    variant="outlined"
                    fullWidth
                    error={!!errors.firstName}
                    helperText={errors.firstName?.message}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Person style={{ color: '#00cc77' }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="lastName"
                control={control}
                rules={{ required: 'Last name is required' }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                    error={!!errors.lastName}
                    helperText={errors.lastName?.message}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Person style={{ color: '#00cc77' }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: 'Email is required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i,
                    message: 'Invalid email address',
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Email Address"
                    variant="outlined"
                    fullWidth
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Email style={{ color: '#00cc77' }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="customerId"
                control={control}
                rules={{ required: 'SWU Customer ID is required' }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="SWU Customer ID"
                    variant="outlined"
                    fullWidth
                    error={!!errors.customerId}
                    helperText={errors.customerId?.message}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Person style={{ color: '#00cc77' }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="phoneNumber"
                control={control}
                rules={{ required: 'Phone number is required' }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Phone Number"
                    variant="outlined"
                    fullWidth
                    error={!!errors.phoneNumber}
                    helperText={errors.phoneNumber?.message}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Phone style={{ color: '#00cc77' }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="birthdate"
                control={control}
                rules={{ required: 'Birthdate is required' }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="date"
                    label="Birthdate"
                    variant="outlined"
                    fullWidth
                    error={!!errors.birthdate}
                    helperText={errors.birthdate?.message}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Cake style={{ color: '#00cc77' }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            {
              loading && <CircularProgress color='success'/>
            }

            {
              errorMessage && <div className={classes.container}>
                <p className={classes.error}>{errorMessage}</p>
              </div>
            }

            {
              successMessage && <div className={classes.container}>
                <p className={classes.success}>{successMessage}</p>
              </div>
            }

            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary" disabled={loading}>
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>

  );

}