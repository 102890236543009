import { useDispatch } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { setLoader } from 'store/actions/common';
import { unlock as unlockAPI } from 'utils/helpers/request/api/locks';

const useUnlockLock = () => {
  const dispatch = useDispatch();
  
  const unlock = async function (lockId) {
    dispatch(setLoader(true))
    
    try {
      await unlockAPI(lockId);
      NotificationManager.success('Successfully  unlocked!');
    } catch (e) {
    }
    
    dispatch(setLoader(false))
  };
  
  return {
    unlock,
  };
};

export {
  useUnlockLock,
};
