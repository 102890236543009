import React from "react";
import { createUseStyles } from "react-jss";
import { usePermissions } from "react-admin";
import TableUncontrolled from "components/List/TableUncontrolled";
import { getFieldsFrom } from "utils/helpers/list";
import CellStatus from "components/Cells/CellStatus";
import { BATTERY_MODAL } from "utils/constants/modals";
import { FILTER_SORT_MAP, LABEL_MAP } from "../../utils/constants";
import CellVehicle from "../CellVehicle";
import CellId from "../CellId";
import styles from "./styles";

const useStyles = createUseStyles(styles);

const ListBatteries = ({ items, onDelete }) => {
  const classes = useStyles();
  const { permissions } = usePermissions();

  const fields = React.useMemo(
    () => getFieldsFrom(items, FILTER_SORT_MAP, LABEL_MAP),
    [items]
  );
  const columns = React.useMemo(
    () => [
      {
        accessor: "id",
        Cell: ({ row }) => <CellId row={row} />,
      },
      {
        accessor: "status",
        disableGlobalFilter: true,
        Cell: ({ row }) => <CellStatus type={row.original.status} />,
      },
      {
        accessor: "bike",
        disableGlobalFilter: true,
        Cell: ({ row }) => <CellVehicle row={row} />,
      },
    ],
    []
  );

  return (
    <TableUncontrolled
      isVisibleSort
      isVisibleFilter
      isCreatable={false}
      isEditable={false}
      isViewable={permissions && permissions.indexOf("view-batteries") > -1}
      isRemovable={permissions && permissions.indexOf("delete-batteries") > -1}
      columns={columns}
      caption="All Batteries"
      entityType="batteries"
      modalType={BATTERY_MODAL}
      totalCount={items.length}
      onDelete={onDelete}
      fields={fields}
      rowsPerPage={20}
      data={items}
    />
  );
};

export default ListBatteries;
