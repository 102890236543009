import client from '../client';

const getPileTypes  = () =>
  client.get('/docking-types');

const deletePileType  = id =>
  client.delete(`/docking-types/${id}`);

const addPileType  = id =>
  client
    .post(
      '/docking-type',
      { id }
    );

const getPiles  = () =>
  client.get('/dockings');

const getPile  = id =>
  client.get(`/dockings/${id}`);

const updatePile  = data =>
  client.put(
    `/dockings/${data.id}`,
    data
    );

const deletePile  = id =>
  client.delete(`/dockings/${id}`);

export {
  getPile,
  getPiles,
  updatePile,
  deletePile,

  addPileType,
  getPileTypes,
  deletePileType,
};
