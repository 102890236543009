exports.eventDescriptions = {
  "SITE_HEARTBEAT":"Station heartbeat",				
  "BIKE_STATE":"Bike heartbeat",
  "RENTAL_START":"Rental started",					
  "RENTAL_START_FORCE":"Forced Rental started",
  "PARKING_START":"Parking Started",					
  "PARKING_END":"Parking Ended",
  "RENTAL_END":"Rental Ended",	
  "FORCE_UNLOCK":"Station Forced Unlocked",
  "SITE_SETTING":"Station restarting",
  "SITE_ONLINE":"Station has sent a login command",					
  "SITE_OFFLINE":"Station has sent a offline signal",	
  "BIKE_LOGIN":"Bike has sent a login command",	
  "VEHICLE_PICK_UP_CONFIRMATION":"Service card used",	  			
  "BIKE_LOCK_REPORT":"System has tried to fix site issue",				
  "PILE_FAILURE_REPORT":"System has tried to fix site issue",			
  "LOCK_DETECTION_STATUS":"System has tried to fix site issue",			
  "SITE_FAULT_CLEARING":"System has tried to fix site issue",    			
  "BIKE_UNLOCK":"Bike Unlocked",						
  "SITE_BIKE_RETURN":"Bike returned back to station",
  "SITE_PILE_UNLOCK":"Station Unlocked",	
}