import { COLOR_DEFAULT_TEXT } from 'utils/constants/colors';

export default {
  wrapper: {
    color: COLOR_DEFAULT_TEXT,
    cursor: 'pointer',
    textDecoration: 'none',
    display: 'block',
    width: '100%',
    textShadow: isCurrent => isCurrent ? '.25px 0px .1px, -.25px 0px .1px' : 'none',
  },
};
