import {
  deleteLock,
  getLock,
  getLocks,
} from 'utils/helpers/request/api/locks';
import {
  useFetchItemWrapper,
  useFetchItemsWrapper,
} from 'utils/hooks/fetch';
import { useDeleteItemsWrapper } from 'utils/hooks/delete';
import { parseFromBackend } from 'utils/helpers/lock';

const useFetchItem = id => useFetchItemWrapper(getLock, id, {
  id: null,
  status: null,
  bike: { id: null, name: '' },
  sim_card: { id: null, name: '' },
}, parseFromBackend);

const useDelete = () => useDeleteItemsWrapper(deleteLock);

const useFetchItems = () => {
  return useFetchItemsWrapper(getLocks);
};

export {
  useDelete,
  useFetchItem,
  useFetchItems,
};
