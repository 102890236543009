import TableUncontrolled from "components/List/TableUncontrolled";
import React from "react";
import { usePermissions } from 'react-admin';
import { getFieldsFrom } from "utils/helpers/list";
import { FILTER_SORT_MAP, LABEL_MAP } from "../../utils/constants";

const ListRoles = ({
  items,
  onDelete,
  onView,
  onEdit,
  onCreate,
  isCreatable,
  isEditable,
  isRemovable,
  isViewable
}) => {
  const hiddenColumns = React.useMemo(() => ["id"], []);
  const { permissions } = usePermissions()
  const fields = React.useMemo(
    () => getFieldsFrom(items, FILTER_SORT_MAP, LABEL_MAP),
    [items]
  );

  const columns = React.useMemo(
    () => [
      {
        accessor: "name",
        Cell: ({ row }) => (
          <>{row.original["name"]}</>
        ),
      },
      {
        accessor: "status",
        Cell: ({ row }) => <>{row.original["status"]}</>,
      }
    ],
    []
  );

  return (
    <TableUncontrolled
      isVisibleSort
      isVisibleFilter
      onView={onView}
      onEdit={onEdit}
      columns={columns}
      hiddenColumns={hiddenColumns}
      caption="Roles"
      createCaption="Add Role"
      entityType="roles"
      totalCount={items.length}
      onDelete={onDelete}
      onCreate={onCreate}
      fields={fields}
      rowsPerPage={20}
      data={items}
      isCreatable={permissions && permissions.indexOf('create-roles') > -1 }
      isEditable={permissions && permissions.indexOf('update-roles') > -1}
      isRemovable={permissions && permissions.indexOf('delete-roles') > -1}
      isViewable={permissions && permissions.indexOf('view-roles') > -1 }
    />
  );
};

export default ListRoles;
