import React from 'react';
import { createUseStyles } from 'react-jss';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import styles from './styles';

const useStyles = createUseStyles(styles);

const AppSelect = ({
  name,
  value,
  label,
  error,
  onChange,
  width,
  disabled,
  isNullable = true,
  options = [],
  classnameWrapper,
}) => {
  const classes = useStyles();
  const isError = React.useMemo(() => !!error, [ error ]);
  const labelId = React.useMemo(() => `${name}-select`, [ name ]);

  return (
    <FormControl
      error={isError}
      variant="outlined"
      className={classnameWrapper}
    >
      <InputLabel id={labelId}>
        {label}
      </InputLabel>
      <Select
        labelId={labelId}
        value={value}
        disabled={disabled}
        onChange={onChange}
        width={width}
        label={label}
      >
        {isNullable && <MenuItem value="">
          None
        </MenuItem>}
        {options.map(o => (
          <MenuItem
            key={o.id}
            value={o.id}
          >
            {o.name}
          </MenuItem>
        ))}
      </Select>
      {isError && (<FormHelperText>{error}</FormHelperText>)}
    </FormControl>
  );
};

export default AppSelect;
