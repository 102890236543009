const parseFromBackend = function (rawData) {
  const currentPileFromSites = rawData.siteInfo ? rawData.siteInfo.piles.find(p => p.pile_id === rawData.id) : {};

  return {
    pileId: rawData.id,
    pileStatus: rawData.status,
    pileLat: rawData.lat,
    pileLon: rawData.lon,
    pileType: {
      id: rawData.docking_type_id,
      name: rawData.docking_type_id || '',
    },
    siteAddress: {
      id: rawData.site_id,
      name: rawData.siteInfo ? rawData.siteInfo.address : '',
    },
    bikeLicensePlate: {
      id: currentPileFromSites.bike_id,
      name: currentPileFromSites.bike_license_plate || '',
    },
    licensePlates: (rawData.siteInfo || { piles: [] }).piles.map(p => ({
      id: p.bike_id,
      name: p.bike_license_plate,
    })),
  };
};

const syntheticPromise = function (items) {
  return new Promise((resolve, reject) => {
    resolve(items);
  });
};

const asyncRequestForBikes = function (bikes) {
  return function () {
    return syntheticPromise({
      data: {
        items: bikes,
      },
    });
  };
};

export {
  parseFromBackend,
  asyncRequestForBikes,
};
