import React from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import LockInfo from 'components/Info/LockInfo';
import AppFormGap from 'components/AppFormGap';
import ButtonDefault from 'components/Buttons/ButtonDefault';
import { useUnlockLock } from 'utils/hooks/unlock';
import styles from './styles';
import { LockOpen } from '@material-ui/icons';

const useStyles = createUseStyles(styles);

const LockInfoGeneral = ({ item }) => {
  const classes = useStyles();
  const { unlock } = useUnlockLock();
  const lockHeartbeatWS = useSelector(state => state.commonReducer.lockHeartbeat[item?.lock?.id]);
  const formattedHeartbeat = React.useMemo(() => {
    const ts = lockHeartbeatWS || item?.lock?.heartbeat;
    
    return moment(+ts * 1000).tz('Europe/Berlin').format('DD/MM/YYYY HH:mm:ss');
  }, [ lockHeartbeatWS, item ]);
  
  const handleUnlock = React.useCallback(() => {
    if (item?.lock) {
      unlock(item.lock.id);
    }
  }, [ unlock, item ]);
  
  return (
    <div className={classes.wrapper}>
      <div className={classes.wrapperHeader}>
        <div className={classes.headerWithIcon}>
          <div className={classes.header}>
            Smart Lock
          </div>
          <div className={classes.icon}>
            {/*<LockIcon />*/}
          </div>
        </div>
        <ButtonDefault onClick={handleUnlock}>
         <LockOpen/> &nbsp; Unlock
        </ButtonDefault>
      </div>
      <AppFormGap />
      <div className={classes.wrapperBody}>
        <LockInfo
          id={item?.lock?.id}
          iccid={item?.lock?.sim_card?.iccid}
          heartbeat={formattedHeartbeat}
        />
      </div>
    </div>
  )
};

export default LockInfoGeneral;
