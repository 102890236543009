import React from 'react';
import { createUseStyles } from 'react-jss';
import styles from './styles';

const useStyles = createUseStyles(styles);

const Marker = ({
  color,
  href,
  Icon
}) => {
  const classes = useStyles({ color });

  return (
    <div className={classes.wrapper}>
      {!!href ? (
        <a href={href}>
          <Icon fontSize="large" />
        </a>
      ) : (
        <Icon fontSize="large" />
      )}
    </div>
  )
};

export default Marker;
