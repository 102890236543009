export default {
  wrapper: {
    '& .MuiInputBase-root.Mui-error': {
      backgroundColor: '#fff8f8',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 20,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
};
