import client from "../client";

export const getPartners = () => client.get("/partners");

export const getAllPartners = () => client.get("/vendors");

export const updatePartnerBikeMapping = (data) => client.put(`/vendor-bike-mapping/${data.id}`, data);

export const getPartnerBikeMapping = (id) =>  client.get(`/vendor-bike-mapping/${id}`)

