import { useDispatch } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { setLoader } from 'store/actions/common';

const useDeleteItemsWrapper = asyncRequest => {
  const dispatch = useDispatch();
  
  const deleteItems = async function(ids) {
    dispatch(setLoader(true))
    
    try {
      for (const id of ids) {
        await asyncRequest(id);
      }
      NotificationManager.success('Successfully  removed!');
    } catch (e) {}
    
    dispatch(setLoader(false))
  };
  
  return {
    deleteItems,
  };
};

export {
  useDeleteItemsWrapper,
};
