import React from 'react';
import { createUseStyles } from 'react-jss';
import CellPile from '../CellPile';
import styles from './styles';

const useStyles = createUseStyles(styles);

const CellPile2 = ({ row }) => {
  const classes = useStyles();
  const moreCount = React.useMemo(() => row.original.bikes.length - 2, [ row.original.bikes ]);
  
  return !!row.original.bikes[1] && (
    <div className={classes.wrapper}>
      <CellPile bike={row.original.bikes[1]} />
      {moreCount > 0 && (
        <div className={classes.wrapperMore}>
          +{moreCount}
        </div>
      )}
    </div>
  );
};

export default CellPile2;
