import React from 'react';
import { useDispatch } from 'react-redux';
import { Auth } from 'aws-amplify';
import { setLoader } from 'store/actions/common';

const useFetchItemsWrapper = asyncRequest => {
  const [ items, setItems ] = React.useState([]);
  const dispatch = useDispatch();
  
  const fetch = async function() {
    dispatch(setLoader(true))
    
    try {
      const { data: { items: itemsAPI } } = await asyncRequest();
      
      setItems(itemsAPI);
    } catch (e) {}
    
    dispatch(setLoader(false))
  };
  
  return {
    items,
    fetch,
  };
};

const useFetchItemWrapper = (asyncRequest, id, defaultState, parser = (item) => item) => {
  const [ item, setItem ] = React.useState(defaultState);
  const dispatch = useDispatch();
  
  const fetch = async function() {
    dispatch(setLoader(true))
    
    try {
      const { data } = await asyncRequest(id);
      
      setItem(parser(data));
      dispatch(setLoader(false))
    } catch (e) {
      dispatch(setLoader(false))
      console.log(e)
      if (e.response.status === 404) window.location.href = '#/not-found';
    }
  };
  
  return {
    item,
    fetch,
  };
};

const useFetchUsername = () => {
  const [ item, setItem ] = React.useState(null);
  
  const fetch = async function() {
    try {
      const userData = await Auth.currentSession();
    
      setItem(userData.accessToken.payload.username);
    } catch (e) {}
  }
  
  return {
    item,
    fetch,
  };
};

export {
  useFetchItemsWrapper,
  useFetchItemWrapper,
  useFetchUsername,
};
