import client from '../client';

const getSites = () =>
  client.post('/sites');

const getSite = id =>
  client.get(`/sites/${id}`);

const deleteSite = id =>
  client.delete(`/sites/${id}`);

const updateSite  = data =>
  client.put(`/sites/${data.id}`, data);

const getSiteInfo = id =>
  client.get(`/sites/info/${id}`);

const unlock = bikeId =>
  client.get(`/sites/unlock/${bikeId}`);

const forceUnlock = bikeId =>
  client.get(`/sites/force-unlock/${bikeId}`);

const cureError = (siteId, pileId, error) =>
  client.get(`/sites/cure-error/${siteId}?pileId=${pileId}&error=${error}`);

const restartSite = (siteId) => {
  client.post(`/sites/${siteId}/restart`);
}

const syncSitesToMetabase = () => {
  client.get(`/sites/sync-to-metabase`);
}

const syncFromHeartbeats = () => {
  client.get(`/sites/syncFromHeartbeats`);
}

const syncTrackerInfo = () => {
  client.get(`/sync/bikes/trackerInfo`);
}

const syncBikesAndSitesAfterDailyRestart = () => {
  client.get('/sites/syncAfterDailyRestart')
}

const refreshPileState = () => {
  client.get(`/sites/refresh/pilestate`);
}

const getSitesV2 = (data={}) =>
  client.post('/sites',data);

const getAllSiteAddresses = () =>
  client.get('/siteaddresses');

export {
  unlock,
  cureError,
  forceUnlock,
  restartSite,
  deleteSite,
  updateSite,
  getSites,
  getSite,
  getSiteInfo,
  syncSitesToMetabase,
  syncFromHeartbeats,
  syncTrackerInfo,
  syncBikesAndSitesAfterDailyRestart,
  refreshPileState,
  getSitesV2,
  getAllSiteAddresses
};
