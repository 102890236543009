export default {
  '@keyframes fadeIn': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  wrapperDivider: {
    margin: 0,
    marginLeft: 15,
  },
  wrapperTrash: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    animationDuration: '1s',
    animationName: '$fadeIn',
  },
  label: {
    fontWeight: 'bold',
    fontSize: 24,
  },
};
