import { COLOR_DEFAULT_TEXT } from 'utils/constants/colors';

export default {
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  wrapperInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: COLOR_DEFAULT_TEXT,
    fontSize: 20,
  },
  wrapperLabel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  wrapperActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flex: 1,
    '& > button': {
      marginRight: 15,
    },
    '& > button:last-child': {
      marginRight: 15,
    },
  },
  label: {
    fontSize: 20,
    fontWeight: 'bold',
    marginRight: 15,
    width: 150,
  },
  indicator: {
    width: 15,
    height: 15,
    marginRight: 15,
    borderRadius: 15,
    backgroundColor: ({ color }) => color,
  },
};
