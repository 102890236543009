import React from 'react';
import { createUseStyles } from 'react-jss';
import { NAVIGATION_RIGHT } from 'utils/constants/menus';
import Navigation from '../Navigation';
import styles from './styles';

const useStyles = createUseStyles(styles);

const NavigationRight = ({ pathId }) => {
  const classes = useStyles();

  return (
    <Navigation
      pathId={pathId}
      items={NAVIGATION_RIGHT}
    />
  );
};

export default NavigationRight;
