import CustomDatePicker from "../CustomDatePicker";
/* eslint-disable no-use-before-define */
import React from "react";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
  bar: {
    display: "flex",
    alignContent: "center",
    justifyContent: "space-between",
    marginTop: "10px",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function FilterByBikes(props) {
  const classes = useStyles();
  const [selectedBikes, setSelectedBikes] = React.useState(props.bikeIds);
  const bikeList = props.bikesFromDb.items;
  const handleChange = (event, value) => {
    setSelectedBikes(value);
    let bikeIdList = [];
    value.forEach((bike) => {
      bikeIdList.push(bike.id);
    });
    props.onChange({ bikeIds: bikeIdList });
    // console.log(event.target.value, value);
  };
  return (
    <div className={classes.bar}>
      <div className={classes.root}>
        <Autocomplete
          multiple
          id="tags-standard"
          options={bikeList}
          getOptionLabel={(option) => option.license_plate}
          // defaultValue={[bikeList[0]]}
          // value={selectedBikes}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Select Bikes"
              placeholder="Bikes"
            />
          )}
        />
      </div>
      <div>
        <CustomDatePicker
          startDate={props.startDate}
          endDate={props.endDate}
          onChange={props.onChange}
          dpBtnText={props.dpBtnText}
          onClickSearch={props.onClickSearch}
          filterBy={props.filterBy}
        />
      </div>
    </div>
  );
}
