import React from 'react';
import throttle from 'lodash/throttle';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextInput from 'components/Inputs/TextInput';

const filter = createFilterOptions();

const AsyncAutocomplete = ({
  label,
  error,
  disabled,
  value: valueArg,
  renderLink,
  asyncRequestFn,
  onChange,
  onUpdate,
  onAddClick
}) => {
  const [ loading, setLoading ] = React.useState(false);
  const [ value, setValue ] = React.useState(valueArg);
  const [inputValue, setInputValue] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const renderInput = React.useCallback(params => {
    return (
      <TextInput
        params={params}
        label={label}
        error={error}
        variant="outlined"
        paramsInputProps={{
          ...params.InputProps,
          endAdornment: (
            <React.Fragment>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
            </React.Fragment>
          ),
        }}
      />
    );
  }, [ loading, error ]);
  const handleChange = React.useCallback((event, newValue) => {
    onUpdate(newValue);
    if (newValue && newValue.inputValue) {
      setOpen(true);
      setValue({
        ...newValue,
        name: newValue.inputValue,
      });
      onChange({
        name: newValue.inputValue,
      });
      onAddClick(newValue.inputValue);
    } else {
      setValue(newValue);
      onChange(newValue);
    }
  }, []);
  const handleInputChange = React.useCallback((event, newInputValue) => {
    setInputValue(newInputValue);
  }, []);
  const handleOpen = React.useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);
  const handleGetOptionSelected = React.useCallback((option, value) => option.name === value.name, []);
  const handleFilterOptions = React.useCallback((options, params) => {
    const filtered = filter(options, params);

    if (params.inputValue !== '') {
      onAddClick && filtered.unshift({
        inputValue: params.inputValue,
        name: `Add "${params.inputValue}"`,
      });
    }

    return filtered;
  }, []);
  const handleGetOptionLabel = React.useCallback((option) => option.name, []);

  const fetchFn = React.useMemo(
    () =>
      throttle(async (request, callback) => {
        setLoading(true);

        try {
          const { data } = await asyncRequestFn();

          callback(data);
        } catch (e) {
          callback([]);
        }

        setLoading(false);
      }, 200),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetchFn({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [ value, inputValue, fetch ]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);
  
  React.useEffect(() => {
    setValue(valueArg);
  }, [ valueArg ]);
  
  return (
    <Autocomplete
      open={open}
      value={value}
      autoComplete
      disabled={disabled}
      includeInputInList
      onChange={handleChange}
      onInputChange={handleInputChange}
      onOpen={handleOpen}
      onClose={handleClose}
      getOptionSelected={handleGetOptionSelected}
      filterOptions={handleFilterOptions}
      getOptionLabel={handleGetOptionLabel}
      options={options}
      loading={loading}
      renderInput={renderInput}
    />
  )
};

export default AsyncAutocomplete;
