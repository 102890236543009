import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { usePermissions } from "react-admin";
import AppForm from "components/AppForm";
import { isDisabledPage } from "utils/helpers/form";
import { useCreateRouter } from "utils/hooks/create";
import { useFetchItem, useDelete } from "utils/hooks/router";
import FormContent from "./components/FormContent";
import { useUpdate } from "./utils/hooks";
import { parseDataToBackend } from "utils/helpers/router";

const Router = () => {
  const { id, operation } = useParams();
  const { permissions } = usePermissions();
  const history = useHistory();
  const { item, fetch } = useFetchItem(id);
  const { deleteItems } = useDelete();
  const { updateItem } = useUpdate();
  const { createItem } = useCreateRouter();
  const handleDelete = React.useCallback(() => {
    deleteItems([id]).then(() => {
      history.push("/routers");
    });
  }, [id]);
  const handleUpdate = React.useCallback(
    ({ values }) => {
      updateItem({ ...values, id }).catch(() => {});
    },
    [id]
  );
  const handleUpdateAndClose = React.useCallback(
    ({ values }) => {
      updateItem({ ...values, id })
        .then(() => {
          history.push("/routers");
        })
        .catch(() => {});
    },
    [id]
  );
  const handleClose = React.useCallback(({ values }) => {
    history.push("/routers");
  }, []);
  const handleCreate = React.useCallback(({ values }) => {
    createItem(parseDataToBackend(values))
      .then((id) => {
        history.push(`/routers/edit/${id}`);
      })
      .catch(() => {});
  }, []);

  React.useEffect(() => {
    fetch();
  }, []);

  return (
    <AppForm
      entity="Router"
      disabled={isDisabledPage(operation)}
      onClose={handleClose}
      onCreate={
        permissions && permissions.indexOf("create-routers") > -1
          ? !id && handleCreate
          : undefined
      }
      onUpdate={
        permissions && permissions.indexOf("update-routers") > -1
          ? !!id && handleUpdate
          : undefined
      }
      onUpdateAndClose={
        permissions && permissions.indexOf("update-routers") > -1
          ? !!id && handleUpdateAndClose
          : undefined
      }
      onDelete={
        permissions && permissions.indexOf("delete-routers") > -1
          ? !!id && handleDelete
          : undefined
      }
    >
      <FormContent id={id} item={item} disabled={isDisabledPage(operation)} />
    </AppForm>
  );
};

export default Router;
