import {
  COLOR_PRIMARY_TEXT_2,
  COLOR_DEFAULT_TEXT,
} from 'utils/constants/colors';

export default {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  wrapperHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: COLOR_DEFAULT_TEXT,
    '& > div': {
      marginRight: 15,
    },
  },
  wrapperInfo: {
    width: '100%',
  },
  wrapperBattery: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  descriptionBattery: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  address: {
    fontWeight: 700,
    fontSize: 28,
  },
  label: {
    fontSize: 12,
    color: COLOR_PRIMARY_TEXT_2,
  },
  level: {
    fontSize: 24,
  },
  icon: {
    '& svg': {
      width: 42,
      height: 42,
    },
  },
  copyIcon :{
    '&:hover':{
      color:'red',
      cursor: 'pointer'
    }
  },
  copiedIcon :{
      color:'green',
  },
  copyLinkText :{
    float:'right'
  },
  redText : {
    color:'red'
  },
  closeIcon: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
};
