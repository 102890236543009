import AppForm from "components/AppForm";
import React from "react";
import { usePermissions } from "react-admin";
import { createUseStyles } from "react-jss";
import { useHistory, useParams } from "react-router-dom";
import { isCreatable, isEditable, isViewable } from "utils/helpers/form";
import { useCreateLock } from "utils/hooks/create";
import { useDelete, useFetchItem } from "utils/hooks/lock";
import FormContent from "./components/FormContent";
import styles from "./styles";
import { parseToBackend } from "./utils/helpers";
import { useUpdate } from "./utils/hooks";

const useStyles = createUseStyles(styles);

const Lock = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id, operation } = useParams();
  const { item, fetch } = useFetchItem(id);
  const { updateItem } = useUpdate();
  const { deleteItems } = useDelete();
  const { createItem } = useCreateLock();
  const { permissions } = usePermissions();
  const handleClose = React.useCallback(() => {
    history.push("/locks");
  }, []);
  const handleCreate = React.useCallback(({ values }) => {
    createItem(values).then(() => {
      history.push(`/locks/edit/${values.id}`);
    });
  }, []);
  const handleUpdate = React.useCallback(
    ({ values }) => {
      updateItem({
        ...parseToBackend(values),
        id,
        new_id: values.id,
      }).then(() => {
        return history.push(`/locks/edit/${values.id}`);
      });
    },
    [operation, item, id]
  );
  const handleUpdateAndClose = React.useCallback(
    ({ values }) => {
      updateItem({
        ...parseToBackend(values),
        id,
        new_id: values.id,
      }).then(() => {
        history.push("/locks");
      });
    },
    [operation, item, id]
  );
  const handleDelete = React.useCallback(() => {
    deleteItems([id]).then(() => {
      history.push("/locks");
    });
  }, [id]);

  React.useEffect(() => {
    if (!isCreatable(operation)) {
      fetch();
    }
  }, []);

  return (
    <AppForm
      entity="Lock"
      onClose={handleClose}
      onCreate={
        permissions && permissions.indexOf("create-locks") > -1
          ? isCreatable(operation) && handleCreate
          : undefined
      }
      onUpdate={
        permissions && permissions.indexOf("update-locks") > -1
          ? isEditable(operation) && handleUpdate
          : undefined
      }
      onUpdateAndClose={
        permissions && permissions.indexOf("update-locks") > -1
          ? isEditable(operation) && handleUpdateAndClose
          : undefined
      }
      onDelete={
        permissions && permissions.indexOf("delete-locks") > -1
          ? (isEditable(operation) || isViewable(operation)) && handleDelete
          : undefined
      }
    >
      <FormContent item={item} operation={operation} />
    </AppForm>
  );
};

export default Lock;
