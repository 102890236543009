import {
  ERROR,
  MAINTENANCE,
  INVENTORY,
  EMPTY,
  CHARGING,
} from 'utils/constants/statuses';

const STATUSES = [
  { id: EMPTY, name: EMPTY },
  { id: INVENTORY, name: INVENTORY },
  { id: MAINTENANCE, name: MAINTENANCE },
  { id: ERROR, name: ERROR },
  { id: CHARGING, name: CHARGING },
];

export {
  STATUSES,
};
