import PageWrapper from "components/PageWrapper";
import React, { useState } from "react";
import LogView from "./components/LogView";
import moment from "moment-timezone";
import { NotificationManager } from "react-notifications";
import { fetchAdminActivities } from "utils/helpers/request/api/logEvents";
import { getBikes } from "utils/helpers/request/api/bikes";
import { getSites } from "utils/helpers/request/api/sites";
import TabSearchBar from "./components/TabSearchBar";

moment.tz.setDefault("Europe/Berlin");

const isTesting = false;

class AdminActivities extends React.Component {
  INTERVAL_1_DAY = "1d";
  TEXT_DAYS = "days";

  FILTER_BY_ACTIVITIES = "activities";
  FILTER_BY_BIKEIDS = "bikeIds";
  FILTER_BY_SITEIDS = "siteIds";

  constructor(props) {
    super(props);

    this.state = {
      filterBy: this.FILTER_BY_ACTIVITIES,
      events: [
        "ALL",
        "roles",
        "users",
        "role-mapping",
        "batteries",
        "sim card",
        "router",
        "lock",
        "city",
        "pile type",
        "pile",
        "connectivity",
        "user groups",
        "sites",
        "bikes",
      ],
      bikeIds: [],
      siteIds: [],
      endDate: moment(),
      startDate: moment().subtract(1, this.TEXT_DAYS),
      // endDate: moment(1664010912746), // for testing only
      // startDate: moment(1664008810166), // for testing only
      offset: 0,
      limit: 100,
      logs: [],
      dpBtnText: this.INTERVAL_1_DAY,
      bikesFromDb: [],
      sitesFromDb: [],
      bikeViewLookUp:[],
      siteViewLookUp:[],
      loading: true,
    };
    // this.init();
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  } // end

  componentDidMount(){
    this.init();
  }

  async handleChange(data) {
    try {
      // updating filter parameters
      this.setState(data);
      this.handleSearch(data);
    } catch (error) {
      console.log(error);
    }
  }

  async handleSearch(data) {
    try {
      // console.log("current", moment().format());
      // console.log("start handle search", this.state.startDate.format());
      // console.log("end handle search", this.state.endDate.format());
      await this.setState(data);
      // fetching latest data for new filters
      let req = {
        startDate: moment(this.state.startDate).valueOf().toString(),
        endDate: moment(this.state.endDate).valueOf().toString(),
        offset: this.state.offset,
        limit: this.state.limit,
      };
      // if date filter not change from page load. use current time stamp as end momnet
      if (this.state.dpBtnText != "custom"){
        let d = moment().format();
        req.endDate= moment().valueOf().toString()
        data.endDate = moment();
      }
      await this.setState(data);
      // console.log(this.state.filterBy);
      if (this.state.filterBy == this.FILTER_BY_ACTIVITIES) {
        let events = this.state.events;
        if (events.indexOf("ALL") == -1) {
          req.events = this.state.events;
        }
      } else if (this.state.filterBy == this.FILTER_BY_BIKEIDS) {
        req.bikeIds = this.state.bikeIds;
      } else if (this.state.filterBy == this.FILTER_BY_SITEIDS) {
        req.siteIds = this.state.siteIds;
      } else {
        console.error(`no filterBy`, this.state.filterBy);
        return;
      }

      this.setState({ loading: true})
      // console.log(req);
      let response;
      if (isTesting) {
        response = await this.getDummyData(); // for testing only
      } else {
        response = await fetchAdminActivities(req);
      }
      if (response && response.status == 200) {
        // console.log(JSON.stringify(response.data));
        this.setState({ logs: response.data.logs || [], loading: false });
      } else {
        NotificationManager.error('Something went wrong while fetch records');
      }
    } catch (error) {
      console.log(error);
      NotificationManager.error('Error occured while handling search');
    }
  }

  async init() {
    try {
      // console.log(moment.now());
      // fetch all bikes
      let response = await getBikes();
      // let response = await this.fetchDummyBikesData(); // for testing only
      if (
        response &&
        response.status == 200 &&
        typeof response.data != "string"
      ) {
        response.data.items = this.filterBikesWithNullLicensePlate(
          response.data.items
        );
        const bikeViewLookUp = this.generateBikeLookUp(response.data.items);
        this.setState({ bikesFromDb: response.data ,bikeViewLookUp:bikeViewLookUp});
      } else {
        // console.log(response);
        NotificationManager.error('Something went wrong while fetching bikes');
      }

      // fetch all sites
      response = await getSites();
      // response =  await this.fetchDummySitesData(); // for testing only
      if (
        response &&
        response.status == 200 &&
        typeof response.data != "string"
      ) {
        const siteViewLookUp = this.generateSiteLookUp(response.data.items);
        this.setState({ sitesFromDb: response.data ,siteViewLookUp:siteViewLookUp});
      } else {
        NotificationManager.error('Something went wrong while fetching sites');
      }

      let req = {
        events: this.state.events,
        startDate: moment(this.state.startDate).valueOf().toString(),
        endDate: moment(this.state.endDate).valueOf().toString(),
        offset: this.state.offset,
        limit: this.state.limit,
      };
      if (isTesting) {
        response = await this.getDummyData(); // for testing only
      } else {
        response = await fetchAdminActivities(req);
        // console.log(response);
      }

      if (
        response &&
        response.status == 200 &&
        typeof response.data != "string"
      ) {
        this.setState({ logs: response.data.logs, loading: false });
      } else {
        NotificationManager.error('Something went wrong while fetching records');
      }
    } catch (e) {
      console.log(e);
      // NotificationManager.error('Something went wrong while fetching records');
    }
  }

  filterBikesWithNullLicensePlate(data) {
    return data.filter((data) => data.license_plate != null);
  }

  generateBikeLookUp(data) {
    let result = {};
    data.forEach(bike=>{
      result[bike.license_plate]=bike.id;
    });
    return result;
  }

  generateSiteLookUp(data){
    let result = {};
    data.forEach(site=>{
      result[site.street]=site.site_id;
    });
    return result;
  }

  // async getDummyData() {
  //   let data = allData;
  //   return { status: 200, data: data };
  // }

  // async fetchDummyBikesData() {
  //   let data = dummybikesData;
  //   return { status: 200, data: data };
  // }

  // async fetchDummySitesData() {
  //   let data = dummySitesData;
  //   return { status: 200, data: data };
  // }

  render() {
      return (
        <PageWrapper>
          <div>
            <TabSearchBar
              events={this.state.events}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              onChange={this.handleChange}
              dpBtnText={this.state.dpBtnText}
              bikesFromDb={this.state.bikesFromDb}
              sitesFromDb={this.state.sitesFromDb}
              // onClickSearch={this.handleSearch}
              bikeIds={this.state.bikeIds}
              siteIds={this.state.siteIds}
            />

            <LogView
            rows={this.state.logs}
            loading={this.state.loading}
            bikeViewLookUp={this.state.bikeViewLookUp}
            siteViewLookUp= {this.state.siteViewLookUp}
            />
          </div>
        </PageWrapper>
      );
  }
}

export default AdminActivities;
