import React from 'react';
import CellBatteryGlobal from 'components/Cells/CellTrackerBattery';


const CellTrackerBattery = ({ row }) => {

  if (row.original.tracker_battery == null) {
    return (<>{""}</>)  
  } else {
    return (<CellBatteryGlobal
      id={row.original.tracker_battery}
      level={Math.floor(row.original.tracker_battery)}
    />);
  }
  
  // return !!row.original.tracker_battery && (
  //   <CellBatteryGlobal
  //     id={row.original.tracker_battery}
  //     level={Math.floor(row.original.tracker_battery)}
  //   />
  // );
};

export default CellTrackerBattery;
