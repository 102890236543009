const OPTIONS = [
  { id: 18, name: 'Bolzen konnte nicht einfahren / öffnen (Error 12)' },
  { id: 19, name: 'Bolzen konnte nicht ausfahren / schließen (Error 13)' },
  { id: 33, name: 'Ladeproblem, Entfernung zum Induktionspad (Error 21)' },
];

export {
  OPTIONS,
};

