import {
  COLOR_DEFAULT_TEXT,
  COLOR_PRIMARY,
} from 'utils/constants/colors';

export default {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    color: COLOR_DEFAULT_TEXT,
    width: '100%',
  },
  wrapperHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  wrapperBody: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    '& > div': {
      flex: 1,
      marginRight: 15,
    },
    '& > div:last-child': {
      marginRight: 0,
    },
  },
  wrapperButton: {
    '& > button': {
      flex: 1,
      marginRight: 15,
    },
    '& > button:last-child': {
      marginRight: 0,
    },
  },
  headerWithIcon: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  header: {
    fontSize: 28,
    fontWeight: 'bold',
    marginRight: 15,
  },
  icon: {
    color: COLOR_PRIMARY,
  },
  wrapperBody50: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '50%',
    '& > div': {
      flex: 1,
      marginRight: 15,
    },
    '& > div:last-child': {
      marginRight: 0,
    },
  },
};
