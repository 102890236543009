import React from 'react';
import CRUModal from 'components/Modals/CRUModal';
import TextInputForm from 'components/Inputs/TextInputForm';
import { requiredValidation } from 'utils/helpers/validations';

const CityModal = ({
  modalInitialData,
  onCreate,
  onUpdate,
  onClose,
}) => {
  const title = React.useMemo(() => onUpdate ? 'Edit City' : 'Add City', []);
  
  return (
    <CRUModal
      title={title}
      entity="City"
      initialValues={modalInitialData}
      onCreate={onCreate}
      onUpdate={onUpdate}
      onClose={onClose}
    >
      <TextInputForm
        name="name"
        label="Name"
        fieldValidation={requiredValidation}
      />
    </CRUModal>
  );
};

export default CityModal;
