import Typography from "@material-ui/core/Typography";
import AppFormGap from "components/AppFormGap";
import AppMultiSelectForm from "components/Inputs/AppMultiSelectForm";
import TextInputForm from "components/Inputs/TextInputForm";
import React, { useEffect } from "react";
import { useForm } from "react-final-form";
import { createUseStyles } from "react-jss";
import { useOptionsItem } from "utils/hooks/vehicle";
import { useFetchItem } from "utils/hooks/partners";
import styles from "./styles";

const useStyles = createUseStyles(styles);

const Partner = ({ id, disabled }) => {
  const classes = useStyles();
  const form = useForm();
  const { item, fetch: fetchItem } = useFetchItem(id);
  const { options, fetchBikeOptions } = useOptionsItem();

  console.log(options)

  useEffect(() => {
    if (id !== "-1") {
      fetchItem();
    }
    fetchBikeOptions();
  }, []);

  useEffect(() => {
    if (!!item.id) {
      form.reset(item);
    }
  }, [item]);

  return (
    <div className={classes.wrapper}>
      <Typography variant="h5">Partner</Typography>
      <AppFormGap />
      <TextInputForm name="name" label="Name" disabled={disabled} />
      <AppFormGap />
      <AppMultiSelectForm
        name="bikes"
        label="Bikes"
        options={options}
        initialValue={[]}
        disabled={disabled}
        renderValue={(selected) => selected.join(", ")}
      />
    </div>
  );
};

export default Partner;
