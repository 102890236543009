export default {
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& button:first-child': {
      marginRight: 15,
    },
  },
};
