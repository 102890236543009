import {
  DELETE,
} from 'components/AppFormHeader/utils/constants';

const getConfirmationParams = ({ entity, action }) => {
  let body = '';

  if (action === DELETE) {
    body = 'Are you sure to delete?'
  }

  return {
    title: entity,
    body,
  };
};

export {
  getConfirmationParams,
};
