import React from "react";
import { createUseStyles } from "react-jss";
import Definition from "components/Definition";
import AppFormGap from "components/AppFormGap";
import styles from "./styles";

const useStyles = createUseStyles(styles);

const StationInfo = ({ street, city, lat, lon, sonoff, partner }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <AppFormGap />
      <Definition term="Sonoff" value={sonoff} link={true}/>
      <AppFormGap />
      <Definition term="Address" value={street} />
      <AppFormGap />
      <Definition term="City" value={city} />
      <AppFormGap />
      <Definition term="Latitude" value={lat} />
      <AppFormGap />
      <Definition term="Longitude" value={lon} />
      <AppFormGap />
      <Definition term="Partner Name" value={partner} />
    </div>
  );
};

export default StationInfo;
