import React from 'react';
import { createUseStyles } from 'react-jss';
import Typography from '@material-ui/core/Typography';
import {
  useForm,
  useFormState,
} from 'react-final-form';
import AppFormGap from 'components/AppFormGap';
import TextInputForm from 'components/Inputs/TextInputForm';
import { requiredValidation } from 'utils/helpers/validations';
import AutocompleteVehicleType from 'components/Inputs/AutocompleteVehicleType';
import AutocompleteLock from 'components/Inputs/AutocompleteLock';
import AutocompletePile from 'components/Inputs/AutocompletePile';
import AutocompleteBattery from 'components/Inputs/AutocompleteBattery';
import AutocompleteSimCard from 'components/Inputs/AutocompleteSimCard';
import AutocompleteDefaultSite from 'components/Inputs/AutocompleteDefaultSite';
import AppSelectForm from 'components/Inputs/AppSelectForm';
import LinkSite from 'components/Links/LInkSite';
import { STATUSES } from '../../utils/constants';
import styles from './styles';
import PileFormSpy from './../../../Pile/components/PileFormSpy/index';
import { usePermissions } from 'react-admin'
import Forbidden from 'pages/Forbidden';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
const useStyles = createUseStyles(styles);
let selectedSite;

const FormContentCreatable = () => {
  const classes = useStyles();
  const form = useForm();
  const formState = useFormState();
  const paramsState = React.useMemo(() => ({ multiline: true, rows: 2 }), []);
  const defaultState = React.useMemo(() => JSON.stringify({ "lat": 49.8626951, "lon": 8.6204333, "position_accuracy": 50, "locked": false, "immobilised": false, "ignition_on": false, "mileage": 0, "box_closed": true, "key_inserted": true, "on_stand": true }), []);
  const { permissions } = usePermissions()

  const filterForLocks = React.useMemo(() => {
    return lock => { return (!lock.bike) }
  }, []);

  const filterForBatteries = React.useMemo(() => {
    return battery => { return (!battery.bike) }
  }, []);

  const filterForPiles = React.useMemo(() => {
    return pile => { return (!pile.bike && pile.site_id === selectedSite) }
  }, []);

  const filterForSimCards = React.useMemo(() => {
    return simCard => { return (!simCard.router || !simCard.lock) }
  }, []);

  function renderPileTitle() {
    return !!formState.values?.pile?.meta?.site_id ? (
      <div className={classes.wrapperPileHeader}>
        Pile (<LinkSite id={formState.values.pile.meta.site_id} />)
      </div>
    ) : 'Pile';
  }

  const updateSelectedSite = (site) => {
    selectedSite = site ? site.id : null;
  }

  return permissions && permissions.indexOf("create-vehicles") > -1 ? (
    <div className={classes.wrapper}>
      <Container>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {/* <Typography variant="h5">Bike</Typography> */}
          <AppFormGap />
        </Grid>
        <Grid item xs={6}>
          <TextInputForm
            name="hardware_id"
            label="Hardware Id"
            initialValue=""
            fieldValidation={requiredValidation}
            required = {true}
            onChange = {requiredValidation}
            placeholder= "hardware"
          />
        </Grid>
        <Grid item xs={6}>
          <TextInputForm
            name="license_plate"
            label="License Plate"
            initialValue=""
            fieldValidation={requiredValidation}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextInputForm
            name="mobile_id"
            label="Wunder Id"
            initialValue=""
            fieldValidation={requiredValidation}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextInputForm
            name="energy_level"
            label="Energy Level"
            fieldValidation={requiredValidation}
            initialValue="47"
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextInputForm
            name="state"
            label="State"
            initialValue={defaultState}
            params={paramsState}
            fieldValidation={requiredValidation}
            required
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <AppSelectForm
            name="status"
            label="Status"
            isNullable={false}
            options={STATUSES}
            initialValue={STATUSES[6].id}
            fieldValidation={requiredValidation}
            required
            width="100"
          />
        </Grid>
        <Grid item xs={6}>
          <TextInputForm name="wunder_vehicle_type_id" label="Wunder Vehicle Type ID" />
        </Grid>
        <Grid item xs={6}>
          <TextInputForm name="gps_tracker_id" label="GPS Tracker ID" />
        </Grid>
        <Grid item xs={6}>
          <TextInputForm name="controller_id" label="Controller ID" />
        </Grid>
        <Grid item xs={6}>
          <TextInputForm name="converter" label="Converter" />
        </Grid>
        <Grid item xs={6}>
          <TextInputForm
            name="year_of_construction"
            label="Year of Construction"
          />
        </Grid>
        <Grid item xs={6}>
          <TextInputForm name="akku_id" label="Akku ID" />
        </Grid>
        <Grid item xs={6}>
          <TextInputForm name="bms_id" label="BMS ID" />
        </Grid>
        <Grid item xs={6}>
          <TextInputForm name="open_vpn_ip" label="OpenVPN-IP" />
        </Grid>
        <Grid item xs={6}>
          <TextInputForm name="motor_id" label="Motor ID" />
        </Grid>
        <Grid item xs={6}>
          <TextInputForm name="frame_number" label="Frame number" />
        </Grid>
        <Grid item xs={6}>
          <AutocompleteVehicleType form={form} />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h5">Live Station</Typography>
          <AppFormGap />
          <AutocompleteDefaultSite form={form} onUpdate={updateSelectedSite} />
        </Grid>
        <Grid item xs={6}>
          <PileFormSpy
            field="defaultSite"
            becomesNotEqual={{ id: formState.values?.pile?.meta?.site_id }}
            set="pile"
            to={""}
          />
          <Typography variant="h5">{renderPileTitle()}</Typography>
          <AppFormGap />
          <AutocompletePile form={form} filterFn={filterForPiles} />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h5">Home Station</Typography>
          <AppFormGap />
          <AutocompleteDefaultSite form={form} name="homeStation" />
        </Grid>
        <Grid item xs={6}>
        </Grid>
        <Grid item xs={6}>
          <AppFormGap />
          <Typography variant="h5">Battery</Typography>
          <AppFormGap />
          <AutocompleteBattery form={form} filterFn={filterForBatteries} />
          <AppFormGap />
        </Grid>
        <Grid item xs={6}>
          <AppFormGap />
          <Typography variant="h5">Lock</Typography>
          <AppFormGap />
          <AutocompleteLock form={form} filterFn={filterForLocks} />
        </Grid>
        {!!formState.values.lock && (
          <Grid item xs={6}>
            <>
              <Typography variant="h5">SIM Card</Typography>
              <AppFormGap />
              <AutocompleteSimCard form={form} filterFn={filterForSimCards} />
            </>
          </Grid>
        )}
      </Grid>
      </Container>
    </div>
  ) : (
    <div className={classes.wrapper}>
      <Forbidden />
    </div>
  );
};

export default FormContentCreatable;
