import get from 'lodash/get';

const parseFromBackend = (rawDataItems) => {
  const [rawData, allBikes] = rawDataItems;
  let energy_level = '', bikeState = null;

  if(rawData.state && (bikeState = JSON.parse(rawData.state))) {
    energy_level = bikeState.energy_level ? bikeState.energy_level + '' : '';

    delete bikeState.energy_level;

    rawData.state = JSON.stringify(bikeState);
  }

  return {
    id: `${rawData.id}`,
    hardware_id: rawData.hardware_id,
    mobile_id: rawData.mobile_id,
    docking_id: rawData.docking_id,
    homeStation: rawData.home_station && {
      id: rawData.home_station.id,
      name: rawData.home_station.address,
      street: rawData.home_station.street,
      lat: rawData.home_station.lat,
      lon: rawData.home_station.lon,
    },
    gps_tracker_id: rawData.gps_tracker_id,
    controller_id: rawData.controller_id,
    converter: rawData.converter,
    year_of_construction: rawData.year_of_construction,
    akku_id: rawData.akku_id,
    bms_id: rawData.bms_id,
    open_vpn_ip: rawData.open_vpn_ip,
    motor_id: rawData.motor_id,
    frame_number: rawData.frame_number,
    defaultSite: rawData.default_site_id && {
      id: rawData.site.id,
      name: rawData.site.address,
      street: rawData.site.street,
      lat: rawData.site.lat,
      lon: rawData.site.lon,
    },
    pile: rawData.docking_id && {
      id: rawData.docking_id,
      name: rawData.docking_id,
      meta: {
        site_id: rawData?.site?.id,
      },
    },
    status: rawData.status,
    battery: rawData.battery && {
      id: rawData.battery.id,
      name: rawData.battery.id,
      energy_level: rawData.battery.energy_level,
    },
    state: rawData.state,
    energy_level,
    vehicle_type: rawData.vehicle_type && {
      id: rawData.vehicle_type,
      name: rawData.vehicle_type,
    },
    license_plate: rawData.license_plate,
    used_vehicle_types: allBikes.map(b => b.vehicle_type).filter(i => !!i && i !== rawData.vehicle_type),
    used_locks: allBikes.map(b => b.lock?.id).filter(i => !!i && i !== rawData.lock?.id),
    site: rawData.site && {
      id: rawData.site.id,
      name: rawData.site.address,
      street: rawData.site.street,
      lat: rawData.site.lat,
      lon: rawData.site.lon,
      icpu:rawData.site.icpu,
    },
    city: rawData.city,
    lock: rawData.lock && {
      id: rawData.lock.id,
      name: rawData.lock.id,
      heartbeat: rawData.lock.heartbeat,
      sim_card: rawData.lock.sim_card,
      status: rawData.lock.status,
    },
    sim_card: (!!rawData.lock && !!rawData.lock.sim_card) && {
      id: rawData.lock.sim_card.id,
      name: rawData.lock.sim_card.iccid,
    },
    router: rawData.router && {
      id: rawData.router.id,
      name: rawData.router.serial_number,
      serial_number: rawData.router.serial_number,
      heartbeat: rawData.router.heartbeat,
      sim_card: rawData.router.sim_card,
      status: rawData.router.status,
    },
    tracker_battery: rawData.tracker_battery,
    tracker_position: rawData.tracker_position && {
      lat: rawData.tracker_position.lat,
      lon: rawData.tracker_position.lon,
      sampledAt: rawData.tracker_position.sampled_at
    },
    tracker_json:rawData.tracker_json,
    default_site_address:rawData.default_site_address,
    is_inside_pile:rawData.is_inside_pile,
    last_used: rawData.last_used,
    wunder_vehicle_type_id: rawData.wunder_vehicle_type_id,
    wunder_reservation_status: rawData.wunder_reservation_status
  };
};

const parseToBackend = rawData => {
  let state = JSON.parse(rawData.state)
  state.energy_level = rawData.energy_level

  return {
    id: rawData.id,
    license_plate: rawData.license_plate,
    hardware_id: rawData.hardware_id,
    username: rawData.username,
    battery_id: get(rawData, 'battery.id'),
    mobile_id: rawData.mobile_id,
    state: JSON.stringify(state),
    status: rawData.status,
    default_site_id: get(rawData, 'defaultSite.id'),
    docking_id: get(rawData, 'pile.id'),
    vehicle_type: get(rawData, 'vehicle_type.id'),
    lock_id: get(rawData, 'lock.id'),
    sim_card_id: get(rawData, 'sim_card.id'),
    gps_tracker_id: rawData.gps_tracker_id,
    controller_id: rawData.controller_id,
    converter: rawData.converter,
    year_of_construction: rawData.year_of_construction,
    akku_id: rawData.akku_id,
    bms_id: rawData.bms_id,
    open_vpn_ip: rawData.open_vpn_ip,
    motor_id: rawData.motor_id,
    frame_number: rawData.frame_number,
    home_station: get(rawData, 'homeStation.id'),
    wunder_vehicle_type_id: rawData.wunder_vehicle_type_id,
    wunder_reservation_status: rawData.wunder_reservation_status
  }
};

export {
  parseToBackend,
  parseFromBackend,
};
