import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { useEffect } from "react";
import { setLoader } from "store/actions/common";
import { useDispatch } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/core/styles";
import ListFirmware from './components/ListFirmware';
import UploadFirmware from './components/UploadFirmware';
import {listFirmware} from '../../utils/helpers/request/api/ota';
import { Paper } from '@mui/material';
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },

}));
const FirmwareMgmt = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [pageIndex,setPageIndex] = React.useState(0);
  const [pageSize,setPageSize] = React.useState(10);
  const [firmwareList,setFirmwareList]= React.useState([]);
  useEffect(()=>{
    // listFirmware(pageIndex,pageSize).then((result)=>{
    //   setFirmwareList(result);
    // })
    init();
  },[pageIndex]);

  const init = async()=>{
    dispatch(setLoader(true))
    let result =  await listFirmware(pageIndex,pageSize);
    setFirmwareList(result);
    dispatch(setLoader(false))
  }

  const refreshFirmwareList=()=>{
    listFirmware(pageIndex,pageSize).then((result)=>{
      setFirmwareList(result);
    })
  }
  return (
    <>
      <Container>
        <Paper elevation={1} style={{ margin: "10px", padding: "10px" }}>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <Typography variant="h5" gutterBottom>
                List Of Uploaded Firmware
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <UploadFirmware
                refresh={refreshFirmwareList}
                pageIndex={pageIndex}
              />
            </Grid>
            <Grid item xs={12}>
              <ListFirmware
                firmwareList={firmwareList}
                pageIndex={pageIndex}
                pageSize={pageSize}
              />
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </>
  );
};

export default FirmwareMgmt;
