import React from 'react';
import { createUseStyles } from 'react-jss';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-final-form';
import Link from '@material-ui/core/Link';
import TextInputForm from 'components/Inputs/TextInputForm';
import AsyncAutocompleteForm from 'components/Inputs/AsyncAutocompleteForm';
import AppFormGap from 'components/AppFormGap';
import PileStatus from 'components/Statuses/PileStatus';
import AppSelectForm from 'components/Inputs/AppSelectForm';
import { asyncRequestForPileType } from 'utils/helpers/form';
import { useFetch } from './utils/hooks';
import PileFormSpy from './components/PileFormSpy';
import PileAddress from './components/PileAddress';
import PileLicensePlate from './components/PileLicensePlate';
import PileActionsUnlock from '../../components/PileActionsUnlock';
import PileCoordinates from './components/PileCoordinates';
import { STATUSES } from './utils/constants';
import styles from './styles';

const useStyles = createUseStyles(styles);

const DEFAULT_LICENSE_PLATE = { id: null, name: '' };

const Pile = ({
  id,
  disabled,
}) => {
  const classes = useStyles();
  const form = useForm();
  const { item, fetch } = useFetch(id);
  
  React.useEffect(() => {
    fetch();
  }, [ ]);
  
  React.useEffect(() => {
    if (!!item.pileId) {
      form.reset(item);
    }
  }, [ item ]);
  
  return (
    <div className={classes.wrapper}>
      <PileFormSpy
        field="siteAddress"
        becomesNotEqual={item.siteAddress}
        set="bikeLicensePlate"
        to={DEFAULT_LICENSE_PLATE}
      />
      <Typography variant="h5">
        Pile {disabled && <PileStatus type={item.pileStatus} />}
      </Typography>
      <AppFormGap />
      <TextInputForm
        name="pileId"
        label="Id"
        disabled
      />
      <AppFormGap />
      <AppSelectForm
        name="pileStatus"
        width={400}
        disabled={disabled}
        label="Status"
        options={STATUSES}
      />
      <AppFormGap />
      <AsyncAutocompleteForm
        label="Type"
        asyncRequestFn={asyncRequestForPileType}
        name="pileType"
        disabled={disabled}
        initAsync
      />
      <AppFormGap />
      <PileCoordinates disabled={disabled} />
      {((disabled && item.siteAddress.id) || !disabled) && (
        <>
          <AppFormGap />
          <AppFormGap />
          <Typography variant="h5">
            Station
          </Typography>
          <AppFormGap />
          <PileAddress />
        </>
      )}
      {!!item.bikeLicensePlate.id && !!item.bikeLicensePlate.name && (
        <>
          <AppFormGap />
          <AppFormGap />
          <Typography variant="h5">
            Bike
          </Typography>
          <AppFormGap />
          <PileLicensePlate items={item.licensePlates} />
          <AppFormGap />
          <PileActionsUnlock bikeId={item.bikeLicensePlate.id} />
        </>
      )}
    </div>
  )
};

export default Pile;
