import { useDispatch } from 'react-redux';
import { setLoader } from 'store/actions/common';
import { unlock } from 'utils/helpers/request/api/sites';

const useTrigger = (id) => {
  const dispatch = useDispatch();
  
  const trigger = async function() {
    dispatch(setLoader(true))
    
    try {
      await unlock(id);
    } catch (e) {}
    
    dispatch(setLoader(false))
  };
  
  return {
    trigger,
  };
};

export {
  useTrigger,
};
