import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import CustomDatePicker from "../CustomDatePicker";

const data = {
  id: "ALL",
  name: "All Activities",
  children: [
    {
      id: "roles",
      name: "Roles",
    },
    {
      id: "users",
      name: "Users",
    },
    {
      id: "batteries",
      name: "Batteries",
    },
    {
      id: "sim card",
      name: "Sim cards",
    },
    {
      id: "router",
      name: "Routers",
    },
    {
      id: "lock",
      name: "Locks",
    },
    {
      id: "city",
      name: "Cities",
    },
    {
      id: "pile type",
      name: "Pile Types",
    },
    {
      id: "pile",
      name: "Piles",
    },
    {
      id: "connectivity",
      name: "Connectivity",
    },
    {
      id: "user groups",
      name: "User Groups",
    },
    {
      id: "sites",
      name: "Sites",
    },
    {
      id: "bikes",
      name: "Vehicles",
    },
  ],
};

const useStyles = makeStyles({
  root: {
    // height: 110,
    // flexGrow: 1,
    maxHeight: 250,
    overflowY: 'auto',
  },
  bar: {
    display: "flex",
    alignContent: "center",
    justifyContent: "space-between",
    // marginTop:"10px"
  },
});

export default function RecursiveTreeView(props) {
  const [selected, setSelected] = React.useState(props.events);
  const classes = useStyles();
  function getChildById(node, id) {
    let array = [];

    function getAllChild(nodes = null) {
      if (nodes === null) return [];
      array.push(nodes.id);
      if (Array.isArray(nodes.children)) {
        nodes.children.forEach((node) => {
          array = [...array, ...getAllChild(node)];
          array = array.filter((v, i) => array.indexOf(v) === i);
        });
      }
      return array;
    }

    function getNodeById(nodes, id) {
      if (nodes.id === id) {
        return nodes;
      } else if (Array.isArray(nodes.children)) {
        let result = null;
        nodes.children.forEach((node) => {
          if (!!getNodeById(node, id)) {
            result = getNodeById(node, id);
          }
        });
        return result;
      }

      return null;
    }

    return getAllChild(getNodeById(node, id));
  }

  function getOnChange(checked, nodes) {
    const allNode = getChildById(data, nodes.id);
    let array = checked
      ? [...selected, ...allNode]
      : selected.filter((value) => !allNode.includes(value));

    array = array.filter((v, i) => array.indexOf(v) === i);
      // additional logic to handle checked/unchecked of all (parent node)
      if (array.length < 15 && nodes.id != 'ALL' && array.indexOf('ALL') !=-1) {
        array = array.filter(v => v != 'ALL');
      } else if (array.length == 14 && nodes.id != 'ALL' && array.indexOf('ALL') == -1) {
        array.push('ALL');
      }
    setSelected(array);
    props.onChange({ events: array });
  }

  const renderTree = (nodes) => (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id}
      label={
        <FormControlLabel
          control={
            <Checkbox
              checked={selected.some((item) => item === nodes.id)}
              onChange={(event) =>
                getOnChange(event.currentTarget.checked, nodes)
              }
              onClick={(e) => e.stopPropagation()}
            />
          }
          label={<>{nodes.name}</>}
          key={nodes.id}
        />
      }
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  return (
    <div className={classes.bar}>
      <div>
        <TreeView
          className={classes.root}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpanded={["0", "3", "4"]}
          defaultExpandIcon={<ChevronRightIcon />}
        >
          {renderTree(data)}
        </TreeView>
      </div>
      <div>
        <CustomDatePicker
          startDate={props.startDate}
          endDate={props.endDate}
          onChange={props.onChange}
          dpBtnText={props.dpBtnText}
          onClickSearch={props.onClickSearch}
          filterBy={props.filterBy}
        />
      </div>
    </div>
  );
}
