const FILTER_SORT_MAP = {
  id: {
    isSortable: false,
    isFilterable: false,
  },
  name: {
    isSortable: true,
    isFilterable: false,
  },
};

const LABEL_MAP = {
  name: {
    label: 'Name',
  },
};

export {
  FILTER_SORT_MAP,
  LABEL_MAP,
};
