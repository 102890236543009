import React from 'react';
import { createUseStyles } from 'react-jss';
import ButtonDefault from 'components/Buttons/ButtonDefault';
import AppFormGap from 'components/AppFormGap';
import RouterInfo from 'components/Info/RouterInfo';
import StationInfo from 'components/Info/StationInfo';
import ButtonCureError from 'components/Buttons/ButtonCureError';
import styles from './styles';

const useStyles = createUseStyles(styles);

const StationInfoGeneral = ({ item }) => {
  const classes = useStyles();
  const handleCureError = React.useCallback(() => {
  
  }, []);
  
  return (
    <div className={classes.wrapper}>
      <div className={classes.wrapperHeader}>
        <div className={classes.headerWithIcon}>
          <div className={classes.header}>
            Live Station
          </div>
          <div className={classes.icon}>
            {/*<LockIcon />*/}
          </div>
        </div>
        <div className={classes.wrapperButton}>
          <ButtonDefault disabled>
            Restart
          </ButtonDefault>
          {!!item.docking_id && (
            <ButtonCureError
              dockingId={item.docking_id}
              siteId={item?.site?.id}
            />
          )}
        </div>
      </div>
      <AppFormGap />
      <div className={classes.wrapperBody}>
        <StationInfo
          city={item?.city?.name}
          street={item?.site?.street}
          lat={item?.site?.lat}
          lon={item?.site?.lon}
        />
        <RouterInfo
          id={item?.router?.serial_number}
          iccid={item?.router?.sim_card?.iccid}
        />
      </div>
    </div>
  )
};

export default StationInfoGeneral;
