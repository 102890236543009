import React from 'react';
import { usePermissions } from 'react-admin';
import { createUseStyles } from 'react-jss';
import TableUncontrolled from 'components/List/TableUncontrolled';
import { getFieldsFrom } from 'utils/helpers/list';
import CellLogo from '../CellLogo';
import CellPileType from '../CellPileType';
import CellPileStatus from '../CellPileStatus';
import {
  FILTER_SORT_MAP,
  LABEL_MAP,
} from '../../utils/constants';
import styles from './styles';

const useStyles = createUseStyles(styles);

const ListPiles = ({
  items,
  onDelete,
  onView,
  onEdit,
}) => {
  const classes = useStyles();
  const { permissions } = usePermissions()
  const hiddenColumns = React.useMemo(() => [ 'status', 'site_id' ], []);
  const fields = React.useMemo(() => getFieldsFrom(items, FILTER_SORT_MAP, LABEL_MAP), [ items ]);
  const columns = React.useMemo(
    () => [
      {
        accessor: 'id',
        Cell: ({ row }) => <CellLogo
          onClick={onEdit}
          row={row}
        />,
      },
      {
        accessor: 'pileStatus',
        Cell: ({ row }) => <CellPileStatus row={row} />,
      },
      {
        accessor: 'docking_type_id',
        Cell: ({ row }) => <CellPileType row={row} />,
      },
      {
        accessor: 'status',
      },
    ],
    [  ],
  );

  return (
    <TableUncontrolled
      isVisibleSort
      isVisibleFilter
      isCreatable={false}
      onView={onView}
      onEdit={onEdit}
      columns={columns}
      hiddenColumns={hiddenColumns}
      caption="All Piles"
      createCaption="Add Pile"
      entityType="piles"
      totalCount={items.length}
      onDelete={onDelete}
      fields={fields}
      rowsPerPage={20}
      data={items}
      isEditable={permissions && permissions.indexOf('update-piles') > -1}
      isRemovable={permissions && permissions.indexOf('delete-piles') > -1}
    />
  );
};

export default ListPiles;
