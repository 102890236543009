import PageWrapper from "components/PageWrapper";
import React from "react";
import {
  useCreateItem,
  useDelete,
  useDeleteItem,
  useFetchItems,
  useUpdateItem
} from "utils/hooks/discounts";
import DiscountModal from "./components/DiscountModal";
import ListDiscounts from "./components/ListDiscounts";

const DiscountManagement = () => {
  const { items, fetch } = useFetchItems();
  const { deleteItems } = useDelete();
  const { deleteItem } = useDeleteItem();
  const { updateItem } = useUpdateItem();
  const { createItem } = useCreateItem();
  const [modalTitle, setModalTitle] = React.useState("");
  const [discountId, setDiscountId] = React.useState(null);
  const [isViewOnly, setIsViewOnly] = React.useState(false);

  const handleDeleteItems = React.useCallback(
    (indexes) => {
      const ids = indexes.map((i) => items[+i].id);

      deleteItems(ids).then(() => {
        fetch();
      });
    },
    [items]
  );

  React.useEffect(() => {
    fetch();
  }, []);

  const handleEdit = React.useCallback(
    (id) => {
      setDiscountId(id);
      setModalTitle("Edit Discount");
    },
    [setDiscountId]
  );

  const handleView = React.useCallback((id) => {
    setDiscountId(id);
    setModalTitle("View Discount");
    setIsViewOnly(true);
  }, []);

  const hideModal = React.useCallback(() => {
    setDiscountId(null);
    setIsViewOnly(false);
  }, []);

  const handleDeleteItem = React.useCallback(() => {
    deleteItem(discountId).then(() => {
      hideModal();
      fetch();
    });
  }, [discountId]);

  const handleClickCreate = React.useCallback(() => {
    setDiscountId("-1");
    setModalTitle("Create New Discount");
  }, []);

  const handleUpdate = React.useCallback(
    async ({ values }) => {
      const discount = {
        id: values.discountId,
        discount: values.discount,
        discount_frequency: values.frequency,
        email_domains: values.emailDomains,
        name: values.name,
        parking_translation_key: values.parkingTranslationKey,
        timing_translation_key: values.timingTranslationKey,
        partner_id: values.partnerId,
        station_group_id: values.stationGroupId,
      };
      updateItem(discount).then(() => {
        hideModal();
        fetch();
      });
    },
    [items]
  );

  const handleCreate = React.useCallback(({ values }) => {
    const discount = {
      discount: values.discount,
      discount_frequency: values.frequency,
      email_domains: values.emailDomains,
      name: values.name,
      parking_translation_key: values.parkingTranslationKey,
      timing_translation_key: values.timingTranslationKey,
      partner_id: values.partnerId,
      station_group_id: values.stationGroupId,
    };
    createItem(discount)
      .then(() => {
        return fetch();
      })
      .then(() => {
        hideModal();
      });
  }, []);

  return (
    <PageWrapper fetchItems={fetch}>
      <ListDiscounts
        items={items}
        onEdit={handleEdit}
        onView={handleView}
        onDelete={handleDeleteItems}
        onCreate={handleClickCreate}
      />
      {!!discountId && (
        <DiscountModal
          id={discountId}
          disabled={isViewOnly}
          title={modalTitle}
          onClose={hideModal}
          onDelete={handleDeleteItem}
          onUpdate={handleUpdate}
          onCreate={handleCreate}
        />
      )}
    </PageWrapper>
  );
};

export default DiscountManagement;
