import React from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import SimCardIcon from '@material-ui/icons/SimCard';
import CellView from 'components/Cells/CellView';
import { setModalData } from 'store/actions/common';
import { SIM_CARD_MODAL } from 'utils/constants/modals';
import styles from './styles';

const useStyles = createUseStyles(styles);

const CellSimCard = ({
  id,
  children,
  status,
}) => {
  const classes = useStyles({ status });
  const dispatch = useDispatch();
  const handleClick = React.useCallback(e => {
    e.preventDefault();
    dispatch(setModalData({ type: SIM_CARD_MODAL, id }));
  }, []);
  
  return (
    <div className={classes.wrapper}>
      <CellView
        Icon={SimCardIcon}
        status={status}
        onClick={handleClick}
      >
        {children}
      </CellView>
    </div>
  );
};

export default CellSimCard;
