import React from 'react';
import ButtonDefault from 'components/Buttons/ButtonDefault';
import { useTrigger } from './utils/hooks';

const ButtonRemoveUserGroup = ({ site, userGroupId }) => {
  const { trigger } = useTrigger(site, userGroupId);
  const onClick = React.useCallback(() => {
    trigger();
  }, []);
  
  return (
    <ButtonDefault onClick={onClick}>
      Remove
    </ButtonDefault>
  )
};

export default ButtonRemoveUserGroup;

