import {
  INVENTORY,
  MAINTENANCE,
  ERROR,
  EMPTY,
  OPERATION,
  CHARGING,
  TRIP,
  WARNING,
  SUCCESS,
  ACTIVE,
  NO_TRACKER,
  OFFLINE,
  ONLINE,
  INSIDE,
  OUTSIDE

} from 'utils/constants/statuses';

import { RENTED, UNKNOWN, RESERVED, IN_TRANSIT, AVAILABLE } from 'utils/constants/reservationStatus';

import {
  COLOR_ERROR,
  COLOR_INFO,
  COLOR_PRIMARY,
  COLOR_PRIMARY_BACKGROUND,
  COLOR_WARNING,
  COLOR_MAINTENANCE,
  COLOR_NO_TRACKER, COLOR_PRIMARY_TEXT_2
} from 'utils/constants/colors';

const getColorForStatus = status => {
  if (status === INVENTORY) return COLOR_INFO;
  if (status === CHARGING) return COLOR_PRIMARY;
  if (status === EMPTY) return COLOR_PRIMARY_BACKGROUND;
  if (status === OPERATION) return COLOR_PRIMARY;
  if (status === ACTIVE) return COLOR_PRIMARY;
  if (status === TRIP) return COLOR_PRIMARY;
  if (status === ERROR) return COLOR_ERROR;
  if (status === MAINTENANCE) return COLOR_MAINTENANCE;
  if (status === WARNING) return COLOR_WARNING;
  if (status === SUCCESS) return COLOR_PRIMARY;
  if (status === "NO TRACKER") return COLOR_NO_TRACKER;
  if (status === ONLINE) return COLOR_PRIMARY;
  if (status === OFFLINE) return COLOR_ERROR;
  if ( status === OUTSIDE) return COLOR_INFO;
  if (status === INSIDE ) return COLOR_PRIMARY;

  if (!status) return 'inherit';

  return  COLOR_PRIMARY_BACKGROUND;
};

const getReservationStatusColor = status => {
  if(status === AVAILABLE) return COLOR_PRIMARY
  if(status === RENTED) return COLOR_INFO
  if(status === RESERVED) return COLOR_INFO
  if(status === IN_TRANSIT) return COLOR_WARNING
  if(status === UNKNOWN) return COLOR_ERROR
}

export {
  getColorForStatus,
  getReservationStatusColor
};
