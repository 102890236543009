import TableUncontrolled from "components/List/TableUncontrolled";
import React from "react";
import { usePermissions } from 'react-admin'
import { getFieldsFrom } from "utils/helpers/list";
import { FILTER_SORT_MAP, LABEL_MAP } from "../../utils/constants";

const ListUsers = ({
  items,
  onDelete,
  onView,
  onEdit,
  onCreate
}) => {
  const hiddenColumns = React.useMemo(() => ["id"], []);
  const fields = React.useMemo(
    () => getFieldsFrom(items, FILTER_SORT_MAP, LABEL_MAP),
    [items]
  );
  const { permissions } = usePermissions()

  const columns = React.useMemo(
    () => [
      {
        accessor: "email",
        Cell: ({ row }) => (
          <>{row.original["email"]}</>
        ),
      },
      {
        accessor: "role",
        Cell: ({ row }) => (
          <>{row.original["role"]}</>
        ),
      },
      {
        accessor: "status",
        Cell: ({ row }) => <>{row.original["status"]}</>,
      }
    ],
    []
  );

  return (
    <TableUncontrolled
      isVisibleSort
      isVisibleFilter
      onView={onView}
      onEdit={onEdit}
      columns={columns}
      hiddenColumns={hiddenColumns}
      caption="Users"
      createCaption="Add User"
      entityType="users"
      totalCount={items.length}
      onDelete={onDelete}
      onCreate={onCreate}
      fields={fields}
      rowsPerPage={20}
      data={items}
      isCreatable={permissions && permissions.indexOf('create-users') > -1 }
      isEditable={permissions && permissions.indexOf('update-users') > -1}
      isRemovable={permissions && permissions.indexOf('delete-users') > -1}
      isViewable={permissions && permissions.indexOf('view-users') > -1 }
    />
  );
};

export default ListUsers;
