import { COLOR_PRIMARY_TEXT_2 } from 'utils/constants/colors';

export default {
  label: {
    fontSize: 12,
    color: COLOR_PRIMARY_TEXT_2,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& svg': {
      height: `14px !important`,
      width: `14px !important`,
    },
  },
};
