import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import {
  getSite,
  getSites,
  deleteSite,
  getSiteInfo,
} from 'utils/helpers/request/api/sites';
import { setLoader } from 'store/actions/common';
import { INVENTORY } from 'utils/constants/statuses';
import { parseFromBackend } from 'utils/helpers/site';
import { initOptions } from 'pages/StationGroups/utils/helpers';

const useFetchItems = () => {
  const [items, setItems] = React.useState([]);
  const dispatch = useDispatch();

  const fetch = async function () {
    dispatch(setLoader(true))

    try {
      const { data: { items: sites } } = await getSites();

      setItems(sites.map((i) => ({
        ...i,
        id: i.site_id,
        onlineStatus: i.onlineStatus ? 'ONLINE':'OFFLINE'
      })));
    } catch (e) { }

    dispatch(setLoader(false))
  };

  return {
    items,
    fetch,
  };
};

const useFetchItem = id => {
  const [item, setItem] = React.useState({
    id: null,
    status: INVENTORY,
    street: null,
    lat: null,
    lon: null,
    icpu: 'http://',
    display: null,
    wifi_master: null,
    reset_controller: null,
    year_of_construction: null,
    partner: null,
    router: { id: null, name: '' },
    simCard: { id: null, name: '' },
    city: { id: null, name: '' },
    piles: [],
    userGroups: null,
    onlineStatus:null,
    msv : null,
    mhv : null,
    sdsv : null,
    sdhv : null,
    rsv : null,
    rhv : null,
  });
  const dispatch = useDispatch();

  const fetch = async function () {
    dispatch(setLoader(true))

    try {
      const { data } = await getSite(id);
      const { data: dataInfo } = await getSiteInfo(id);
      if (!!data && !!dataInfo) {
        setItem(parseFromBackend({
          ...data,
          ...dataInfo,
        }));
      } else {
        NotificationManager.error('Not found');
      }
    } catch (e) {
      if (e.response && e.response.status === 404) window.location.href = '#/not-found';
      console.log(e);
    }

    dispatch(setLoader(false))
  };

  return {
    item,
    fetch,
  };
};

const useDelete = () => {
  const dispatch = useDispatch();

  const deleteItems = async function (ids) {
    dispatch(setLoader(true))

    try {
      for (const id of ids) {
        await deleteSite(id);
      }
      NotificationManager.success('Successfully  removed!');
    } catch (e) { }

    dispatch(setLoader(false))
  };

  return {
    deleteItems,
  };
};

export const useOptionsItem = () => {
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();

  const fetchStationOptions = async () => {
    dispatch(setLoader(true));

    try {
      const { data } = await getSites();

      const result = initOptions(data)

      setOptions(result);

    } catch (error) {
      console.log("Something unexpected happened. Error: ", error);
    }

    dispatch(setLoader(false));
  };

  return {
    options,
    fetchStationOptions,
  };
};

export {
  useDelete,
  useFetchItem,
  useFetchItems,
};
