import React from 'react';
import { usePermissions } from 'react-admin'
import TableUncontrolled from 'components/List/TableUncontrolled';
import { getFieldsFrom } from 'utils/helpers/list';
import { USER_GROUP_MODAL } from 'utils/constants/modals';
import { FILTER_SORT_MAP, LABEL_MAP } from '../../utils/constants';
import CellName from '../CellName';
import CellWunderPromotionCode from '../CellWunderPromotionCode';
import CellStatusCommon from 'components/Cells/CellStatus';

const ListUserGroups = ({
  items,
  onDelete,
}) => {
  const fields = React.useMemo(() => getFieldsFrom(items, FILTER_SORT_MAP, LABEL_MAP), [ items ]);
  const { permissions } = usePermissions()
  const columns = React.useMemo(
    () => [
      {
        accessor: 'name',
        Cell: ({ row }) => <CellName row={row} id={row.original.id}/>,
      },
      {
        accessor: 'status',
        Cell: ({ row }) => <CellStatusCommon type={row.original.status}/>,
      },
      {
        accessor: 'wunder_promotion_code_id',
        Cell: ({ row }) => <CellWunderPromotionCode row={row} />,
      },
    ],
    [],
  );

  return (
    <TableUncontrolled
      isVisibleSort
      isVisibleFilter
      columns={columns}
      createCaption="Add User Group"
      caption="All User Groups"
      entityType="user-groups"
      totalCount={items.length}
      modalType={USER_GROUP_MODAL}
      onDelete={onDelete}
      fields={fields}
      rowsPerPage={20}
      data={items}
      isRemovable={
        permissions && permissions.indexOf("delete-user-groups") > -1
      }
      isCreatable={
        permissions && permissions.indexOf("create-user-groups") > -1
      }
      isViewable={
        permissions && permissions.indexOf("view-user-groups") > -1
      }
      isEditable={
        permissions && permissions.indexOf("update-user-groups") > -1
      }
    />
  );
};

export default ListUserGroups;
