import {
  SET_LOADER,
  SET_MODAL_DATA,
  SET_WS_LOCK_HEARTBEAT,
} from '../constants/common';

const setLoader = payload => ({
  type: SET_LOADER,
  payload,
});

const setWSLockHeartbeat = payload => ({
  type: SET_WS_LOCK_HEARTBEAT,
  payload,
});

const setModalData = payload => ({
  type: SET_MODAL_DATA,
  payload,
});

export {
  setLoader,
  setModalData,
  setWSLockHeartbeat,
};
