import React from 'react';
import RoomIcon from '@material-ui/icons/Room';
import Marker from '../Marker';

const MarkerDefault = ({
  color,
  href,
}) => {
  return <Marker
    color={color}
    href={href}
    Icon={RoomIcon}
  />
};

export default MarkerDefault;
