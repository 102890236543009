import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { usePermissions } from "react-admin";
import AppForm from "components/AppForm";
import { isDisabledPage } from "utils/helpers/form";
import PageWrapper from "components/PageWrapper";
import { useFetchItem, useDelete } from "utils/hooks/simCard";
import FormContent from "./components/FormContent";
import { useUpdate } from "./utils/hooks";

const SimCard = () => {
  const { id, operation } = useParams();
  const history = useHistory();
  const { permissions } = usePermissions();
  const { item, fetch } = useFetchItem();
  const { deleteItems } = useDelete();
  const { updateItem } = useUpdate();
  const handleDelete = React.useCallback(() => {
    deleteItems([id]).then(() => {
      history.push("/sim-cards");
    });
  }, [id]);
  const handleUpdate = React.useCallback(({ values }) => {
    updateItem(values);
  }, []);
  const handleUpdateAndClose = React.useCallback(({ values }) => {
    updateItem(values).then(() => {
      history.push("/sim-cards");
    });
  }, []);
  const handleClose = React.useCallback(({ values }) => {
    history.push("/sim-cards");
  }, []);

  React.useEffect(() => {
    fetch(id);
  }, [id]);

  return (
    <PageWrapper>
      <AppForm
        entity="SIM card"
        disabled={isDisabledPage(operation)}
        onClose={handleClose}
        onUpdate={
          permissions && permissions.indexOf("update-sim-cards") > -1
            ? handleUpdate
            : undefined
        }
        onUpdateAndClose={
          permissions && permissions.indexOf("update-sim-cards") > -1
            ? handleUpdateAndClose
            : undefined
        }
        onDelete={
          permissions && permissions.indexOf("delete-sim-cards") > -1
            ? handleDelete
            : undefined
        }
      >
        <FormContent item={item} disabled={isDisabledPage(operation)} />
      </AppForm>
    </PageWrapper>
  );
};

export default SimCard;
