import client from '../client';

const uploadFirmware = async (data)=>{

    try {
      let result = await client.post(`/ota/firmware/upload`,data,{
        headers: {
          'Content-Type': 'multipart/form-data',
          'accept':'*/*'
        }});
      return true;
    } catch (error){
      console.log(error);
      return false;
    }
}

const listFirmware = async (pageIndex,pageSize)=>{
  try {
    let result = [];
    let response =  await client.get(`/ota/firmware/list?pageIndex=${pageIndex}&pageSize=${pageSize}`);
    if (response.status == '200'){
      result =  response.data;
    }
    return result;
  } catch (error) {
    console.log(error);
    return [];
  }
}

const getFirmwareVersions = async ()=>{
  try {
    let response = await client.get(`/ota/firmware/versions`);
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
}

const triggerOta = async (data) =>{
  try {
    let response = await client.post(`/ota/firmware/trigger`,data,{headers:{'accept':'*/*'}});
    if (response.status == '200'){
      return response.data;
    }else {
      return null;
    }
    
  } catch (error) {
    console.log(error);
    return null;
  }
}

const listRequests = async ()=>{
  try {
    let response = await client.get(`/ota/req/list`);
    if (response.status == '200'){
      return response.data;
    }else {
      return [];
    }
  } catch (error) {
    console.log(error);
    return [];
  }
}

const trackRequests = async (data)=>{
  try {
    let response = await client.post(`/ota/req/track`,data,{headers:{'accept':'*/*'}});
    if (response.status == '200'){
      return response.data;
    }else {
      return [];
    }
  } catch (error) {
    console.log(error);
    return [];
  }
}

const refreshRequestStatus = async (id)=>{
  try {
    let response = await client.get(`/ota/req/refreshstatus/${id}`);
    if (response.status == '200'){
      return true;
    }else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}

export {
  uploadFirmware,
  listFirmware,
  getFirmwareVersions,
  triggerOta,
  listRequests,
  trackRequests,
  refreshRequestStatus

}