import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";

export default function DataGridSites(props) {
  const rows = props.filteredSites ? props.filteredSites : [];
  const columns = [
    { field: "site_id", headerName: "Site ID", width: 150 },
    {
      field: "address",
      headerName: "Site Address",
      width: 450,
    },
    {
      field: props.cType,
      headerName: props.cTypeName,
      width: 150,
    },
  ];
  const handleSiteSelected = (event) => {
    props.handleSiteSelected(event);
  };
  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <DataGrid
        getRowId={(row) => row.site_id}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection
        disableRowSelectionOnClick
        onSelectionModelChange={handleSiteSelected}
      />
    </Box>
  );
}
