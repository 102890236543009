import React from "react";
import { createUseStyles } from "react-jss";
import CellSiteGlobal from "components/Cells/CellSite";
import styles from "./styles";

const useStyles = createUseStyles(styles);

const CellHomeStation = ({ row }) => {
  const classes = useStyles();

  if (row.original.homeStation) {
    return (
      row.original.homeStation && (
        <CellSiteGlobal
          withIcon
          id={row.original.homeStation.id}
          status={row.original.homeStation.status}
        >
          <div className={classes.wrapperContent}>
            {/* <div className={classes.location}>
            Location ({row.original.site.lat}, {row.original.site.lon})
          </div> */}
            <div className={classes.address}>
              {row.original.homeStation.address}
            </div>
          </div>
        </CellSiteGlobal>
      )
    );
  } else {
    return (
      <CellSiteGlobal>
        <div className={classes.wrapperContent}>
          {/* <div className={classes.location}>
            Location ({row.original.site.lat}, {row.original.site.lon})
          </div> */}
          <div className={classes.address}></div>
        </div>
      </CellSiteGlobal>
    );
  }
};

export default CellHomeStation;
