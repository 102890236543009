import React from 'react';
import { createUseStyles } from 'react-jss';
import CellViewSite from 'components/Cells/CellViewSite';
import styles from './styles';

const useStyles = createUseStyles(styles);

const CellSite = ({ row }) => {
  const classes = useStyles();
  
  return !!row.original.site && (
    <CellViewSite
      id={row.original.site.id}
      status={row.original.site.status}
    >
      {row.original.site.address}
    </CellViewSite>
  );
};

export default CellSite;
