const FILTER_SORT_MAP = {
  id: {
    isSortable: true,
    isFilterable: false,
  },
  status: {
    isSortable: false,
    isFilterable: true,
  },
  sim_card: {
    isSortable: false,
    isFilterable: false,
  },
  bike: {
    isSortable: false,
    isFilterable: false,
  },
};

const LABEL_MAP = {
  id: {
    label: 'Id',
  },
  status: {
    label: 'Status',
  },
};

export {
  FILTER_SORT_MAP,
  LABEL_MAP,
};
