import React from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from '@material-ui/core/Paper';
import {AVAILABLE, IN_TRANSIT, RENTED, RESERVED, UNKNOWN} from "../../../../../utils/constants/reservationStatus";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
    // maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const options = [
  "State",
  "Pile State",
  "Bike Battery",
  "Tracker Battery",
  "License Plate",
  "Reservation Status",
  "Last Used"
];
const stateOptions = [
  "MAINTENANCE",
  "ACTIVE",
  "SHIPPING_MODE",
  "TESTING",
  "OUT_OF_ORDER",
  "FUEL_LEVEL_LOW",
  "INTERNAL_RESERVATION",
  "OFFLINE",
  "OPERATION",
];
const pileStateOptions = [
  'INSIDE',
  'OUTSIDE'
]

const reservationOptions = [
    AVAILABLE,
    IN_TRANSIT,
    RENTED,
    RESERVED,
    UNKNOWN
]

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function AdvancedSearch(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const [selectedStateOptions,setSelectedStateOptions] = React.useState([]);
  const [selectedPileStateOptions,setSelectedPileStateOptions] = React.useState([]);
  const [selectedReservationStatus, setSelectedReservationStatus] = React.useState([]);
  const [minBB,setMinBB] = React.useState(0);
  const [maxBB,setMaxBB] = React.useState(100);
  const [minTB,setMinTB] = React.useState(0);
  const [maxTB,setMaxTB] = React.useState(100);
  const [minLU, setMinLU] = React.useState(0);
  const [maxLU, setMaxLU] = React.useState(0);
  const [selectedBikes, setSelectedBikes] = React.useState([]);
  let allObj = {id:'all',license_plate:'ALL Bikes'};
  const bikeLicensePlates = props.licensePlatesFromDb.map(item=>{ return {'id':item.license_plate,'license_plate':item.license_plate}})
  const bikeList = bikeLicensePlates ? [allObj,...bikeLicensePlates] : [];
  const handleClearFilter = () => {
    setSelectedOptions([]);
    setSelectedStateOptions([]);
    setSelectedPileStateOptions([]);
    setMinBB(0);
    setMaxBB(100);
    setMinTB(0);
    setMaxTB(100);
    setMinLU(0);
    setMaxLU(0);
    setSelectedBikes([]);
    setSelectedReservationStatus([]);
    props.handleClearAdvSearch(['status','is_inside_pile','energy_level','tracker_battery','license_plate', 'wunder_reservation_status', 'last_used']);
  };

  const handleSearch =()=>{
    let filterObj = [];
    if (selectedOptions.length === 0){
      // no option selected for filter

      props.handleClearAdvSearch(['status','is_inside_pile','energy_level','tracker_battery','license_plate', 'wunder_reservation_status', 'last_used']);

      return;
    }
    // state
    if (selectedOptions.includes('State') && selectedStateOptions.length > 0 ){
      filterObj.push({id:'status',value:selectedStateOptions})
    } else {
      filterObj.push({id:'status',value:undefined})
    }

    // pile state
    if (selectedOptions.includes('Pile State') && selectedPileStateOptions.length >0 ){
      filterObj.push({id:'is_inside_pile',value:selectedPileStateOptions})
    } else {
      filterObj.push({id:'is_inside_pile',value:undefined})
    }

    // "Bike Battery",
    if (selectedOptions.includes('Bike Battery') && (minBB !== 0 || maxBB !== 100)){
      filterObj.push({id:'energy_level',value:[parseInt(minBB,10),parseInt(maxBB,10)]})
    } else {
      filterObj.push({id:'energy_level',value:undefined})
    }

    // "Tracker Battery",
    if (selectedOptions.includes('Tracker Battery') && (minTB !== 0 || maxTB !== 100)){
      filterObj.push({id:'tracker_battery',value:[parseInt(minTB,10),parseInt(maxTB,10)]})
    } else {
      filterObj.push({id:'tracker_battery',value:undefined})
    }

    // "License Plate",
    if (selectedOptions.includes('License Plate') && selectedBikes.length > 0 ){
      let bikeIdList = [];
      selectedBikes.forEach((bike) => {
        bikeIdList.push(bike.license_plate);
      });
      filterObj.push({id:'license_plate',value:bikeIdList});
    } else {
      filterObj.push({id:'license_plate',value:undefined});
    }

    // Reservation Status
    if (selectedOptions.includes('Reservation Status') && selectedReservationStatus.length > 0 ){
      filterObj.push({id:'wunder_reservation_status',value:selectedReservationStatus})
    } else {
      filterObj.push({id:'wunder_reservation_status',value:undefined})
    }

    // Last Used
    if (selectedOptions.includes('Last Used')  && (minLU !== 0 || maxLU !== 100)){
      filterObj.push({id:'last_used',value:[parseInt(minLU,10),parseInt(maxLU,10)]})
    } else {
      filterObj.push({id:'last_used',value:undefined})
    }

    // should be called in last
    props.handleAdvSearch(filterObj);
  }
  const handleMinBBChange = (event) =>{
    if (event.target.value < 0 ){
      setMinBB(0);
    } else {
      setMinBB(event.target.value);
    }
  }
  const handleMaxBBChange = (event) => {
    if (event.target.value > 100 ){
      setMaxBB(100);
    } else {
      setMaxBB(event.target.value);
    }
  }

  const handleMinTBChange = (event) =>{
    if (event.target.value < 0 ){
      setMinTB(0);
    } else {
      setMinTB(event.target.value);
    }
  }
  const handleMaxTBChange = (event) => {
    if (event.target.value > 100 ){
      setMaxTB(100);
    } else {
      setMaxTB(event.target.value);
    }
  }

  const handleMinLUChange = (event) =>{
    if (event.target.value < 0 ){
      setMinLU(0);
    } else {
      setMinLU(event.target.value);
    }
  }
  const handleMaxLUChange = (event) => {
    if (event.target.value > 100 ){
      setMaxLU(100);
    } else {
      setMaxLU(event.target.value);
    }
  }

  const handleChange = (event) => {
    setSelectedOptions(event.target.value);
    // console.log(event.target.value);
    // bugfix : call pagination search only if clicked on search
    // handleSearch();
  };
  const handleStateOptionChange = (event) =>{
    console.log(selectedStateOptions)
    setSelectedStateOptions(event.target.value);
  }
  const handlePileStateOptionChange = (event) =>{
    // console.log(event.target.value);
    setSelectedPileStateOptions(event.target.value);
  }

  const handleLicensePlateChange = (event, value) => {
    setSelectedBikes(value);
  };
  return (
    <Container >
       <Paper elevation={1} style={{margin:'10px',padding:'10px'}} >
      <Grid continer spacing={3}>
        <Grid item xs={12}>
          <MultiSelect
            value={selectedOptions}
            onChange={handleChange}
            label={"Advanced Search Filters"}
            options={options}
          />
        </Grid>

        {selectedOptions.includes("State") && (
          <Grid item xs={12}>
            <MultiSelect
              value={selectedStateOptions}
              onChange={handleStateOptionChange}
              label={"State"}
              options={stateOptions}
            />
          </Grid>
        )}
        {selectedOptions.includes("Reservation Status") && (
            <Grid item xs={12}>
              <MultiSelect
                  value={selectedReservationStatus}
                  onChange={(e) => setSelectedReservationStatus(e.target.value)}
                  label={"Reservation Status"}
                  options={reservationOptions}
              />
            </Grid>
        )}
        {selectedOptions.includes("Pile State") && (
          <Grid item xs={12}>
            <MultiSelect
              value={selectedPileStateOptions}
              onChange={handlePileStateOptionChange}
              label={"Pile State"}
              options={pileStateOptions}
            />
          </Grid>
        )}
        {selectedOptions.includes("Bike Battery") && (
          <Grid item xs={12}>
            <TextField
              id="minBB"
              label="Min Bike Battery %"
              type="number"
              value={minBB}
              onChange={handleMinBBChange}
              InputLabelProps={{
                shrink: true,
              }}
              style={{marginLeft:'10px'}}
            />
            <span style={{marginLeft:'10px'}}>To</span>
            <TextField
              id="maxBB"
              label="Max Bike Battery %"
              type="number"
              value={maxBB}
              onChange={handleMaxBBChange}
              InputLabelProps={{
                shrink: true,
              }}
              style={{marginLeft:'10px'}}
            />
          </Grid>
        )}
        {selectedOptions.includes("Tracker Battery") && (
          <Grid item xs={12}>
            <TextField
              id="minTB"
              label="Min Tracker Battery %"
              type="number"
              value={minTB}
              onChange={handleMinTBChange}
              InputLabelProps={{
                shrink: true,
              }}
              style={{marginLeft:'10px'}}
            />
            <span style={{marginLeft:'10px'}}>To</span>
            <TextField
              id="maxTB"
              label="Max Tracker Battery %"
              type="number"
              value={maxTB}
              onChange={handleMaxTBChange}
              InputLabelProps={{
                shrink: true,
              }}
              style={{marginLeft:'10px'}}
            />
          </Grid>
        )}
        {selectedOptions.includes("Last Used") && (
            <Grid item xs={12}>
              <TextField
                  id="minLU"
                  label="Min Hours"
                  type="number"
                  value={minLU}
                  onChange={handleMinLUChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{marginLeft:'10px'}}
              />
              <span style={{marginLeft:'10px'}}>To</span>
              <TextField
                  id="maxLU"
                  label="Max Hours"
                  type="number"
                  value={maxLU}
                  onChange={handleMaxLUChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{marginLeft:'10px'}}
              />
            </Grid>
        )}
        {selectedOptions.includes("License Plate") && (
          <Grid item xs={12}>
            <Autocomplete
              style={{marginLeft:'10px'}}
              multiple
              id="tags-standard"
              options={bikeList}
              getOptionLabel={(option) => option.license_plate ? option.license_plate : 'No License Plate'}
              // defaultValue={[bikeList[0]]}
              // value={selectedBikes}
              onChange={handleLicensePlateChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Select Bikes"
                  placeholder="Bikes"
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12} style={{padding:'10px'}}>
          <Button variant="contained" color="primary" onClick={handleSearch}>
              Search
            </Button>
            <Button variant="contained"  style={{marginLeft:'10px'}} onClick={handleClearFilter}>
            Clear Filters
          </Button>
          </Grid>


      </Grid>
      </Paper>
    </Container>
  );
}

function MultiSelect(props){
  const classes = useStyles();
  return (
    <FormControl className={classes.formControl}>
    <InputLabel id="ad-state-mutiple-checkbox-label">
      {props.label}
    </InputLabel>
    <Select
      labelId="ad-state-mutiple-checkbox-label"
      id="ad-state-mutiple-checkbox"
      multiple
      value={props.value}
      onChange={props.onChange}
      input={<Input />}
      renderValue={(selected) => selected.join(", ")}
      MenuProps={MenuProps}
    >
      {props.options.map((name) => (
        <MenuItem key={name} value={name}>
          <Checkbox checked={props.value.indexOf(name) > -1} />
          <ListItemText primary={name} />
        </MenuItem>
      ))}
    </Select>
  </FormControl>
  )
}

function BatteryFilter(props){
  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>

      </Grid>
      <Grid item xs={4}>

      </Grid>
      <Grid item xs={4}>

      </Grid>
    </Grid>
  )
}
