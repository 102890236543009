import React from 'react';
import { createUseStyles } from 'react-jss';
import CellLockGlobal from 'components/Cells/CellLock';
import styles from './styles';

const useStyles = createUseStyles(styles);

const CellLock = ({ row }) => {
  const classes = useStyles();
  
  return !!row.original.lock && (
    <CellLockGlobal
      id={row.original.lock.id}
      status={row.original.lock.status}
    >
      {row.original.lock.id}
    </CellLockGlobal>
  )
};

export default CellLock;
