import { COLOR_SECONDARY } from 'utils/constants/colors';

export default {
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapperInner: {
    outline: 'none',
    backgroundColor: COLOR_SECONDARY,
  },
};
