import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import {
  makeStyles,
  useTheme,
} from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginRight: '100%',
  },
}));

const TablePaginationActions = props => {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;
  const { previousPage,canPreviousPage,nextPage,canNextPage} = onChangePage;
  const handleBackButtonClick = event => {
    onChangePage(event, page - 1)
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1)
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={previousPage}
        disabled={!canPreviousPage}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
       onClick={nextPage}
       disabled={!canNextPage}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
    </div>
  );
};

export default TablePaginationActions;
