import React from 'react';
import { createUseStyles } from 'react-jss';
import { NAVIGATION_LEFT } from 'utils/constants/menus';
import Divider from 'components/Divider';
import Navigation from '../Navigation';
import NavigationMore from '../NavigationMore';
import styles from './styles';

const useStyles = createUseStyles(styles);

const NavigationLeft = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Navigation items={NAVIGATION_LEFT} />
      <Divider />
      <NavigationMore />
    </div>
  );
};

export default NavigationLeft;
