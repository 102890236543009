const requiredValidation = value => {
  const message = 'Required';

  if (!value) return message;
  
  if (value.trim().length === 0) return undefined;
  
  return undefined;
}
const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export {
  composeValidators,
  requiredValidation,
};
