import React from 'react';
import { createUseStyles } from 'react-jss';
import CellSite from 'components/Cells/CellSite';
import styles from './styles';

const useStyles = createUseStyles(styles);

const CellAddress = ({ row }) => {
  const classes = useStyles();
  const addressArray = row.original.address.split(',');
  return (
    <CellSite id={row.original.id}>
      <div className={classes.wrapper}>
        {addressArray[0]},
        <br></br>
        <div className={classes.city}>
        {addressArray[1]}
        </div>  
      </div>

    </CellSite>
  );
};

export default CellAddress;
