import React from 'react';
import { createUseStyles } from 'react-jss';
import { ReactComponent as Icon } from './images/filter.svg';
import styles from './styles';

const useStyles = createUseStyles(styles);

const FilterIcon = () => {
  const classes = useStyles();
  
  return (
    <div className={classes.wrapper}>
      <Icon />
    </div>
  )
};

export default FilterIcon;
