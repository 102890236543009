import React from 'react';
import { useDispatch } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import {
  getPiles,
  deletePile,
  updatePile,
} from 'utils/helpers/request/api/dockings';
import { setLoader } from 'store/actions/common';
import { parseDataToBackend } from './helpers';

const useFetch = () => {
  const [ items, setItems ] = React.useState([]);
  const dispatch = useDispatch();

  const fetch = async function() {
    dispatch(setLoader(true))

    try {
      const { data: { items: piles } } = await getPiles();

      setItems(piles);
    } catch (e) {}

    dispatch(setLoader(false))
  };

  return {
    items,
    fetch,
  };
};

const useDeleteItems = () => {
  const dispatch = useDispatch();

  const deleteItems = async function(ids) {
    dispatch(setLoader(true))

    try {
      for (const id of ids) {
        await deletePile(id);
      }
      NotificationManager.success('Successfully  removed!');
    } catch (e) {}

    dispatch(setLoader(false))
  };

  return {
    deleteItems,
  };
};

const useDeleteItem = () => {
  const dispatch = useDispatch();

  const deleteItem = async function(id) {
    dispatch(setLoader(true))

    try {
      await deletePile(id);
      NotificationManager.success('Successfully  removed!');
    } catch (e) {}

    dispatch(setLoader(false))
  };

  return {
    deleteItem,
  };
};

const useUpdateItem = () => {
  const dispatch = useDispatch();

  const updateItem = async function(data) {
    dispatch(setLoader(true))

    try {
      await updatePile(parseDataToBackend(data));
      NotificationManager.success('Successfully  updated!');
    } catch (e) {}

    dispatch(setLoader(false))
  };

  return {
    updateItem,
  };
};

export {
  useFetch,
  useDeleteItems,
  useDeleteItem,
  useUpdateItem,
};
