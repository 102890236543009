const FILTER_SORT_MAP = {
  id: {
    isSortable: false,
    isFilterable: false,
  },
  status: {
    isSortable: false,
    isFilterable: true,
  },
  energy_level: {
    isSortable: true,
    isFilterable: false,
  },
  battery_id: {
    isSortable: false,
    isFilterable: false,
  },
  license_plate: {
    isSortable: true,
    isFilterable: false,
  },
  state: {
    isSortable: false,
    isFilterable: false,
  },
  site: {
    isSortable: false,
    isFilterable: true,
  },
  lock: {
    isSortable: false,
    isFilterable: false,
  },
  vehicle_type: {
    isSortable: false,
    isFilterable: false,
  },
  tracker_battery: {
    isSortable: true,
    isFilterable: false,
  },
  tracker_position: {
    isSortable: false,
    isFilterable: false,
  },
  tracker_json: {
    isSortable: false,
    isFilterable: false,
  },
  is_inside_pile :{
    isSortable: true,
    isFilterable: false,
  }
};

const LABEL_MAP = {
  license_plate: {
    label: 'License Plate',
  },
  status: {
    label: 'Status',
  },
};

export {
  FILTER_SORT_MAP,
  LABEL_MAP,
};
