import React from 'react';
import { createUseStyles } from 'react-jss';
import EvStationIcon from '@material-ui/icons/EvStation';
import CellIconLink from 'components/Cells/CellIconLink';
import styles from './styles';

const useStyles = createUseStyles(styles);

const CellEditPile = ({
  children,
  href,
  onClick,
  status,
}) => {
  const classes = useStyles();
  
  return (
    <CellIconLink
      href={href}
      Icon={EvStationIcon}
      status={status}
      onClick={onClick}
    >
      {children}
    </CellIconLink>
  );
};

export default CellEditPile;
