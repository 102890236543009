import React from 'react';
import { createUseStyles } from 'react-jss';
import ButtonDefault from 'components/Buttons/ButtonDefault';
import { useUnlockActions } from 'pages/Pile/utils/hooks';
import styles from 'components/PileActionsUnlock/styles';

const useStyles = createUseStyles(styles);

const PileActionsUnlock = ({ bikeId }) => {
  const classes = useStyles();
  const {
    softUnlock,
    hardUnlock,
  } = useUnlockActions(bikeId);
  
  return (
    <div className={classes.wrapper}>
      <ButtonDefault onClick={softUnlock}>
        Unlock
      </ButtonDefault>
      <ButtonDefault onClick={hardUnlock}>
        Force Unlock
      </ButtonDefault>
    </div>
  )
};

export default PileActionsUnlock;
