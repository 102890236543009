import {
  COLOR_ERROR,
  COLOR_PRIMARY,
  COLOR_WARNING,
} from 'utils/constants/colors';

const getLevelColor = level => {
  if (level < 20) return COLOR_ERROR;
  if (level < 40) return COLOR_WARNING;
  if (level >= 40) return COLOR_PRIMARY;
};

export default {
  wrapper: {
    color: ({ level }) => getLevelColor(level),
  },
};
