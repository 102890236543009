import React from 'react';
import { createUseStyles } from 'react-jss';
import TextField from '@material-ui/core/TextField';
import styles from './styles';

const useStyles = createUseStyles(styles);

const TextInput = ({
  params,
  error,
  paramsInputProps,
  label,
  variant = 'outlined',
  required
}) => {
  const classes = useStyles();

  return (
    <TextField
      {...params}
      label={label}
      variant={variant}
      error={!!error}
      helperText={error}
      fullWidth
      InputProps={paramsInputProps}
      required={required}
    />
  )
};

export default TextInput;
