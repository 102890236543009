import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FilterByActivities from "../FilterByActivities";
import FilterByBikes from "../FilterByBikes";
import FilterBySites from "../FilterBySites";
import { color } from "@mui/system";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box component="div" div={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
    // backgroundColor: 'red',
    // backgroundColor:'transparent'

  },
  app_bar_css: {
    backgroundColor:'transparent',
    boxShadow:'none',
    color:'black'
  },
  tab_label: {
    color:'#black'
  }
}));

export default function SimpleTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const FILTER_BY_ACTIVITIES = "activities";
  const FILTER_BY_BIKEIDS = "bikeIds";
  const FILTER_BY_SITEIDS = "siteIds";

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.app_bar_css}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          // className={classes.tabcss}
          // indicatorColor="primary"
          // textColor="primary"
        >
          <Tab label="Filter By Activities" {...a11yProps(0)} className={classes.tab_label} />
          {props.hideMenu ? "" : <Tab label="Filter By Bikes" {...a11yProps(1)} className={classes.tab_label}/>}
          {props.hideMenu ? "" : <Tab label="Filter By Sites" {...a11yProps(2)} className={classes.tab_label}/>}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <FilterByActivities
          events={props.events}
          startDate={props.startDate}
          endDate={props.endDate}
          onChange={props.onChange}
          dpBtnText={props.dpBtnText}
          // onClickSearch={props.onClickSearch}
          filterBy={FILTER_BY_ACTIVITIES}
          fromLocalPref={props.fromLocalPref}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <FilterByBikes
          bikesFromDb={props.bikesFromDb}
          bikeIds = {props.bikeIds}
          startDate={props.startDate}
          endDate={props.endDate}
          onChange={props.onChange}
          dpBtnText={props.dpBtnText}
          // onClickSearch={props.onClickSearch}
          filterBy={FILTER_BY_BIKEIDS}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <FilterBySites
          sitesFromDb={props.sitesFromDb}
          siteIds={props.siteIds}
          startDate={props.startDate}
          endDate={props.endDate}
          onChange={props.onChange}
          dpBtnText={props.dpBtnText}
          // onClickSearch={props.onClickSearch}
          filterBy={FILTER_BY_SITEIDS}
        />
      </TabPanel>
    </div>
  );
}
