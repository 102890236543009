import React from "react";
import { Grid } from "@mui/material";
import Typography from "@material-ui/core/Typography";
import Divider from '@material-ui/core/Divider';
import moment from 'moment-timezone';
export default function ItsMyBikeGeneralV2 ({item,id}){
  let serialnumber = JSON.parse(item?.tracker_json)?.serialnumber;
  return (
    <Grid container spacing={3}>
    <Grid item xs={12}>
      
      <Typography gutterBottom variant="h6" component="h2">
      It's My Bike Informations
      </Typography>
      <Divider />
    </Grid>
    <Grid item xs={6}>
      <Typography gutterBottom variant="body1" component="h2">
        Tracker ID:
      </Typography>
    </Grid>
    <Grid item xs={6}>
      <Typography gutterBottom variant="body1" component="h2">
        
      <a href={`https://web.itsmybike.cloud/device/${serialnumber}`} target="_blank">{serialnumber}</a>
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
        Latitude:
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
      {item?.tracker_position?.lat}
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
        Longitude:
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
      {item?.tracker_position?.lon}
      </Typography>
    </Grid>

    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
        Last Updated On:
      </Typography>
    </Grid>
    <Grid item xs={6} style={{ "padding-top": 0 }}>
      <Typography gutterBottom variant="body1" component="h2">
      {moment(item?.tracker_position?.sampledAt).tz('Europe/Berlin').format('DD/MM/YYYY HH:mm:ss')}
      </Typography>
    </Grid>


  </Grid>
  );
}