export default {
  wrapper: {
    width:'1100px'
  },
  wrapperRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  wrapperRight: {
    position: 'relative',
    flex: 1,
    alignSelf: 'stretch',
  },
  wrapperLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: 20,
  },
};
