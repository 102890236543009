import client from '../client';

const getUserGroups = () => client.get('/user-groups');

const getUserGroup = (id) => client.get(`/user-group/${id}`);

const addUserGroup = (data) => client.post('/user-groups/new', data);

const updateUserGroup = (id, data) => client.put(`/user-group/${id}`, data);

const deleteUserGroup = id => client.delete(`/user-group/${id}`);

const addSiteToUserGroup = (siteId, userGroupId) => client.post(`/user-groups/${userGroupId}/sites/add/${siteId}`);

const removeSiteFromUserGroup = (siteId, userGroupId) => client.delete(`/user-groups/${userGroupId}/sites/remove/${siteId}`);

export {
  getUserGroups,
  getUserGroup,
  addUserGroup,
  updateUserGroup,
  deleteUserGroup,
  addSiteToUserGroup,
  removeSiteFromUserGroup
};
