import React from 'react';
import { createUseStyles } from 'react-jss';
import AppFormGap from 'components/AppFormGap';
import PileInfo from '../PileInfo';
import styles from './styles';

const useStyles = createUseStyles(styles);

const PileInfoGeneral = ({ item }) => {
  const classes = useStyles();
  
  return !!item.piles.length && (
    <div className={classes.wrapper}>
      <div className={classes.label}>
        Piles
      </div>
      <AppFormGap />
      <div className={classes.wrapperItems}>
        {item.piles.map(p => (
          <React.Fragment key={p.pile_id}>
            <PileInfo
              id={p.pile_id}
              siteId={item.id}
              bikeId={p.bike_id}
              licensePlate={p.bike_license_plate}
              status={p.status}
            />
            <AppFormGap />
          </React.Fragment>
        ))}
      </div>
    </div>
  )
};

export default PileInfoGeneral;
