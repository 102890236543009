import CellStatus from "components/Cells/CellStatus";
import TableUncontrolled from "components/List/TableUncontrolled";
import React from "react";
import { usePermissions } from "react-admin";
import { createUseStyles } from "react-jss";
import { LOCK_MODAL } from "utils/constants/modals";
import { getFieldsFrom } from "utils/helpers/list";
import { FILTER_SORT_MAP, LABEL_MAP } from "../../utils/constants";
import CellId from "../CellId";
import CellSimCard from "../CellSimCard";
import CellVehicle from "../CellVehicle";
import styles from "./styles";

const useStyles = createUseStyles(styles);

const ListLocks = ({ items, onView, onDelete }) => {
  const classes = useStyles();
  const { permissions } = usePermissions();
  const fields = React.useMemo(
    () => getFieldsFrom(items, FILTER_SORT_MAP, LABEL_MAP),
    [items]
  );
  const columns = React.useMemo(
    () => [
      {
        accessor: "id",
        Cell: ({ row }) => <CellId row={row} onClick={onView} />,
      },
      {
        accessor: "status",
        disableGlobalFilter: true,
        Cell: ({ row }) => <CellStatus type={row.original.status} />,
      },
      {
        accessor: "bike",
        disableGlobalFilter: true,
        Cell: ({ row }) => <CellVehicle row={row} />,
      },
      {
        accessor: "sim_card",
        disableGlobalFilter: true,
        Cell: ({ row }) => <CellSimCard row={row} />,
      },
    ],
    []
  );

  return (
    <TableUncontrolled
      isVisibleSort
      isVisibleFilter
      createCaption="Add Lock"
      columns={columns}
      caption="All Locks"
      entityType="locks"
      totalCount={items.length}
      onDelete={onDelete}
      onView={onView}
      modalType={LOCK_MODAL}
      fields={fields}
      rowsPerPage={20}
      data={items}
      isRemovable={permissions && permissions.indexOf("delete-locks") > -1}
      isCreatable={permissions && permissions.indexOf("create-locks") > -1}
      isViewable={permissions && permissions.indexOf("view-locks") > -1}
      isEditable={permissions && permissions.indexOf("update-locks") > -1}
    />
  );
};

export default ListLocks;
