const FILTER_SORT_MAP = {
  id: {
    isSortable: false,
    isFilterable: false,
  }
};

const LABEL_MAP = {
  discount_details: {
    label: "Name",
  }
};

const STATUS = [
  { id: 'ACTIVE', name: 'ACTIVE' },
  { id: 'INACTIVE', name: 'INACTIVE' }
];

export { FILTER_SORT_MAP, LABEL_MAP, STATUS };
