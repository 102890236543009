import client from '../client';

const getAppConfig  = () =>
  client.get('/app-config');

const updateAppConfig  = data =>
  client.put('/app-config', data);

export {
  getAppConfig,
  updateAppConfig,
};
