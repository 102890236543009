import React from 'react';
import { createUseStyles } from 'react-jss';
import CellRouter from 'components/Cells/CellRouter';
import styles from './styles';

const useStyles = createUseStyles(styles);

const CellSerialNumber = ({ row }) => {
  const classes = useStyles();
  
  return (
    <CellRouter
      withIcon
      id={row.original.id}
      status={row.original.status}
    >
      {row.original.serial_number}
    </CellRouter>
  );
};

export default CellSerialNumber;
