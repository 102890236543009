import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { createUseStyles } from "react-jss";
import CellStatus from "components/Cells/CellStatus";
import BatteryIcon from "components/BatteryIcon";
import BikeInfo from "components/Info/BikeInfo";
import AppFormGap from "components/AppFormGap";
import styles from "./styles";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
const useStyles = createUseStyles(styles);

const BikeInfoGeneral = ({ item, id, hideBikeInfo = false, onClose }) => {
  const classes = useStyles();
  const [copied, setCopied] = useState(false);
  const handleCopyViewLink = async () => {
    let baseUrl = window.location.origin;
    let viewUrl = `${baseUrl}/#/vehicles/view/${id}`;
    await navigator.clipboard.writeText(viewUrl);
    setCopied(true);
  };

  return (
    <div className={classes.wrapper}>
      {hideBikeInfo === true && (
        <div className={[classes.wrapperHeader, classes.wrapperInfo].join(" ")}>
          <div className={classes.container}>
            <div className={[classes.licensePlate, classes.marginRight10].join(" ")}>{item.license_plate}</div>
            <CellStatus type={item.status} className={classes.marginRight10} />
            {item?.tracker_json && item.tracker_json == null && <CellStatus type={"NO TRACKER"} />}
            {item?.battery && item?.battery?.id && !!item.battery && (
              <div className={[classes.wrapperBattery, classes.marginRight10].join(" ")}>
                <div className={classes.icon}>
                  <BatteryIcon level={item.battery.energy_level} />
                </div>
                <div className={classes.descriptionBattery}>
                  <div className={classes.label}>BATTERY</div>
                  <div className={classes.level}>
                    {Math.floor(item.battery.energy_level)}%
                  </div>
                </div>
              </div>
            )}
            {item?.tracker_battery && !!item.tracker_json && (
              <div className={[classes.wrapperBattery, classes.marginRight10].join(" ")}>
                <div className={classes.icon}>
                  <BatteryIcon level={item.tracker_battery} />
                </div>
                <div className={classes.descriptionBattery}>
                  <div className={classes.label}>TRACKER BATTERY</div>
                  <div className={classes.level}>
                    {Math.floor(item.tracker_battery)}%
                  </div>
                </div>
              </div>
            )}
            {item?.id && !!id && (
              <div
                onClick={handleCopyViewLink}
                className={copied ? classes.copiedIcon : classes.copyIcon}
              >
                <FilterNoneIcon titleAccess="Copy View Link" />
                <div className={classes.copyLinkText}>
                  {copied ? "View Link Copied!" : ""}
                </div>
              </div>
            )}
          </div>
          <div>
            <CloseIcon
              onClick={() => onClose()}
              className={classes.closeIcon}
            />
          </div>
        </div>
      )}

      <AppFormGap />
      {item?.tracker_position && item.tracker_position == null && (
        <span className={classes.redText}>
          No Position Details Available from Its My Bike
        </span>
      )}
      <AppFormGap />
      {hideBikeInfo === false && (
        <div className={classes.wrapperInfo}>
          <BikeInfo
            vehicleType={item?.vehicle_type?.id}
            hardwareId={item?.hardware_id}
            wunderId={item?.mobile_id}
          />
        </div>
      )}
    </div>
  );
};

export default BikeInfoGeneral;
