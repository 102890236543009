import React from 'react';
import { createUseStyles } from 'react-jss';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import FilterIcon from 'components/Icons/FilterIcon';
import styles from './styles';

const useStyles = createUseStyles(styles);

const IconFilter = ({
  filters,
  onClick,
}) => {
  const classes = useStyles();
  const count = React.useMemo(() => (
    filters.filter(f => !!f.value.id).length
  ), [ filters ]);
  const classesBadge = React.useMemo(() => ({
    badge: classes.badge
  }), []);

  return (
    <Button
      aria-controls="fade-menu"
      aria-haspopup="true"
      onClick={onClick}
    >
      <Badge
        badgeContent={count}
        classes={classesBadge}
        color="primary"
      >
        <FilterIcon />
      </Badge>
    </Button>
  );

};

export default IconFilter;
