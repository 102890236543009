import React from 'react';
import { createUseStyles } from 'react-jss';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-final-form';
import AppFormGap from 'components/AppFormGap';
import TextInputForm from 'components/Inputs/TextInputForm';
import CellStatus from 'components/Cells/CellStatus';
import { isViewable } from 'utils/helpers/form';
import { requiredValidation } from 'utils/helpers/validations';
import styles from './styles';

const useStyles = createUseStyles(styles);

const FormContentViewable = ({
  operation,
  item,
}) => {
  const classes = useStyles();
  const form = useForm();
  
  React.useEffect(() => {
    if (!!item.id) {
      form.reset(item);
    }
  }, [ item ]);
  
  return (
    <div className={classes.wrapper}>
      <Typography variant="h5">
        User Group
      </Typography>
      <AppFormGap />
      <TextInputForm
        name="name"
        label="Name"
        disabled
      />
      <AppFormGap />
      <TextInputForm
        name="wunder_promotion_code_id"
        label="Wunder Promotion Code Id"
        disabled
      />
    </div>
  )
};

export default FormContentViewable;
