import React from 'react';
import { createUseStyles } from 'react-jss';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmationModal from 'components/Modals/ConfirmationModal';
import styles from './styles';

const useStyles = createUseStyles(styles);

const ButtonDelete = ({
  onClick,
  subModal,
  ids,
}) => {
  const classes = useStyles();
  const [ modalData, setModalData ] = React.useState(null);
  const handleRejectConfirmation = React.useCallback(() => {
    setModalData(null);
  }, []);
  const handleDelete = React.useCallback(() => {
    setModalData({
      title: 'Items',
      body: `Are you sure to delete ${Object.keys(ids).length} item(s)?`,
      onApprove: () => {
        onClick(Object.keys(ids));
        setModalData(null);
      },
    });
  }, [ ids ]);

  return (
    <div className={classes.wrapper}>
      <IconButton
        color="error"
        onClick={handleDelete}
        component="span"
      >
        <DeleteIcon />
      </IconButton>
      {!!modalData && <ConfirmationModal
        {...modalData}
        subModal={subModal}
        onReject={handleRejectConfirmation}
      />}
    </div>
  )
};

export default ButtonDelete;
