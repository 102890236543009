import Typography from "@material-ui/core/Typography";
import AppFormGap from "components/AppFormGap";
import AppMultiSelectForm from "components/Inputs/AppMultiSelectForm";
import TextInputForm from "components/Inputs/TextInputForm";
import React, { useEffect } from "react";
import { useForm } from "react-final-form";
import { createUseStyles } from "react-jss";
import { useOptionsItem } from "utils/hooks/site";
import { useFetchItem } from "utils/hooks/stationGroups";
import styles from "./styles";

const useStyles = createUseStyles(styles);

const StationGroup = ({ id, disabled }) => {
  const classes = useStyles();
  const form = useForm();
  const { item, fetch: fetchItem } = useFetchItem(id);
  const { options, fetchStationOptions } = useOptionsItem();

  useEffect(() => {
    if (id !== "-1") {
      fetchItem();
    }
    fetchStationOptions();
  }, []);

  useEffect(() => {
    if (!!item.stationGroupId) {
      form.reset(item);
    }
  }, [item]);

  return (
    <div className={classes.wrapper}>
      <Typography variant="h5">Station Group</Typography>
      <AppFormGap />
      <TextInputForm name="name" label="Name" disabled={disabled} />
      <AppFormGap />
      <AppMultiSelectForm
        name="stationIds"
        label="Stations"
        options={options}
        initialValue={[]}
        disabled={disabled}
        renderValue={(selected) => selected.join(", ")}
      />
    </div>
  );
};

export default StationGroup;
