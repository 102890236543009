import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { useForm, useFormState } from "react-final-form";
import BikeLocation from "./components/BikeLocation";
import styles from "./styles";
import { usePermissions } from "react-admin";
import Forbidden from "pages/Forbidden";
import Container from "@material-ui/core/Container";
import { Grid } from "@mui/material";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import BikeDetailsTabs from "./components/BikeDetailsTabs";
import BikeCardInfoV2 from "./components/BikeCardInfoV2";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useDelete } from "utils/hooks/vehicle";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import { getConfirmationParams } from "./utils/helpers";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
const useStyles = createUseStyles(styles);

const FormContentViewable = ({ item, id, entity }) => {
  // console.log(item);
  const classes = useStyles();
  const form = useForm();
  const formState = useFormState();
  const { permissions } = usePermissions();
  const dispatch = useDispatch();
  const history = useHistory();
  const { deleteItems } = useDelete();
  const [copied, setCopied] = useState(false);
  const [modalData, setModalData] = React.useState(null);
  const handleRejectConfirmation = React.useCallback(() => {
    setModalData(null);
  }, []);
  const handleDelete = React.useCallback(() => {
    setModalData({
      ...getConfirmationParams({ entity: entity, action: "DELETE" }),
      onApprove: () => {
        deleteItems([id]).then(() => {
          history.push(`/vehicles`);
          setModalData(null);
        });
      },
    });
  }, [entity, formState, false]);

  const handleLogView = React.useCallback(() => {
    history.push(`/log-viewer/bike/${id}`);
  }, [id]);

  const handleEdit = React.useCallback(() => {
    history.push(`/vehicles/edit/${id}`);
  }, [id]);

  const handleCopyViewLink = async () => {
    let baseUrl = window.location.origin;
    let viewUrl = `${baseUrl}/#/vehicles/view/${id}`;
    await navigator.clipboard.writeText(viewUrl);
    setCopied(true);
  };

  React.useEffect(() => {
    if (!!item.id) {
      form.reset(item);
    }
  }, [item]);

  return permissions && permissions.indexOf("view-vehicles") > -1 ? (
    <div className={classes.wrapper}>
      {!!modalData && (
        <ConfirmationModal {...modalData} onReject={handleRejectConfirmation} />
      )}
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Card className={classes.root}>
              <CardContent>
                <div className={classes.bikeInfoMap}>
                  <BikeLocation item={item} />
                </div>
                <div>
                  <div className={classes.bikeNameAndLogo}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {item.license_plate}
                    </Typography>
                    {!!id && (
                      <div
                        onClick={handleCopyViewLink}
                        className={
                          copied ? classes.copiedIcon : classes.copyIcon
                        }
                      >
                        <FilterNoneIcon titleAccess="Copy View Link" />
                        <div className={classes.copyLinkText}>
                          {copied ? "View Link Copied!" : ""}
                        </div>
                      </div>
                    )}
                  </div>
                  <BikeCardInfoV2 item={item} id={id} />
                </div>
              </CardContent>
              <CardActions>
                <Grid container spacing={1}>
                  {/* <Grid item xs={4}>
                    {permissions &&
                    permissions.indexOf("view-log-viewer") > -1 ? (
                      <Button
                        variant="contained"
                        className={classes.cardActionBtn}
                        onClick={handleLogView}
                      >Log Viewer</Button>
                    ) : (
                      <></>
                    )}
                  </Grid> */}
                  <Grid item xs={6}>
                    {permissions &&
                    permissions.indexOf("update-vehicles") > -1 ? (
                      <Button
                        variant="contained"
                        className={classes.cardActionBtn}
                        onClick={handleEdit}
                      ><EditIcon/>
                        Edit
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    {permissions &&
                    permissions.indexOf("delete-vehicles") > -1 ? (
                      <Button
                        variant="contained"
                        className={classes.cardActionBtn}
                        onClick={handleDelete}
                      > <DeleteIcon/>
                        Delete
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={8}>
            <BikeDetailsTabs item={item} id={id} />
          </Grid>
        </Grid>
      </Container>
    </div>
  ) : (
    <div className={classes.wrapper}>
      <Forbidden />
    </div>
  );
};

export default FormContentViewable;
