import {
  getAppConfig,
  updateAppConfig,
} from 'utils/helpers/request/api/appConfig';
import { useFetchItemWrapper } from 'utils/hooks/fetch';
import { useUpdateWrapper } from 'utils/hooks/update';
import { parseFromBackend } from './helpers';

const useFetch = id => useFetchItemWrapper(getAppConfig, id, {
  id: null,
  mobile_base_url_api: null,
  mobile_webhook_key: null,
  mobile_hardware_key: null,
  mobile_api_key: null,
  api_key: null,
  site_queue_url: null,
  bike_queue_url: null,
  connector_queue_url: null,
}, parseFromBackend);

const useUpdate = () => useUpdateWrapper(updateAppConfig)

export {
  useFetch,
  useUpdate,
};
