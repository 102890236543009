import React from 'react';
import { createUseStyles } from 'react-jss';
import { useField } from 'react-final-form';
import AppSelect from 'components/Inputs/AppSelect';
import styles from './styles';

const useStyles = createUseStyles(styles);

const AppSelectForm = ({
  name,
  label,
  width,
  disabled,
  initialValue,
  isNullable = true,
  classnameWrapper,
  options = [],
}) => {
  const classes = useStyles();
  const {
    input: { onChange, value },
    meta: { touched, error }
  } = useField(name, { initialValue });
  const handleChange = React.useCallback(e => {
    onChange(e.target.value);
  }, [ ]);

  return (
    <AppSelect
      value={value}
      label={label}
      disabled={disabled}
      width={width}
      options={options}
      isNullable={isNullable}
      classnameWrapper={classnameWrapper}
      onChange={handleChange}
      error={touched && error}
    />
  );
};

export default AppSelectForm;
