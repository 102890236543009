import React from 'react';
import {
  addVehicleType,
  getVehicleTypes,
  deleteVehicleType,
} from 'utils/helpers/request/api/bikes';
import { useFetchItemsWrapper } from 'utils/hooks/fetch';
import { useDeleteItemsWrapper } from 'utils/hooks/delete';
import { useCreateWrapper } from 'utils/hooks/create';

const useFetch = () => useFetchItemsWrapper(getVehicleTypes);

const useDeleteItems = () => useDeleteItemsWrapper(deleteVehicleType);

const useCreateItem = () => useCreateWrapper(addVehicleType);

export {
  useFetch,
  useCreateItem,
  useDeleteItems,
};
