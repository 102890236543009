import React from "react";
import { createUseStyles } from "react-jss";
import { usePermissions } from "react-admin";
import { useForm } from "react-final-form";
import TextInputForm from "components/Inputs/TextInputForm";
import AppFormGap from "components/AppFormGap";
import AppSelectForm from "components/Inputs/AppSelectForm";
import { STATUSES } from "utils/constants/battery/statuses";
import styles from "./styles";
import Forbidden from "pages/Forbidden";

const useStyles = createUseStyles(styles);

const FormContentViewable = ({ item }) => {
  const classes = useStyles();
  const form = useForm();
  const { permissions } = usePermissions();

  React.useEffect(() => {
    if (!!item.id) {
      form.reset(item);
    }
  }, [item]);

  return permissions && permissions.indexOf("view-batteries") > -1 ? (
    <div className={classes.wrapper}>
      <TextInputForm name="id" label="Id" disabled />
      <AppFormGap />
      <AppSelectForm name="status" label="Status" disabled options={STATUSES} />
      <AppFormGap />
      <TextInputForm name="bike.energy_level" label="Charging level" disabled />
    </div>
  ) : (
    <div className={classes.wrapper}>
      <Forbidden />
    </div>
  );
};

export default FormContentViewable;
