import { createMuiTheme } from "@material-ui/core/styles";
import PageLayout from "components/PageLayout";
import SigoLayout from "components/SigoLayout";
import Batteries from "pages/Batteries";
import Cities from "pages/Cities";
import Connectivity from "pages/Connectivity";
import DiscountManagement from "pages/DiscountManagement";
import Home from "pages/Home";
import Lock from "pages/Lock";
import Locks from "pages/Locks";
import LoginPage from "pages/Login";
import NotFound from "pages/NotFound";
import Partners from "pages/Partners";
import LogViewer from "pages/LogViewer";
import AdminActivities from "pages/AdminActivities";
import Piles from "pages/Piles";
import PileTypes from "pages/PileTypes";
import Roles from "pages/Roles";
import Router from "pages/Router";
import Routers from "pages/Routers";
import SimCard from "pages/SimCard";
import SimCards from "pages/SimCards";
import Site from "pages/Site";
import Sites from "pages/Sites";
import StationGroups from "pages/StationGroups";
import UI from "pages/UI";
import UserGroup from "pages/UserGroup";
import UserGroups from "pages/UserGroups";
import Users from "pages/Users";
import Vehicle from "pages/Vehicle";
import Vehicles from "pages/Vehicles";
import VehicleTypes from "pages/VehicleTypes";
import authProvider from "providers/authProvider";
import dataProvider from "providers/dataProvider";
import * as React from "react";
import { Admin, Authenticated, Resource } from "react-admin";
import injectSheet from "react-jss";
import { Redirect, Route } from "react-router-dom";
import commonReducer from "store/reducers/common";
import { COLOR_DEFAULT_TEXT } from "utils/constants/colors";
import FirmwareMgmt from "./pages/FirmwareMgmt";
import BulkOTASite from "./pages/BulkOTASite";
import BulkOTATrack from "./pages/BulkOTATrack";
import SwuUser from "pages/SwuUser";
const styles = {
  "@global": {
    "#root": {
      color: COLOR_DEFAULT_TEXT,
    },
  },
};

const customRoutes = [
  <Route
    key="home"
    path="/home"
    render={(props) => (
      <Authenticated>
        <PageLayout page="home">
          <Home {...props} />
        </PageLayout>
      </Authenticated>
    )}
  />,
  <Route
    key="vehicle"
    path="/vehicles/:operation/:id?"
    render={(props) => (
      <Authenticated>
        <PageLayout page="vehicles">
          <Vehicle {...props} />
        </PageLayout>
      </Authenticated>
    )}
  />,
  <Route
    key="vehicles"
    path="/vehicles"
    render={(props) => (
      <Authenticated>
        <PageLayout page="vehicles">
          <Vehicles {...props} />
        </PageLayout>
      </Authenticated>
    )}
  />,
  <Route
    key="ui"
    path="/ui"
    render={(props) => (
      <Authenticated>
        <PageLayout page="ui">
          <UI {...props} />
        </PageLayout>
      </Authenticated>
    )}
  />,
  <Route
    key="sitesOperationId"
    path="/sites/:operation/:id?"
    render={(props) => (
      <Authenticated>
        <PageLayout page="sites">
          <Site {...props} />
        </PageLayout>
      </Authenticated>
    )}
  />,
  <Route
    key="batteries"
    path="/batteries"
    render={(props) => (
      <Authenticated>
        <PageLayout page="batteries">
          <Batteries {...props} />
        </PageLayout>
      </Authenticated>
    )}
  />,
  <Route
    key="sites"
    path="/sites"
    render={(props) => (
      <Authenticated>
        <PageLayout page="sites">
          <Sites {...props} />
        </PageLayout>
      </Authenticated>
    )}
  />,
  <Route
    key="lock"
    path="/locks/:operation/:id?"
    render={(props) => (
      <Authenticated>
        <PageLayout page="locks">
          <Lock {...props} />
        </PageLayout>
      </Authenticated>
    )}
  />,
  <Route
    key="locks"
    path="/locks"
    render={(props) => (
      <Authenticated>
        <PageLayout page="locks">
          <Locks {...props} />
        </PageLayout>
      </Authenticated>
    )}
  />,
  <Route
    key="cities"
    path="/cities"
    render={(props) => (
      <Authenticated>
        <PageLayout page="cities">
          <Cities {...props} />
        </PageLayout>
      </Authenticated>
    )}
  />,
  <Route
    key="routersOperationId"
    path="/routers/:operation/:id?"
    render={(props) => (
      <Authenticated>
        <PageLayout page="routers">
          <Router {...props} />
        </PageLayout>
      </Authenticated>
    )}
  />,
  <Route
    key="routers"
    path="/routers"
    render={(props) => (
      <Authenticated>
        <PageLayout page="routers">
          <Routers {...props} />
        </PageLayout>
      </Authenticated>
    )}
  />,
  <Route
    key="simCard"
    path="/sim-cards/:operation/:id"
    render={(props) => (
      <Authenticated>
        <PageLayout page="routers">
          <SimCard {...props} />
        </PageLayout>
      </Authenticated>
    )}
  />,
  <Route
    key="simCards"
    path="/sim-cards"
    render={(props) => (
      <Authenticated>
        <PageLayout page="sim-cards">
          <SimCards {...props} />
        </PageLayout>
      </Authenticated>
    )}
  />,
  <Route
    key="vehicleTypes"
    path="/vehicle-types"
    render={(props) => (
      <Authenticated>
        <PageLayout page="vehicle-types">
          <VehicleTypes {...props} />
        </PageLayout>
      </Authenticated>
    )}
  />,
  <Route
    key="piles"
    path="/piles"
    render={(props) => (
      <Authenticated>
        <PageLayout page="piles">
          <Piles {...props} />
        </PageLayout>
      </Authenticated>
    )}
  />,
  <Route
    key="pileTypes"
    path="/pile-types"
    render={(props) => (
      <Authenticated>
        <PageLayout page="pile-types">
          <PileTypes {...props} />
        </PageLayout>
      </Authenticated>
    )}
  />,
  <Route
    key="connectivity"
    path="/connectivity"
    render={(props) => (
      <Authenticated>
        <PageLayout page="connectivity">
          <Connectivity {...props} />
        </PageLayout>
      </Authenticated>
    )}
  />,
  <Route
    key="userGroupsOperationId"
    path="/user-groups/:operation/:id?"
    render={(props) => (
      <Authenticated>
        <PageLayout page="user-groups">
          <UserGroup {...props} />
        </PageLayout>
      </Authenticated>
    )}
  />,
  <Route
    key="userGroups"
    path="/user-groups"
    render={(props) => (
      <Authenticated>
        <PageLayout page="user-groups">
          <UserGroups {...props} />
        </PageLayout>
      </Authenticated>
    )}
  />,
  <Route
    key="discountManagement"
    path="/discount-management"
    render={(props) => (
      <Authenticated>
        <PageLayout page="discount-management">
          <DiscountManagement {...props} />
        </PageLayout>
      </Authenticated>
    )}
  />,
  <Route
    key="stationGroups"
    path="/station-groups"
    render={(props) => (
      <Authenticated>
        <PageLayout page="station-groups">
          <StationGroups {...props} />
        </PageLayout>
      </Authenticated>
    )}
  />,
  <Route
    key="roles"
    path="/roles"
    render={(props) => (
      <Authenticated>
        <PageLayout page="roles">
          <Roles {...props} />
        </PageLayout>
      </Authenticated>
    )}
  />,
  <Route
    key="users"
    path="/users"
    render={(props) => (
      <Authenticated>
        <PageLayout page="users">
          <Users {...props} />
        </PageLayout>
      </Authenticated>
    )}
  />,
  <Route
    key="partners"
    path="/partners"
    render={(props) => (
      <Authenticated>
        <PageLayout page="partners">
          <Partners {...props} />
        </PageLayout>
      </Authenticated>
    )}
  />,
  <Route
    key="log-viewer"
    path="/log-viewer/:ctgr?/:id?"
    render={(props) => (
      <Authenticated>
        <PageLayout page="log-viewer">
          <LogViewer {...props} />
        </PageLayout>
      </Authenticated>
    )}
  />,
  <Route
  key="admin-activities"
  path="/admin-activities"
  render={(props) => (
    <Authenticated>
      <PageLayout page="admin-activities">
        <AdminActivities {...props} />
      </PageLayout>
    </Authenticated>
  )}
/>,
  <Route 
    key="firmware-mgmt"
    path="/firmware-mgmt"
    render={(props)=>(
      <Authenticated>
        <PageLayout page="firmware-mgmt">
          <FirmwareMgmt {...props} />
        </PageLayout>
      </Authenticated>
    )}
    />,
    <Route 
    key="bulk-ota-site"
    path="/bulk-ota-site"
    render={(props)=>(
      <Authenticated>
        <PageLayout page="bulk-ota-site">
          <BulkOTASite {...props} />
        </PageLayout>
      </Authenticated>
    )}
    />,
    <Route 
    key="bulk-ota-track"
    path="/bulk-ota-track"
    render={(props)=>(
      <Authenticated>
        <PageLayout page="bulk-ota-track">
          <BulkOTATrack {...props} />
        </PageLayout>
      </Authenticated>
    )}
    />,
  <Route
    key="notFound"
    path="/not-found"
    render={(props) => (
      <Authenticated>
        <PageLayout>
          <NotFound {...props} />
        </PageLayout>
      </Authenticated>
    )}
  />,
  <Route
    key="swu-user"
    path="/swu-user"
    render={(props) => (
      <SwuUser/>
    )}
  />,
  <Redirect key="defaultAppRoute" from="/" to="home" />,
];

const customReducers = {
  commonReducer,
};

const theme = createMuiTheme({
  typography: {
    fontFamily: ["Lato", "-apple-system", "Arial", "sans-serif"].join(","),
    h5: {
      fontWeight: "bold",
    },
  },
  palette: {
    primary: {
      light: "#7ef1c1",
      main: "#00cc77",
      dark: "#97c692",
      contrastText: "#fff",
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    text: {
      primary: COLOR_DEFAULT_TEXT,
    },
  },
});

const App = () => (
  <Admin
    theme={theme}
    layout={SigoLayout}
    dataProvider={dataProvider}
    customRoutes={customRoutes}
    customReducers={customReducers}
    authProvider={authProvider}
    loginPage={LoginPage}
  >
    <Resource name="" />
  </Admin>
);

export default injectSheet(styles)(App);
