import React from 'react';
import { createUseStyles } from 'react-jss';
import TableUncontrolled from 'components/List/TableUncontrolled';
import { getFieldsFrom } from 'utils/helpers/list';
import {
  FILTER_SORT_MAP,
  LABEL_MAP,
} from '../../utils/constants';
import CellName from '../CellName';
import styles from './styles';

const useStyles = createUseStyles(styles);

const ListCities = ({
  items,
  onDelete,
  onCreate,
  onEdit,
}) => {
  const classes = useStyles();
  const fields = React.useMemo(() => getFieldsFrom(items, FILTER_SORT_MAP, LABEL_MAP), [ items ]);
  const columns = React.useMemo(
    () => [
      {
        accessor: 'name',
        Cell: ({ row }) => (
          <CellName
            row={row}
            onClick={onEdit}
          />
        ),
      },
    ],
    [  ],
  );

  return (
    <TableUncontrolled
      isVisibleSort
      isEditable={false}
      isViewable={false}
      columns={columns}
      createCaption="Add City"
      caption="All Cities"
      entityType="cities"
      totalCount={items.length}
      onDelete={onDelete}
      onCreate={onCreate}
      fields={fields}
      rowsPerPage={20}
      data={items}
    />
  );
};

export default ListCities;
