import { COLOR_DEFAULT_TEXT } from 'utils/constants/colors';

const baseStyles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center'

}

export default {
  wrapper: baseStyles,
  wrapperInfo: {
    ...baseStyles,
    flex: 1,
    color: COLOR_DEFAULT_TEXT,
    fontSize: 28,
  },
  wrapperLabel: baseStyles,
  wrapperActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flex: 1,
    '& > button': {
      marginRight: 15,
    },
    '& > button:last-child': {
      marginRight: 15,
    },
  },
  name: {
    fontSize: 20,
    marginRight: 15,
  },
  indicator: {
    width: 15,
    height: 15,
    borderRadius: 15,
    // backgroundColor: ({ color }) => color,
  },
};
