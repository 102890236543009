const FILTER_SORT_MAP = {
  id: {
    isSortable: false,
    isFilterable: false,
  },
  status: {
    isSortable: false,
    isFilterable: true,
  },
  serial_number: {
    isSortable: true,
    isFilterable: false,
  },
  mac_address: {
    isSortable: false,
    isFilterable: false,
  },
  site: {
    isSortable: false,
    isFilterable: false,
  },
  sim_card: {
    isSortable: false,
    isFilterable: false,
  },
};

const LABEL_MAP = {
  status: {
    label: 'Status',
  },
  serial_number: {
    label: 'Serial number',
  },
};

export {
  FILTER_SORT_MAP,
  LABEL_MAP,
};
