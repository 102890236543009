import React from 'react';
import { useDispatch } from 'react-redux';
import { usePermissions } from 'react-admin';
import { useDelete } from 'utils/hooks/battery';
import CRUModal from 'components/Modals/CRUModal';
import { setModalData } from 'store/actions/common';
import { useFetchItem } from 'utils/hooks/battery';
import FormContentCreatable from './components/FormContentCreatable';
import FormContentViewable from './components/FormContentViewable';

const BatteryModal = ({
  id,
  onCreate,
  fetchItems,
  modalInitialData,
}) => {
  const dispatch = useDispatch();
  const { deleteItems } = useDelete();
  const { item, fetch } = useFetchItem(id);
  const { permissions } = usePermissions();
  const title = React.useMemo(() => onCreate ? 'Add Battery' : 'View Battery', []);
  const onClose = React.useCallback(() => {
    dispatch(setModalData());
  }, []);
  const handleDelete = React.useCallback(() => {
    deleteItems([ id ])
      .finally(() => {
        fetchItems();
        dispatch(setModalData());
      });
  }, []);
  
  React.useEffect(() => {
    if (id) {
      fetch();
    }
  }, [ id ]);
  
  return (
    <CRUModal
      title={title}
      entity="Battery"
      initialValues={modalInitialData}
      onDelete={permissions && permissions.indexOf('delete-batteries') > -1 ? handleDelete : false}
      onCreate={onCreate}
      onClose={onClose}
    >
      {onCreate ? <FormContentCreatable item={item} /> : <FormContentViewable item={item} />}
    </CRUModal>
  );
};

export default BatteryModal;
