import { CELL_LINK } from 'utils/constants/styles';
import { getColorForStatus } from 'utils/helpers/colorGetters';

export default {
  wrapper: {
    ...CELL_LINK,
    cursor: 'pointer',
  },
  wrapperIcon: {
    color: ({ status }) => getColorForStatus(status),
  },
};
