import CustomDatePicker from "../CustomDatePicker";
/* eslint-disable no-use-before-define */
import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
  bar: {
    display: "flex",
    alignContent: "center",
    justifyContent: "space-between",
    marginTop: "10px",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function FilterBySites(props) {
  const classes = useStyles();
  const [selectedSites, setSelectedSites] = React.useState(props.siteIds);
  const siteList = props.sitesFromDb.items;
  const handleChange = (event, value) => {
    setSelectedSites(value);
    let siteList = [];
    value.forEach((site) => {
      siteList.push(site.site_id);
    });
    props.onChange({ siteIds: siteList ,bikeIds:[]});
    // console.log(event.target.value, value);
  };
  return (
    <div className={classes.bar}>
      <div className={classes.root}>
        <Autocomplete
          multiple
          id="tags-standard"
          options={siteList}
          getOptionLabel={(option) => option.address}
          // defaultValue={[bikeList[0]]}
          // value={selectedBikes}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Select Site"
              placeholder="Sites"
            />
          )}
        />
      </div>
      <div>
        <CustomDatePicker
          startDate={props.startDate}
          endDate={props.endDate}
          onChange={props.onChange}
          dpBtnText={props.dpBtnText}
          onClickSearch={props.onClickSearch}
          filterBy={props.filterBy}
        />
      </div>
    </div>
  );
}
