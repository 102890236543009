export default {
  wrapper: {},
  classnameWrapper1: {
    minWidth: '100% !important',
  },
  gap: {
    width: '100%',
    height: 20,
  },
};
