import client from '../client';

const addBattery = data =>
  client.post('/battery', data);

const getBatteries = () =>
  client.get('/batteries');

const getBattery = id =>
  client.get(`/batteries/${id}`);

const deleteBattery = id =>
  client.delete(`/batteries/${id}`);

const updateBattery = data =>
  client.put(`/batteries/${data.id}`, data);

export {
  addBattery,
  getBatteries,
  getBattery,
  deleteBattery,
  updateBattery,
};
