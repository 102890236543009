import CRUModal from "components/Modals/CRUModal";
import React from "react";
import { usePermissions } from "react-admin";
import Role from "../Role";

const RolesModal = ({
  onClose,
  onDelete,
  onUpdate,
  disabled,
  onCreate,
  id,
  title,
  onChangePermissions,
}) => {
  const { permissions } = usePermissions();
  return (
    <CRUModal
      title={title}
      entity="Role"
      disabled={disabled}
      onDelete={
        title !== "Create New Role"
          ? permissions && permissions.indexOf("delete-roles") > -1
            ? onDelete
            : false
          : undefined
      }
      onUpdate={
        title !== "Create New Role"
          ? permissions && permissions.indexOf("update-roles") > -1
            ? onUpdate
            : false
          : undefined
      }
      onCreate={
        title === "Create New Role"
          ? permissions && permissions.indexOf("create-roles") > -1
            ? onCreate
            : false
          : undefined
      }
      onClose={onClose}
    >
      <Role
        id={id}
        disabled={disabled}
        onChangePermissions={onChangePermissions}
      />
    </CRUModal>
  );
};

export default RolesModal;
