import uniqBy from 'lodash/uniqBy';

const getFieldsFrom = (items, filterSortMap, labelMap) => {
  if (!items.length) return [];
  
  return Object.keys(items[0]).map((item, idx) => ({
    id: item,
    label: item,
    ...filterSortMap[item],
    ...labelMap[item],
    values: uniqBy(items.map((subItem, subIdx) => ({
      id:  +`${idx + 1}${subIdx + 1}`,
      label: subItem[item],
    })), 'label'),
  }));
};

export {
  getFieldsFrom,
};
