import TableUncontrolled from "components/List/TableUncontrolled";
import React from "react";
import { usePermissions } from "react-admin";
import { getFieldsFrom } from "utils/helpers/list";
import { FILTER_SORT_MAP, LABEL_MAP } from "../../utils/constants";

const ListPartners = ({ items, onDelete, onView, onEdit, onCreate }) => {
  const hiddenColumns = React.useMemo(() => ["id"], []);
  const { permissions } = usePermissions();
  const fields = React.useMemo(
    () => getFieldsFrom(items, FILTER_SORT_MAP, LABEL_MAP),
    [items]
  );

  const columns = React.useMemo(
    () => [
      {
        accessor: "name",
        Cell: ({ row }) => <>{row.original["name"]}</>,
      },
      {
        accessor: "status",
        Cell: ({ row }) => <>{row.original["status"]}</>,
      },
    ],
    []
  );

  return (
    <TableUncontrolled
      isVisibleSort
      isVisibleFilter
      onView={onView}
      onEdit={onEdit}
      columns={columns}
      hiddenColumns={hiddenColumns}
      caption="All Partners"
      createCaption="Add Partners"
      entityType="partners"
      totalCount={items.length}
      onDelete={onDelete}
      onCreate={onCreate}
      fields={fields}
      rowsPerPage={20}
      data={items}
      isRemovable={
        permissions && permissions.indexOf("delete-partners") > -1
      }
      isCreatable={false}
      isViewable={
        false
      }
      isEditable={
        permissions && permissions.indexOf("update-partners") > -1
      }
    />
  );
};

export default ListPartners;
