import React from 'react';
import { createUseStyles } from 'react-jss';
import { useForm } from 'react-final-form';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import AppFormGap from 'components/AppFormGap';
import TextInputForm from 'components/Inputs/TextInputForm';
import { requiredValidation } from 'utils/helpers/validations';
import AppSelectForm from 'components/Inputs/AppSelectForm';
import { STATUSES } from 'pages/SimCard/utils/constants';
import CellStatus from 'components/Cells/CellStatus';
import LinkLock from 'components/Links/LInkLock';
import styles from './styles';

const useStyles = createUseStyles(styles);

const FormContent = ({
  item,
  disabled,
}) => {
  const classes = useStyles();
  const form = useForm();
  
  React.useEffect(() => {
    if (!!item.id) {
      form.reset(item);
    }
  }, [ item ]);
  
  return (
    <div className={classes.wrapper}>
      <AppFormGap />
      <Typography variant="h5">
        SIM Card {disabled && <CellStatus type={item.status}/>}
      </Typography>
      <AppFormGap />
      <TextInputForm
        name="iccid"
        label="ICCID"
        disabled={disabled}
        fieldValidation={requiredValidation}
        required
      />
      {!disabled && (
        <>
          <AppFormGap />
          <AppSelectForm
            name="status"
            label="Status"
            disabled={disabled}
            options={STATUSES}
          />
        </>
      )}
      {
        !!item.router && (
          <>
            <AppFormGap />
            <AppFormGap />
            <Typography variant="h5">
              <Link
                href={`#/routers/edit/${item.router.id}`}
                target="_blank"
              >
                Router
              </Link>
            </Typography>
            <AppFormGap />
            <TextInputForm
              disabled
              name="router.serial_number"
              label="Serial number"
            />
          </>
        )
      }
      {
        !!item.lock && (
          <>
            <AppFormGap />
            <AppFormGap />
            <Typography variant="h5">
              <LinkLock id={item.lock.id} />
            </Typography>
            <AppFormGap />
            <TextInputForm
              name="lock.id"
              label="ID"
              disabled
            />
          </>
        )
      }
    </div>
  )
};

export default FormContent;
