export default {
  container: {
    position: "absolute",
    top: "20%",
    left: "37%",
    width: "300px",
    border: "1px solid #dfdfdf",
    padding: "50px",
    boxShadow: "10px 10px 10px #dfdfdf",
    borderRadius: "3px",
  },
  formInput: {
      'margin': '20px',
  },
  label: {
    'marginLeft': '10px',
    'font': 'inherit'
  },
  input: {
    margin: "10px",
    border: "1px solid #dfdfdf",
    height: "30px",
    color: "inherit",
    font: "inherit",
    paddingLeft: "10px",
    width: "250px",
    '&:focus': {
        border: "1px solid #dfdfdf",
    }

  },
  logo: {
    display: "flex",
    "justify-content": "center",
  },

  login: {
    display: "flex",
    "justify-content": "center",
  },
  
  submit: {
      border: '1px solid #dfdfdf',
      width: '100px',
      height: '40px',
      font: 'inherit',
      borderRadius: '3px',
      boxShadow: "1px 1px 1px #dfdfdf",
      '&:hover': {
          'opacity': '0.8',
          'cursor': 'pointer'
      }
  },

  error: {
    margin: '20px',
    textAlign: 'center',
    font: 'inherit',
    color: 'rgba(255, 0, 0, 0.7)'
  },

  googleButton: {
    display: 'flex',
    marginTop: '20px',
    justifyContent: 'center'
  }
};
