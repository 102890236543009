import { COLOR_PRIMARY_BACKGROUND } from 'utils/constants/colors';

export default {
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    paddingBottom: 15,
    borderBottom: `1px solid ${COLOR_PRIMARY_BACKGROUND}`,
  },
  wrapperLeftHeader: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  wrapperRightHeader: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > button': {
      marginRight: 10,
    },
    '& > button:last-child': {
      marginRight: 0,
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize:'large'
  },
  btnActive : {
    backgroundColor : `green`,
  },
  btnInactive : {
    backgroundColor : 'blue'
  }
};
