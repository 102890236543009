const FILTER_SORT_MAP = {
  id: {
    isSortable: false,
    isFilterable: false,
  }
};

const LABEL_MAP = {
  discount_details: {
    label: "Name",
  }
};

const DISCOUNT_FREQUENCY = [
  { id: '-1', name: 'Unlimited' },
  { id: 1, name: '1x pro Tag' },
  { id: 2, name: '2x pro Tag' },
  { id: 3, name: '3x pro Tag' },
  { id: 4, name: '4x pro Tag' },
];

export { FILTER_SORT_MAP, LABEL_MAP, DISCOUNT_FREQUENCY };
