import { COLOR_PRIMARY_BACKGROUND } from 'utils/constants/colors';
import { WIDTH_MAX } from 'utils/constants/resolutions';

export default {
  wrapper: {
    backgroundColor: '#00CC77',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapperToolbar: {
    width: WIDTH_MAX,
    display: 'flex',
    flexDirection: 'row',
    height: 60,
  },
  [`@media (max-width: ${WIDTH_MAX}px)`]: {
    wrapperToolbar: {
      width: '100%',
    }
  },
  wrapperNavigation: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },
};
