import React from 'react';
import { createUseStyles } from 'react-jss';
import AppFormGap from 'components/AppFormGap';
import UserGroupInfo from '../UserGroupInfoGeneral/UserGroupInfo';
import styles from './styles';

const useStyles = createUseStyles(styles);

const UserGroupInfoGeneral = ({ userGroups, item }) => {
  const classes = useStyles();

  return !!userGroups.length && (
    <div className={classes.wrapper}>
      <div className={classes.label}>
        User Groups
      </div>
      <AppFormGap />
      <div className={classes.wrapperItems}>
        {userGroups.map( userGroup => (
          <React.Fragment key={userGroup.id}>
            <UserGroupInfo
              userGroupId={userGroup.id}
              name={userGroup.name}
              site={item}
            />
            <AppFormGap />
          </React.Fragment>
        ))}
      </div>
    </div>
  )
};

export default UserGroupInfoGeneral;
