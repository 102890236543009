import React from 'react';
import { createUseStyles } from 'react-jss';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-final-form';
import AppFormGap from 'components/AppFormGap';
import TextInputForm from 'components/Inputs/TextInputForm';
import AppSelectForm from 'components/Inputs/AppSelectForm';
import { requiredValidation } from 'utils/helpers/validations';
import styles from './styles';
import { STATUSES } from '../../../../../pages/UserGroup/utils/constants';

const useStyles = createUseStyles(styles);

const FormContentCreatable = ({ item }) => {
  const classes = useStyles();
  const form = useForm();
  
  React.useEffect(() => {
    if (!!item.id) {
      form.reset(item);
    }
  }, [ item ]);
  
  return (
    <div className={classes.wrapper}>
      <Typography variant="h5">
        User Group
      </Typography>
      <AppFormGap />
      <TextInputForm
        name="name"
        label="Name"
        fieldValidation={requiredValidation}
      />
      <AppFormGap />
      <TextInputForm
        name="wunder_promotion_code_id"
        label="Wunder Promotion Code Id"
        fieldValidation={requiredValidation}
      />
      <AppFormGap />
      <AppSelectForm
            name="status"
            label="Status"
            options={STATUSES}
            isNullable={false}
            initialValue={STATUSES[0].id}
          />
    </div>
  )
};

export default FormContentCreatable;
