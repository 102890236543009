import React from 'react';
import { createUseStyles } from 'react-jss';
import styles from './styles';

const useStyles = createUseStyles(styles);

const CellStatus = ({ type }) => {
  const classes = useStyles({ type });
  return (
    <div className={classes.wrapper}>
      {type}
    </div>
  );
};

export default CellStatus;
