import { COLOR_PRIMARY_TEXT_2 } from 'utils/constants/colors';

export default {
  heartbeat: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textTransform: 'uppercase',
    fontSize: 10,
    color: COLOR_PRIMARY_TEXT_2,
  },
  lockId : {
    fontWeight: 500,
    fontSize: 15,
  }
};
