import React from 'react';
import {
  MARKER_DEFAULT,
  MARKER_STATION,
} from 'utils/constants/markers';
import { COLOR_PRIMARY } from 'utils/constants/colors';
import MarkerDefault from '../MarkerDefault';
import MarkerStation from '../MarkerStation';

const MarkerFactory = ({
  color = COLOR_PRIMARY,
  type = MARKER_DEFAULT,
  href,
}) => {
  if (type === MARKER_STATION) {
    return <MarkerStation
      color={color}
      href={href}
    />;
  }
  
  return <MarkerDefault
    color={color}
    href={href}
  />;
};

export default MarkerFactory;
