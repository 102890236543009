import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import { Info, Receipt } from "@material-ui/icons";
import AppForm from "components/AppForm";
import AppLoader from "components/AppLoader";
import AppModal from "components/Modals/AppModal";
import PropTypes from "prop-types";
import React from "react";
import { createUseStyles } from "react-jss";
import { COLOR_PRIMARY_BACKGROUND } from "utils/constants/colors";
import LogViewer from "../../../pages/LogViewer";
import StationInfoGeneral from "../SiteModal/components/FormContent/components/StationInfoGeneral";
import BikeInfoGeneral from "../VehicleModal/components/FormContent/components/BikeInfoGeneral";
import styles from "./styles";
import { usePermissions } from "react-admin";

const useStyles = createUseStyles(styles);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ padding: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStylesForTabs = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  wrapperBody: {
    marginTop: 32,
  },
  wrapperInner: {
    minWidth: ({ minWidth = 600 }) => minWidth,
    minHeight: 100,
  },
  wrapperForm: {
    maxHeight: 600,
    maxWidth: 1200,
    overflowY: "auto",
    padding: "20px",
    width: "1200px",
  },
  divider: {
    position: "absolute",
    bottom: -16,
    left: -16,
    right: -16,
    height: 1,
    backgroundColor: COLOR_PRIMARY_BACKGROUND,
  },
  app_bar_css: {
    backgroundColor: "transparent",
    boxShadow: "none",
    color: "black",
  },
  tab_label: {
    color: "#black",
  },
}));

const CRUModal = ({
  title,
  entity,
  disabled,
  minWidth = 1200,
  validate,
  initialValues,
  onCreate,
  onTrigger,
  onUpdate,
  onDelete,
  onEdit,
  onClose,
  onView,
  children,
  onLogView,
  ctgr,
  ctgrId,
  item,
  id,
}) => {
  const classes = useStyles({ minWidth });

  return (
    <AppModal onClose={onClose}>
      <div className={classes.wrapperForm}>
        {
          // console.log(item)
        }
        {ctgr === "bike" && (
          <BikeInfoGeneral
            item={item}
            id={id}
            hideBikeInfo={true}
            onClose={onClose}
          />
        )}
        {ctgr === "site" && (
          <StationInfoGeneral
            item={item}
            hideSiteInfo={true}
            onClose={onClose}
          />
        )}
        {["bike", "site"].indexOf(ctgr) > -1 && (
          <SimpleTabs
            info={{
              title,
              entity,
              disabled,
              minWidth,
              validate,
              initialValues,
              onCreate,
              onTrigger,
              onUpdate,
              onDelete,
              onEdit,
              onClose,
              onView,
              children,
              onLogView,
              ctgr,
              ctgrId,
            }}
          />
        )}

        {["bike", "site"].indexOf(ctgr) === -1 && (
          <AppForm
            initialValues={initialValues}
            validate={validate}
            toolbar={false}
            disabled={disabled}
            entity={entity}
            title={title}
            onCreate={onCreate}
            onTrigger={onTrigger}
            onClose={onClose}
            onEdit={onEdit}
            onDelete={onDelete}
            onUpdate={onUpdate}
            onView={onView}
            onLogView={onLogView}
          >
            <div className={classes.wrapperInner}>
              <div className={classes.wrapperBody}>{children}</div>
            </div>
          </AppForm>
        )}
      </div>
      <AppLoader />
    </AppModal>
  );
};

export default CRUModal;

function SimpleTabs(props) {
  const classes = useStylesForTabs(); // useStyles();
  const [value, setValue] = React.useState(0);
  const [copyOfProps, setCopyOfProps] = React.useState(props);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { permissions } = usePermissions();

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.app_bar_css}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab
            label="Details"
            {...a11yProps(0)}
            className={classes.tab_label}
            icon={<Info />}
            iconPosition="start"
          />
          {permissions && permissions.indexOf("view-log-viewer") > -1 ? (
            <Tab
              label="Logs"
              {...a11yProps(1)}
              className={classes.tab_label}
              icon={<Receipt />}
              iconPosition="start"
            />
          ) : null}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <AppForm
          initialValues={props.info.initialValues}
          validate={props.info.validate}
          toolbar={false}
          disabled={props.info.disabled}
          entity={props.info.entity}
          title={props.info.title}
          onCreate={props.info.onCreate}
          onTrigger={props.info.onTrigger}
          // onClose={props.info.onClose}
          onEdit={props.info.onEdit}
          onDelete={props.info.onDelete}
          onUpdate={props.info.onUpdate}
          onView={props.info.onView}
          onLogView={props.info.onLogView}
          children={props.info.children}
        >
          <div className={classes.wrapperInner}>
            <div className={classes.wrapperBody}>{props.info.children}</div>
          </div>
        </AppForm>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <LogViewer ctgr={props.info.ctgr} ctgrId={props.info.ctgrId} />
      </TabPanel>
    </div>
  );
}
