import React from 'react';
import { useDispatch } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { getUserGroups, getUserGroup, addUserGroup, deleteUserGroup, updateUserGroup, addSiteToUserGroup } from 'utils/helpers/request/api/userGroups';
import { setLoader } from 'store/actions/common';

const useFetchItems = () => {
  const [ items, setItems ] = React.useState([]);
  const dispatch = useDispatch();
  
  const fetch = async function() {
    dispatch(setLoader(true))
    
    try {
      const { data: { items: userGroups } } = await getUserGroups();
      
      setItems(userGroups);
    } catch (error) {
      console.log('Something unexpected happened. Error: ', error)
    }
    
    dispatch(setLoader(false))
  };
  
  return {
    items,
    fetch,
  };
};

const useFetchItem = id => {
  const [ item, setItem ] = React.useState({
    name: null,
    wunder_promotion_code_id: null,
    status: null,
  });

  const dispatch = useDispatch();
  
  const fetch = async function() {
    if (!id) return ;
    dispatch(setLoader(true))
    
    try {
      const { data } = await getUserGroup(id);
      if (!!data) {
        setItem(data);
      } else {
        NotificationManager.error('User group not found');
      }
      
    } catch (e) {
      if (e.response.status === 404) window.location.href = '#/not-found';
    }
    
    dispatch(setLoader(false))
  };
  
  return {
    item,
    fetch,
  };
};

const useCreate = () => {
  const dispatch = useDispatch();
  
  const createItem = async function(data) {
    dispatch(setLoader(true))
    
    try {
      const { data: { id } } = await addUserGroup(data);
      NotificationManager.success('User group successfully created!');
      
      dispatch(setLoader(false))
      
      return id;
    } catch (error) {
      console.log('Something unexpected happened. Error: ', error)
      dispatch(setLoader(false))
    }
    
  };

  const associateSiteToUserGroup = async function(data) {
    dispatch(setLoader(true))
    
    try {
      const { data: { userGroupId } } = await addSiteToUserGroup(data.siteId, data.userGroupId);
      NotificationManager.success('Site successfully associated to a user group!');
      
      dispatch(setLoader(false))
      
      return userGroupId;
    } catch (error) {
      console.log('Something unexpected happened. Error: ', error)
      dispatch(setLoader(false))
    }
  }
  
  return {
    createItem,
    associateSiteToUserGroup
  };
};

const useUpdate = () => {
  const dispatch = useDispatch();

  const updateItem = async function(id, data) {
    dispatch(setLoader(true))

    try {
      await updateUserGroup(id, data);
      NotificationManager.success('User group successfully updated!');
      dispatch(setLoader(false));
    } catch (error) {
      console.log('Something unexpected happened. Error: ', error)
      dispatch(setLoader(false));
    }
  };

  return {
    updateItem,
  };
};

const useDelete = () => {
  const dispatch = useDispatch();
  
  const deleteItems = async function(ids) {
    dispatch(setLoader(true))
    try {
      for (const id of ids) {
        await deleteUserGroup(id);
      }
      NotificationManager.success('User group successfully removed!');
    } catch (error) {
      console.log('Something unexpected happened. Error: ', error)
    }
    
    dispatch(setLoader(false))
  };
  
  return {
    deleteItems,
  };
};

export {
  useFetchItems,
  useFetchItem,
  useCreate,
  useUpdate,
  useDelete,
};
