import { COLOR_DEFAULT_TEXT } from 'utils/constants/colors';

export default {
  wrapper: {
    fontSize: 18,
    color: `${COLOR_DEFAULT_TEXT} !important`,
    fontWeight: 500,
  },
  city : {
    fontSize:18,
    fontWeight: 500,
  }
};
