import React from 'react';
import { createUseStyles } from 'react-jss';
import AsyncAutocompleteForm from 'components/Inputs/AsyncAutocompleteForm';
import { asyncRequestForPiles } from 'utils/helpers/form';
import styles from './styles';

const useStyles = createUseStyles(styles);

const AutocompletePile = ({
  disabled,
  filterFn,
  label = 'Id',
}) => {
  const classes = useStyles();
  const asyncRequest = React.useMemo(() => {
    return () => asyncRequestForPiles(filterFn);
  }, [ filterFn ]);
  
  return (
    <div className={classes.wrapper}>
      <AsyncAutocompleteForm
        label={label}
        asyncRequestFn={asyncRequest}
        disabled={disabled}
        emptyMessage="No empty piles"
        name="pile"
        initAsync
      />
    </div>
  )
};

export default AutocompletePile;
