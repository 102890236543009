import client from "../client";

const addRouter = (data) => client.post("/router", data);

const getRouters = () => client.get("/routers");

const getRouterBySerialNumber = (serialNumber) =>
  client
    .get(`/routers?serial_number=${serialNumber}`)
    .then(({ data: { items } }) => {
      return !!items[0];
    });

const getRouterByMacAddress = (macAddress) =>
  client
    .get(`/routers?mac_address=${macAddress}`)
    .then(({ data: { items } }) => {
      return !!items[0];
    });

const getRouter = (id) => client.get(`/routers/${id}`);

const deleteRouter = (id) => client.delete(`/routers/${id}`);

const updateRouter = (data) => client.put(`/routers/${data.id}`, data);

const sendSMS = (iccid, payload) => client.post(`/sims/${iccid}/sms`, payload);

export {
  getRouters,
  addRouter,
  getRouter,
  deleteRouter,
  updateRouter,
  getRouterBySerialNumber,
  getRouterByMacAddress,
  sendSMS,
};
