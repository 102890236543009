import React from 'react';
import { usePermissions } from 'react-admin';
import CRUModal from 'components/Modals/CRUModal';
import Pile from 'pages/Pile';

const PileModal = ({
  onClose,
  onDelete,
  onUpdate,
  disabled,
  id,
}) => {
  const title = React.useMemo(() => !disabled ? 'Edit Pile' : 'View Pile', [ disabled ]);
  const { permissions } = usePermissions()
  
  return (
    <CRUModal
      title={title}
      entity="Pile"
      disabled={disabled}
      onDelete={permissions && permissions.indexOf('update-piles') > -1 ? onDelete : undefined}
      onUpdate={permissions && permissions.indexOf('update-piles') > -1 ? onUpdate : undefined}
      onClose={onClose}
    >
      <Pile
        id={id}
        disabled={disabled}
      />
    </CRUModal>
  )
};

export default PileModal;
