import React from 'react';
import { createUseStyles } from 'react-jss';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Logo from './components/Logo';
import NavigationLeft from './components/NavigationLeft';
import NavigationRight from './components/NavigationRight';
import styles from './styles';

const useStyles = createUseStyles(styles);

const SigoAppBar = props => {
  const classes = useStyles();

  return (
    <AppBar
      {...props}
      title=""
      position="absolute"
      style={styles.wrapper}
    >
      <Toolbar style={styles.wrapperToolbar}>
        <a href="#">
          <Logo />
        </a>
        <div className={classes.wrapperNavigation}>
          {
            localStorage.getItem('userId') ? <>
              <NavigationLeft />
              <NavigationRight />
            </> : <></>
          }

        </div>
      </Toolbar>
    </AppBar>
  )
};

export default SigoAppBar;
