const FILTER_SORT_MAP = {
  id: {
    isSortable: false,
    isFilterable: false
  },
  name: {
    isSortable: true,
    isFilterable: true
  },
  status: {
    isSortable: true,
    isFilterable: true
  },
  wunder_promotion_code_id: {
    isSortable: true,
    isFilterable: true
  },
  created_at: {
    isSortable: false,
    isFilterable: false
  },
  updated_at: {
    isSortable: false,
    isFilterable: false
  }
};

const LABEL_MAP = {
  name: {
    label: 'Name'
  },
  status: {
    label: 'Status'
  },
  wunder_promotion_code_id: {
    label: 'Wunder Promotion Code Id'
  },
  created_at: {
    label: 'Created At'
  },
  updated_at: {
    label: 'Updated At'
  }
};

export {
  FILTER_SORT_MAP,
  LABEL_MAP
};
