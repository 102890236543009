import React from 'react';
import { createUseStyles } from 'react-jss';
import CellBatteryGlobal from 'components/Cells/CellBattery';
import styles from './styles';

const useStyles = createUseStyles(styles);

const CellBattery = ({ row }) => {
  return !!row.original.battery_id && (
    <CellBatteryGlobal
      id={row.original.battery_id}
      level={Math.floor(row.original.energy_level)}
    />
  );
};

export default CellBattery;
