import React from 'react';
import { createUseStyles } from 'react-jss';
import styles from './styles';

const useStyles = createUseStyles(styles);

const Divider = ({ classNameWrapper }) => {
  const classes = useStyles();
  const className = React.useMemo(() => `${classes.wrapper} ${classNameWrapper}`, []);

  return (
    <div className={className} />
  )
};

export default Divider;
