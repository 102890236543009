import uniqBy from 'lodash/uniqBy';

const getFieldsFrom = items => {
  if (!items.length) return [];

  return Object.keys(items[0]).map((item, idx) => ({
    id: item,
    label: item,
    values: uniqBy(items.map((subItem, subIdx) => ({
      id:  +`${idx + 1}${subIdx + 1}`,
      label: subItem[item],
    })), 'label'),
  }));
};

const validateForm = values => {
  const errors = {};

  if (!values.pileType) {
    errors.pileType = 'The Name of Pile Type is required';
  }

  return errors;
};

export {
  validateForm,
  getFieldsFrom,
};
