import PageWrapper from "components/PageWrapper";
import React from "react";
import {
  useCreateItem,
  useDelete,
  useDeleteItem,
  useFetchStationGroups,
  useUpdateItem,
} from "utils/hooks/stationGroups";
import ListStationGroups from "./components/ListStationGroups";
import StationGroupModal from "./components/StationGroupModal";

const StationGroups = () => {
  const { stationGroups, fetchStationGroups } = useFetchStationGroups();
  const { deleteItems } = useDelete();
  const { deleteItem } = useDeleteItem();
  const { updateItem } = useUpdateItem();
  const { createItem } = useCreateItem();
  const [stationGroupId, setStationGroupId] = React.useState(null);
  const [isViewOnly, setIsViewOnly] = React.useState(false);
  const [modalTitle, setModalTitle] = React.useState("");

  React.useEffect(() => {
    fetchStationGroups();
  }, []);

  const handleDeleteItems = React.useCallback(
    (indexes) => {
      const ids = indexes.map((i) => stationGroups[+i].id);

      deleteItems(ids).then(() => {
        fetchStationGroups();
      });
    },
    [stationGroups]
  );

  const hideModal = React.useCallback(() => {
    setStationGroupId(null);
    setIsViewOnly(false);
  }, []);

  const handleDeleteItem = React.useCallback(() => {
    deleteItem(stationGroupId).then(() => {
      hideModal();
      fetchStationGroups();
    });
  }, [stationGroupId]);

  const handleClickCreate = React.useCallback(() => {
    setStationGroupId("-1");
    setModalTitle("Create Station Group");
  }, []);

  const handleUpdate = React.useCallback(async ({ values }) => {
    const stationGroup = {
      id: values.stationGroupId,
      name: values.name,
      status: values.status,
      stationIds: values.stationIds,
    };
    updateItem(stationGroup).then(() => {
      hideModal();
      fetchStationGroups();
    });
  }, []);

  const handleCreate = React.useCallback(({ values }) => {
    const stationGroup = {
      name: values.name,
      status: 'active',
      stationIds: values.stationIds,
    };
    createItem(stationGroup)
      .then(() => {
        return fetchStationGroups();
      })
      .then(() => {
        hideModal();
      });
  }, []);

  const handleEdit = React.useCallback(
    (id) => {
      setStationGroupId(id);
      setModalTitle("Edit Station Group");
    },
    [setStationGroupId]
  );

  const handleView = React.useCallback((id) => {
    setStationGroupId(id);
    setModalTitle("View Station Group");
    setIsViewOnly(true);
  }, []);

  return (
    <PageWrapper>
      <ListStationGroups
        items={stationGroups}
        onEdit={handleEdit}
        onView={handleView}
        onDelete={handleDeleteItems}
        onCreate={handleClickCreate}
      />
      {!!stationGroupId && (
        <StationGroupModal
          id={stationGroupId}
          disabled={isViewOnly}
          title={modalTitle}
          onClose={hideModal}
          onDelete={handleDeleteItem}
          onUpdate={handleUpdate}
          onCreate={handleCreate}
        />
      )}
    </PageWrapper>
  );
};

export default StationGroups;
