import React from 'react';
import { createUseStyles } from 'react-jss';
import RoomIcon from '@material-ui/icons/Room';
import { useDispatch } from 'react-redux';
import CellView from 'components/Cells/CellView';
import { setModalData } from 'store/actions/common';
import { SITE_MODAL } from 'utils/constants/modals';
import styles from './styles';

const useStyles = createUseStyles(styles);

const PinPointIcon = () => {
  const classes = useStyles();
  
  return (
    <div className={classes.wrapperIcon}>
      {/* <RoomIcon/> */}
    </div>
  );
}

const CellSite = ({
  id,
  status,
  withIcon,
  children,
}) => {
  const classes = useStyles({ status });
  const dispatch = useDispatch();
  const handleClick = React.useCallback(e => {
    e.preventDefault();
    dispatch(setModalData({ type: SITE_MODAL, id }));
  }, [id]); // fix for rendering old id
  
  return (
    <CellView
      Icon={withIcon && PinPointIcon}
      status={status}
      onClick={handleClick}
    >
      {children}
    </CellView>
  );
};

export default CellSite;
