import React from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import CellLockGlobal from 'components/Cells/CellLock';
import CellHeartbeat from 'components/Cells/CellHeartbeat';
import styles from './styles';

const useStyles = createUseStyles(styles);

const CellLock = ({ row }) => {
  const classes = useStyles();
  const lockHeartbeatWS = useSelector(state => state.commonReducer.lockHeartbeat[row.original?.lock?.id]);
  
  return row.original.lock && (
    <CellLockGlobal
      id={row.original.lock.id}
      status={row.original.lock.status}
      locked={row.original.state.locked}
    >
      <div className={classes.wrapperContent}>
        <div className={classes.heartbeat}>
          Lock&nbsp;&nbsp;<CellHeartbeat ts={lockHeartbeatWS || row.original.lock.heartbeat} />
        </div>
        <div className={classes.lockId}>
          {row.original.lock.id}
        </div>
      </div>
    </CellLockGlobal>
  );
};

export default CellLock;
