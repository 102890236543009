import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { createUseStyles } from "react-jss";
import styles from "./styles";
import AppFormGap from "components/AppFormGap";
import TextInputForm from "components/Inputs/TextInputForm";
import { useForm } from "react-final-form";
import { useFetchItem } from "utils/hooks/discounts";
import AppSelectForm from "components/Inputs/AppSelectForm";
import { DISCOUNT_FREQUENCY } from "pages/DiscountManagement/utils/constants";
import { useFetchPartners } from "utils/hooks/partners";
import { useFetchStationGroups } from "utils/hooks/stationGroups";

const useStyles = createUseStyles(styles);

const Discount = ({ id, disabled }) => {
  const classes = useStyles();
  const form = useForm();
  const { item, fetch } = useFetchItem(id);
  const { partners, fetchPartners } = useFetchPartners();
  const { stationGroups, fetchStationGroups} = useFetchStationGroups();

  useEffect(() => {
    fetchPartners();
    fetchStationGroups();
  }, []);
  

  useEffect(() => {
    if (id !== "-1") fetch();
  }, []);

  useEffect(() => {
    if (!!item.discountId) {
      form.reset(item);
    }
  }, [item]);

  return (
    <div className={classes.wrapper}>
      <Typography variant="h5">Discount</Typography>
      <AppFormGap />
      <TextInputForm name="name" label="Name" disabled={disabled} />
      <AppFormGap />
      <AppSelectForm
        name="partnerId"
        label="Partners"
        options={partners}
        disabled={disabled}
      />
      <AppFormGap />
      <AppSelectForm
        name="stationGroupId"
        label="Station Groups"
        options={stationGroups}
        disabled={disabled}
      />
      <AppFormGap />
      <TextInputForm
        name="discount"
        label="Discount in &euro;"
        type="number"
        disabled={disabled}
      />
      <AppFormGap />
      <AppSelectForm
        name="frequency"
        label="Discount Frequency"
        options={DISCOUNT_FREQUENCY}
        disabled={disabled}
      />
      <AppFormGap />
      <TextInputForm
        name="emailDomains"
        label="Email Domains"
        disabled={disabled}
      />
      <AppFormGap />
      <TextInputForm
        name="parkingTranslationKey"
        label="Parking Translation Key"
        disabled={disabled}
      />
      <AppFormGap />
      <TextInputForm
        name="timingTranslationKey"
        label="Timing Translation Key"
        disabled={disabled}
      />
      <AppFormGap />
    </div>
  );
};

export default Discount;
