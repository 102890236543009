import { COLOR_DEFAULT_TEXT } from 'utils/constants/colors';

export default {
  wrapper: {
    backgroundColor: '#fff',
    '& th': {
      backgroundColor: '#fff',
      color: '#AEAEAE',
      fontWeight: 600,
      letterSpacing: 0.25,
      fontSize: 14,
      textTransform: 'uppercase',
    },
    '& thead th' :{
      backgroundColor: '#6fc97f',
      color:'#000',
      border: '0.1px solid #ccc !important',
    },
    '& tbody tr': {
      height: 47,
    },
    '& tbody tr:hover': {
      backgroundColor: '#F5F6F7',
      cursor: 'pointer',
    },
    '& tbody td': {
      fontFamily: 'sans-serif',
      fontStyle: 'normal',
      fontSize: 14,
      padding: '8px 15px',
      lineHeight: '21px',
      borderLeft: '1px solid #ccc !important',
      // borderTop: '1px solid #ccc !important',
    },
    '& tbody tr td:first-child': {
      width: 50,
      textAlign: 'center',
      
    },
    '& tbody tr td:last-child': {
      width: 80,
    },
    '& tbody tr td:nth-child(6)': {
      width: 100,
    },
    '& tbody tr td:nth-child(7)': {
      width: 100,
    },
    '& tbody td:last-child':{
      borderRight: '1px solid #ccc !important',
      
    },
    '& .MuiTableCell-body': {
      color: `${COLOR_DEFAULT_TEXT} !important`,
    }
  },
  wrapperCell: {
    display: 'flex',
    flexDirection: 'row',
  },
  wrapperCaption: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 14,
    paddingRight: 14,
    paddingTop: 10,
    paddingBottom: 10,
  },
  wrapperLeft: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  wrapperRight: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight:'400px',
    '& > div': {
      marginRight: 15,
    },
    '& > button': {
      marginLeft: 15,
      maxWidth: 250,
    },
    '& > div:last-child': {
      marginRight: 0,
      marginLeft: 15,
    },
    '& > a' : {
      textAlign: 'center'
    }
  },
  wrapperPagination: {
    color: '#AEAEAE !important',
    '& p': {
      fontFamily: 'sans-serif',
      fontStyle: 'normal',
    },
  },
  button: {
    padding: '4px 14px !important',
  },
  caption: {
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '38px',
  },
  sortBtn:{
    '&:hover':{
      cursor:'pointer',
      textDecoration:'underline'
    }
  }
};
