import React from 'react';
import { createUseStyles } from 'react-jss';
import CityModal from 'components/Modals/CityModal';
import { useCreateCity } from 'utils/hooks/create';
import {
  useFetch,
  useUpdateItem,
  useDeleteItems,
} from './utils/hooks';
import ListCities from './components/ListCities';
import styles from './styles';

const useStyles = createUseStyles(styles);

const Cities = () => {
  const classes = useStyles();
  const { items, fetch } = useFetch();
  const { updateItem } = useUpdateItem();
  const { createItem } = useCreateCity();
  const { deleteItems } = useDeleteItems();
  const [ modalData, setModalData ] = React.useState(null);
  const handleDeleteItems = React.useCallback(indexes => {
    const ids = indexes.map(i => items[+i].id);
    
    deleteItems(ids)
      .then(() => {
        fetch();
      });
  }, [ items ]);
  const handleClickCreate = React.useCallback(() => {
    showModal({});
  }, []);
  const handleClickEdit = React.useCallback((data) => {
    showModal(data);
  }, []);
  const showModal = React.useCallback(data => {
    setModalData(data);
  }, []);
  const hideModal = React.useCallback(() => {
    setModalData();
  }, []);
  const handleUpdate = React.useCallback(({ values }) => {
    updateItem(values)
      .then(() => {
        return fetch();
      })
      .then(() => {
        hideModal();
      });
  }, []);
  const handleCreate = React.useCallback(({ values }) => {
    createItem(values)
      .then(() => {
        return fetch();
      })
      .then(() => {
        hideModal();
      });
  }, []);
  
  React.useEffect(() => {
    fetch();
  }, []);

  return (
    <div className={classes.wrapper}>
      <ListCities
        items={items}
        onDelete={handleDeleteItems}
        onCreate={handleClickCreate}
        onEdit={handleClickEdit}
      />
      {!!modalData && (
        <CityModal
          modalInitialData={modalData}
          onClose={hideModal}
          onUpdate={!!modalData.name && handleUpdate}
          onCreate={!modalData.name && handleCreate}
        />
      )}
    </div>
  )
};

export default Cities;
