import React from "react";
import * as c3 from "c3";
import "c3/c3.css";
import moment from "moment";
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
moment.tz.setDefault("Europe/Berlin");
class GraphView extends React.Component {
  constructor(props) {
    super(props);
    let { graphData, tickFormat  } = this.generateGraphData(this.props,this.props.data,this.props.events);
    // console.log(graphData)
    // console.log(tickFormat)
    this.renderStackedBarGraph(graphData, tickFormat, '#chart');
    // console.log(this.props);
    if (props.src === 'bike'){
      // console.log('bike----->')
      // console.log(this.props.batteryData);
      let { graphData, tickFormat } = this.generateGraphJsonData(this.props,this.props.batteryData,'BATTERY');
      // console.log(graphData);
      this.renderGraph(graphData, tickFormat, '#chartBattery');
    } else if (props.src ==='site') {
      let {graphData,tickFormat} = this.generateGraphJsonData(this.props,this.props.heartbeatGraphData,'SITE_HEARTBEAT');
      this.renderBarGraph(graphData,tickFormat,"#chartSiteHb");
    }
    let {isDataPresent,donutData:jsonDonutData} = this.generateDonutData(this.props.eventWiseCountData);
    this.renderDonut('#donutChart',jsonDonutData,isDataPresent)
  }

  calculateBucketingParams(props) {
    const TICK_FORMAT_HMS = "%H:%M:%S";
    const TICK_FORMAT_YMD_HMS =  "%Y-%m-%d %H:%M:%S";
    const ONE_DAY_IN_MILLISECONDS = 86400000;
    const ONE_HOUR_IN_MILLISECONDS = 3600000;
    const ONE_MIN_IN_MILLISECONDS = 60000;
    const INTERVAL_7_DAY = "7d";
    const INTERVAL_1_DAY = "1d";
    const INTERVAL_1_HOUR = "1hr";
    const INTERVAL_30_MIN = "30m";
    const INTERVAL_15_MIN = "15m";
    const INTERVAL_5_MIN = "5m";
    const INTERVAL_CUSTOM = "custom";
    const MOMENT_TEXT_DAYS = "days";
    const MOMENT_TEXT_HOURS = "hours";
    const MOMENT_TEXT_MINUTES = "minutes";
    let momentInterval = null;
    let momentIntervalText = null;
    let count = 0;
    if (props.dpBtnText == INTERVAL_CUSTOM) {
      let diff = props.endDate.diff(props.startDate);
      if (diff >= ONE_DAY_IN_MILLISECONDS) {
        count = Math.round(diff / ONE_DAY_IN_MILLISECONDS);
        momentInterval = 1;
        momentIntervalText = MOMENT_TEXT_DAYS;
      } else if (
        diff >= ONE_HOUR_IN_MILLISECONDS &&
        diff < ONE_DAY_IN_MILLISECONDS
      ) {
        count = Math.round(diff / ONE_HOUR_IN_MILLISECONDS);
        momentInterval = 1;
        momentIntervalText = MOMENT_TEXT_HOURS;
      } else {
        count = Math.round(diff / ONE_MIN_IN_MILLISECONDS);
        momentInterval = 1;
        momentIntervalText = MOMENT_TEXT_MINUTES;
      }
    } else if (props.dpBtnText == INTERVAL_7_DAY) {
      count = 7;
      momentInterval = 1;
      momentIntervalText = MOMENT_TEXT_DAYS;
    } else if (props.dpBtnText == INTERVAL_1_DAY) {
      count = 24;
      momentInterval = 1;
      momentIntervalText = MOMENT_TEXT_HOURS;
    } else if (props.dpBtnText == INTERVAL_1_HOUR) {
      count = 60;
      momentInterval = 1;
      momentIntervalText = MOMENT_TEXT_MINUTES;
    } else if (props.dpBtnText == INTERVAL_30_MIN) {
      count = 30;
      momentInterval = 1;
      momentIntervalText = MOMENT_TEXT_MINUTES;
    } else if (props.dpBtnText == INTERVAL_15_MIN) {
      count = 15;
      momentInterval = 1;
      momentIntervalText = MOMENT_TEXT_MINUTES;
    } else if (props.dpBtnText == INTERVAL_5_MIN) {
      count = 5;
      momentInterval = 1;
      momentIntervalText = MOMENT_TEXT_MINUTES;
    }
    let tickFormat = TICK_FORMAT_HMS;
    if (momentIntervalText == MOMENT_TEXT_DAYS) {
      tickFormat = TICK_FORMAT_YMD_HMS;
    }
    return {
      count,
      momentInterval,
      momentIntervalText,
      tickFormat,
    };
  }

  generateDonutData(data=[]){
    let donutData = {};
    let isDataPresent = data.length == 0 ? false: true;
    // console.log('event len',events.length)
    data.forEach(item=>{
      if (donutData[item.event] == undefined){
        donutData[item.event] = item.count;
      }
    })
    // console.log(donutData);
    return {donutData,isDataPresent};
  }
  generateGraphData(props,data=[],events=[]) {
    // console.log(data);
    // console.log(events)
    const eventsArray = events;
    const momentStartDateObj = moment(props.startDate);
    let hMap = {};
    let xAxisTickArray = [];
    let xAxisTickArrayWithFormatString = [];
    let rawData = data;

    let {
      format,
      interval,
      count,
      momentInterval,
      momentIntervalText,
      tickFormat,
    } = this.calculateBucketingParams(props);
    let startDateUnix = momentStartDateObj;

    // add first x axis tick with default value
    xAxisTickArray.push(startDateUnix.valueOf());
    xAxisTickArrayWithFormatString.push(startDateUnix.valueOf());

    hMap[startDateUnix.valueOf()] = 0;

    // add all x axis tick with default value
    for (let i = 0; i < count; i++) {
      startDateUnix = momentStartDateObj.add(
        momentInterval,
        momentIntervalText
      );
      xAxisTickArray.push(startDateUnix.valueOf());
      xAxisTickArrayWithFormatString.push(startDateUnix.valueOf());
      hMap[startDateUnix.valueOf()] = 0;
    }

    let myGraph = { x: ["x", ...xAxisTickArrayWithFormatString] };
    const tickArrayLength = xAxisTickArray.length;
    eventsArray.forEach((event) => {
      myGraph[event] = Array(tickArrayLength + 1).fill(null);
      myGraph[event][0] = event;
    });

    // iterate on all epochtime from db and classify its bucket and final count
    rawData.forEach((item) => {
      let flag = false;
      // check on tick buckets
      for (let i = 0; i < xAxisTickArray.length - 1; i++) {
        if (
          item.epochtime > xAxisTickArray[i] &&
          item.epochtime <= xAxisTickArray[i + 1]
        ) {
          flag = true;
          if (hMap[xAxisTickArray[i]]) {
            hMap[xAxisTickArray[i]] += 1;
          } else {
            hMap[xAxisTickArray[i]] = 1;
          }

          if (myGraph[item.event]) {
            myGraph[item.event][i + 1]++;
          }
          if (
            props.src == "bike" &&
            item.event == "BIKE_STATE" &&
            item.code == "BATTERY"&&
            myGraph["BATTERY"]
          ) {
            myGraph["BATTERY"][i + 1] = item.capacity;
          }
        }
      }
    });

    let finalResult = [];
    for (let key in myGraph) {
        finalResult.push(myGraph[key]);
    }

    // console.log(finalResult)
    return { graphData: finalResult, tickFormat: tickFormat};
  }

  generateGraphJsonData(props,data=[],yAxisLabel){
    let xAxis = ['x'];
    let yAxis = [yAxisLabel];
    data.forEach(item=>{
      if (item.created_at){
        xAxis.push(new Date(item.created_at))
      }
      if (item.capacity){
       yAxis.push(Number(item.capacity));
      }
      if (item.count){
        yAxis.push(Number(item.count));
      }
    })
    let graphData = [xAxis,yAxis];
    let tickFormat = ''
    if (props.interval ==='min'){
      tickFormat = '%H:%M:%S';
    } else if (props.interval === 'hour') {
      tickFormat = '%H:%M:%S';//'%Y-%m-%d %H:%M:%S';
    } else if (props.interval === 'day') {
      tickFormat = '%Y-%m-%d';
    }
    // console.log(graphData);
    // console.log(tickFormat)
    return {graphData,tickFormat};
  }


  renderGraph(columnData, tickFormat,chartId) {
    let type = 'line';
    this.displayGraph(chartId,columnData,tickFormat,type);
  }

  renderBarGraph(columnData,tickFormat,chartId) {
    let type = "bar";
    this.displayGraph(chartId,columnData,tickFormat,type);
  }

  renderStackedBarGraph(columnData,tickFormat,chartId) {
    let type = "bar";
    this.displayStackedBarGraph(chartId,columnData,tickFormat,type);
  }
  displayStackedBarGraph(bindto,columnData,tickFormat,type) {
    var chart = c3.generate({
      bindto: bindto,
      data: {
        x: "x",
        columns: columnData,
        labels: true,
        type: type,
        groups:[this.props.events]
      },
      bar : (type === 'bar')? {width:{ratio:0.1}}:{},
      tooltip: {
        grouped: false, // Default true
      },
      axis: {
        x: {
          type: "timeseries",
          tick: {
            format: tickFormat,
            rotate:90
          },
          localtime: false,
        },
        y: {
          // label: "Count",
        },
      },
    });
  }

  displayGraph(bindto,columnData,tickFormat,type) {
    // console.log(bindto,columnData)
    var chart = c3.generate({
      bindto: bindto,
      data: {
        x: "x",
        columns: columnData,
        labels: true,
        type: type
      },
      bar : (type === 'bar')? {width:{ratio:0.1}}:{},
      tooltip: {
        grouped: false, // Default true
      },
      axis: {
        x: {
          type: "timeseries",
          tick: {
            format: tickFormat,
            rotate:90
          },
          localtime: false,
        },
        y: {
          // label: "Count",
        },
      },
    });
  }

  renderDonut(bindto,jsonData,isDataPresent){
    // console.log('is ',isDataPresent)
    if (isDataPresent){
      this.displayDonut(bindto,jsonData);
    }
  }
  displayDonut(bindto,jsonData) {
    var chart = c3.generate({
      bindto: bindto,
      data: {
          json:jsonData,
          type : 'donut',
          // onclick: function (d, i) { console.log("onclick", d, i); },
          // onmouseover: function (d, i) { console.log("onmouseover", d, i); },
          // onmouseout: function (d, i) { console.log("onmouseout", d, i); }
      },
      donut: {
          title: "Event Count",
          label: {
            format: function (value, ratio, id) {
                return value;
            }
        }
      }
  });
  }
  render() {
    // !!!! old code for displaying all events


    // let { graphData, tickFormat,batteryColumnData } = this.generateGraphData(this.props);
    // this.renderGraph(graphData, tickFormat,batteryColumnData);
    let { graphData, tickFormat  } = this.generateGraphData(this.props,this.props.data,this.props.events);
    this.renderStackedBarGraph(graphData, tickFormat, '#chart');
    // console.log(this.props);
    // let {isDataPresent,donutData:jsonDonutData} = this.generateDonutData(this.props,this.props.data,this.props.events);
    // this.renderDonut('#donutChart',jsonDonutData,isDataPresent)
    if (this.props.src === 'bike'){
      // console.log('bike----->')
      // console.log(this.props.batteryData);
      let { graphData, tickFormat } = this.generateGraphJsonData(this.props,this.props.batteryData,'BATTERY');
      // console.log(graphData);
      this.renderGraph(graphData, tickFormat, '#chartBattery');

    } else if (this.props.src ==='site') {
      let {graphData,tickFormat} = this.generateGraphJsonData(this.props,this.props.heartbeatGraphData,'SITE_HEARTBEAT');
      this.renderBarGraph(graphData,tickFormat,"#chartSiteHb");
    }
    // {this.props.batteryData == undefined || this.props.batteryData.length == 0 && <div><CircularProgress/></div>}
    let {isDataPresent,donutData:jsonDonutData} = this.generateDonutData(this.props.eventWiseCountData);
    this.renderDonut('#donutChart',jsonDonutData,isDataPresent)
    return (
      <div>
        {/* <Divider/> */}
        {this.props.src ==='bike' && <h4>Battery Graph</h4>}
        {this.props.src === 'bike' && this.props.batteryData.length == 0 && <>No Result found</>}
        {this.props.src === 'bike' && this.props.batteryData.length >  0 && <div id="chartBattery"></div>}
        {this.props.src ==='site' && <h4>Site Graph</h4>}
        {this.props.src === 'site' && this.props.heartbeatGraphData.length == 0  && <>No Result found</>}
        {this.props.src === 'site' && this.props.heartbeatGraphData.length > 0  && <div id="chartSiteHb"></div>}
        {this.props.loading && <div style={{textAlign: "center", justifyContent: 'center'}}><CircularProgress/></div>}
        <Divider/>
        <h4>Event Graph</h4>
        {this.props.data.length > 0 && <div id="chart"></div>}
        {this.props.data.length == 0 && <>No Result found</>}
        <Divider/>
        <h4>Event Count-Wise Graph</h4>
        {this.props.eventWiseCountData.length > 0 && <div id="donutChart"></div>}
        {this.props.eventWiseCountData.length == 0 && <>No Result found</>}
        {this.props.loading && <div style={{textAlign: "center", justifyContent: 'center'}}><CircularProgress/></div>}
      </div>
    );
  }
}

export default GraphView;
