import React from 'react';
import { createUseStyles } from 'react-jss';
import CellEditSimCard from 'components/Cells/CellEditSimCard';
import styles from './styles';

const useStyles = createUseStyles(styles);

const CellSimCard = ({ row }) => {
  const classes = useStyles();
  
  return !!row.original.sim_card && (
    <CellEditSimCard
      id={row.original.sim_card.id}
      status={row.original.sim_card.status}
    >
      {row.original.sim_card.iccid}
    </CellEditSimCard>
  );
};

export default CellSimCard;
