import { getReservationStatusColor} from 'utils/helpers/colorGetters';
import {COLOR_DEFAULT_TEXT, COLOR_SECONDARY} from 'utils/constants/colors';

export default {
    wrapper: {
        backgroundColor: ({ type }) => getReservationStatusColor(type),
        color: COLOR_SECONDARY,
        borderRadius: 4,
        fontSize: 14,
        width: 126,
        height: 31,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    textCenter: {
        color: COLOR_DEFAULT_TEXT,
        borderRadius: 4,
        fontSize: 14,
        width: 126,
        height: 31,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    }
};
