import React from 'react';
import EvStationIcon from '@material-ui/icons/EvStation';
import Marker from '../Marker';

const MarkerStation = ({
  color,
  href,
 }) => {
  return <Marker
    color={color}
    href={href}
    Icon={EvStationIcon}
  />
};

export default MarkerStation;
